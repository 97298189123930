import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Button from '@components/ui/Button';
import Text from '@components/ui/Text';
import { ArrowLeftIcon, GoogleTextIcon } from '@src/hoc/withIconStyles';
import { isSuperDMApp, isValidEmail, shouldShowWebviewErrorModal } from '@utils/common';
import useWindowSize from '@hooks/useWindow';
import useFetch from '@hooks/useFetch';
import { generateOTP, loginUser } from '@api/auth';
import { ICallSignUpFuncParams, ILoginStepProps, TLoginStepStates } from './LoginStep.types';
import useGoogleOAuth from '@hooks/useGoogleOAuth';
import mixpanelActions from '@utils/mixpanel';
import { ACCESS_TOKEN, mainFiltersArr, mixPanelEvents } from '@utils/constants';
import { AppDispatch } from '@store/index';
import { useDispatch } from 'react-redux';
import { getUserDetails } from '@api/user';
import { IUserLoginPayload } from '@api/auth/auth.types';
import { setLocalStorage } from '@utils/localStorage';
import { setUserData, setUserDraftData } from '@store/userDetailsSlice/userDetailsSlice';
import { isProUserFunc } from '@src/models/user';
import { setMainFilters } from '@store/inboxSlice/inboxSlice';
import DotsLoader from '@components/ui/DotsLoader';
import classes from './LoginStep.styles.module.scss';

const OTP_LENGTH = 6;

function LoginStep({
	withAnimation,
	receiverUsername,
	receiverName,
	initialEmail,
	onAuthDataAvailable,
	setLoadingState,
	message,
	selectedInboxCategory,
}: ILoginStepProps) {
	const { isMobile } = useWindowSize();
	const dispatch = useDispatch<AppDispatch>();
	const { token, openGoogleOAuthScreen } = useGoogleOAuth(true);

	const [inputEmailState, setInputEmailState] = useState({
		value: initialEmail ?? '',
		error: '',
	});
	const [otpState, setOtpState] = useState({
		value: Array(OTP_LENGTH).fill(''),
		error: '',
	});

	const [loginState, setLoginState] = useState<TLoginStepStates>(
		initialEmail ? 'OPT_VERIFY' : 'LOGIN'
	);
	const [currFocusedIndex, setCurrFocusedIndex] = useState<number | null>(null);

	const emailInputRef = useRef<HTMLInputElement | null>(null);
	const otpInputsRef = useRef<(HTMLInputElement | null)[]>(Array(OTP_LENGTH).fill(null));
	const otpWithDotsRef = useRef<(HTMLDivElement | null)[]>(Array(OTP_LENGTH).fill(null));
	const divToScrollEnd = useRef<HTMLDivElement | null>(null);

	const { callApi: callGenerateOTP, status: callGenerateOTPStatus } = useFetch(generateOTP);
	const {
		callApi: signUp,
		status: signUpStatus,
		errorStatus: signUpErrorStatus,
	} = useFetch(loginUser);
	const { callApi: fetchUserDetails, status: fetchUserDetailsStatus } = useFetch(getUserDetails);

	const isLoading =
		signUpStatus === 'loading' ||
		fetchUserDetailsStatus === 'loading' ||
		callGenerateOTPStatus === 'loading';

	const shouldHideGoogleBtn = shouldShowWebviewErrorModal(isMobile) || isSuperDMApp();

	const isEmailInputError = !!inputEmailState.error;
	const isOtpInputError = !!otpState.error;

	const displayContinueBtn = inputEmailState.value.length > 0;

	const handleGoogleBtnClick = () => {
		if (isLoading) return;
		mixpanelActions.trackOnboarding(mixPanelEvents.INTRO_PAGE_GOOGLE_BTN_CLICK, receiverUsername);

		openGoogleOAuthScreen();
	};

	const handleInputEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (isLoading) return;
		setInputEmailState({
			value: e.target.value,
			error: '',
		});
	};

	const handleInputEmailKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (isLoading) return;
		if (e.key === 'Enter') {
			handleContinue();
		}
	};

	const handleBack = () => {
		if (isLoading) return;
		setOtpState({
			error: '',
			value: Array(OTP_LENGTH).fill(''),
		});
		setLoginState('LOGIN');
		onAuthDataAvailable(null, null, null);
	};

	const handleContinue = async () => {
		if (isLoading) return;
		mixpanelActions.trackOnboarding(
			mixPanelEvents.INTRO_PAGE_EMAIL_PROCEED_CLICKED,
			receiverUsername ?? '',
			'',
			'',
			inputEmailState.value
		);

		emailInputRef.current?.focus();
		const inputEmail = inputEmailState.value;

		if (!isValidEmail(inputEmail)) {
			setInputEmailState((prevState) => {
				return {
					...prevState,
					error: `That doesn’t seem right`,
				};
			});
			return;
		}

		try {
			await callGenerateOTP(inputEmail);
			setLoginState('OPT_VERIFY');
			mixpanelActions.trackOnboarding(mixPanelEvents.INTRO_PAGE_OTP_SHOWN, receiverUsername);
			onAuthDataAvailable(inputEmail, null, null);
		} catch (error) {
			setInputEmailState((prevState) => {
				return {
					...prevState,
					error: error,
				};
			});
		}
	};

	const handleOTPChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		if (isLoading) return;
		const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters

		let newOTP = [...otpState.value];

		if (value.length === OTP_LENGTH) {
			newOTP = value.split('');
		} else {
			const digitValueToAdd = value.slice(-1);
			newOTP[index] = digitValueToAdd;
			!!digitValueToAdd && otpInputsRef.current[index + 1]?.focus();
		}

		if (newOTP.join('').length === OTP_LENGTH) {
			handleVerifyOtp(newOTP);
		}

		setOtpState({
			value: newOTP,
			error: '',
		});
	};

	const handleVerifyOtp = (otpValue?: string[]) => {
		if (isLoading) return;
		const otpDigitsValue = otpValue ? otpValue.join('') : otpState.value.join('');

		const otpFocusIndex =
			otpDigitsValue.length === OTP_LENGTH ? otpDigitsValue.length - 1 : otpDigitsValue.length;

		otpInputsRef.current[otpFocusIndex]?.focus();

		if (otpDigitsValue.length !== OTP_LENGTH) {
			setOtpState((prevState) => {
				return {
					...prevState,
					error: `That doesn’t seem right`,
				};
			});
			return;
		}

		mixpanelActions.trackOnboarding(
			mixPanelEvents.INTRO_PAGE_EMAIL_OTP_SUBMITTED,
			receiverUsername
		);

		const formattedOTP = Number(otpDigitsValue);

		callSignUp({ email: inputEmailState.value, otp: formattedOTP });
	};

	const handleOTPKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
		if (isLoading) return;
		if (e.key === 'Enter') {
			handleVerifyOtp();
		}

		if (e.key === 'Backspace') {
			!otpState.value[index] && otpInputsRef.current[index - 1]?.focus();
		}
	};

	const callSignUp = async ({ email, otp, googleOAuthCode }: ICallSignUpFuncParams) => {
		if (isLoading) return;

		const payload: IUserLoginPayload = {
			email: email ?? null,
			login_type: googleOAuthCode ? 'GOOGLE' : 'EMAIL',
			otp: otp ?? null,
			remember_me: true,
			token_id: googleOAuthCode ?? null,
			receiver_username: receiverUsername,
			content: message,
			label: selectedInboxCategory,
		};

		try {
			const resData = await signUp(payload);
			const jwtToken = resData?.jwtToken ?? null;
			const draftJWTToken = resData?.draftJWTToken ?? null;

			if (jwtToken) {
				setLocalStorage(ACCESS_TOKEN, jwtToken);
				const data = await fetchUserDetails(jwtToken);
				if (data) {
					mixpanelActions.identifyUserAndSetEmail({
						email: data.email,
						username: data.username,
						userType: data.userType,
						userID: data.userId,
					});
					mixpanelActions.registerSecondaryUser(
						data.isSecondaryUser ? 'yes' : 'no',
						data.secondaryUserEmail
					);
					dispatch(setUserData({ userData: data ? { ...data } : null }));
					isProUserFunc(data) &&
						dispatch(
							setMainFilters({
								filtersToPrepend: [...mainFiltersArr],
							})
						);
				}
			} else {
				dispatch(setUserData({ userData: resData?.userData ? { ...resData.userData } : null }));
				dispatch(
					setUserDraftData({
						userDraftData: resData?.draftUser ? { ...resData.draftUser } : null,
						draftJWTToken: draftJWTToken,
					})
				);
			}

			onAuthDataAvailable(email ?? null, googleOAuthCode ?? null, otp ?? null);
		} catch (error) {
			// handle error
			if (signUpErrorStatus === 403) {
				setOtpState((prevState) => {
					return {
						...prevState,
						error: 'Too many attempts!',
					};
				});
				return;
			}

			setOtpState((prevState) => {
				return {
					...prevState,
					error: error,
				};
			});
		}
	};

	useEffect(() => {
		if (!token) return;
		callSignUp({ googleOAuthCode: token });
	}, [token]);

	useEffect(() => {
		setLoadingState(isLoading);
	}, [isLoading]);

	useEffect(() => {
		const handleResize = () => {
			const otpDigitsValue = otpState.value.join('');

			if (otpDigitsValue.length > 0 && otpDigitsValue.length < OTP_LENGTH - 1) return;

			// for ios
			window.scrollTo({
				behavior: 'instant',
				top: document.documentElement.scrollHeight - (window.visualViewport?.height ?? 0),
			});

			const ele = document.getElementById('root');

			// for android
			ele?.scrollIntoView({ behavior: 'smooth', block: 'end' });
		};

		window.visualViewport?.addEventListener('resize', handleResize);

		return () => {
			window.visualViewport?.removeEventListener('resize', handleResize);
		};
	}, [otpState]);

	return (
		<div
			className={clsx(
				classes.loginStepContainer,
				withAnimation && classes.animateLoginStepContainer,
				!withAnimation && classes.loginContainerHeight,
				isLoading && classes.loginStepContainerLoadingState
			)}
		>
			{isLoading && (
				<div className={classes.loadingContainer}>
					<DotsLoader customDesktopSize={60} customMobileSize={60} />
				</div>
			)}

			<Text variant="h2" lineHeight={2} semiBold>
				{loginState === 'LOGIN' && 'Add your email'}
				{loginState === 'OPT_VERIFY' && `Enter the OTP`}
			</Text>

			{loginState === 'LOGIN' && (
				<Text
					variant="p"
					tiny
					light
					tertiary
					lineHeight={1.6}
					customClass={classes.subText}
				>{`You’ll be notified here when ${receiverName} replies.`}</Text>
			)}
			{loginState === 'OPT_VERIFY' && (
				<div className={classes.mailTextContainer}>
					<Text
						variant="p"
						tiny
						light
						tertiary
						lineHeight={1.6}
						customClass={classes.subText}
					>{`OTP sent to ${inputEmailState.value}`}</Text>
					<Button
						btnText={
							<Text variant="span" tiny light tertiary lineHeight={1.6}>
								{'Change'}
							</Text>
						}
						onClick={handleBack}
						customClass={classes.backBtn}
					/>
				</div>
			)}
			{loginState === 'LOGIN' && (
				<div ref={divToScrollEnd}>
					{!shouldHideGoogleBtn && (
						<div className={classes.googleBtnContainer}>
							<Button
								btnText={
									<Text variant="span" fontSize={1.3} lineHeight={1.7} semiBold white>
										{'Continue with Google'}
									</Text>
								}
								onClick={handleGoogleBtnClick}
								prefixIcon={<GoogleTextIcon size={1.6} />}
								customClass={classes.googleBtn}
								primary
							/>
							<Text variant="span" tertiaryV0 tiny lineHeight={1.4}>
								{'or'}
							</Text>
						</div>
					)}

					<div
						className={clsx(
							classes.inputWithBtn,
							inputEmailState.value.length > 0 && classes.inputWithBtnActiveState
						)}
					>
						<input
							type="email"
							className={classes.input}
							value={inputEmailState.value}
							onChange={handleInputEmailChange}
							placeholder={'Enter your email...'}
							onKeyDown={handleInputEmailKeyDown}
							autoFocus={shouldHideGoogleBtn || !!inputEmailState.value}
							ref={emailInputRef}
							autoComplete="off"
						/>
						{displayContinueBtn && (
							<Button
								btnText={<ArrowLeftIcon size={2} className={classes.arrowIcon} />}
								onClick={handleContinue}
								customClass={classes.continueBtn}
								primary
							/>
						)}
					</div>
					<Text variant="p" error lineHeight={1.6} light tiny customClass={classes.errorText}>
						{isEmailInputError && inputEmailState.error}
					</Text>
				</div>
			)}
			{loginState === 'OPT_VERIFY' && (
				<div ref={divToScrollEnd}>
					<div className={classes.otpInputsContainer}>
						{otpState.value.map((otpDigit, index) => {
							return (
								<div
									key={index}
									className={clsx(
										classes.otpInputWithDot,
										!otpDigit && index !== currFocusedIndex && classes.otpInputInitialState
									)}
									ref={(el) => {
										otpWithDotsRef.current[index] = el;
									}}
								>
									<input
										type="text"
										className={clsx(classes.otpInput, !!otpDigit && classes.otpInputActiveState)}
										onChange={(e) => handleOTPChange(e, index)}
										value={otpDigit}
										onKeyDown={(e) => handleOTPKeyDown(e, index)}
										inputMode="numeric"
										autoFocus={index === 0}
										onFocus={() => setCurrFocusedIndex(index)}
										ref={(el) => {
											otpInputsRef.current[index] = el;
										}}
										autoComplete="off"
									/>
								</div>
							);
						})}
					</div>

					<Text variant="p" error lineHeight={1.6} light tiny customClass={classes.errorText}>
						{isOtpInputError && otpState.error}
					</Text>
				</div>
			)}
		</div>
	);
}

export default LoginStep;
