import React, { StrictMode } from 'react';
import { Provider } from 'react-redux';
import {
	Route,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements,
	redirect,
} from 'react-router-dom';
import { store } from './store';
import RootLayout from '@components/RootLayout';
import InboxLayout from '@components/InboxLayout';
import '@styles/global.scss';
import GoogleCallback from '@components/GoogleCallback';
import LinkedInCallback from '@components/LinkedInCallback';
import PageNotFound from '@components/PageNotFound';
import ShareableLinkPage from '@components/ShareableLinkPage';
import {
	ALL_PATH,
	GET_EARLY_ACCESS,
	GOOGLE_CALLBACK_PATH,
	INBOX_PATH,
	LINKEDIN_CALLBACK_PATH,
	MAIN_PATH,
	PAGE_NOT_FOUND_PATH,
	PROFILE_PATH,
	SELECTED_CHAT_USER_NAME_PATH,
	SELECTED_PROFILE_USER_ID,
	SETTINGS,
	USERNAME_PATH,
	INTERNAL_ERROR,
	ACCOUNT_UNDER_REVIEW,
	INBOX_SEARCH_PATH,
	INBOX_SEARCH_PATH_DESKTOP,
	OVERVIEW_PATH,
	GOOGLE_CALLBACK_PATH_ONBOARDING,
	DISCOVER_PATH,
} from '@utils/constants';
import Landing from '@components/Landing';
import Profile from '@components/Profile';
import ErrorBoundary from '@components/ErrorBoundary';
import JoinWaitList from '@components/JoinWaitList';
import Settings from '@components/Settings';
import VpnError from '@components/VpnError';
import AccountUnderReview from '@components/AccountUnderReview';
import InboxSearchPage from '@components/MobilePages/InboxSearchPage';
import SearchPage from '@components/SearchPage';
import OverviewPage from '@components/OverviewPage';
import ChatRedirect from '@components/ChatRedirect';
import VerifyLinkedinProvider from '@components/VerifyLinkedInProvider';
import GoogleCallbackOnboarding from '@components/GoogleCallbackOnboarding';
import DiscoverPage from '@components/DiscoverPage';
import ScrollableLayout from '@components/ScrollableLayout';
import LeavingModalProvider from '@components/LeavingModalProvider';

const appRouter = createBrowserRouter(
	createRoutesFromElements(
		<>
			<Route
				path={MAIN_PATH}
				element={
					<LeavingModalProvider>
						<VerifyLinkedinProvider>
							<RootLayout />
						</VerifyLinkedinProvider>
					</LeavingModalProvider>
				}
				errorElement={<ErrorBoundary />}
			>
				<Route index element={<Landing />} />
				<Route path={INBOX_PATH} element={<InboxLayout />} />
				<Route path={`${INBOX_PATH}/${SELECTED_CHAT_USER_NAME_PATH}`} element={<ChatRedirect />} />
				<Route path={INBOX_SEARCH_PATH} element={<InboxSearchPage />} />
				<Route path={INBOX_SEARCH_PATH_DESKTOP} element={<SearchPage />} />
				<Route path={PAGE_NOT_FOUND_PATH} element={<PageNotFound />} />
				<Route path={INTERNAL_ERROR} element={<VpnError />} />
				<Route path={OVERVIEW_PATH} element={<OverviewPage />} />
				<Route path={LINKEDIN_CALLBACK_PATH} element={<LinkedInCallback />} />

				{/* scrollable common layout routes */}
				<Route element={<ScrollableLayout />}>
					<Route path={PROFILE_PATH + '/' + SELECTED_PROFILE_USER_ID} element={<Profile />} />
					<Route path={SETTINGS} element={<Settings />} />
					<Route path={ACCOUNT_UNDER_REVIEW} element={<AccountUnderReview />} />
					<Route path={GET_EARLY_ACCESS} element={<JoinWaitList withLayout />} />
				</Route>

				<Route path={DISCOVER_PATH} element={<DiscoverPage />} />

				<Route path={USERNAME_PATH} element={<ShareableLinkPage />} />
			</Route>
			<Route path={GOOGLE_CALLBACK_PATH} element={<GoogleCallback />} />
			<Route path={GOOGLE_CALLBACK_PATH_ONBOARDING} element={<GoogleCallbackOnboarding />} />
			<Route path={ALL_PATH} loader={() => redirect(PAGE_NOT_FOUND_PATH)} />
		</>
	)
);

function App() {
	return (
		<StrictMode>
			<Provider store={store}>
				<RouterProvider router={appRouter} />
			</Provider>
		</StrictMode>
	);
}

export default App;
