import React from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MainLayoutHeader from '@components/MainLayout/MainLayoutHeader';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import { isPendingMessageSentFunc } from '@src/models/user';
import VerifyLinkedInAlertTopHeader from '@components/VerifyLinkedInAlertTopHeader';
import classes from './ScrollableLayout.styles.module.scss';

function ScrollableLayout() {
	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);

	const showVerifyLinkedInAlert = isPendingMessageSentFunc(userDetails);

	return (
		<div className={classes.scrollableLayout}>
			{showVerifyLinkedInAlert && <VerifyLinkedInAlertTopHeader />}
			<header className={classes.mainHeaderContainer}>
				<MainLayoutHeader />
			</header>
			<main className={classes.mainContainer}>
				<Outlet />
			</main>
		</div>
	);
}

export default ScrollableLayout;
