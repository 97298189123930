import React, { useMemo } from 'react';
import clsx from 'clsx';
import * as Switch from '@radix-ui/react-switch';
import Text from '@components/ui/Text';
import { FilterCloseIcon } from '@src/hoc/withIconStyles';
import { ISingleClickSelectProps } from './SingleClickSelect.types';
import Button from '../Button';
import classes from './SingleClickSelect.styles.module.scss';
import useWindowSize from '@hooks/useWindow';

function SingleClickSelect<T>({
	header,
	onSelectOption,
	optionsMap,
	selectedOptionId,
	getOptionId,
	onClickSelect,
	checkSelectedOptionActive,
	filterView,
}: ISingleClickSelectProps<T>) {
	const { isMobile } = useWindowSize();

	const handleValueChange = () => {
		onClickSelect && onClickSelect();

		if (!optionsMap) return;

		const options = Object.values(optionsMap);
		const optionToSelect = options.find((option) => getOptionId(option) !== selectedOptionId);

		if (!optionToSelect) return;

		onSelectOption(optionToSelect, true);
	};

	const isSelectedOption = useMemo(() => {
		if (!optionsMap || !selectedOptionId) return false;

		const selectedOption = optionsMap[selectedOptionId];

		if (!selectedOption) return false;

		return checkSelectedOptionActive(selectedOption);
	}, [selectedOptionId, optionsMap]);

	if (filterView === 'SWITCH') {
		return (
			<div className={classes.switchContainer}>
				{!isMobile && (
					<Text variant="span" small lineHeight={2} color="#808487">
						{header}
					</Text>
				)}
				<Switch.Root
					className={classes.switchRoot}
					checked={isSelectedOption}
					onCheckedChange={handleValueChange}
				>
					<Switch.Thumb className={classes.switchThumb} />
				</Switch.Root>
				{isMobile && (
					<Text variant="span" semiBold fontSize={1.1} lineHeight={1} color="#808487">
						{header}
					</Text>
				)}
			</div>
		);
	}

	return (
		<Button
			onClick={handleValueChange}
			btnText={<Text variant="span">{header}</Text>}
			suffixIcon={
				<>
					{isSelectedOption && (
						<FilterCloseIcon
							size={1.4}
							className={clsx(
								classes.singleClickSelectCloseIcon,
								isSelectedOption && classes.singleClickSelectCloseIconActive
							)}
						/>
					)}
				</>
			}
			customClass={clsx(
				classes.singleClickSelectTrigger,
				isSelectedOption && classes.singleClickSelectTriggerActive
			)}
		/>
	);
}

export default SingleClickSelect;
