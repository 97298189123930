import React, { useState, useMemo, useRef, useEffect } from 'react';
import * as Popover from '@radix-ui/react-popover';
import Text from '@components/ui/Text';
import { QuicKReplyFilledIcon } from '@src/hoc/withIconStyles';
import classes from './PopoverLayoutDesktop.styles.module.scss';
import EditSendView from '@components/CannedResponseMobile/EditSendView';
import EmptyState from '@components/CannedResponseMobile/EmptyState';
import ListView from '@components/CannedResponseMobile/ListView';
import CannedResponseModal from '@components/Chats/ChatsSection/CannedResponseModal';
import Modal from '@components/ui/Modal';
import { useSelector } from 'react-redux';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import {
	ICannedResponseDesktopProps,
	TCannedResponseDesktopModalState,
} from './PopoverLayoutDesktop.types';
import { IUserCannedResponse } from '@api/user/user.types';
import mixpanelActions from '@utils/mixpanel';
import { mixPanelEvents } from '@utils/constants';

function PopoverLayoutDesktop({
	senderFirstName,
	onSendBtnClick,
	isChatBookmarked,
	maxCharLimit,
	minCharLimit,
	onSelectCannedResponse,
	onCloseModal,
}: ICannedResponseDesktopProps) {
	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);
	const [selectedCannedResponse, setSelectedCannedResponse] = useState<IUserCannedResponse | null>(
		null
	);
	const [open, setOpen] = useState(false);
	const [modalState, setModalState] =
		useState<TCannedResponseDesktopModalState>('EMPTY_STATE_VIEW');
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [showManageCannedResponseModal, setShowManageCannedResponseModal] = useState(false);

	const popoverTriggerRef = useRef<HTMLButtonElement | null>(null);

	const userId = userDetails?.userId;

	const cannedResponses = useMemo(() => {
		return userDetails?.cannedResponses ?? [];
	}, [userDetails]);

	const openPopover = () => {
		if (cannedResponses.length > 0) {
			setOpen(true);
		} else {
			setOpenModal(true);
			setModalState('EMPTY_STATE_VIEW');
		}
	};

	const handleOnSelectCannedResponse = (cannedResponse: IUserCannedResponse) => {
		setOpenModal(true);
		setModalState('EDIT_SEND_VIEW');
		setOpen(false);
		onSelectCannedResponse && onSelectCannedResponse(cannedResponse);
		setSelectedCannedResponse(cannedResponse);
	};

	const handleAddOrEditClicked = () => {
		mixpanelActions.trackQuickReply(mixPanelEvents.QUICK_REPLY_SHEET_ADD_OR_EDIT_CLICKED);
		setSelectedCannedResponse(null);
		setShowManageCannedResponseModal(true);
		setModalState('ADD_OR_EDIT_VIEW');
	};

	const handleSendCannedResponse = (text: string, shouldBookmark: boolean) => {
		mixpanelActions.trackQuickReply(mixPanelEvents.QUICK_REPLY_SHEET_CONTINUE_CLICKED);
		closeSendModal();
		onSendBtnClick(text, shouldBookmark);
	};

	const handleCloseManageCannedResponseModal = async () => {
		setShowManageCannedResponseModal(false);
		onCloseCannedResponse();
		if (cannedResponses.length > 0) {
			setOpen(true);
		}
	};

	const onCloseCannedResponse = () => {
		setShowManageCannedResponseModal(false);
	};

	const handleAddClick = () => {
		mixpanelActions.trackQuickReply(mixPanelEvents.QUICK_REPLY_SHEET_ADD_CLICKED);
		setSelectedCannedResponse(null);
		setShowManageCannedResponseModal(true);
		setModalState('ADD_OR_EDIT_VIEW');
		setOpenModal(!openModal);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
		onCloseModal();
		setOpen(true);
	};

	const closeSendModal = () => {
		setOpenModal(false);
		onCloseModal();
		setOpen(false);
	};

	useEffect(() => {
		const obsEle = popoverTriggerRef.current;

		if (!obsEle) return;

		const obsOptions = {
			root: null,
			rootMargin: '-220px 0px -10px 0px',
			threshold: 0.1,
		};

		const obsCallback = (entries: IntersectionObserverEntry[]) => {
			const [entry] = entries;

			if (!entry.isIntersecting && open) {
				setOpen(false);
			}
		};

		const observer = new IntersectionObserver(obsCallback, obsOptions);

		observer.observe(obsEle);

		return () => {
			if (!obsEle) return;

			observer.unobserve(obsEle);
		};
	}, [open]);

	return (
		<>
			<Popover.Root open={open} onOpenChange={setOpen}>
				<Popover.Trigger asChild ref={popoverTriggerRef}>
					<button
						className={classes.quickReplyBtnDesktop}
						onClick={(e) => {
							e.stopPropagation();
							openPopover();
						}}
					>
						<QuicKReplyFilledIcon size={1.6} />
						<Text variant="span" small brandPrimaryColor semiBold customClass={classes.replyText}>
							{'Quick reply'}
						</Text>
					</button>
				</Popover.Trigger>
				<Popover.Portal>
					{open && cannedResponses.length > 0 && (
						<Popover.Content align="end" side="bottom" className={classes.popoverContent}>
							<ListView
								cannedResponses={cannedResponses}
								onClickCannedResponse={handleOnSelectCannedResponse}
								onClickAddOrEdit={handleAddOrEditClicked}
								senderFirstName={senderFirstName}
							/>
						</Popover.Content>
					)}
				</Popover.Portal>
			</Popover.Root>

			{openModal && (
				<Modal
					onCloseModal={handleCloseModal}
					showModal={openModal}
					bottomInMobile
					noPadding
					customClass={classes.modalContainer}
				>
					{modalState === 'EMPTY_STATE_VIEW' && (
						<EmptyState onClickAdd={handleAddClick} onClickCancel={handleCloseModal} />
					)}

					{modalState === 'EDIT_SEND_VIEW' && (
						<EditSendView
							isChatBookmarked={isChatBookmarked}
							onClickSend={handleSendCannedResponse}
							selectedCannedResponse={selectedCannedResponse}
							senderFirstName={senderFirstName}
							maxCharLimit={maxCharLimit}
							minCharLimit={minCharLimit}
							onClickBack={handleCloseModal}
							showCloseIcon={true}
						/>
					)}
				</Modal>
			)}

			{modalState === 'ADD_OR_EDIT_VIEW' && showManageCannedResponseModal && (
				<CannedResponseModal
					cannedResponseData={userDetails?.cannedResponses ?? []}
					userId={userId}
					showModal={showManageCannedResponseModal}
					onCloseModal={handleCloseManageCannedResponseModal}
				/>
			)}
		</>
	);
}

export default PopoverLayoutDesktop;
