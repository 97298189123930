import React, { useEffect } from 'react';
import ChatsHeader from './ChatsHeader';
import ChatsFooter from './ChatsFooter';
import ChatsContent from './ChatsContent';
import classes from './ChatsSection.styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchChatMessages,
	getSelectedChatDetailsFromGlobalState,
	setCurrChatMessages,
} from '@store/selectedChatSlice/selectedChatSlice';
import { AppDispatch } from '@store/index';
import useFetch from '@hooks/useFetch';
import { markChatAsRead, pollChatMessages } from '@api/chats';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import { IPollMessagesPayload } from '@api/chats/chats.types';
import {
	getInboxDataFromGlobalState,
	setCurrChatsDataSelectedChatVisited,
	setCurrReadChatsMap,
	setReadChatActionCount,
} from '@store/inboxSlice/inboxSlice';
import { getFullNameSenderProfileFunc } from '@src/models/inbox';
import Text from '@components/ui/Text';
import Avatar from '@components/ui/Avatar';
import FallbackPicIcon from '@public/icons/fallbackPicIcon.svg';

function ChatsSection() {
	const dispatch = useDispatch<AppDispatch>();
	const { currReadChatsMap } = useSelector(getInboxDataFromGlobalState);
	const { selectedChat, allChatMessages } = useSelector(getSelectedChatDetailsFromGlobalState);
	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);
	const { callApi: callMarkChatAsRead } = useFetch(markChatAsRead);
	const { callApi: callPollChatMessages } = useFetch(pollChatMessages);

	const stickyNameContainerRef = React.useRef<HTMLDivElement | null>(null);
	const chatsSectionRef = React.useRef<HTMLDivElement | null>(null);

	const chatId = selectedChat?.chatId ?? null;
	const senderId = selectedChat?.senderDetails?.userId ?? null;
	const currUserId = userDetails?.userId ?? null;
	const currUsername = userDetails?.username ?? '';
	const chatRead = currReadChatsMap[selectedChat?.chatId ?? -1] ?? selectedChat?.read ?? true;
	const lastMessageId = allChatMessages[allChatMessages.length - 1]?.id;
	const senderProfileDetails = selectedChat?.senderDetails?.profileData;
	const senderFullName = senderProfileDetails
		? getFullNameSenderProfileFunc(senderProfileDetails)
		: '';
	const senderProfilePicURL = senderProfileDetails?.profilePicture;

	useEffect(() => {
		const callApis = chatId && senderId && currUserId && currUsername;

		if (!callApis) return;

		(async function () {
			try {
				dispatch(
					fetchChatMessages({
						chatId: chatId,
						username: currUsername,
						userId: currUserId,
					})
				);
				if (!chatRead) {
					await callMarkChatAsRead({
						chat_id: chatId,
						sender_id: senderId,
						user_id: currUserId,
						read: true,
					});
					dispatch(setReadChatActionCount({ chatRead: true, chatId: chatId }));
					dispatch(setCurrReadChatsMap({ chatId: chatId, chatRead: true }));
				}
			} catch (error) {
				// handle error
			}
		})();
	}, [chatId, senderId, currUserId, currUsername]);

	useEffect(() => {
		const callPoll = !!senderId && !!currUserId && !!lastMessageId;

		let shouldPoll = true;

		if (!callPoll) return;

		let abortController: AbortController;

		const payload: IPollMessagesPayload = {
			last_message_id: lastMessageId,
			receiver_id: currUserId,
			sender_id: senderId,
		};

		(async function poll() {
			abortController = new AbortController();
			if (shouldPoll) {
				try {
					const res = await callPollChatMessages(payload, abortController.signal);
					if ((res?.messages ?? []).length > 0) {
						dispatch(setCurrChatMessages({ polledChatMessages: res.messages, chatId: res.chatId }));
					}
					poll();
				} catch (error) {
					// handle error
				}
			}
		})();

		return () => {
			shouldPoll = false;
			if (abortController) {
				abortController.abort();
			}
		};
	}, [senderId, currUserId, lastMessageId]);

	useEffect(() => {
		dispatch(setCurrChatsDataSelectedChatVisited({ isVisited: true }));
	}, []);

	useEffect(() => {
		const handleResize = () => {
			const visualViewportOffsetTop = window.visualViewport?.offsetTop ?? 0;
			const visualViewportHeight = window.visualViewport?.height ?? 0;
			const viewportHeight = document.getElementById('root')?.scrollHeight ?? 0;

			const stickyNameContainerEle = stickyNameContainerRef.current;

			if (!stickyNameContainerEle) return;

			const stickyNameContainerTopPos = Math.round(viewportHeight - visualViewportHeight + 16);

			if (
				visualViewportHeight < viewportHeight &&
				visualViewportOffsetTop >= viewportHeight - visualViewportHeight
			) {
				stickyNameContainerEle.classList.add(classes.showStickyName);
				stickyNameContainerEle.style.top = `${stickyNameContainerTopPos}px`;
			} else {
				stickyNameContainerEle.classList.remove(classes.showStickyName);
				stickyNameContainerEle.style.top = `${stickyNameContainerTopPos}px`;
			}
		};

		window.visualViewport?.addEventListener('resize', handleResize);
		window.visualViewport?.addEventListener('scroll', handleResize);

		return () => {
			window.visualViewport?.removeEventListener('resize', handleResize);
			window.visualViewport?.removeEventListener('scroll', handleResize);
		};
	}, []);

	return (
		<section className={classes.chatsSection} ref={chatsSectionRef}>
			<ChatsHeader />
			<ChatsContent />
			<ChatsFooter />
			<div className={classes.stickyNameContainer} ref={stickyNameContainerRef}>
				<Avatar
					size={2}
					profilePicURL={senderProfilePicURL}
					fallbackIcon={FallbackPicIcon}
					customRootClass={classes.profileImage}
				/>
				<Text variant="h3" fontSize={1.3} lineHeight={2}>
					{senderFullName}
				</Text>
			</div>
		</section>
	);
}

export default ChatsSection;
