import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import Text from '@components/ui/Text';
import ExperienceFallbackIcon from '@public/icons/experienceFallbackIcon.svg';
import EducationFallbackIcon from '@public/icons/educationFallbackIcon.svg';
import {
	ChevronRightIcon,
	EmployeeIcon,
	GraduateIcon,
	HighlyFollowIcon,
} from '@src/hoc/withIconStyles';
import { ITagsChevronState, ITagsProps } from './Tags.types';
import classes from './Tags.styles.module.scss';
import Avatar from '@components/ui/Avatar';
import mixpanelActions from '@utils/mixpanel';
import { mixPanelEvents } from '@utils/constants';
import Button from '@components/ui/Button';
import useWindowSize from '@hooks/useWindow';

function Tags({
	tags,
	onTouchStart,
	onTouchEnd,
	customClassName,
	tagsWrapperCustomClassName,
	cardView,
}: ITagsProps) {
	const { isMobile } = useWindowSize();

	const cardTagsChevronRef = useRef<ITagsChevronState>({
		chevronLeftDiv: null,
		chevronRightDiv: null,
		currScrolledPos: 0,
		scrollableContainer: null,
		scrollableContainerWrapper: null,
		leftBoxShadowRef: null,
		rightBoxShadowRef: null,
	});

	const cardTagsScrolled = useRef<boolean>(false);

	const handleTagsScroll = () => {
		handleTagsDivScroll();
		if (cardTagsScrolled.current) return;
		cardTagsScrolled.current = true;
		mixpanelActions.trackCardTagsScroll(mixPanelEvents.CARD_TAGS_SCROLLED, 'NEW', cardView);
	};

	const handleTouchStart = () => {
		onTouchStart && onTouchStart();
	};

	const handleTouchEnd = () => {
		onTouchEnd && onTouchEnd();
	};

	const handleClickLeftChevronBtn = () => {
		if (isMobile) return;
		const scrollEle = cardTagsChevronRef.current.scrollableContainer;
		const scrollEleWrapper = cardTagsChevronRef.current.scrollableContainerWrapper;
		const scrollPos = cardTagsChevronRef.current.currScrolledPos;

		if (!scrollEle || !scrollEleWrapper) return;

		if (scrollPos <= 0) return;

		const scrollToUpdate = Math.ceil(scrollPos - scrollEleWrapper.scrollWidth);

		scrollEle.scrollTo({ behavior: 'smooth', left: scrollToUpdate });
		cardTagsChevronRef.current.currScrolledPos = scrollToUpdate;
	};

	const handleClickRightChevronBtn = () => {
		if (isMobile) return;
		const scrollEle = cardTagsChevronRef.current.scrollableContainer;
		const scrollEleWrapper = cardTagsChevronRef.current.scrollableContainerWrapper;
		const scrollPos = cardTagsChevronRef.current.currScrolledPos;

		if (!scrollEle || !scrollEleWrapper) return;

		const scrollToUpdate = Math.ceil(scrollPos + scrollEleWrapper.scrollWidth);

		if (scrollToUpdate > scrollEle.scrollWidth) return;

		scrollEle.scrollTo({ behavior: 'smooth', left: scrollToUpdate });
		cardTagsChevronRef.current.currScrolledPos = scrollToUpdate;
	};

	const handleTagsDivMouseEnter = () => {
		if (isMobile) return;

		const scrollEle = cardTagsChevronRef.current.scrollableContainer;
		const chevronLeft = cardTagsChevronRef.current.chevronLeftDiv;
		const chevronRight = cardTagsChevronRef.current.chevronRightDiv;
		const scrollPos = cardTagsChevronRef.current.currScrolledPos;
		const scrollEleWrapper = cardTagsChevronRef.current.scrollableContainerWrapper;

		if (!scrollEle || !scrollEleWrapper) return;

		const shouldShowRightChevron = scrollEleWrapper.scrollWidth + scrollPos < scrollEle.scrollWidth;

		shouldShowRightChevron
			? chevronRight?.classList.add(classes.showChevron)
			: chevronRight?.classList.remove(classes.showChevron);

		const shouldShowLeftChevron = scrollPos > 0;
		shouldShowLeftChevron
			? chevronLeft?.classList.add(classes.showChevron)
			: chevronLeft?.classList.remove(classes.showChevron);
	};

	const handleTagsDivMouseLeave = () => {
		if (isMobile) return;
		const chevronLeft = cardTagsChevronRef.current.chevronLeftDiv;
		const chevronRight = cardTagsChevronRef.current.chevronRightDiv;

		chevronRight?.classList.remove(classes.showChevron);
		chevronLeft?.classList.remove(classes.showChevron);
	};

	const handleTagsDivScroll = () => {
		const leftBoxShadow = cardTagsChevronRef.current.leftBoxShadowRef;
		const rightBoxShadow = cardTagsChevronRef.current.rightBoxShadowRef;
		const scrollEleWrapper = cardTagsChevronRef.current.scrollableContainerWrapper;
		const scrollEle = cardTagsChevronRef.current.scrollableContainer;

		if (!leftBoxShadow || !rightBoxShadow || !scrollEleWrapper || !scrollEle) return;

		if (scrollEle.scrollLeft > 0) {
			leftBoxShadow.classList.add(classes.showBoxShadow);
		} else {
			leftBoxShadow.classList.remove(classes.showBoxShadow);
		}

		if (
			Math.round(scrollEle.scrollLeft) + Math.round(scrollEleWrapper.clientWidth) <
			Math.round(scrollEle.scrollWidth)
		) {
			rightBoxShadow.classList.add(classes.showBoxShadow);
		} else {
			rightBoxShadow.classList.remove(classes.showBoxShadow);
		}

		if (isMobile) return;
		cardTagsChevronRef.current.currScrolledPos = Math.ceil(scrollEle.scrollLeft);
		handleTagsDivMouseEnter();
	};

	useEffect(() => {
		const scrollEle = cardTagsChevronRef.current.scrollableContainer;
		const scrollEleWrapper = cardTagsChevronRef.current.scrollableContainerWrapper;
		const rightBoxShadow = cardTagsChevronRef.current.rightBoxShadowRef;

		if (!scrollEle || !scrollEleWrapper || !rightBoxShadow) return;

		if (scrollEle.scrollWidth > scrollEleWrapper.clientWidth) {
			rightBoxShadow.classList.add(classes.showBoxShadow);
		}
	}, [isMobile]);

	return (
		<div
			className={clsx(
				classes.tagsContainerWithChevron,
				!!tagsWrapperCustomClassName && tagsWrapperCustomClassName
			)}
			ref={(el) => {
				cardTagsChevronRef.current.scrollableContainerWrapper = el;
			}}
			onMouseEnter={handleTagsDivMouseEnter}
			onMouseLeave={handleTagsDivMouseLeave}
		>
			<div
				className={classes.leftBoxShadow}
				ref={(el) => {
					cardTagsChevronRef.current.leftBoxShadowRef = el;
				}}
			/>
			<div
				className={classes.chevronLeft}
				ref={(el) => {
					cardTagsChevronRef.current.chevronLeftDiv = el;
				}}
			>
				<Button
					btnText={<ChevronRightIcon size={1.2} className={classes.icon} />}
					onClick={handleClickLeftChevronBtn}
				/>
			</div>
			<div
				className={clsx(classes.tagsContainer, !!customClassName && customClassName)}
				onScroll={handleTagsScroll}
				onTouchStart={handleTouchStart}
				onTouchEnd={handleTouchEnd}
				ref={(el) => {
					cardTagsChevronRef.current.scrollableContainer = el;
				}}
			>
				{tags.map((tag, index) => {
					const displayText = tag.text;

					const fallbackIcon =
						tag.type === 'COMPANY'
							? ExperienceFallbackIcon
							: tag.type === 'EDUCATION'
							? EducationFallbackIcon
							: null;

					const imageUrl = tag.imageURL;

					const isIconTag = tag.type === 'ICON';
					const isImageTag = tag.type !== 'ICON';

					const IconToDisplay =
						tag.icon === 'HIGHLY_EXPERIENCED' ? (
							<EmployeeIcon size={1.6} />
						) : tag.icon === 'LINKEDIN_HIGHLY_FOLLOWED' ? (
							<HighlyFollowIcon size={1.6} />
						) : tag.icon === 'RECENT_GRADUATE' ? (
							<GraduateIcon size={1.6} />
						) : null;

					const isTagCompanyOrEducation = tag.type === 'COMPANY' || tag.type === 'EDUCATION';

					return (
						<div
							className={clsx(
								classes.tagContainer,
								isTagCompanyOrEducation && classes.tagContainerCompanyOrEducation
							)}
							key={index}
						>
							{isIconTag && IconToDisplay}
							{isImageTag && (
								<div className={classes.avatarContainer}>
									<Avatar
										size={1.6}
										profilePicURL={imageUrl ?? undefined}
										fallbackIcon={fallbackIcon ?? undefined}
									/>
								</div>
							)}
							<Text variant="p" tiny lineHeight={1.6}>
								{displayText}
							</Text>
						</div>
					);
				})}
			</div>
			<div
				className={classes.chevronRight}
				ref={(el) => {
					cardTagsChevronRef.current.chevronRightDiv = el;
				}}
			>
				<Button
					btnText={<ChevronRightIcon size={1.2} className={classes.icon} />}
					onClick={handleClickRightChevronBtn}
				/>
			</div>
			<div
				className={classes.rightBoxShadow}
				ref={(el) => {
					cardTagsChevronRef.current.rightBoxShadowRef = el;
				}}
			/>
		</div>
	);
}

export default Tags;
