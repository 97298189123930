export const getAmPmTimeFromUTC = (utcTimeStamp: string) => {
	if (!utcTimeStamp) return;
	const date = new Date(utcTimeStamp);

	// Format the date in AM/PM format in the current time zone
	const amPmTime = date.toLocaleTimeString('en-US', {
		hour: '2-digit',
		minute: '2-digit',
		hour12: true,
	});

	return amPmTime;
};

export const isSameDayTime = (utcTimeStamp1?: string, utcTimeStamp2?: string) => {
	if (!utcTimeStamp1 || !utcTimeStamp2) return;
	const date1 = new Date(utcTimeStamp1).setSeconds(0, 0);
	const date2 = new Date(utcTimeStamp2).setSeconds(0, 0);

	return date1 === date2;
};

export const isTodayTime = (utcTimestamp: string) => {
	if (!utcTimestamp) return;

	const dateInUTC = new Date(utcTimestamp).setHours(0, 0, 0, 0);
	const todayDateInUTC = new Date().setHours(0, 0, 0, 0);

	return dateInUTC === todayDateInUTC;
};

export const getMonthDayFromUTC = (utcTimeStamp: string) => {
	if (!utcTimeStamp) return;

	const date = new Date(utcTimeStamp);

	//Format the date in month(MMM) Day(DD) in the current time zone
	const monthDay = date.toLocaleDateString('en-US', {
		month: 'short',
		day: '2-digit',
	});

	return monthDay;
};

export const getDayMonthFromUTC = (utcTimeStamp: string) => {
	if (!utcTimeStamp) return;

	const date = new Date(utcTimeStamp);

	// Format the date in Day(DD) Month(MMM) in the current time zone
	const dayMonth = date.toLocaleDateString('en-GB', {
		day: '2-digit',
		month: 'short',
	});

	return dayMonth;
};

export const getMinutesDiffFromUTC = (utcTimestamp: string) => {
	if (!utcTimestamp) return;
	const prevTime = new Date(utcTimestamp);
	const currentTime = new Date();

	// Check if both timestamps are on the same day
	if (
		currentTime.getFullYear() !== prevTime.getFullYear() ||
		currentTime.getMonth() !== prevTime.getMonth() ||
		currentTime.getDate() !== prevTime.getDate()
	) {
		return undefined;
	}

	// Calculate the time difference in milliseconds
	const timeDifference = currentTime.getTime() - prevTime.getTime();

	// Convert milliseconds to minutes
	const minutesPassed = Math.floor(timeDifference / (1000 * 60));

	return minutesPassed;
};

export const getMonthNameFromNumber = (monthNumber: number) => {
	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];

	// Ensure the monthNumber is within a valid range (0-11)
	return months[monthNumber - 1] ?? '';
};

interface IDateObj {
	year: number;
	month: number;
}

export const getMonthsDifference = (
	startDate: IDateObj | null,
	endDate: IDateObj | null
): number => {
	if (!startDate) return 0;

	const startYear: number = startDate.year;
	const startMonth: number = startDate.month;

	// If endDate is not provided, default to the current month and year
	const currentDate: Date = new Date();
	const endYear: number = endDate?.year ?? currentDate.getFullYear();
	const endMonth: number = endDate?.month ?? currentDate.getMonth() + 1;

	return (endYear - startYear) * 12 + (endMonth - startMonth) + 1;
};

export const getMonthYearText = (
	startMonth?: number | null,
	startYear?: number | null,
	endMonth?: number | null,
	endYear?: number | null,
	showPresentText?: boolean
) => {
	const startMonthYear =
		startMonth || startYear
			? `${startMonth ? getMonthNameFromNumber(startMonth) : ''} ${startYear ?? ''}`.trim()
			: '';
	const endMonthYear =
		endMonth || endYear
			? `${endMonth ? getMonthNameFromNumber(endMonth) : ''} ${endYear ?? ''}`.trim()
			: '';

	const endMonthYearText = endMonthYear
		? ` - ${endMonthYear}`
		: `${showPresentText ? ' - Present' : ''}`;

	return startMonthYear ? `${startMonthYear}${endMonthYearText}` : '';
};

export const getTotalYearsMonthText = (months: number) => {
	if (!months) return '';

	const monthsModular = months % 12;
	const years = Math.floor(months / 12);

	const yearsText = `${years} ${years > 1 ? 'yrs' : 'yr'}`;
	const monthsText = `${monthsModular} ${monthsModular > 1 ? 'mos' : 'mo'}`;

	if (months < 12) return monthsText;

	if (!monthsModular) return yearsText;
	return `${yearsText} ${monthsText}`;
};

export const parseSyncTimestamp = (timestamp: string | Date): Date | null => {
  if (!timestamp) return null;
  
  if (timestamp instanceof Date) {
    return isNaN(timestamp.getTime()) ? null : timestamp;
  }
  
  const date = new Date(timestamp);
  return isNaN(date.getTime()) ? null : date;
};

export const getMonthsSinceLastSync = (lastSyncTimestamp: string | Date): number => {
  const lastSyncDate = parseSyncTimestamp(lastSyncTimestamp);
  if (!lastSyncDate) return 0;

  const currentDate = new Date();
  
  return getMonthsDifference(
    { year: lastSyncDate.getFullYear(), month: lastSyncDate.getMonth() + 1 },
    { year: currentDate.getFullYear(), month: currentDate.getMonth() + 1 }
  );
};