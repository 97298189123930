import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { CHAT_SEARCH_PARAM, INBOX_PATH } from '@utils/constants';

function ChatRedirect() {
	const { selectedChatUsername } = useParams();

	return <Navigate to={`${INBOX_PATH}?${CHAT_SEARCH_PARAM}=${selectedChatUsername}`} replace />;
}

export default ChatRedirect;
