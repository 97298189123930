import React, { useEffect, useMemo, useState } from 'react';
import { Navigate } from 'react-router';
import Text from '@components/ui/Text';
import classes from './Landing.styles.module.scss';
import Button from '@components/ui/Button';
import {
	ACCESS_TOKEN,
	ACCOUNT_UNDER_REVIEW,
	DISCOVER_PATH,
	EARLY_ACCESS_FORM,
	GOOGLE_OAUTH_TOKEN_PARAM,
	INBOX_PATH,
	REDIRECT_URL,
	mainFiltersArr,
	mixPanelEvents,
} from '@utils/constants';
import { getHomePageDiscoveryList, loginUser } from '@api/auth';
import useFetch from '@hooks/useFetch';
import { getUserDetails } from '@api/user';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/index';
import { setLocalStorage } from '@utils/localStorage';
import {
	getUserDetailsFromGlobalState,
	setHomePageDiscoveryList,
	setUserData,
} from '@store/userDetailsSlice/userDetailsSlice';
import { setMainFilters } from '@store/inboxSlice/inboxSlice';
import Toast from '@components/ui/Toast';
import { IRefProps } from '@components/ui/Toast/Toast.types';
import { AccountNotFoundIcon, ArrowDownIconV2, LogoTextIcon } from '@src/hoc/withIconStyles';
import mixpanelActions from '@utils/mixpanel';
import AuthModal from '@components/AuthModal';
import { IAuthModalProps } from '@components/AuthModal/AuthModal.types';
import { IUserLoginPayload } from '@api/auth/auth.types';
import {
	getFullNameUserMetaDataFunc,
	isAccountBlockedFunc,
	isAccountPendingFunc,
	isAccountRejectedFunc,
	isAccountVerifiedFunc,
	isProUserFunc,
} from '@src/models/user';
import { useSearchParams, useNavigate } from 'react-router-dom';
import useWindowSize from '@hooks/useWindow';
import DiscoveryCard from '@components/DiscoverPage/DiscoveryCard';
import Loader from '@components/ui/Loader';
import clsx from 'clsx';

function Landing() {
	const navigate = useNavigate();
	const { isMobile } = useWindowSize();
	const toastRef = React.useRef<IRefProps>(null);

	const [showSignInModal, setShowSignInModal] = useState(false);
	const [modalToastError, setModalToastError] = useState('');

	const dispatch = useDispatch<AppDispatch>();

	const [searchParams, setSearchParams] = useSearchParams();

	const { data: userDetails, homePageDiscoveryList } = useSelector(getUserDetailsFromGlobalState);

	const {
		callApi: login,
		status: loginStatus,
		errorStatus: loginErrorStatus,
	} = useFetch(loginUser);
	const { callApi: fetchUserDetails, status: fetchUserDetailsStatus } = useFetch(getUserDetails);
	const { callApi: callHomePageDiscoveryList } = useFetch(getHomePageDiscoveryList);

	const redirectURL = decodeURIComponent(searchParams.get(REDIRECT_URL) ?? '');
	const googleOAuthToken = React.useRef(
		decodeURIComponent(searchParams.get(GOOGLE_OAUTH_TOKEN_PARAM) ?? '')
	);

	const userId = userDetails?.userId;

	const listToDisplay = useMemo(() => {
		if (!homePageDiscoveryList?.length) return [];

		return homePageDiscoveryList[0].items.slice(0, 3);
	}, [homePageDiscoveryList]);

	const onClickJoinWaitList = () => {
		mixpanelActions.trackLandingPage(mixPanelEvents.LANDING_PAGE_EARLY_ACCESS_CLICKED);
		window.open(EARLY_ACCESS_FORM, '_blank');
	};

	const onClickSignIn = () => {
		mixpanelActions.trackLandingPage(mixPanelEvents.LANDING_PAGE_SIGN_IN_CLICKED);
		setShowSignInModal(true);
	};

	const isLoadingSignIn = loginStatus === 'loading' || fetchUserDetailsStatus === 'loading';

	const handleOnClickPrivacy = () => {
		window.open(`${window.location.origin}/privacy`, '_blank');
	};
	const handleOnClickTermsAndConditions = () => {
		window.open(`${window.location.origin}/tnc`, '_blank');
	};

	const handleModalSignInSuccess: IAuthModalProps['onSuccessAuth'] = async ({
		googleOAuthCode,
		email,
		otp,
	}) => {
		toastRef.current?.unPublish();
		setModalToastError('');
		const payload: IUserLoginPayload = {
			email: email ?? null,
			login_type: googleOAuthCode ? 'GOOGLE' : 'EMAIL',
			otp: otp ?? null,
			remember_me: true,
			token_id: googleOAuthCode ?? null,
		};

		try {
			const resData = await login(payload);
			const jwtToken = resData?.jwtToken ?? null;

			if (jwtToken) {
				setLocalStorage(ACCESS_TOKEN, jwtToken);
				const data = await fetchUserDetails(jwtToken);
				if (data) {
					mixpanelActions.identifyUserAndSetEmail({
						email: data.email,
						username: data.username,
						userType: data.userType,
						userID: data.userId,
					});
					mixpanelActions.registerSecondaryUser(
						data.isSecondaryUser ? 'yes' : 'no',
						data.secondaryUserEmail
					);
					dispatch(setUserData({ userData: data ? { ...data } : null }));
					isProUserFunc(data) &&
						dispatch(
							setMainFilters({
								filtersToPrepend: [...mainFiltersArr],
							})
						);
				}
			} else {
				toastRef.current?.publish();
			}
			setShowSignInModal(false);
			// can check draft user condition check to show model to alert user to register instead of login
		} catch (error) {
			// handle error

			if (loginErrorStatus === 403) {
				setModalToastError('Too many attempts!');
				return;
			}

			setModalToastError(error);
		}
	};

	const handleCloseModal = () => {
		setShowSignInModal(false);
		setModalToastError('');
	};

	const handleSeeAllUsers = () => {
		mixpanelActions.trackLandingPage(mixPanelEvents.LANDING_PAGE_SEE_ALL_CLICKED);
		navigate(DISCOVER_PATH);
	};

	useEffect(() => {
		if (googleOAuthToken.current) {
			handleModalSignInSuccess({ googleOAuthCode: googleOAuthToken.current });
			setSearchParams((prevSearchParams) => {
				const searchParamsToSet = new URLSearchParams(prevSearchParams);
				searchParamsToSet.delete(GOOGLE_OAUTH_TOKEN_PARAM);
				return searchParamsToSet;
			});
		}
	}, []);

	useEffect(() => {
		if (userId) return;

		mixpanelActions.trackLandingPage(mixPanelEvents.LANDING_PAGE_OPENED, undefined, false);

		if (homePageDiscoveryList) return;

		(async function () {
			try {
				const resData = await callHomePageDiscoveryList();

				dispatch(setHomePageDiscoveryList({ homePageDiscoveryList: resData }));
			} catch (error) {
				// handle error
			}
		})();
	}, [homePageDiscoveryList, userId]);

	if (!!userId && (isAccountRejectedFunc(userDetails) || isAccountBlockedFunc(userDetails))) {
		return <Navigate to={ACCOUNT_UNDER_REVIEW} replace />;
	}

	if (!!userId && (isAccountVerifiedFunc(userDetails) || isAccountPendingFunc(userDetails))) {
		window.history.replaceState({ idx: 0 }, '');

		return redirectURL ? (
			<Navigate to={redirectURL} replace />
		) : (
			<Navigate to={INBOX_PATH} replace />
		);
	}

	return (
		<div className={classes.landingPageLayout}>
			<header className={classes.headerContainer}>
				<LogoTextIcon size={isMobile ? 2.4 : 4} />
				<div className={classes.headerBtnsContainer}>
					<Button
						btnText={
							<Text
								variant="span"
								tertiary
								tiny={isMobile}
								lineHeight={isMobile ? 1.4 : 2.2}
							>{`Terms`}</Text>
						}
						onClick={handleOnClickTermsAndConditions}
					/>
					<Button
						btnText={
							<Text
								variant="span"
								tertiary
								tiny={isMobile}
								lineHeight={isMobile ? 1.4 : 2.2}
							>{`Privacy`}</Text>
						}
						onClick={handleOnClickPrivacy}
					/>
				</div>
			</header>
			<main className={classes.mainContainer}>
				<div
					className={clsx(
						classes.loginContainer,
						!!googleOAuthToken.current && classes.loginContainerNoAnimation
					)}
				>
					<Text
						variant="h1"
						semiBold
						fontSize={isMobile ? 4 : 6.2}
						lineHeight={isMobile ? 4.8 : 8.6}
					>
						{'Manage DMs like never before.'}
					</Text>
					<Text
						variant="p"
						lineHeight={isMobile ? 2.2 : 2.8}
						fontSize={isMobile ? 1.8 : 2.4}
						tertiary
					>
						{'Less clutter. More control.'}
					</Text>
					<div className={classes.loginBtnsContainer}>
						<Button
							btnText={
								<Text variant="span" semiBold white lineHeight={2.2}>
									{'Get early access'}
								</Text>
							}
							onClick={onClickJoinWaitList}
							customClass={classes.earlyAccessBtn}
						/>
						<Button
							btnText={
								<Text variant="span" semiBold brandPrimaryColor lineHeight={2.2}>
									{'Sign in'}
								</Text>
							}
							onClick={onClickSignIn}
							customClass={classes.signInBtn}
							customLoader={<Loader size={2} />}
							isLoading={isLoadingSignIn}
						/>
					</div>
				</div>

				{!!listToDisplay.length && (
					<div
						className={clsx(
							classes.usersListContainer,
							!!googleOAuthToken.current && classes.usersListContainerNoAnimation
						)}
					>
						<div className={classes.listHeaderContainer}>
							<Text
								variant="h2"
								lineHeight={isMobile ? 2.4 : 2.8}
								fontSize={isMobile ? 1.8 : 2.2}
								secondary
							>
								{'Trusted by the best'}
							</Text>
							<Button
								btnText={
									<Text
										variant="span"
										tertiary
										small={isMobile}
										light={isMobile}
										lineHeight={isMobile ? 2 : 2.2}
									>
										{'See all'}
									</Text>
								}
								suffixIcon={
									<ArrowDownIconV2 size={isMobile ? 1.6 : 2} className={classes.chevronIcon} />
								}
								onClick={handleSeeAllUsers}
							/>
						</div>
						<div className={classes.usersList}>
							{listToDisplay.map((user) => (
								<DiscoveryCard
									bio={user.bio}
									fullName={getFullNameUserMetaDataFunc(user)}
									profilePicURL={user.imageURL}
									updatesTextList={user.socialProof}
									username={user.username}
									key={user.username}
									onClickSendDM={() => {
										mixpanelActions.trackDiscover({
											eventName: mixPanelEvents.LANDING_PAGE_DISCOVERY_CARD_CLICKED,
											chatUsername: user.username,
										});
									}}
									hideSendDMButton
									customRootClass={classes.cardCustomClass}
								/>
							))}
						</div>
					</div>
				)}
			</main>

			{showSignInModal && (
				<AuthModal
					modalHeader={'Sign in to SuperDM'}
					onCloseModal={handleCloseModal}
					showModal={showSignInModal}
					authType="LOGIN"
					onSuccessAuth={handleModalSignInSuccess}
					modalError={modalToastError}
					isLoadingAuth={isLoadingSignIn}
					onClickContinueEmailBtn={(inputEmail) => {
						mixpanelActions.trackLandingPage(
							mixPanelEvents.LANDING_PAGE_EMAIL_PROCEED_CLICKED,
							inputEmail
						);
					}}
					onClickGoogleBtn={() => {
						mixpanelActions.trackLandingPage(mixPanelEvents.LANDING_PAGE_GOOGLE_BTN_CLICK);
					}}
					onOTPInputShown={() => {
						mixpanelActions.trackLandingPage(mixPanelEvents.LANDING_PAGE_OTP_SHOWN);
					}}
					onOTPSubmitted={() => {
						mixpanelActions.trackLandingPage(mixPanelEvents.LANDING_PAGE_EMAIL_OTP_SUBMITTED);
					}}
				/>
			)}
			<Toast
				ref={toastRef}
				toastType="ERROR"
				header="No Account Found"
				content="To create account, apply for early access"
				icon={<AccountNotFoundIcon size={1.8} className={classes.toastIcon} />}
			/>
		</div>
	);
}
export default Landing;
