import {
	GOOGLE_CALLBACK_PATH,
	GOOGLE_CALLBACK_PATH_ONBOARDING,
	GOOGLE_OAUTH_STATE_PARAM_VALUE,
	LINKEDIN_CALLBACK_PATH,
	LINKEDIN_OAUTH_STATE_PARAM_VALUE,
} from './constants';

export const getURLQueryStringFromObj = (obj: { [key: string]: string }) => {
	const params = [];
	for (const [key, value] of Object.entries(obj)) {
		const queryStringParam = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
		params.push(queryStringParam);
	}
	return params.join('&');
};

export const getGoogleOAuthURL = (isOnboarding?: boolean) => {
	const options = {
		redirect_uri: `${window.location.origin}${
			isOnboarding ? GOOGLE_CALLBACK_PATH_ONBOARDING : GOOGLE_CALLBACK_PATH
		}`,
		client_id: process.env.GOOGLE_CLIENT_ID as string,
		response_type: 'token',
		scope: [
			'https://www.googleapis.com/auth/userinfo.profile',
			'https://www.googleapis.com/auth/userinfo.email',
		].join(' '),
		include_granted_scopes: 'true',
		state: GOOGLE_OAUTH_STATE_PARAM_VALUE,
	};

	return `${process.env.GOOGLE_OAUTH_ROOT_URL}?${getURLQueryStringFromObj(options)}`;
};

export const getLinkedInOAuthURL = () => {
	const options = {
		response_type: 'code',
		client_id: process.env.LINKEDIN_CLIENT_ID as string,
		redirect_uri: `${window.location.origin}${LINKEDIN_CALLBACK_PATH}`,
		scope: ['profile', 'email', 'openid'].join(' '),
		state: LINKEDIN_OAUTH_STATE_PARAM_VALUE,
	};

	return `${process.env.LINKEDIN_OAUTH_ROOT_URL}/authorization?${getURLQueryStringFromObj(
		options
	)}`;
};
