import React, { ForwardedRef, useCallback, useEffect, useImperativeHandle } from 'react';
import clsx from 'clsx';
import { PanInfo, motion, useAnimation, useMotionValue, useTransform } from 'framer-motion';
import Text from '@components/ui/Text';
import {
	EyeClosedIcon,
	SwipeLeftIcon,
	SwipeRightIcon,
	TickBoldIcon,
} from '@src/hoc/withIconStyles';
import useWindowSize from '@hooks/useWindow';
import {
	ISwipeableChatCardProps,
	ISwipeActionsRefObj,
	TSwipeDirection,
} from './SwipeableChatCard.type';
import classes from './SwipeableChatCard.styles.module.scss';
import Button from '@components/ui/Button';
import { mixPanelEvents } from '@utils/constants';
import mixpanelActions from '@utils/mixpanel';

function SwipeableChatCard(
	{
		onCardDismiss,
		children,
		customClassName,
		isSwipedCard,
		isTopCard,
		isTopSecondCard,
		onClickCard,
		swipeMode,
		swipedCardDirection,
		displayFirstTimeEducation,
		onCloseFirstTimeEducation,
	}: ISwipeableChatCardProps,
	ref: ForwardedRef<ISwipeActionsRefObj>
) {
	const { width } = useWindowSize();
	const [velocity, setVelocity] = React.useState(0);
	const [swipeDirection, setSwipeDirection] = React.useState<TSwipeDirection | null>(null);
	const feedbackContainerRef = React.useRef<HTMLDivElement | null>(null);
	const swipeableChatCardRef = React.useRef<HTMLDivElement | null>(null);

	const displaySwipeEducation = displayFirstTimeEducation && isTopCard;

	const swipeThreshold = width * 0.5;
	const velocityThreshold = 500;

	const xDistanceToSwipe = width;
	const xDistanceToSwipeScale = 1.5;
	const swipedCardXDistanceToSwipeScale = 1.4;
	const translateXThreshold = 45;
	const rotateThreshold = 15;

	const initialOpacityValue = isTopSecondCard ? 0 : 1;
	const initialScaleValue = isTopSecondCard ? 0.94 : 1;

	const controls = useAnimation();
	const x = useMotionValue(0.0001);

	// swipe animation values
	const rotate = useTransform(
		x,
		[-swipeThreshold, 0, swipeThreshold],
		[rotateThreshold, 0, -rotateThreshold]
	);
	const translateX = useTransform(
		x,
		[-swipeThreshold, 0, swipeThreshold],
		[-translateXThreshold, 0, translateXThreshold]
	);

	// feedback animation values
	const leftFeedbackBackgroundColor = 'rgba(5, 104, 203, 0.96)';
	const rightFeedbackBackgroundColor = 'rgba(2, 158, 102, 0.96)';

	const feedbackBackgroundColor = useTransform(
		x,
		[-swipeThreshold, swipeThreshold],
		[leftFeedbackBackgroundColor, rightFeedbackBackgroundColor]
	);
	const feedbackOpacity = useTransform(x, [-swipeThreshold, 0, swipeThreshold], [1, 0, 1]);

	const feedbackText = swipeDirection === 'left' ? 'Keep unread' : 'Mark as read';
	const FeedbackIcon = swipeDirection === 'left' ? EyeClosedIcon : TickBoldIcon;

	const resetToInitialState = () => {
		controls.start({
			opacity: 1,
			translateY: isTopCard
				? 0
				: -((swipeableChatCardRef.current?.getBoundingClientRect().height || 0) * 0.03) - 8,
			x: 0,
			scale: isTopCard ? 1 : 0.94,
			boxShadow: isTopCard
				? `0px 0px 10px 4px rgba(97, 94, 94, 0.08)`
				: isTopSecondCard
				? `0px 0px 10px 0px rgba(97, 94, 94, 0.16)`
				: 'none',
			transition: { duration: 0.25 },
		});
	};

	const swipeCardToInitialState = () => {
		x.set(
			(swipedCardDirection === 'right' ? xDistanceToSwipe : -xDistanceToSwipe) *
				swipedCardXDistanceToSwipeScale
		);
	};

	const handleOnPanEndAnimation = useCallback(
		(XDistance: number) => {
			controls.start({
				x: (XDistance < 0 ? -xDistanceToSwipe : xDistanceToSwipe) * xDistanceToSwipeScale,
				translateY: 0,
				transition: { duration: 0.35 },
			});
		},
		[controls, xDistanceToSwipe, xDistanceToSwipeScale]
	);

	const handleOnPan = (e: MouseEvent | TouchEvent | PointerEvent, pointInfo: PanInfo) => {
		if (!isTopCard) return;
		const { x: xOffset } = pointInfo.offset;

		const canSwipeCard = swipeMode && !displayFirstTimeEducation;

		if (canSwipeCard) {
			const xDistance = x.get();
			const velocityValueToUpdate = Math.abs(xOffset) > Math.abs(xDistance) ? x.getVelocity() : 0;
			x.set(xOffset);
			setVelocity(velocityValueToUpdate);
			setSwipeDirection(xOffset < 0 ? 'left' : 'right');
			const feedbackContainer = feedbackContainerRef.current;
			if (feedbackContainer) {
				feedbackContainer.classList.remove(classes.hideFeedbackContainer);
			}
		} else {
			resetToInitialState();
		}
	};

	const handleOnPanEnd = () => {
		if (!isTopCard) return;

		const xDistance = x.get();

		if (Math.abs(xDistance) > swipeThreshold || Math.abs(velocity) > velocityThreshold) {
			handleOnPanEndAnimation(xDistance);
			return;
		}

		resetToInitialState();
	};

	const handleOnClickCard = () => {
		if (!isTopCard || displayFirstTimeEducation) return;

		onClickCard && onClickCard();
	};

	const handleSwipeEducationClick = () => {
		mixpanelActions.trackCardSwipe(mixPanelEvents.CARD_SWIPE_EDUCATION_DONE);
		!!onCloseFirstTimeEducation && onCloseFirstTimeEducation();
	};

	useEffect(() => {
		const unSubscribeX = x.on('change', (value) => {
			if (Math.abs(value) === 0) {
				const feedbackContainer = feedbackContainerRef.current;
				if (feedbackContainer) {
					feedbackContainer.classList.add(classes.hideFeedbackContainer);
				}
			}

			if (Math.abs(value) >= xDistanceToSwipe * xDistanceToSwipeScale) {
				swipeDirection && onCardDismiss(swipeDirection);
			}
		});

		return () => {
			unSubscribeX();
		};
	}, [x, xDistanceToSwipe, swipeDirection, onCardDismiss]);

	useEffect(() => {
		if (isSwipedCard) {
			swipeCardToInitialState();
			setTimeout(() => {
				resetToInitialState();
			}, 0);
			return;
		}
		resetToInitialState();
	}, [isTopCard, isTopSecondCard, isSwipedCard]);

	useEffect(() => {
		if (displaySwipeEducation) {
			mixpanelActions.trackCardSwipe(mixPanelEvents.CARD_SWIPE_EDUCATION_SHOWN);
		}
	}, [displaySwipeEducation]);

	useImperativeHandle(
		ref,
		() => ({
			callSwipeAction: (distance: number, swipeDirection: TSwipeDirection) => {
				handleOnPanEndAnimation(distance);
				setSwipeDirection(swipeDirection);
				const feedbackContainer = feedbackContainerRef.current;
				if (feedbackContainer) {
					feedbackContainer.classList.remove(classes.hideFeedbackContainer);
				}
			},
		}),
		[handleOnPanEndAnimation]
	);

	return (
		<motion.div
			onPan={handleOnPan}
			onPanEnd={handleOnPanEnd}
			style={{
				rotate: rotate,
				translateX: translateX,
				x: x,
				opacity: initialOpacityValue,
				scale: initialScaleValue,
			}}
			className={clsx(classes.swipeableChatCard, customClassName && customClassName)}
			animate={controls}
			onClick={handleOnClickCard}
			ref={swipeableChatCardRef}
		>
			<motion.div
				className={classes.feedbackContainer}
				style={{
					backgroundColor: feedbackBackgroundColor,
					opacity: feedbackOpacity,
					alignItems: swipeDirection === 'left' ? 'flex-end' : 'flex-start',
					textAlign: swipeDirection === 'left' ? 'right' : 'left',
				}}
				ref={feedbackContainerRef}
			>
				<FeedbackIcon size={3.2} className={classes.feedbackIcon} />
				<Text variant="p" fontSize={2.4} semiBold lineHeight={2.8} white>
					{feedbackText}
				</Text>
			</motion.div>

			{displaySwipeEducation && (
				<div className={classes.educationContainer}>
					<div className={classes.swipesContainer}>
						<div>
							<SwipeLeftIcon size={4} className={classes.swipeLeftIcon} />
							<Text variant="h3" semiBold lineHeight={2} color={'#4A6B89'}>
								{'Swipe left to keep unread'}
							</Text>
						</div>
						<div>
							<SwipeRightIcon size={4} className={classes.swipeRightIcon} />
							<Text variant="h3" semiBold lineHeight={2} color={'#4A6B89'}>
								{'Swipe right to mark as read'}
							</Text>
						</div>
					</div>
					<Button
						btnText={
							<Text variant="span" lineHeight={2.2} semiBold white>
								{'Got it'}
							</Text>
						}
						onClick={handleSwipeEducationClick}
						customClass={classes.educationButton}
					/>
				</div>
			)}

			{children}
		</motion.div>
	);
}

export default React.forwardRef(SwipeableChatCard);
