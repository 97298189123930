import React, { useEffect, useState } from 'react';
import Text from '@components/ui/Text';
import classes from './GoogleCallbackOnboarding.styles.module.scss';
import mixpanelActions from '@utils/mixpanel';
import {
	GOOGLE_OAUTH_ERROR_PARAM,
	GOOGLE_OAUTH_STATE_PARAM,
	GOOGLE_OAUTH_STATE_PARAM_VALUE,
	GOOGLE_OAUTH_STATE_PARAM_VALUE_OLD,
	GOOGLE_OAUTH_TOKEN_PARAM,
	mixPanelEvents,
} from '@utils/constants';
import DotsLoader from '@components/ui/DotsLoader';
import { ErrorIconV2 } from '@src/hoc/withIconStyles';
import useDelayedLoader from '@hooks/useDelayedLoader';

function GoogleCallbackOnboarding() {
	const [showError, setShowError] = useState(false);

	const isLoading = !showError;
	const showLoader = useDelayedLoader(isLoading);

	useEffect(() => {
		mixpanelActions.trackEvent(mixPanelEvents.GOOGLE_OAUTH_LOADING_SCREEN_SHOWN);
		const urlHashData = window.location.href.split('#')[1];
		const searchParams = new URLSearchParams(urlHashData);

		const state = searchParams.get(GOOGLE_OAUTH_STATE_PARAM);
		const error = searchParams.get(GOOGLE_OAUTH_ERROR_PARAM);
		const token = searchParams.get(GOOGLE_OAUTH_TOKEN_PARAM);

		// handling error case

		if (
			!window.opener ||
			(state !== GOOGLE_OAUTH_STATE_PARAM_VALUE && state !== GOOGLE_OAUTH_STATE_PARAM_VALUE_OLD) ||
			!!error
		) {
			mixpanelActions.trackGoogleOAuth(
				mixPanelEvents.GOOGLE_OAUTH_ERROR_SHOWN,
				error ?? !window.opener ? 'No parent window found' : 'state mismatch'
			);
			setShowError(true);
			return;
		}
		window.opener.postMessage({
			token: token,
		});

		window.close();
	}, []);

	const errorHeaderText = 'Something went wrong';
	const errorSubText = 'Please close the window and try again.';

	return (
		<main className={classes.callbackContainer}>
			{showLoader && <DotsLoader />}
			{!!showError && (
				<div className={classes.feedbackContainer}>
					<div className={classes.iconContainer}>
						<ErrorIconV2 size={9.6} />
					</div>

					<Text variant="h1" medium>
						{errorHeaderText}
					</Text>

					<Text variant="p" small light secondary>
						{errorSubText}
					</Text>
				</div>
			)}
		</main>
	);
}

export default GoogleCallbackOnboarding;
