import React, { useEffect, useMemo, useRef, useState } from 'react';
import Text from '@components/ui/Text';
import { createPortal } from 'react-dom';
import Button from '@components/ui/Button';
import {
	IChatActionsToastContext,
	IChatActionsToastProviderProps,
	IProviderToastState,
} from './ChatActionsToastProvider.types';
import { CloseIcon } from '@src/hoc/withIconStyles';
import { useLocation } from 'react-router-dom';
import classes from './ChatsActionsToastProvider.styles.module.scss';

export const ChatActionsToastContext = React.createContext<IChatActionsToastContext>({
	setToastData: () => {},
});

const TOAST_DURATION = 5000;

function ChatActionsToastProvider(props: IChatActionsToastProviderProps) {
	const [toastState, setToastState] = useState<IProviderToastState>({
		onClickToastAction: () => {},
		toastStatus: 'idle',
		toastText: '',
		actionText: '',
		chatId: null,
	});
	const [displayToast, setDisplayToast] = useState(false);
	const timerRef = useRef<NodeJS.Timeout>();
	const { pathname, search } = useLocation();

	const resetToastState = () => {
		timerRef.current && clearTimeout(timerRef.current);
		setDisplayToast(false);
		setToastState({
			actionText: '',
			onClickToastAction: () => {},
			toastStatus: 'idle',
			toastText: '',
			chatId: null,
		});
	};

	const setToastData = (updatedData: IProviderToastState) => {
		if (toastState.chatId !== updatedData.chatId) {
			timerRef.current && clearTimeout(timerRef.current);
		}

		setDisplayToast(true);

		setToastState((prevState) => {
			return {
				...prevState,
				...updatedData,
			};
		});

		const { toastStatus } = updatedData;

		if (toastStatus === 'success' || toastStatus === 'failed') {
			timerRef.current = setTimeout(() => {
				resetToastState();
			}, TOAST_DURATION);
		}
	};

	const handleCloseToast = () => {
		resetToastState();
	};

	const value = useMemo(() => {
		return {
			setToastData,
		};
	}, []);

	useEffect(() => {
		return () => {
			resetToastState();
		};
	}, [pathname, search]);

	const element = (
		<div className={classes.chatsToastRoot}>
			<div className={classes.chatActionToastContainer} key={toastState.chatId}>
				<Text variant="p" tiny white customClass={classes.statusText}>
					{toastState.toastText}
				</Text>
				{toastState.toastStatus === 'success' && (
					<Button
						btnText={
							<Text variant="span" tiny white>
								{toastState.actionText}
							</Text>
						}
						onClick={() => {
							toastState.onClickToastAction && toastState.onClickToastAction();
							resetToastState();
						}}
						customClass={classes.actionBtn}
					/>
				)}
				{(toastState.toastStatus === 'failed' || toastState.toastStatus === 'success') && (
					<Button
						onClick={handleCloseToast}
						btnText={<CloseIcon size={1.6} className={classes.closeIcon} />}
						customClass={classes.closeBtn}
					/>
				)}
			</div>
		</div>
	);

	const domEle = document.getElementById('modalRoot');

	return (
		<ChatActionsToastContext.Provider value={value}>
			{props.children}
			{displayToast && domEle && createPortal(element, domEle)}
		</ChatActionsToastContext.Provider>
	);
}

export default ChatActionsToastProvider;
