import { INewFilterOptionMap } from '@src/models/inbox';
import { generateExperienceFilterOptions } from './common';
import { Tabs } from '@src/models/user';

export const EARLY_ACCESS_FORM = `https://docs.google.com/forms/d/e/1FAIpQLSesS4QsE-gMUeGVjWaPJ6zf87f9dG7_AhM4eAbMJXt-LlM_0w/viewform`;

export const ACCESS_TOKEN = 'access_token';
export const REDIRECT_URL = 'redirectURL';
export const NOTIFICATION_DEVICE_TOKEN = 'notification_device_token';

// OAuth
export const oauthRedirectPath = 'path';

export const GOOGLE_OAUTH_TOKEN_PARAM = 'access_token';
export const GOOGLE_OAUTH_ERROR_PARAM = 'error';
export const GOOGLE_OAUTH_STATE_PARAM = 'state';
export const GOOGLE_OAUTH_STATE_PARAM_VALUE = 'google-oauth';
export const GOOGLE_OAUTH_STATE_PARAM_VALUE_OLD = 'pass-through value';

export const LINKEDIN_OAUTH_TOKEN_PARAM = 'code';
export const LINKEDIN_OAUTH_ERROR_PARAM = 'error';
export const LINKEDIN_OAUTH_STATE_PARAM = 'state';
export const LINKEDIN_OAUTH_STATE_PARAM_VALUE = 'linked-in-oauth';
export const LINKEDIN_OAUTH_ERROR_DESCRIPTION_PARAM = 'error_description';

// discovery page
export const CURRENT_CLICKED_DISCOVERY_USER = 'currentClickedDiscoveryUser';

// app update
export const WEB_VERSION_CODE = 'Web-Version-Code';
export const DEVICE_TYPE = 'Device-Type';

export const REFRESH_TIMESTAMP = 'refresh_timestamp';
// routes
export const MAIN_PATH = '/';
export const PAGE_NOT_FOUND_PATH = '/page-not-found';
export const INTERNAL_ERROR = '/error';
export const INBOX_PATH = '/message';
export const USERNAME_PATH = ':username';
export const GOOGLE_CALLBACK_PATH = '/google-callback';
export const GOOGLE_CALLBACK_PATH_ONBOARDING = '/google-callback-onboarding';
export const LINKEDIN_CALLBACK_PATH = '/linkedin-callback';
export const PROFILE_PATH = '/profile';
export const GET_EARLY_ACCESS = '/get-early-access';
export const SELECTED_CHAT_USER_NAME_PATH = ':selectedChatUsername';
export const SELECTED_PROFILE_USER_ID = ':selectedProfileUsername';
export const SETTINGS = '/settings';
export const ALL_PATH = '*';
export const ACCOUNT_UNDER_REVIEW = '/account-under-review';
export const INBOX_SEARCH_PATH = '/search';
export const INBOX_SEARCH_PATH_DESKTOP = '/search-message';
export const OVERVIEW_TAB_NAME = ':tabName';
export const OVERVIEW_PATH = `${OVERVIEW_TAB_NAME}/overview`;
export const DISCOVER_PATH = '/discover';

// filters
export const COUNTRY = 'country';
export const CITY = 'city';
export const FUNCTION = 'function';
export const COMPANY = 'company';
export const EDUCATION = 'education';
export const LABEL = 'label';
export const TAG = 'personal_tag';
export const TITLE = 'title';
export const EXPERIENCE_MIN = 'experience_min';
export const EXPERIENCE_MAX = 'experience_max';
export const SORT = 'sort_by';
export const ADVANCED = 'advanced';
export const MIN_MESSAGE_SCORE = 'min_message_score';
export const MIN_FOLLOWERS = 'min_followers';
export const MIN_SCORE = 'min_score';
export const BOOKMARK = 'bookmark';
export const UN_BOOKMARK = 'un_bookmark';
export const ARCHIVED = 'archived';
export const UN_ARCHIVED = 'un_archived';
export const TIME_PERIOD = 'time_period';
export const READ = 'read';
export const NOT_READ = 'not_read';
export const REPLIED = 'replied';
export const NOT_REPLIED = 'not_replied';
export const UNREAD = 'unread';
export const NOT_UNREAD = 'not_unread';
export const MAX_CREATION_TIMESTAMP = 'max_creation_timestamp';

export const filtersHeadingMappings: { [key: string]: string } = {
	[COUNTRY]: 'Country',
	[CITY]: 'City',
	[FUNCTION]: 'Job Function',
	[COMPANY]: 'Company',
	[EDUCATION]: 'Education',
	[LABEL]: 'Category',
	[TAG]: 'Label',
	[TITLE]: 'Job Title',
	[EXPERIENCE_MIN]: 'Min Experience',
	[EXPERIENCE_MAX]: 'Max Experience',
	[SORT]: 'Sorted by',
	[ADVANCED]: 'Advanced',
	[TIME_PERIOD]: 'Duration',
	[UNREAD]: 'Unread',
};

export const UNREAD_OPTIONS = {
	UNREAD: UNREAD,
	NOT_UNREAD: NOT_UNREAD,
};

export const UnreadOptions: INewFilterOptionMap = {
	[UNREAD_OPTIONS.UNREAD]: {
		count: 0,
		value: UNREAD_OPTIONS.UNREAD,
		isActiveOption: true,
	},
	[UNREAD_OPTIONS.NOT_UNREAD]: {
		count: 0,
		value: UNREAD_OPTIONS.NOT_UNREAD,
		isActiveOption: false,
	},
};

export const SORT_OPTIONS = {
	RECENT: 'Newest',
	SCORE: 'Top',
};

export const SORT_OPTIONS_VALUE_MAPPINGS = {
	[SORT_OPTIONS.RECENT]: 'last_message_timestamp',
	[SORT_OPTIONS.SCORE]: 'score',
};

export const sortOptions: INewFilterOptionMap = {
	[SORT_OPTIONS.SCORE]: {
		count: 0,
		value: SORT_OPTIONS.SCORE,
	},
	[SORT_OPTIONS.RECENT]: {
		count: 0,
		value: SORT_OPTIONS.RECENT,
	},
};

export const TIME_PERIOD_OPTIONS = {
	ALL: 'All Time',
	LAST_30_DAYS: 'Last 30 Days',
};

export const TIME_PERIOD_OPTIONS_VALUE_MAPPINGS = {
	[TIME_PERIOD_OPTIONS.ALL]: 'lifetime',
	[TIME_PERIOD_OPTIONS.LAST_30_DAYS]: 'last_30_days',
};

export const timePeriodOptions = {
	[TIME_PERIOD_OPTIONS.ALL]: {
		count: 0,
		value: TIME_PERIOD_OPTIONS.ALL,
	},
	[TIME_PERIOD_OPTIONS.LAST_30_DAYS]: {
		count: 0,
		value: TIME_PERIOD_OPTIONS.LAST_30_DAYS,
	},
};

export const ADVANCED_FILTER_OPTIONS = {
	HIGHLY_FOLLOWED: 'Highly Followed',
	RECOMMENDED: 'Recommended',
	WELL_WRITTEN: 'Well Written',
};

export const ADVANCED_FILTER_OPTIONS_MAPPINGS = {
	[ADVANCED_FILTER_OPTIONS.HIGHLY_FOLLOWED]: 'min_followers',
	[ADVANCED_FILTER_OPTIONS.RECOMMENDED]: 'min_score',
	[ADVANCED_FILTER_OPTIONS.WELL_WRITTEN]: 'min_message_score',
};

export const ADVANCED_FILTER_OPTIONS_DEFAULT_VALUES_MAP = {
	[ADVANCED_FILTER_OPTIONS.HIGHLY_FOLLOWED]: 10000,
	[ADVANCED_FILTER_OPTIONS.RECOMMENDED]: 18,
	[ADVANCED_FILTER_OPTIONS.WELL_WRITTEN]: 16,
};

export const advancedOptions = {
	[ADVANCED_FILTER_OPTIONS.HIGHLY_FOLLOWED]: {
		count: 0,
		value: ADVANCED_FILTER_OPTIONS.HIGHLY_FOLLOWED,
		valueMap: ADVANCED_FILTER_OPTIONS_DEFAULT_VALUES_MAP[ADVANCED_FILTER_OPTIONS.HIGHLY_FOLLOWED],
	},
	[ADVANCED_FILTER_OPTIONS.RECOMMENDED]: {
		count: 0,
		value: ADVANCED_FILTER_OPTIONS.RECOMMENDED,
		valueMap: ADVANCED_FILTER_OPTIONS_DEFAULT_VALUES_MAP[ADVANCED_FILTER_OPTIONS.RECOMMENDED],
	},
	[ADVANCED_FILTER_OPTIONS.WELL_WRITTEN]: {
		count: 0,
		value: ADVANCED_FILTER_OPTIONS.WELL_WRITTEN,
		valueMap: ADVANCED_FILTER_OPTIONS_DEFAULT_VALUES_MAP[ADVANCED_FILTER_OPTIONS.WELL_WRITTEN],
	},
};

export const experience_min_options = generateExperienceFilterOptions('min');

export const experience_max_options = generateExperienceFilterOptions('max');

// main filters
export const BOOKMARKS = 'Bookmarks';
export const ALL = 'All';
export const CHATS = 'Chats';

export const mainFiltersArr: Tabs[] = [
	{
		name: BOOKMARKS,
		displayName: BOOKMARKS,
		preAppliedFilters: {
			bookmark: true,
			sort_by: 'last_message_timestamp',
			archived: false,
		},
		archiveCountRequired: false,
		showTimePeriodFilter: false,
		unreadFilterRequired: false,
		chatsListView: 'LIST_VIEW',
		overviewEnabled: false,
	},
	{
		name: ALL,
		displayName: ALL,
		preAppliedFilters: {
			archived: false,
			sort_by: 'bw_score',
			unread: true,
		},
		archiveCountRequired: true,
		showTimePeriodFilter: false,
		unreadFilterRequired: true,
		chatsListView: 'SCROLL_CARD',
		overviewEnabled: true,
	},
];

export const liteUserMainFiltersArr: Tabs[] = [
	{
		name: CHATS,
		displayName: CHATS,
		preAppliedFilters: {
			sort_by: 'last_message_timestamp',
			archived: false,
		},
		showTimePeriodFilter: false,
		overviewEnabled: false,
		archiveCountRequired: false,
		unreadFilterRequired: false,
	},
];

export const archivePreAppliedFilters = {
	archived: true,
	sort_by: 'last_message_timestamp',
};

export const archiveOverviewHeading = 'Archived DMs';

export const allMainFiltersArr = [...mainFiltersArr, ...liteUserMainFiltersArr];

export const mixPanelEvents = {
	INTRO_PAGE_SHOWN: 'Intro Page Shown',
	INTRO_PAGE_STEP_ONE_SUCCESS: 'Intro Page Step One Success',
	INTRO_PAGE_STEP_TWO_SUCCESS: 'Intro Page Step Two Success',
	INTRO_PAGE_MESSAGE_EDIT_CLICKED: 'Intro Page Message Edit Clicked',
	INTRO_PAGE_CONTINUE_MESSAGE_CLICKED: 'Intro Page Continue Message Clicked',
	INTRO_PAGE_LOGIN_CLICKED: 'Intro Page Login Clicked',
	INTRO_PAGE_SUPER_DM_CLICKED: 'Intro Page SuperDM Clicked',
	INTRO_PAGE_EDUCATION_NEXT_CLICKED: 'Intro Page Education Next Clicked',
	INTRO_PAGE_EDUCATION_BACK_CLICKED: 'Intro Page Education Back Clicked',
	INTRO_PAGE_EMAIL_MODAL_SHOWN: 'Intro Page Email Modal Shown',
	EMAIL_MODAL_COPY_LINK_CLICKED: 'Email Modal Copy Link Clicked',
	INTRO_PAGE_GOOGLE_BTN_CLICK: 'Intro Page Continue Google Clicked',
	INTRO_PAGE_EMAIL_PROCEED_CLICKED: 'Intro Page Email Proceed Clicked',
	LANDING_PAGE_EMAIL_PROCEED_CLICKED: 'Landing Page Email Proceed Clicked',
	INTRO_PAGE_OTP_SHOWN: 'Intro Page Email OTP Shown',
	LANDING_PAGE_OTP_SHOWN: 'Landing Page Email OTP Shown',
	INTRO_PAGE_EMAIL_OTP_SUBMITTED: 'Intro Page Email OTP Submit',
	LANDING_PAGE_EMAIL_OTP_SUBMITTED: 'Landing Page Email OTP Submit',
	INTRO_PAGE_SUCCESS_SHOWN: 'Intro Page Success Shown',
	INTRO_PAGE_SENT_SHOWN: 'Intro Page Sent Shown',
	LANDING_PAGE_GOOGLE_BTN_CLICK: 'Landing Page Continue Google Clicked',
	LINKEDIN_CONNECT_PAGE_SHOWN: 'LinkedIn Connect Page Shown',
	LINKEDIN_CONNECT_KNOW_URL_CLICKED: 'LinkedIn Connect Know URL Clicked',
	LINKEDIN_CONNECT_REMEMBER_USERNAME_CLICKED: 'LinkedIn Connect REMEMBER Username Clicked',
	LINKEDIN_CONNECT_SUBMIT_CLICKED: 'LinkedIn Connect Submit Clicked',
	LINKEDIN_CONNECT_SUBMIT_FAILED: 'LinkedIn Connect Submit Failed',
	LINKEDIN_CONNECT_SUCCESS_SHOW: 'LinkedIn Connect Success Shown',
	ONBOARDING_VERIFICATION_PAUSED_SHOWN: 'Verification Paused Page Shown',
	INBOX_OPENED: 'Inbox Opened',
	INBOX_CATEGORY_DROPDOWN_SHOW: 'Inbox Category Dropdown Shown',
	INBOX_CATEGORY_DROPDOWN_SELECTED: 'Inbox Category Dropdown Selected',
	INBOX_MESSAGE_SEND_CLICKED: 'Inbox Message Send Clicked',
	INBOX_MESSAGE_SUCCESS: 'Inbox Message Sent',
	INBOX_VIEW_FULL_PROFILE_CLICKED: 'Inbox View Full Profile Clicked',
	PROFILE_PANEL_LINKEDIN_CLICKED: 'Profile Panel LinkedIn Clicked',
	INBOX_COPY_LINK_CLICKED: 'Inbox Copy Link Clicked',
	INBOX_CLAIM_LINK_CLICKED: 'Claim Link Page Early Access Clicked',
	INBOX_TAB_CLICKED: 'Inbox Tab Clicked',
	INBOX_REPORT_CLICKED: 'Inbox Report Clicked',
	INBOX_REPORT_SUBMITTED: 'Inbox Report Submitted',
	INBOX_BLOCK_CLICKED: 'Inbox Block Clicked',
	INBOX_BLOCK_SUBMITTED: 'Inbox Block Submitted',
	INBOX_CHAT_OPENED: 'Inbox Chat Opened',
	VIEW_LINKEDIN_PROFILE_CLICKED: 'View LinkedIn Profile Clicked',
	LINKED_VERIFY_TOP_BAR_CLICKED: 'Top Bar Verify Now Clicked',
	LINKEDIN_VERIFY_SHOWN: 'LinkedIn Verify Shown',
	LINKEDIN_VERIFY_CONTINUE_CLICKED: 'LinkedIn Verify Continue Clicked',
	LINKEDIN_VERIFY_SUCCESS_SHOWN: 'LinkedIn Verify Success Shown',
	LINKEDIN_VERIFY_ERROR_SHOWN: 'LinkedIn Verify Error Shown',
	VERIFICATION_PENDING_SHOWN: 'Verification Pending Page Shown',
	LANDING_PAGE_OPENED: 'Landing Page Opened',
	LANDING_PAGE_EARLY_ACCESS_CLICKED: 'Landing Page Early Access Clicked',
	LANDING_PAGE_SIGN_IN_CLICKED: 'Landing Page Sign In Clicked',
	HEADER_COPY_LINK_CLICKED: 'Header Copy Link Clicked',
	HEADER_LOGO_CLICKED: 'Logo Clicked in Header',
	HEADER_CLAIM_LINK_CLICKED: 'Claim Link Clicked',
	HEADER_PROFILE_PIC_CLICKED: 'My Photo Clicked',
	HEADER_SETTINGS_CLICKED: 'Settings Clicked',
	HEADER_MY_PROFILE_CLICKED: 'My Profile Clicked',
	CHAT_ADDED_TO_BOOKMARK: 'Added to Bookmark',
	CHAT_REMOVED_FROM_BOOKMARK: 'Removed from Bookmark',
	PAGE_NOT_FOUND_SHOWN: 'Page Not Found shown',
	NETWORK_ISSUE_PAGE_SHOWN: 'Network Issue Page Shown',
	INBOX_SORT_CLICKED: 'Inbox Sort Clicked',
	INBOX_SORT_SELECTED: 'Inbox Sort Selected',
	INBOX_FILTER_CLICKED: 'Inbox Filter Clicked',
	INBOX_FILTER_SELECTED: 'Inbox Filter Selected',
	INBOX_PROFILE_NAME_CLICKED: 'Inbox Profile Name Clicked',
	INBOX_MORE_FILTERS_CLICKED: 'Inbox More Filters Clicked',
	SAVE_FILTER_CLICKED: 'Save Filters Clicked',
	SAVE_FILTER_SUCCESS: 'Save Filter Success',
	LOGOUT_CLICKED: 'Logout Clicked',
	ATTACHMENT_ICON_CLICKED: 'Attachment Icon Clicked',
	ATTACHMENT_ADDED: 'Attachment Added',
	ATTACHMENT_ERROR_SHOWN: 'Attachment Error Shown',
	QUICK_REPLY_POP_UP_SHOWN: 'Quick Reply Popup Shown',
	QUICK_REPLY_SELECTED: 'Quick Reply Selected',
	QUICK_REPLY_MANAGE_MODAL_SHOWN: 'Quick Reply Manage Modal Shown',
	QUICK_REPLY_NEW_SAVED: 'Quick Reply New Saved',
	QUICK_REPLY_SAVED: 'Quick Reply Saved',
	QUICK_REPLY_DELETED: 'Quick Reply Deleted',
	NOTIFICATION_CLICKED: 'Notification Clicked',
	SEARCH_DONE: 'Search Done',
	SEARCH_RESULTS_SHOWN: 'Search Results Shown',
	SEND_AS_EMAIL_CLICKED: 'Send as Email Clicked',
	SEND_AS_EMAIL_SUCCESS: 'Send as Email Success',
	SEND_AS_EMAIL_FAILURE: 'Send as Email Failure',
	WIDGET_CLICKED: 'Widget Clicked',
	LINKEDIN_CONNECT_PREVIEW_SHOWN: 'LinkedIn Connect Preview Shown',
	LINKEDIN_CONNECT_PREVIEW_ERROR: 'LinkedIn Connect Preview error',
	LINKEDIN_CONNECT_PREVIEW_YES_CLICKED: 'LinkedIn Connect Preview Yes Clicked',
	LINKEDIN_CONNECT_PREVIEW_NO_CLICKED: 'LinkedIn Connect Preview No Clicked',
	VIEW_ALL_DMS_CLICKED: 'View All DMs Clicked',
	NEW_DMS_CLICKED: 'You have new DMs Clicked',
	CARD_QUICK_REPLY_CLICKED: 'Card Quick Reply Clicked',
	CARD_MARK_AS_READ_CLICKED: 'Card Mark as Read Clicked',
	CARD_MARK_AS_READ_UNDO_CLICKED: 'Card Mark as Read Undo Clicked',
	CARD_QUICK_REPLY_OPEN_CHAT_CLICKED: 'Card Quick Reply Open Chat Clicked',
	QUICK_REPLY_SHEET_SHOWN: 'Quick Reply Sheet Shown',
	QUICK_REPLY_SHEET_ADD_CLICKED: 'Quick Reply Sheet Add Clicked',
	QUICK_REPLY_SHEET_ADD_OR_EDIT_CLICKED: 'Quick Reply Sheet Add or Edit Clicked',
	QUICK_REPLY_SHEET_EDIT_CLICKED: 'Quick Reply Sheet Edit Clicked',
	QUICK_REPLY_SHEET_ADDED: 'Quick Reply Sheet Added',
	QUICK_REPLY_SHEET_DELETED: 'Quick Reply Sheet Deleted',
	QUICK_REPLY_SHEET_EDITED: 'Quick Reply Sheet Edited',
	QUICK_REPLY_SHEET_CONTINUE_CLICKED: 'Quick Reply Sheet Continue Clicked',
	CARD_BOOKMARK_CLICKED: 'Card Bookmark Clicked',
	CARD_TAGS_SCROLLED: 'Card Tags Scrolled',
	CHAT_DELETE_CLICKED: 'Chat Delete Clicked',
	CHAT_DELETE_SUCCESS: 'Chat Delete Success',
	MESSAGE_SENDING_ERROR_SHOWN: 'Message Sending Error Shown',
	APP_CRASH_ERROR_SHOWN: 'App Crash Error Shown',
	LINKED_IN_OAUTH_LOADING_SCREEN_SHOWN: 'LinkedIn OAuth Loading Screen Shown',
	GOOGLE_OAUTH_LOADING_SCREEN_SHOWN: 'Google OAuth Loading Screen Shown',
	GOOGLE_OAUTH_ERROR_SHOWN: 'Google OAuth Error Shown',
	WEB_APP_VERSION_CHECK: 'Web App Version Check',
	WEB_APP_UPDATE_MODAL_SHOWN: 'Web App Update Modal Shown',
	RELAUNCH_BUTTON_CLICKED: 'Relaunch Button Clicked',
	CARD_RIGHT_SWIPE_DONE: 'Card Swipe Right Done',
	CARD_LEFT_SWIPE_DONE: 'Card Swipe Left Done',
	CARD_SWIPE_UNDO_CLICKED: 'Card Swipe Undo Clicked',
	CARD_SCROLL_DONE: 'Card Scroll Done',
	CARD_SWIPE_EDUCATION_SHOWN: 'Card Swipe Education Shown',
	CARD_SWIPE_EDUCATION_DONE: 'Card Swipe Education Done',
	CHAT_MARKED_AS_READ: 'Card Mark as Read Success',
	CHAT_MARKED_AS_UNREAD: 'Card Mark as Read Undo Done',
	CARD_LAYOUT_CHANGED: 'Card Layout Changed',
	SWITCH_USER_CLICKED: 'Switch User Clicked',
	API_ERROR_UNAUTHORIZED: 'API Error Unauthorized',
	LABEL_ICON_CLICKED: 'Label Icon Clicked',
	ADD_NEW_LABEL_CLICKED: 'Add New Label Clicked',
	NEW_LABEL_ADDED: 'New Label Added',
	DELETE_LABEL_ICON_CLICKED: 'Delete Label Icon Clicked',
	LABEL_DELETED: 'Label Deleted',
	LABEL_ASSIGNED_TO_CHAT: 'Label Assigned to Chat',
	LABEL_UNASSIGNED_FROM_CHAT: 'Label Unassigned from Chat',
	MARK_ALL_AS_READ_CLICKED: 'Mark All as Read Clicked',
	MARK_ALL_AS_READ_CANCEL_CLICKED: 'Mark All as Read Cancel Clicked',
	MARK_ALL_AS_READ_YES_CLICKED: 'Mark All as Read Yes Clicked',
	MARK_ALL_AS_READ_SUCCESS: 'Mark All as Read Success',
	REPLY_TO_ALL_CLICKED: 'Reply to All Clicked',
	BULK_REPLY_API_ERROR_SHOWN: 'Bulk Reply API Error Shown',
	BULK_REPLY_LIMIT_ERROR_SHOWN: 'Bulk Reply Limit Error Shown',
	BULK_REPLY_EMPTY_ERROR_SHOWN: 'Bulk Reply Empty Error Shown',
	BULK_REPLY_STEP_ONE_SHOWN: 'Bulk Reply Step One Shown',
	BULK_REPLY_STEP_TWO_SHOWN: 'Bulk Reply Step Two Shown',
	BULK_REPLY_STEP_THREE_SHOWN: 'Bulk Reply Step Three Shown',
	BULK_REPLY_SUCCESS_SHOWN: 'Bulk Reply Success Shown',
	BULK_REPLY_ERROR_SHOWN: 'Bulk Reply Error Shown',
	BULK_REPLY_LOADING_SHOWN: 'Bulk Reply Loading Shown',
	BULK_REPLY_NEXT_CLICKED: 'Bulk Reply Next Clicked',
	BULK_REPLY_BACK_CLICKED: 'Bulk Reply Back Clicked',
	BULK_REPLY_DONE_CLICKED: 'Bulk Reply Done Clicked',
	BULK_REPLY_SEND_CLICKED: 'Bulk Reply Send Clicked',
	BULK_REPLY_EXIT_ALERT_SHOWN: 'Bulk Reply Exit Alert Shown',
	ADD_TEAM_MEMBER_CLICKED: 'Add Team Member Clicked',
	ADD_TEAM_MEMBER_FAILED: 'Add Team Member Failed',
	ADD_TEAM_MEMBER_SUCCESS: 'Add Team Member Success',
	EDIT_TEAM_MEMBER_CLICKED: 'Edit Team Member Clicked',
	EDIT_TEAM_MEMBER_FAILED: 'Edit Team Member Failed',
	EDIT_TEAM_MEMBER_SUCCESS: 'Edit Team Member Success',
	DELETE_TEAM_MEMBER_CLICKED: 'Delete Team Member Clicked',
	DELETE_TEAM_MEMBER_FAILED: 'Delete Team Member Failed',
	DELETE_TEAM_MEMBER_SUCCESS: 'Delete Team Member Success',
	SHOW_ALL_CATEGORIES_CLICKED: 'Show All Categories Clicked',
	BULK_ACTIONS_NUDGE_SHOWN: 'Bulk Actions Nudge Shown',
	DISCOVER_PAGE_OPENED: 'Discover Page Opened',
	DISCOVER_PAGE_TAB_CLICKED: 'Discover Page Tab Clicked',
	DISCOVER_PAGE_CARD_CLICKED: 'Discover Page Card Clicked',
	DISCOVER_PAGE_EARLY_ACCESS_CLICKED: 'Discover Page Early Access Clicked',
	LANDING_PAGE_SEE_ALL_CLICKED: 'Landing Page See All Clicked',
	INBOX_PAGE_DISCOVER_BTN_CLICKED: 'Inbox Page Discover Button Clicked',
	LANDING_PAGE_DISCOVERY_CARD_CLICKED: 'Landing Page Discovery Card Clicked',
};

export const NOTIFICATION_SOURCE_PARAM_KEY = 'source';
export const NOTIFICATION_SOURCE_PARAM_VALUE = 'notification';
export const NOTIFICATION_TYPE_PARAM_KEY = 'type';

export const APP_IDENTIFIERS = ['linkedin', 'twitter'];
export const IPHONE = 'iphone';
export const INSTAGRAM = 'instagram';
export const FACEBOOK = 'facebook';
export const ANDROID = 'android';

export const cannedResponseReplaceWords = {
	firstName: `{first_name}`,
};

export const PUBLIC_VAPID_KEY =
	'BDFtWHTrraEovU10wIIEiAn84qa - lKKKzml7m6UHXFSIUv3Icu2UUyU0WThllBJp_KNna1vrYan10I_yUrXTg34';

export const MESSAGE_CHARACTER_LIMIT = 2000;
export const FIRST_MESSAGE_CHARACTER_LIMIT = 500;
export const FIRST_MESSAGE_MIN_CHARACTER_LIMIT = 50;
export const ATTACHMENT_SIZE_LIMIT = 3 * 1024 * 1024;
export const CANNED_RESPONSE_LABEL_CHARACTER_LIMIT = 20;
export const CANNED_RESPONSE_MESSAGE_CHARACTER_LIMIT = 2000;
export const INTRO_MESSAGE_CHARACTER_LIMIT = 1500;
export const CATEGORIES_CHARACTER_LIMIT = 40;

// search params
export const CHAT_SEARCH_PARAM = 'chat';
export const INBOX_TAB_SEARCH_PARAM = 'tab';
export const INBOX_TYPE_SEARCH_PARAM = 'inbox';
export const SEARCH_PARAM = 'search';

export const FILTERS_SEARCH_PARAMS = [
	COUNTRY,
	CITY,
	FUNCTION,
	COMPANY,
	EDUCATION,
	LABEL,
	TITLE,
	EXPERIENCE_MIN,
	EXPERIENCE_MAX,
	SORT,
	TIME_PERIOD,
	UNREAD,
	TAG,
];

export const MULTI_FILTERS_SEARCH_PARAMS = [
	TITLE,
	COUNTRY,
	EDUCATION,
	CITY,
	FUNCTION,
	LABEL,
	COMPANY,
	TAG,
];

export const proUserSearchParamsKeys = [
	CHAT_SEARCH_PARAM,
	INBOX_TAB_SEARCH_PARAM,
	...FILTERS_SEARCH_PARAMS,
];

export const lightUserSearchParamsKeys = [CHAT_SEARCH_PARAM, INBOX_TAB_SEARCH_PARAM];

export const InboxTypesSearchParamsValues = {
	['TAB']: 'Tab',
	['OVERVIEW']: 'Overview',
};

export const DISCOVER_PAGE_TAB_PARAM = 't';
export const DISCOVER_PAGE_LOCATION_PARAM = 'l';

export const DISCOVERY_PAGE_LOCATION_VALUES = {
	['INDIA']: 'in',
	['WORLD']: 'wo',
};

export const DISCOVERY_PAGE_LOCATION_VALUES_MAP = {
	[DISCOVERY_PAGE_LOCATION_VALUES.INDIA]: 'INDIA',
	[DISCOVERY_PAGE_LOCATION_VALUES.WORLD]: 'WORLD',
};

export const SORT_RELEVANCE_OPTION_VALUE_IN_NEW = 'bw_score';

export const SORT_RELEVANCE_OPTION_VALUES_ARR = [
	SORT_RELEVANCE_OPTION_VALUE_IN_NEW,
	SORT_OPTIONS_VALUE_MAPPINGS[SORT_OPTIONS.SCORE],
];

export const SORT_RELEVANCE_OPTION_VALUES_TAB_MAPPINGS: { [key: string]: string } = {
	[UNREAD]: SORT_RELEVANCE_OPTION_VALUES_ARR[0],
};

export const UNREAD_FILTER_OPTIONS_DEFAULT_SORT_OPTION_MAPPINGS: { [key: string]: string } = {
	[UNREAD_OPTIONS.UNREAD]: SORT_OPTIONS['SCORE'],
	[UNREAD_OPTIONS.NOT_UNREAD]: SORT_OPTIONS['RECENT'],
};

export const LOADING_SWIPE_CARD_INDEX_TO_SUBTRACT = 3;

export const DEFAULT = 'default';

export const FETCH_CHATS_DATA_PAGE_SIZE = 10;
export const FETCH_CHATS_MESSAGES_PAGE_SIZE = 10;

export const LIKE_REACTION_TEXT = '👍';

export const PERSONAL_LABEL_CHAR_LIMIT = 20;

export const BULK_REPLY_CHATS_THRESHOLD = 500;

export const BULK_ACTIONS_NUDGE_CHATS_DATA_THRESHOLD = 2;
