import React, { useEffect, useState } from 'react';
import { ISlidingTextProps } from './SlidingText.types';
import classes from './SlidingText.styles.module.scss';

function SlidingText({ children, shouldSlide }: ISlidingTextProps) {
	const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
		if (!shouldSlide) return;

		const interval = setInterval(() => {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % React.Children.count(children));
		}, 5000); // Change text every 5 seconds

		return () => clearInterval(interval);
	}, [children, shouldSlide]);

	return (
		<div className={classes.slideContainer}>
			{React.Children.map(children, (child, index) => (
				<div
					key={index}
					className={classes.slideUp}
					style={{ display: index === currentIndex ? 'block' : 'none' }}
				>
					{child}
				</div>
			))}
		</div>
	);
}

export default SlidingText;
