import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getUserDetailsFromGlobalState,
	setUserCannedResponses,
} from '@store/userDetailsSlice/userDetailsSlice';
import ListView from './ListView';
import Modal from '@components/ui/Modal';
import { editUserCannedResponses } from '@api/user'; 
import useFetch from '@hooks/useFetch';
import { IEditUserCannedResponsesPayload, IUserCannedResponse } from '@api/user/user.types';
import { AppDispatch } from '@store/index';
import {
	ICannedResponseMobileProps,
	TAddOrUpdateState,
	TCannedResponseMobileModalState,
} from './CannedResponseMobile.types';
import classes from './CannedResponseMobile.styles.module.scss';
import mixpanelActions from '@utils/mixpanel';
import { mixPanelEvents } from '@utils/constants';
import EditSendView from './EditSendView';
import EmptyState from './EmptyState';
import EditListView from './EditListView';
import AddOrEditView from './AddOrEditView';
import Toast from '@components/ui/Toast';
import { IRefProps } from '@components/ui/Toast/Toast.types';
import { AlertIcon } from '@src/hoc/withIconStyles';

function CannedResponseMobile({
	onCloseModal,
	senderFirstName,
	onSendBtnClick,
	isChatBookmarked,
	maxCharLimit,
	minCharLimit,
	horizontalMode,
	initialSelectedCannedResponse,
	onSelectCannedResponse,
}: ICannedResponseMobileProps) {
	const dispatch = useDispatch<AppDispatch>();
	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);
	const [openModal, setOpenModal] = useState(true);
	const [selectedCannedResponse, setSelectedCannedResponse] = useState<IUserCannedResponse | null>(
		null
	);
	const [modalState, setModalState] = useState<TCannedResponseMobileModalState>('EMPTY_STATE_VIEW');
	const [toastError, setToastError] = useState('');
	const toastRef = useRef<IRefProps>(null);

	const { callApi: callEditCannedResponse, status: callEditCannedResponseStatus } =
		useFetch(editUserCannedResponses);

	const cannedResponses = useMemo(() => {
		return userDetails?.cannedResponses ?? [];
	}, [userDetails]);

	const userId = userDetails?.userId;
	const isLoading = callEditCannedResponseStatus === 'loading';

	const handleSendCannedResponse = (text: string, shouldBookmark: boolean) => {
		mixpanelActions.trackQuickReply(mixPanelEvents.QUICK_REPLY_SHEET_CONTINUE_CLICKED);
		handleCloseModal();
		onSendBtnClick(text, shouldBookmark);
	};

	const handleAddOrEditClicked = () => {
		mixpanelActions.trackQuickReply(mixPanelEvents.QUICK_REPLY_SHEET_ADD_OR_EDIT_CLICKED);
		setSelectedCannedResponse(null);
		setModalState('EDIT_LIST_VIEW');
	};

	const handleAddClick = () => {
		mixpanelActions.trackQuickReply(mixPanelEvents.QUICK_REPLY_SHEET_ADD_CLICKED);
		setSelectedCannedResponse(null);
		setModalState('ADD_OR_EDIT_VIEW');
	};

	const handleAddOrEditBackClick = () => {
		if (horizontalMode) {
			if (cannedResponses.length > 0) {
				setModalState('EDIT_LIST_VIEW');
			} else {
				handleCloseModal();
			}
		} else {
			if (cannedResponses.length > 0) {
				setModalState('EDIT_LIST_VIEW');
			} else {
				setModalState('EMPTY_STATE_VIEW');
			}
		}
	};

	const handleOnSelectCannedResponse = (cannedResponse: IUserCannedResponse) => {
		onSelectCannedResponse && onSelectCannedResponse(cannedResponse);
		setSelectedCannedResponse(cannedResponse);
		setModalState('EDIT_SEND_VIEW');
	};

	const handleEditSendBackClick = () => {
		if (horizontalMode) {
			handleCloseModal();
		} else {
			if (cannedResponses.length > 0) {
				setModalState('LIST_VIEW');
			} else {
				setModalState('EMPTY_STATE_VIEW');
			}
		}
	};

	const handleEditListBackClick = () => {
		if (cannedResponses.length > 0) {
			setModalState('LIST_VIEW');
		} else {
			setModalState('EMPTY_STATE_VIEW');
		}
	};

	const handleOnSelectEditListCannedResponse = (cannedResponse: IUserCannedResponse) => {
		mixpanelActions.trackQuickReply(mixPanelEvents.QUICK_REPLY_SHEET_EDIT_CLICKED);
		setSelectedCannedResponse(cannedResponse);
		setModalState('ADD_OR_EDIT_VIEW');
	};

	const handleSaveUpdateCannedResponses = async (
		updatedCannedResponses: IUserCannedResponse[],
		state: TAddOrUpdateState
	) => {
		if (!userId || isLoading) return;

		toastRef.current?.unPublish();

		try {
			const payload: IEditUserCannedResponsesPayload = {
				user_id: userId,
				canned_responses: updatedCannedResponses,
			};
			const resData = await callEditCannedResponse(payload);
			dispatch(setUserCannedResponses({ cannedResponses: resData }));

			if (state === 'ADD') {
				mixpanelActions.trackQuickReply(mixPanelEvents.QUICK_REPLY_SHEET_ADDED);
				return;
			}

			if (state === 'UPDATE') {
				mixpanelActions.trackQuickReply(mixPanelEvents.QUICK_REPLY_SHEET_EDITED);
				return;
			}

			if (state === 'DELETE') {
				mixpanelActions.trackQuickReply(mixPanelEvents.QUICK_REPLY_SHEET_DELETED);
				return;
			}
		} catch (error) {
			setToastError(error);
			toastRef.current?.publish();
		}
	};

	const handleCloseModal = () => {
		if (isLoading) return;
		setOpenModal(false);
		onCloseModal();
	};

	useEffect(() => {
		if (horizontalMode) {
			if (initialSelectedCannedResponse) {
				setSelectedCannedResponse(initialSelectedCannedResponse);
				setModalState('EDIT_SEND_VIEW');
			} else if (cannedResponses.length === 0) {
				setModalState('EMPTY_STATE_VIEW');
			} else {
				setModalState('EDIT_LIST_VIEW');
			}
		} else {
			if (cannedResponses.length > 0) {
				setModalState('LIST_VIEW');
			} else {
				setModalState('EMPTY_STATE_VIEW');
			}
		}
	}, [cannedResponses, horizontalMode, initialSelectedCannedResponse]);

	useEffect(() => {
		mixpanelActions.trackQuickReply(mixPanelEvents.QUICK_REPLY_SHEET_SHOWN);
	}, []);

	return (
		<Modal
			onCloseModal={handleCloseModal}
			showModal={openModal}
			bottomInMobile
			noPadding
			customClass={classes.modalContainer}
		>
			{modalState === 'EMPTY_STATE_VIEW' && <EmptyState onClickAdd={handleAddClick}/>}
			{modalState === 'LIST_VIEW' && (
				<ListView
					cannedResponses={cannedResponses}
					onClickCannedResponse={handleOnSelectCannedResponse}
					onClickAddOrEdit={handleAddOrEditClicked}
					senderFirstName={senderFirstName}
				/>
			)}
			{modalState === 'EDIT_LIST_VIEW' && (
				<EditListView
					cannedResponses={cannedResponses}
					onClickAdd={handleAddClick}
					onClickBack={handleEditListBackClick}
					onClickCannedResponse={handleOnSelectEditListCannedResponse}
					senderFirstName={senderFirstName}
					shouldShowBackBtn={!horizontalMode}
				/>
			)}
			{modalState === 'EDIT_SEND_VIEW' && (
				<EditSendView
					isChatBookmarked={isChatBookmarked}
					onClickSend={handleSendCannedResponse}
					selectedCannedResponse={selectedCannedResponse}
					senderFirstName={senderFirstName}
					maxCharLimit={maxCharLimit}
					minCharLimit={minCharLimit}
					onClickBack={handleEditSendBackClick}
					showCloseIcon={horizontalMode}
				/>
			)}
			{modalState === 'ADD_OR_EDIT_VIEW' && (
				<AddOrEditView
					onClickBack={handleAddOrEditBackClick}
					onClickSaveUpdatedCannedResponses={handleSaveUpdateCannedResponses}
					cannedResponses={cannedResponses}
					isLoading={isLoading}
					selectedCannedResponseToEdit={selectedCannedResponse}
				/>
			)}

			<Toast
				ref={toastRef}
				toastType={'ERROR'}
				header={toastError}
				icon={<AlertIcon size={1.8} className={classes.toastIcon} />}
			/>
		</Modal>
	);
}

export default CannedResponseMobile;
