import { IAppliedFiltersPayload } from '@api/chats/chats.types';
import {
	BookMarkEmptyStateIcon,
	NewTabEmptyStateIcon,
	NotFoundIcon,
} from '@src/hoc/withIconStyles';
import { INewFilterOptionMap } from '@src/models/inbox';
import {
	APP_IDENTIFIERS,
	IPHONE,
	INSTAGRAM,
	FACEBOOK,
	ANDROID,
	INBOX_TAB_SEARCH_PARAM,
	BOOKMARKS,
	ALL,
	CHAT_SEARCH_PARAM,
	FILTERS_SEARCH_PARAMS,
	MULTI_FILTERS_SEARCH_PARAMS,
	proUserSearchParamsKeys,
	lightUserSearchParamsKeys,
	CHATS,
	DISCOVER_PAGE_TAB_PARAM,
	DISCOVER_PAGE_LOCATION_PARAM,
} from '@utils/constants';

export function isValidLinkedInProfileURL(url: string, linkedInPrefix: string) {
	const profileLinkURL = url.split('?')[0];
	// Regular expression to match common LinkedIn profile URL patterns
	const linkedinRegex = /.*linkedin\.com(\/in)?\/[^\s]+$/;

	return {
		isValid:
			linkedinRegex.test(profileLinkURL) &&
			!profileLinkURL.includes('profilesettings') &&
			!profileLinkURL.includes('public') &&
			profileLinkURL !== linkedInPrefix &&
			!profileLinkURL.endsWith('/settings') &&
			!profileLinkURL.endsWith('/me') &&
			!profileLinkURL.endsWith('/feed'),

		profileLinkURL,
	};
}
export function generateExperienceFilterOptions(type: 'min' | 'max') {
	const generatedObject: INewFilterOptionMap = {};

	for (let i = 1; i <= 10; i++) {
		if (type === 'min') {
			generatedObject[i] = {
				count: 0,
				valueDisplay: `${i}+ year${i > 1 ? 's' : ''}`,
				value: `${i}`,
			};
		} else if (type === 'max') {
			generatedObject[i] = {
				count: 0,
				valueDisplay: `<${i} year${i > 1 ? 's' : ''}`,
				value: `${i}`,
			};
		}
	}
	return generatedObject;
}

export function isValidEmail(email: string) {
	// Regular expression for a basic email validation
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return emailRegex.test(email);
}

export function shouldShowWebviewErrorModal(isMobile: boolean) {
	const userAgent = window.navigator.userAgent.toLocaleLowerCase();

	const isIphoneWithLinkedInOrTwitter =
		userAgent.includes(IPHONE) &&
		APP_IDENTIFIERS.some((identifier) => userAgent.includes(identifier));

	const isFacebook = document.referrer.toLocaleLowerCase().includes(FACEBOOK) && isMobile;

	const instagramEdgeCase =
		userAgent.includes(IPHONE) && userAgent.includes('os 17_') && !userAgent.includes('safari');

	const isInstagram = userAgent.includes(INSTAGRAM) || instagramEdgeCase;

	return isIphoneWithLinkedInOrTwitter || isFacebook || isInstagram;
}

export function isSuperDMApp() {
	const userAgent = window.navigator.userAgent.toLocaleLowerCase();
	const superDMAppDeviceUserAgentName = process.env.SUPER_DM_APP_DEVICE_USER_AGENT_NAME as string;
	const wordToCheck = superDMAppDeviceUserAgentName.toLocaleLowerCase();
	return userAgent.includes(wordToCheck);
}

export function getSuperDMAppVersion() {
	const superDMAppDeviceUserAgentName = process.env.SUPER_DM_APP_DEVICE_USER_AGENT_NAME as string;
	const wordToCheck = superDMAppDeviceUserAgentName.toLocaleLowerCase() + '/';
	const userAgent = window.navigator.userAgent.toLocaleLowerCase();
	const version = userAgent.split(wordToCheck)[1]?.split(' ')[0] ?? '';
	return version;
}

export function isAndroid() {
	const userAgent = window.navigator.userAgent.toLocaleLowerCase();
	return userAgent.includes(ANDROID);
}

export function isPWAApp() {
	return window.matchMedia('(display-mode: standalone)').matches;
}

export function isIphone() {
	const userAgent = window.navigator.userAgent.toLocaleLowerCase();

	return userAgent.includes(IPHONE);
}

export async function parseFileToArrayBuffer(file: Blob) {
	try {
		const buffer = await file.arrayBuffer();
		const array = new Uint8Array(buffer);
		return array;
	} catch (error) {
		console.error('Error parsing file to ArrayBuffer:', error);
		throw new Error(error); // Re-throw the error or handle it as needed
	}
}

export async function convertArrayBufferToFile(file: File): Promise<File> {
	try {
		const imgBuffer = await parseFileToArrayBuffer(file);

		// Create a Blob from the ArrayBuffer
		const blob = new Blob([imgBuffer]);

		// Convert Blob to File
		const newFile = new File([blob], `${file.name}`, {
			type: file.type,
			lastModified: file.lastModified,
		});

		return newFile;
	} catch (error) {
		throw new Error(error);
	}
}

function stringArraysEqual(arr1: string[], arr2: string[]): boolean {
	if (arr1.length !== arr2.length) {
		return false;
	}

	const sortedArr1 = [...arr1].sort();
	const sortedArr2 = [...arr2].sort();

	for (let i = 0; i < sortedArr1.length; i++) {
		if (sortedArr1[i] !== sortedArr2[i]) {
			return false;
		}
	}

	return true;
}

export function deepEqualFilters(
	obj1: IAppliedFiltersPayload,
	obj2: IAppliedFiltersPayload
): boolean {
	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (const key of keys1) {
		const val1 = obj1[key];
		const val2 = obj2[key];

		if (typeof val1 !== typeof val2) {
			return false;
		}

		if (val1 instanceof Array && val1 !== null && val2 instanceof Array && val2 !== null) {
			if (!stringArraysEqual(val1, val2)) {
				return false;
			}
		} else if (val1 !== val2) {
			return false;
		}
	}

	return true;
}
export function formatFirstName(firstName: string): string {
	const nameParts = firstName.split(' ');
	const nameToUse = nameParts[0].length <= 4 && nameParts.length > 1 ? firstName : nameParts[0];
	return nameToUse;
}

export function getValidUpdatedSearchToUpdate(prevParams: URLSearchParams, isProUser: boolean) {
	const initialSelectedChatUsernameFromURL = decodeURIComponent(
		prevParams.get(CHAT_SEARCH_PARAM) ?? ''
	);
	const initialInboxTabNameFromURL = decodeURIComponent(
		prevParams.get(INBOX_TAB_SEARCH_PARAM) ?? ''
	);

	const proUserTabs = [BOOKMARKS, ALL];
	const liteUserTabs = [CHATS];

	const searchParamsToUpdate = new URLSearchParams(prevParams);

	if (isProUser) {
		prevParams.forEach((value, key) => {
			if (proUserSearchParamsKeys.includes(key)) return;
			searchParamsToUpdate.delete(key);
		});

		if (!proUserTabs.includes(initialInboxTabNameFromURL ?? '')) {
			if (initialSelectedChatUsernameFromURL) {
				searchParamsToUpdate.delete(INBOX_TAB_SEARCH_PARAM);
			} else {
				searchParamsToUpdate.set(INBOX_TAB_SEARCH_PARAM, encodeURIComponent(proUserTabs[1]));
			}
		}
	} else {
		prevParams.forEach((value, key) => {
			if (lightUserSearchParamsKeys.includes(key)) return;
			searchParamsToUpdate.delete(key);
		});

		if (!liteUserTabs.includes(initialInboxTabNameFromURL ?? '')) {
			if (initialSelectedChatUsernameFromURL) {
				searchParamsToUpdate.delete(INBOX_TAB_SEARCH_PARAM);
			} else {
				searchParamsToUpdate.set(INBOX_TAB_SEARCH_PARAM, encodeURIComponent(liteUserTabs[0]));
			}
		}
	}

	return searchParamsToUpdate;
}

export function getValidUpdatedDiscoverPageSearchToUpdate(prevParams: URLSearchParams) {
	const searchParamsToUpdate = new URLSearchParams(prevParams);

	const validDiscoverPageSearchParams = [DISCOVER_PAGE_TAB_PARAM, DISCOVER_PAGE_LOCATION_PARAM];

	prevParams.forEach((value, key) => {
		if (validDiscoverPageSearchParams.includes(key)) return;
		searchParamsToUpdate.delete(key);
	});

	return searchParamsToUpdate;
}

export function getAllFiltersFromParams(searchParams: URLSearchParams) {
	const singleSelectFiltersFromParams: Record<string, string> = {};
	const multiSelectFiltersFromParams: Record<string, string[]> = {};

	searchParams.forEach((value, key) => {
		if (!FILTERS_SEARCH_PARAMS.includes(key)) return;

		const values = value.split(',');

		if (MULTI_FILTERS_SEARCH_PARAMS.includes(key)) {
			multiSelectFiltersFromParams[key] = values.map((value) => decodeURIComponent(value));
		} else {
			singleSelectFiltersFromParams[key] = decodeURIComponent(values[0]);
		}
	});

	return { singleSelectFiltersFromParams, multiSelectFiltersFromParams };
}

export function removeKeysFromAppliedFiltersObject(
	obj: IAppliedFiltersPayload,
	keys: string[]
): IAppliedFiltersPayload {
	const newObj = { ...obj };
	keys.forEach((key) => {
		delete newObj[key];
	});
	return newObj;
}

export function areURLSearchParamsEqual(
	params1: URLSearchParams,
	params2: URLSearchParams
): boolean {
	return params1.toString() === params2.toString();
}

export function createTextStylesObject({
	lineHeight,
	fontSize,
	color,
}: {
	lineHeight?: number;
	fontSize?: number;
	color?: string;
}): React.CSSProperties {
	const styles: React.CSSProperties = {};
	if (lineHeight) {
		styles.lineHeight = `${lineHeight}rem`;
	}
	if (fontSize) {
		styles.fontSize = `${fontSize}rem`;
	}
	if (color) {
		styles.color = color;
	}
	return styles;
}

export type TDeviceType = 'MOBILE' | 'DESKTOP';

export function getDeviceDimensionsInfo(windowHeight: number, windowWidth: number) {
	const isMobile = windowWidth <= 650;
	const isTablet = windowWidth > 650 && windowWidth <= 1024;
	const isDesktop = windowWidth > 1024;
	const isLargeScreen = windowWidth > 1200;
	const height = windowHeight;
	const width = windowWidth;
	const deviceType: TDeviceType = isMobile ? 'MOBILE' : 'DESKTOP';

	return {
		isMobile,
		isTablet,
		isDesktop,
		isLargeScreen,
		height,
		width,
		deviceType,
	};
}

interface IGetEmptyStatesFuncProps {
	selectedMainFilterName?: string;
	isFiltersDisplayed?: boolean;
	isMobile?: boolean;
	isDefaultFiltersChanged?: boolean;
	isUnreadFilterSelected?: boolean;
}

export function getEmptyStatesData({
	selectedMainFilterName,
	isFiltersDisplayed,
	isMobile,
	isDefaultFiltersChanged,
	isUnreadFilterSelected,
}: IGetEmptyStatesFuncProps) {
	let paddingTop = '';

	if (isFiltersDisplayed) {
		paddingTop = `${isMobile ? 94 : 148}px`;
	} else {
		paddingTop = `${isMobile ? 160 : 216}px`;
	}

	const styles: React.CSSProperties = paddingTop ? { paddingTop } : {};

	const iconStyles: React.CSSProperties = {};

	if (selectedMainFilterName === BOOKMARKS) {
		return {
			icon: BookMarkEmptyStateIcon,
			iconSize: 14,
			iconStyles: iconStyles,
			containerStyles: styles,
			heading: isDefaultFiltersChanged ? `Nothing to show` : `Everyone deserves a clean inbox`,
			subText: `Only chats you bookmark will show up here`,
			tapToRefreshCTAText: `Tap to refresh`,
			clearFiltersCTAText: `Clear filters`,
			archivedDMsCTAText: `See archived DMs`,
			viewAllDMsCTAText: `View all DMs`,
		};
	} else if (selectedMainFilterName === ALL && isUnreadFilterSelected) {
		return {
			icon: NewTabEmptyStateIcon,
			iconSize: 14,
			iconStyles: iconStyles,
			containerStyles: styles,
			heading: `You’re all caught up`,
			subText: '',
			tapToRefreshCTAText: `Tap to refresh`,
			clearFiltersCTAText: `Clear filters`,
			archivedDMsCTAText: `See archived DMs`,
			viewAllDMsCTAText: `View all DMs`,
		};
	} else if (selectedMainFilterName === ALL || selectedMainFilterName === CHATS) {
		return {
			icon: NewTabEmptyStateIcon,
			iconSize: 14,
			iconStyles: iconStyles,
			containerStyles: styles,
			heading: `Nothing to show`,
			subText: '',
			tapToRefreshCTAText: `Tap to refresh`,
			clearFiltersCTAText: `Clear filters`,
			archivedDMsCTAText: `See archived DMs`,
			viewAllDMsCTAText: `View all DMs`,
		};
	} else {
		iconStyles.marginTop = '2rem';
		return {
			icon: NotFoundIcon,
			iconSize: 10,
			iconStyles: iconStyles,
			containerStyles: styles,
			heading: `No results found`,
			subText: '',
			tapToRefreshCTAText: `Tap to refresh`,
			clearFiltersCTAText: `Clear filters`,
			archivedDMsCTAText: `See archived DMs`,
			viewAllDMsCTAText: `View all DMs`,
		};
	}
}

export function genDraftMessageKey(username: string) {
	return `draft-message-${username}`;
}
