import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedChatDetailsFromGlobalState } from '@store/selectedChatSlice/selectedChatSlice';
import { InboxCategories } from '@src/models/user';
import Button from '@components/ui/Button';
import { CloseIcon, ImageIcon, PdfFileIcon, UploadPDFIcon } from '@src/hoc/withIconStyles';
import InboxSelect from '@components/Chats/ChatsSection/InboxSelect';
import {
	ATTACHMENT_SIZE_LIMIT,
	FIRST_MESSAGE_CHARACTER_LIMIT,
	FIRST_MESSAGE_MIN_CHARACTER_LIMIT,
	MESSAGE_CHARACTER_LIMIT,
	mixPanelEvents,
} from '@utils/constants';
import useWindowSize from '@hooks/useWindow';
import mixpanelActions from '@utils/mixpanel';
import Text from '@components/ui/Text';
import { convertArrayBufferToFile } from '@utils/common';
import { IMessageStepProps } from './MessageStep.types';
import classes from './MessageStep.styles.module.scss';
import DotsLoader from '@components/ui/DotsLoader';

function MessageStep({
	inboxCategoriesMap = {},
	receiverFirstName,
	receiverUsername,
	maxCharCountEnabled,
	minCharCountEnabled,
	message: initialMessage,
	selectedInboxCategory: initialSelectedInboxCategory,
	selectedPdfFile: initialSelectedPdfFile,
	onSendMessage,
	onInputActivityChange,
}: IMessageStepProps) {
	const { isMobile } = useWindowSize();
	const [textAreaStr, setTextAreaStr] = useState(initialMessage ?? '');
	const [selectedInboxCategory, setSelectedInboxCategory] = useState<string | null>(
		initialSelectedInboxCategory ?? null
	);
	const [selectedAttachment, setSelectedAttachment] = useState<File | null>(
		initialSelectedPdfFile ?? null
	);
	const [inputInactivityCount, setInputInactivityCount] = useState(1);

	const attachmentInputRef = useRef<HTMLInputElement | null>(null);
	const [isConvertingAttachment, setIsConvertingAttachment] = useState(false);

	const [validationState, setValidationState] = useState({
		error: '',
		info: maxCharCountEnabled
			? `Please keep this within ${FIRST_MESSAGE_CHARACTER_LIMIT} characters`
			: '',
	});

	const { selectedChat } = useSelector(getSelectedChatDetailsFromGlobalState);

	const textAreaRef = useRef<HTMLTextAreaElement>(null);

	const isChatExist = !!selectedChat?.chatId;

	const showSelectCategory =
		Object.keys(inboxCategoriesMap).length > 0 &&
		!isChatExist &&
		(textAreaStr.length > 0 || selectedInboxCategory);

	const checkForSelectedCategory = showSelectCategory;
	const checkForMinMessageLength = minCharCountEnabled;
	const checkForMaxMessageLength = maxCharCountEnabled;

	const sendButtonDisabled = textAreaStr.trim().length === 0;

	const handleInboxSelect = (option: InboxCategories, selected: boolean) => {
		selected &&
			setValidationState((prevState) => {
				return {
					...prevState,
					error: '',
				};
			});
		setSelectedInboxCategory(selected ? option.name : null);
		selected &&
			mixpanelActions.trackChatInboxCategory(
				mixPanelEvents.INBOX_CATEGORY_DROPDOWN_SELECTED,
				receiverUsername ?? '',
				'yes',
				selectedInboxCategory ?? ''
			);
	};

	const handleSendMessage = async () => {
		if (textAreaStr.trim().length === 0) {
			const errorToDisplay = `Message cannot be empty`;

			setValidationState((prevState) => {
				return {
					...prevState,
					error: errorToDisplay,
				};
			});
			mixpanelActions.trackMessageError({
				eventName: mixPanelEvents.MESSAGE_SENDING_ERROR_SHOWN,
				messageErrorType: 'Min Limit',
				charCount: 0,
				validationCount: FIRST_MESSAGE_MIN_CHARACTER_LIMIT,
				errorShown: errorToDisplay,
			});
			textAreaRef.current?.focus();
			return;
		}

		const content = textAreaStr;

		if (!selectedInboxCategory && checkForSelectedCategory) {
			const errorToDisplay = `Please choose a message category`;

			setValidationState((prevState) => {
				return {
					...prevState,
					error: errorToDisplay,
				};
			});
			mixpanelActions.trackMessageError({
				eventName: mixPanelEvents.MESSAGE_SENDING_ERROR_SHOWN,
				messageErrorType: 'Category',
				errorShown: errorToDisplay,
			});
			textAreaRef.current?.focus();
			return;
		}

		if (content.length < FIRST_MESSAGE_MIN_CHARACTER_LIMIT && checkForMinMessageLength) {
			const errorToDisplay = `First message needs to be at least ${FIRST_MESSAGE_MIN_CHARACTER_LIMIT} characters`;

			setValidationState((prevState) => {
				return {
					...prevState,
					error: errorToDisplay,
				};
			});
			mixpanelActions.trackMessageError({
				eventName: mixPanelEvents.MESSAGE_SENDING_ERROR_SHOWN,
				messageErrorType: 'Min Limit',
				charCount: content.length,
				validationCount: FIRST_MESSAGE_MIN_CHARACTER_LIMIT,
				errorShown: errorToDisplay,
			});
			textAreaRef.current?.focus();
			return;
		}

		mixpanelActions.trackChatInboxCategory(
			mixPanelEvents.INTRO_PAGE_CONTINUE_MESSAGE_CLICKED,
			receiverUsername ?? '',
			'yes',
			selectedInboxCategory ?? '',
			selectedAttachment?.type
		);

		onSendMessage(selectedInboxCategory, content.trim(), selectedAttachment);
	};

	const handleInputKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		const shouldSendOnClickEnter = e.key === 'Enter' && !e.shiftKey && !isMobile;

		if (shouldSendOnClickEnter) {
			e.preventDefault();
		}

		if (shouldSendOnClickEnter && !sendButtonDisabled) {
			e.preventDefault();
			handleSendMessage();
		}
	};

	const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const content = e.target.value;
		setInputInactivityCount(2);

		if (content.length > FIRST_MESSAGE_CHARACTER_LIMIT && checkForMaxMessageLength) {
			const errorToDisplay = `First message cannot be longer than ${FIRST_MESSAGE_CHARACTER_LIMIT} characters`;

			setValidationState((prevState) => {
				return {
					...prevState,
					error: errorToDisplay,
				};
			});
			mixpanelActions.trackMessageError({
				eventName: mixPanelEvents.MESSAGE_SENDING_ERROR_SHOWN,
				messageErrorType: 'Max Limit',
				validationCount: FIRST_MESSAGE_CHARACTER_LIMIT,
				charCount: content.length,
				errorShown: errorToDisplay,
			});
			return;
		}

		if (content.length > MESSAGE_CHARACTER_LIMIT && !checkForMaxMessageLength) {
			const errorToDisplay = `Message cannot be longer than ${MESSAGE_CHARACTER_LIMIT} characters`;

			setValidationState((prevState) => {
				return {
					...prevState,
					error: errorToDisplay,
				};
			});
			mixpanelActions.trackMessageError({
				eventName: mixPanelEvents.MESSAGE_SENDING_ERROR_SHOWN,
				messageErrorType: 'Max Limit',
				validationCount: MESSAGE_CHARACTER_LIMIT,
				charCount: content.length,
				errorShown: errorToDisplay,
			});
			return;
		}

		setValidationState((prevState) => {
			return {
				...prevState,
				error: '',
			};
		});

		setTextAreaStr(content);
	};

	const handleAttachmentChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files ?? [];

		if (files.length > 0) {
			const file = files[0];

			const isValidFileType =
				file.type &&
				(file.type === 'application/pdf' ||
					file.type === 'image/png' ||
					file.type === 'image/jpeg');

			if (!isValidFileType) {
				const errorToDisplay = `File needs to be PDF/PNG/JPEG`;
				setValidationState((prevState) => {
					return {
						...prevState,
						error: errorToDisplay,
					};
				});
				mixpanelActions.trackAttachments({
					eventName: mixPanelEvents.ATTACHMENT_ERROR_SHOWN,
					isTemp: 'yes',
					attachment: file.type,
					errorShown: errorToDisplay,
				});
				return;
			}

			if (file.size > ATTACHMENT_SIZE_LIMIT) {
				const errorToDisplay = `File cannot be more than 3 MB`;
				setValidationState((prevState) => {
					return {
						...prevState,
						error: errorToDisplay,
					};
				});
				mixpanelActions.trackAttachments({
					eventName: mixPanelEvents.ATTACHMENT_ERROR_SHOWN,
					isTemp: 'yes',
					attachment: file.type,
					errorShown: errorToDisplay,
				});
				return;
			}

			try {
				setIsConvertingAttachment(true);
				const fileToUpload = await convertArrayBufferToFile(file);
				setSelectedAttachment(fileToUpload);
				mixpanelActions.trackAttachments({
					eventName: mixPanelEvents.ATTACHMENT_ADDED,
					isTemp: 'yes',
					attachment: file.type,
				});
				setIsConvertingAttachment(false);
				setValidationState((prevState) => {
					return {
						...prevState,
						error: '',
					};
				});
			} catch (error) {
				// handle error
				setIsConvertingAttachment(false);
				setValidationState((prevState) => {
					return {
						...prevState,
						error: 'Failed to load file, please try again',
					};
				});

				mixpanelActions.trackAttachments({
					eventName: mixPanelEvents.ATTACHMENT_ERROR_SHOWN,
					isTemp: 'yes',
					attachment: file.type,
					errorShown: error ?? 'Failed to load file, please try again',
				});
			}
		}
	};

	const handleAttachmentUpload = () => {
		if (isConvertingAttachment) return;
		mixpanelActions.trackAttachments({
			eventName: mixPanelEvents.ATTACHMENT_ICON_CLICKED,
			isTemp: 'yes',
		});
		handleCloseAttachment();
		attachmentInputRef.current?.click();
	};

	const handleCloseAttachment = () => {
		if (attachmentInputRef.current?.value) {
			attachmentInputRef.current.value = '';
		}
		setSelectedAttachment(null);
	};

	const handleInputContainerClick = () => {
		textAreaRef.current?.focus();
	};

	const showMessageWithFileUploadLoader = isConvertingAttachment;
	const showValidation = validationState.error;

	const currCharCountWithMaxLimitText = `${textAreaStr.length} / ${FIRST_MESSAGE_CHARACTER_LIMIT}`;

	useEffect(() => {
		if (inputInactivityCount === 1) {
			onInputActivityChange(false);
			return;
		}

		onInputActivityChange(true);

		const intervalId = setInterval(() => {
			setInputInactivityCount((prevCount) => {
				return prevCount - 1;
			});
		}, 1000);

		return () => {
			clearInterval(intervalId);
		};
	}, [inputInactivityCount]);

	useEffect(() => {
		if (showSelectCategory && !selectedInboxCategory) {
			mixpanelActions.trackChatInboxCategory(
				mixPanelEvents.INBOX_CATEGORY_DROPDOWN_SHOW,
				receiverUsername ?? '',
				'yes'
			);
		}
	}, [showSelectCategory, selectedInboxCategory, receiverUsername]);

	useEffect(() => {
		const textAreaEle = textAreaRef.current;

		if (!textAreaEle) return;

		textAreaEle.style.height = 'max-content';

		if (textAreaStr.length > 0) {
			textAreaEle.style.height = `${textAreaEle.scrollHeight}px`;
		}
	}, [textAreaStr]);

	return (
		<div className={classes.messageStepContainer}>
			{showMessageWithFileUploadLoader && (
				<div className={classes.addingFileLoader}>
					<DotsLoader customDesktopSize={60} customMobileSize={60} />
					<Text variant="p" lineHeight={1.6} tiny>
						{'Adding attachment'}
					</Text>
				</div>
			)}

			<div className={classes.inputContainer} onClick={handleInputContainerClick}>
				<div onClick={(e) => e.stopPropagation()}>
					{showSelectCategory && (
						<InboxSelect
							optionsMap={inboxCategoriesMap}
							getOptionDisplayName={(option) => option.name}
							getOptionId={(option) => option.name}
							header={'Choose a message category'}
							onSelectOption={handleInboxSelect}
							selectedOptionId={selectedInboxCategory}
							name={receiverFirstName}
						/>
					)}
				</div>

				<textarea
					rows={1}
					ref={textAreaRef}
					className={classes.input}
					placeholder={'Write your message...'}
					onChange={handleTextAreaChange}
					onKeyDown={handleInputKeyDown}
					value={textAreaStr}
					autoComplete="off"
				/>

				<div className={classes.pdfContainer}>
					{selectedAttachment && (
						<div className={classes.selectedPdfContainer}>
							{selectedAttachment.type === 'application/pdf' && <PdfFileIcon size={1.2} />}
							{(selectedAttachment.type === 'image/png' ||
								selectedAttachment.type === 'image/jpeg') && <ImageIcon size={1.2} />}
							<Text variant="span" tiny lineHeight={1.6} customClass={classes.pdfName}>
								{selectedAttachment.name}
							</Text>
							<Button
								btnText={<CloseIcon size={1.2} className={classes.closeIcon} />}
								onClick={handleCloseAttachment}
								customClass={classes.closePdfBtn}
							/>
						</div>
					)}

					{!!validationState.info && (
						<Text
							variant="p"
							fontSize={isMobile ? 1.1 : 1.2}
							lineHeight={isMobile ? 1.6 : 1.8}
							light
							tertiary
						>
							{currCharCountWithMaxLimitText}
						</Text>
					)}

					<>
						<input
							type="file"
							accept="image/png,image/jpeg,.pdf"
							name="upload"
							onChange={handleAttachmentChange}
							ref={attachmentInputRef}
							hidden
							autoComplete="off"
						/>
						<Button
							btnText={<UploadPDFIcon size={isMobile ? 1.6 : 1.8} />}
							onClick={handleAttachmentUpload}
							customClass={classes.pdfUploadBtn}
						/>
					</>
				</div>
			</div>

			<div className={classes.validationContainer}>
				{showValidation && (
					<Text variant="p" error tiny light lineHeight={1.6}>
						{validationState.error}
					</Text>
				)}
				<Button
					btnText={
						<Text variant="span" fontSize={1.3} lineHeight={1.8} semiBold white>
							{'Continue'}
						</Text>
					}
					primary
					onClick={handleSendMessage}
					customClass={classes.nextBtn}
				/>
			</div>
		</div>
	);
}

export default MessageStep;
