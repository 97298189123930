import React, { useEffect, useMemo } from 'react';
import Button from '@components/ui/Button';
import Text from '@components/ui/Text';
import Avatar from '@components/ui/Avatar';
import FallbackPicIcon from '@public/icons/fallbackPicIcon.svg';
import HeaderContainer from '../HeaderContainer';
import ActionsContainer from '../ActionsContainer';
import { IConfirmViewProps } from './ConfirmView.types';
import classes from './ConfirmView.styles.module.scss';
import mixpanelActions from '@utils/mixpanel';
import { mixPanelEvents } from '@utils/constants';

const IMAGES_TO_DISPLAY = 3;

function ConfirmView({
	onClickBack,
	onClickCloseIcon,
	onClickSend,
	isSelectAll,
	chatsToIncludeOrExclude,
	replyText,
	chatsDataToDisplay,
	chatsDataTotalCount,
}: IConfirmViewProps) {
	const selectedChatsCount = isSelectAll
		? chatsDataTotalCount - chatsToIncludeOrExclude.length
		: chatsToIncludeOrExclude.length;

	const selectedChatsCountText =
		selectedChatsCount === 1 ? '1 person' : `${selectedChatsCount} people`;

	const imagesToDisplay = useMemo(() => {
		const chatsToDisplay = isSelectAll
			? chatsDataToDisplay.filter((chat) => !chatsToIncludeOrExclude.includes(chat.chatId ?? -1))
			: chatsDataToDisplay.filter((chat) => chatsToIncludeOrExclude.includes(chat.chatId ?? -1));

		return chatsToDisplay.slice(0, IMAGES_TO_DISPLAY);
	}, [chatsDataToDisplay, isSelectAll, chatsToIncludeOrExclude]);

	const imagesRemainingCount = selectedChatsCount - IMAGES_TO_DISPLAY;
	const displayRemainingImagesCount = imagesRemainingCount > 0;

	const handleSendClick = () => {
		mixpanelActions.trackBulkReply(mixPanelEvents.BULK_REPLY_SEND_CLICKED);
		onClickSend();
	};

	const handleCloseIconClick = () => {
		onClickCloseIcon();
	};

	const handleBackClick = () => {
		mixpanelActions.trackBulkReply(mixPanelEvents.BULK_REPLY_BACK_CLICKED);
		onClickBack();
	};

	useEffect(() => {
		mixpanelActions.trackBulkReply(mixPanelEvents.BULK_REPLY_STEP_THREE_SHOWN);
	}, []);

	return (
		<>
			<HeaderContainer headerText={'Confirm and send'} onCloseIconClick={handleCloseIconClick} />
			<div className={classes.confirmViewContainer}>
				<div className={classes.imagesContainer}>
					{imagesToDisplay.map((chatsData) => {
						const profilePicURL = chatsData.senderDetails?.profileData?.profilePicture;

						return (
							<Avatar
								size={3.2}
								profilePicURL={profilePicURL}
								fallbackIcon={FallbackPicIcon}
								key={chatsData.chatId}
							/>
						);
					})}

					{displayRemainingImagesCount && (
						<div className={classes.remainingImagesCount}>
							<Text
								variant={'span'}
								extraTiny
								lineHeight={1.2}
								color={'#6D7076'}
							>{`+${imagesRemainingCount}`}</Text>
						</div>
					)}
				</div>
				<Text
					variant={'p'}
					lineHeight={2.2}
				>{`This reply will be sent to ${selectedChatsCountText} separately`}</Text>
				<div className={classes.replyTextContainerWrapper}>
					<div className={classes.replyTextContainer}>
						<Text variant={'p'} small lineHeight={2} light>
							{replyText}
						</Text>
					</div>
				</div>
			</div>
			<ActionsContainer
				buttonsArr={[
					<Button
						btnText={
							<Text variant={'span'} lineHeight={2.2} semiBold>
								{'Back'}
							</Text>
						}
						onClick={handleBackClick}
						customClass={classes.backBtn}
						key={'confirmViewBack'}
					/>,
					<Button
						btnText={
							<Text variant={'span'} lineHeight={2.2} semiBold white>
								{'Send'}
							</Text>
						}
						primary
						onClick={handleSendClick}
						key={'confirmViewSend'}
					/>,
				]}
				currModalState={'CONFIRM_VIEW'}
			/>
		</>
	);
}

export default ConfirmView;
