import React, { useState } from 'react';
import withAuth from '@src/hoc/withAuth';
import Text from '@components/ui/Text';
import Categories from './Categories';
import IntroMessage from './IntroMessage';
import Preferences from './Preferences';
import TeamMembers from './TeamMembers';
import { TSettingsEditSections } from './Settings.types';
import classes from './Settings.styles.module.scss';
import { useSelector } from 'react-redux';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import { Navigate } from 'react-router-dom';
import { INBOX_PATH } from '@utils/constants';
import { isProUserFunc } from '@src/models/user';

function Settings() {
	const [currEditSection, setCurrEditSection] = useState<TSettingsEditSections | null>(null);

	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);

	const showTeamMembers = !userDetails?.isSecondaryUser;

	if (!isProUserFunc(userDetails)) {
		return <Navigate to={INBOX_PATH} replace />;
	}

	return (
		<section className={classes.settingsContainer}>
			<Text variant="h1" medium2M semiBold>
				Settings
			</Text>
			<IntroMessage setCurrEditSection={setCurrEditSection} currEditSection={currEditSection} />
			<span aria-hidden className={classes.divider} />
			<Categories setCurrEditSection={setCurrEditSection} currEditSection={currEditSection} />
			<span aria-hidden className={classes.divider} />
			<Preferences />

			{showTeamMembers && (
				<>
					<span aria-hidden className={classes.divider} />
					<TeamMembers />
				</>
			)}
		</section>
	);
}

export default withAuth(Settings);
