import React, { useEffect, useMemo, useRef, useState } from 'react';
import Modal from '@components/ui/Modal';
import classes from './CannedResponseModal.styles.module.scss';
import { ICannedResponseModalProps, TMode } from './CannedResponseModal.types';
import Text from '@components/ui/Text';
import {
	AlertIcon,
	CheckCircleIcon,
	CloseIcon,
	DeleteIcon,
	PlusIconV2,
	TickIconColored,
} from '@src/hoc/withIconStyles';
import Button from '@components/ui/Button';
import clsx from 'clsx';
import { IEditUserCannedResponsesPayload, IUserCannedResponse } from '@api/user/user.types';
import useFetch from '@hooks/useFetch';
import { editUserCannedResponses } from '@api/user';
import Toast from '@components/ui/Toast';
import { IRefProps } from '@components/ui/Toast/Toast.types';
import Loader from '@components/ui/Loader';
import mixpanelActions from '@utils/mixpanel';
import {
	CANNED_RESPONSE_LABEL_CHARACTER_LIMIT,
	CANNED_RESPONSE_MESSAGE_CHARACTER_LIMIT,
	cannedResponseReplaceWords,
	mixPanelEvents,
} from '@utils/constants';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/index';
import { setUserCannedResponses } from '@store/userDetailsSlice/userDetailsSlice';

function CannedResponseModal({
	onCloseModal,
	showModal,
	cannedResponseData,
	userId,
}: ICannedResponseModalProps) {
	const [cannedResponseDataCopy, setCannedResponseDataCopy] = useState(cannedResponseData);
	const [editData, setEditData] = useState({
		name: '',
		text: '',
		bookmark: false,
	});
	const [mode, setMode] = useState<TMode>('ADD');
	const [selectedCannedResponse, setSelectedCannedResponse] = useState<IUserCannedResponse | null>(
		null
	);
	const [showDeleteAlertModal, setShowDeleteAlertModal] = useState(false);
	const [toastState, setToastState] = useState({ error: '', info: '' });
	const toastRef = useRef<IRefProps>(null);

	const dispatch = useDispatch<AppDispatch>();

	const [editBtnState, setEditBtnState] = useState({
		text: 'Save',
		isSuccessState: false,
	});

	const { callApi: callEditCannedResponse, status: callEditCannedResponseStatus } =
		useFetch(editUserCannedResponses);

	useEffect(() => {
		mixpanelActions.trackCannedResponse({
			eventName: mixPanelEvents.QUICK_REPLY_MANAGE_MODAL_SHOWN,
		});
	}, []);

	const handleCloseIconClick = () => {
		if (callEditCannedResponseStatus === 'loading') return;
		onCloseModal && onCloseModal();
	};

	const handleCloseDeleteAlertModal = () => {
		if (callEditCannedResponseStatus === 'loading') return;
		setShowDeleteAlertModal(false);
	};

	const handleAddReplyClick = (e: React.MouseEvent<HTMLDivElement | MouseEvent>) => {
		e.stopPropagation();
		if (callEditCannedResponseStatus === 'loading') return;
		setMode('ADD');
		setSelectedCannedResponse(null);
		setEditData({ name: '', text: '', bookmark: false });
		resetEditBtnState();
	};

	const handleCallEditApi = async (
		cannedResponses: IUserCannedResponse[],
		cannedResponseToBeFocused: IUserCannedResponse | null,
		successMessage: string,
		labelName: string
	) => {
		toastRef.current?.unPublish();
		if (!userId) return;
		try {
			const payload: IEditUserCannedResponsesPayload = {
				user_id: userId,
				canned_responses: cannedResponses,
			};
			const resData = await callEditCannedResponse(payload);
			dispatch(setUserCannedResponses({ cannedResponses: resData }));
			setCannedResponseDataCopy(resData);
			setSelectedCannedResponse(cannedResponseToBeFocused);
			if (!cannedResponseToBeFocused) {
				// means delete action
				mixpanelActions.trackCannedResponse({
					eventName: mixPanelEvents.QUICK_REPLY_DELETED,
					labelName: labelName,
				});
				setMode('ADD');
				setEditData({ name: '', text: '', bookmark: false });
				setToastState({ error: '', info: successMessage });
				toastRef.current?.publish();
			} else {
				// means non delete actions
				if (mode === 'ADD') {
					mixpanelActions.trackCannedResponse({
						eventName: mixPanelEvents.QUICK_REPLY_NEW_SAVED,
						labelName: labelName,
					});
				}

				if (mode === 'EDIT') {
					mixpanelActions.trackCannedResponse({
						eventName: mixPanelEvents.QUICK_REPLY_SAVED,
						labelName: labelName,
					});
				}
				setMode('EDIT');
				setEditBtnState((prevState) => {
					return {
						...prevState,
						isSuccessState: true,
						text: 'Saved',
					};
				});
			}
			setShowDeleteAlertModal(false);
		} catch (error) {
			// handle error
			setToastState({ info: '', error: error });
			toastRef.current?.publish();
		}
	};

	const handleBtnClick = () => {
		toastRef.current?.unPublish();
		const isCharExceeded = editData.text.trim().length > CANNED_RESPONSE_MESSAGE_CHARACTER_LIMIT;

		if (isCharExceeded) {
			setToastState((prevState) => {
				return {
					...prevState,
					error: `Reply cannot be longer than ${CANNED_RESPONSE_MESSAGE_CHARACTER_LIMIT} characters`,
					info: '',
				};
			});
			toastRef.current?.publish();
			return;
		}

		let nameExist = false;

		for (const cannedResponse of cannedResponseDataCopy) {
			const editDataNameLowerCase = editData.name.toLocaleLowerCase().trim();
			const cannedResponseNameLowerCase = cannedResponse.name.toLocaleLowerCase().trim();
			const selectedCannedResponseNameLowerCase = selectedCannedResponse?.name
				.toLocaleLowerCase()
				.trim();

			if (mode === 'ADD' && cannedResponseNameLowerCase === editDataNameLowerCase) {
				nameExist = true;
				break;
			}

			if (
				mode === 'EDIT' &&
				cannedResponseNameLowerCase === editDataNameLowerCase &&
				cannedResponseNameLowerCase !== selectedCannedResponseNameLowerCase
			) {
				nameExist = true;
				break;
			}
		}

		if (nameExist) {
			setToastState((prevState) => {
				return {
					...prevState,
					error: 'Label name already exists',
					info: '',
				};
			});
			toastRef.current?.publish();
			return;
		}

		let cannedResponseToBeFocused: IUserCannedResponse | null = null;

		if (mode === 'EDIT') {
			const newCannedData = cannedResponseDataCopy.map((prevCannedResponse) => {
				if (prevCannedResponse.name === selectedCannedResponse?.name) {
					cannedResponseToBeFocused = {
						...prevCannedResponse,
						name: editData.name.trim(),
						text: editData.text.trim(),
						bookmark_when_used: editData.bookmark,
					};
					return cannedResponseToBeFocused;
				} else {
					return { ...prevCannedResponse };
				}
			});
			handleCallEditApi(
				newCannedData,
				cannedResponseToBeFocused,
				'Quick reply Saved',
				editData.name.trim()
			);
			return;
		}

		if (mode === 'ADD') {
			let max_priority = cannedResponseDataCopy[0]?.priority ?? 0;

			const newCannedData: IUserCannedResponse[] = cannedResponseDataCopy.map((cannedResponse) => {
				if (cannedResponse.priority > max_priority) {
					max_priority = cannedResponse.priority;
				}
				return { ...cannedResponse };
			});

			const cannedResponseToAdd = {
				name: editData.name.trim(),
				text: editData.text.trim(),
				priority: max_priority + 1,
				bookmark_when_used: editData.bookmark,
			};
			newCannedData.unshift(cannedResponseToAdd);
			handleCallEditApi(
				newCannedData,
				cannedResponseToAdd,
				'Quick reply added',
				editData.name.trim()
			);
			return;
		}
	};

	const handleDeleteClick = () => {
		if (callEditCannedResponseStatus === 'loading') return;
		resetEditBtnState();
		setShowDeleteAlertModal(true);
	};

	const handleDelete = () => {
		const newCannedData: IUserCannedResponse[] = cannedResponseDataCopy.filter(
			(cannedResponse) => cannedResponse.name !== selectedCannedResponse?.name
		);

		handleCallEditApi(
			newCannedData,
			null,
			'Quick reply deleted',
			selectedCannedResponse?.name ?? ''
		);
	};

	const handleNameClick = (cannedResponse: IUserCannedResponse) => {
		if (callEditCannedResponseStatus === 'loading') return;
		setMode('EDIT');
		setSelectedCannedResponse(cannedResponse);
		setEditData((prevState) => {
			return {
				...prevState,
				name: cannedResponse.name,
				text: cannedResponse.text,
				bookmark: cannedResponse.bookmark_when_used ?? false,
			};
		});
		resetEditBtnState();
	};

	const handleOnChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;

		if (value.length > CANNED_RESPONSE_LABEL_CHARACTER_LIMIT) return;

		setEditData((prevState) => {
			return {
				...prevState,
				name: e.target.value,
			};
		});
		resetEditBtnState();
	};

	const handleOnChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setEditData((prevState) => {
			return {
				...prevState,
				text: e.target.value,
			};
		});
		resetEditBtnState();
	};

	const resetEditBtnState = () => {
		setEditBtnState((prevState) => {
			return {
				...prevState,
				isSuccessState: false,
				text: 'Save',
			};
		});
	};

	const handleBookmarkAction = (e: React.MouseEvent<HTMLDivElement | MouseEvent>) => {
		e.stopPropagation();
		setEditData((prevState) => {
			return {
				...prevState,
				bookmark: !editData.bookmark,
			};
		});
	};

	const handleTextAreaFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
		const textToAdd = `Hi ${cannedResponseReplaceWords.firstName}`;
		const ele = e.currentTarget;

		if (!editData.text) {
			setEditData((prevState) => {
				return { ...prevState, text: textToAdd };
			});
		}

		setTimeout(() => {
			ele?.setSelectionRange(textToAdd.length, textToAdd.length);
		}, 0);
	};

	const isAddSaveBtnDisabled = useMemo(() => {
		const editDataNameLowerCase = editData.name.toLocaleLowerCase().trim();
		const editDataTextLowerCase = editData.text.toLocaleLowerCase().trim();
		const editDataBookmark = editData.bookmark;

		if (!selectedCannedResponse) {
			return editDataNameLowerCase.length === 0 || editDataTextLowerCase.length === 0;
		}

		const selectedCannedResponseNameLowerCase = selectedCannedResponse.name
			.toLocaleLowerCase()
			.trim();
		const selectedCannedResponseTextLowerCase = selectedCannedResponse?.text
			.toLocaleLowerCase()
			.trim();
		const selectedCannedResponseBookmark = selectedCannedResponse?.bookmark_when_used ?? true;

		return (
			(selectedCannedResponseNameLowerCase === editDataNameLowerCase &&
				selectedCannedResponseTextLowerCase === editDataTextLowerCase &&
				editDataBookmark === selectedCannedResponseBookmark) ||
			editDataNameLowerCase.length === 0 ||
			editDataTextLowerCase.length === 0
		);
	}, [selectedCannedResponse, editData]);

	return (
		<>
			<Modal
				noPadding
				customClass={classes.modalContainer}
				showModal={showModal}
				onCloseModal={handleCloseIconClick}
			>
				<div className={classes.header}>
					<Text variant="h2" semiBold>
						{'Quick replies'}
					</Text>
					<Button btnText={<CloseIcon size={2} />} onClick={handleCloseIconClick} />
				</div>
				<div className={classes.content}>
					<div className={classes.namesSection}>
						<div
							className={clsx(classes.name, classes.addBtn, mode === 'ADD' && classes.selectedMode)}
							tabIndex={0}
							onClick={handleAddReplyClick}
						>
							<PlusIconV2 size={1.2} />
							<Text variant="h3" small secondary>
								{'Add a quick reply'}
							</Text>
						</div>
						{cannedResponseDataCopy.map((cannedResponse) => {
							const isSelected = selectedCannedResponse?.name === cannedResponse.name;
							return (
								<div
									className={clsx(classes.name, isSelected && classes.selectedMode)}
									key={cannedResponse.name}
									tabIndex={0}
									onClick={(e) => {
										e.stopPropagation();
										handleNameClick(cannedResponse);
									}}
								>
									<Text variant="h3" small secondary={!isSelected}>
										{cannedResponse.name}
									</Text>
								</div>
							);
						})}
					</div>
					<div className={classes.editSection}>
						<div className={classes.inputContainer}>
							<label htmlFor="cannedResponseName" className={classes.label}>
								<Text variant="span" small>
									{'Label'}
								</Text>
								<Text variant="p" secondary tiny ultraLight>
									{`Short internal name. Eg: ‘Share Calendly’`}
								</Text>
							</label>
							<input
								id="cannedResponseName"
								className={classes.input}
								type="text"
								value={editData.name}
								onChange={handleOnChangeName}
								placeholder={'Enter label name...'}
								autoFocus
								autoComplete="off"
							/>
						</div>
						<div className={classes.inputContainer}>
							<label htmlFor="cannedResponseTex" className={classes.label}>
								<Text variant="span" small>
									{'Reply'}
								</Text>
								<Text variant="p" secondary tiny ultraLight>
									{`Use {first_name} to automatically pick people’s first name`}
								</Text>
							</label>
							<textarea
								id="cannedResponseTex"
								className={classes.textAreaInput}
								value={editData.text}
								onChange={handleOnChangeText}
								placeholder={'Enter quick-reply text...'}
								autoComplete="off"
								onFocus={handleTextAreaFocus}
							/>
						</div>
						<div
							tabIndex={0}
							className={classes.bookmarkActionContainer}
							onClick={handleBookmarkAction}
						>
							<div className={clsx(classes.checkbox, editData.bookmark && classes.checkboxActive)}>
								{editData.bookmark && <TickIconColored size={1.6} />}
							</div>

							<Text variant="p" small light>
								{'Bookmark the chat automatically when using this template'}
							</Text>
						</div>
						<div className={classes.btnsContainer}>
							{mode === 'EDIT' && (
								<Button
									prefixIcon={<DeleteIcon size={1.6} />}
									btnText={
										<Text variant="span" secondary small light>
											{'Delete'}
										</Text>
									}
									onClick={handleDeleteClick}
									customClass={classes.deleteBtn}
								/>
							)}
							<Button
								btnText={
									<Text
										variant="span"
										disabled={isAddSaveBtnDisabled || !editBtnState.isSuccessState}
										white={!isAddSaveBtnDisabled || editBtnState.isSuccessState}
										small
									>
										{editBtnState.text}
									</Text>
								}
								prefixIcon={
									editBtnState.isSuccessState ? <CheckCircleIcon size={1.6} /> : undefined
								}
								primary
								isLoading={!showDeleteAlertModal && callEditCannedResponseStatus === 'loading'}
								disabled={isAddSaveBtnDisabled}
								onClick={handleBtnClick}
								customClass={clsx(
									classes.addSaveBtn,
									editBtnState.isSuccessState && classes.successStateBtn
								)}
							/>
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				header={'Are you sure?'}
				onCloseModal={handleCloseDeleteAlertModal}
				showModal={showDeleteAlertModal}
			>
				<div className={classes.deleteAlertModalContentContainer}>
					<Text variant="p" light small>
						{'This quick-reply will permanently be deleted. '}
					</Text>

					<div className={classes.buttonsContainer}>
						<Button
							btnText={
								<Text variant="span" small semiBold>
									{'Cancel'}
								</Text>
							}
							onClick={() => {
								setShowDeleteAlertModal(false);
							}}
							customClass={classes.modalCancelBtn}
							disabled={callEditCannedResponseStatus === 'loading'}
						/>
						<Button
							btnText={
								<Text variant="span" white small semiBold>
									{'Yes, Delete'}
								</Text>
							}
							onClick={handleDelete}
							customLoader={<Loader allWhite />}
							isLoading={callEditCannedResponseStatus === 'loading'}
							customClass={classes.modalDeleteBtn}
						/>
					</div>
				</div>
			</Modal>
			<Toast
				ref={toastRef}
				toastType={toastState.info ? 'SUCCESS' : 'ERROR'}
				header={toastState.info ? toastState.info : toastState.error}
				icon={
					toastState.info ? (
						<CheckCircleIcon size={1.8} />
					) : (
						<AlertIcon size={1.8} className={classes.toastIcon} />
					)
				}
			/>
		</>
	);
}

export default CannedResponseModal;
