import React from 'react';
import clsx from 'clsx';
import ITextProps from './Text.types';
import classes from './Text.styles.module.scss';
import { createTextStylesObject } from '@utils/common';

function Text(props: ITextProps) {
	const classNames = clsx(
		classes.commonTextStyles,
		props.brandPrimaryColor && classes.brandPrimaryColor,
		props.brandSecondaryColor && classes.brandSecondaryColor,
		props.brandTertiaryColor && classes.brandTertiaryColor,
		props.secondary && classes.secondary,
		props.tertiary && classes.tertiary,
		props.tertiaryV0 && classes.tertiaryV0,
		props.disabled && classes.disabled,
		props.bold && classes.bold,
		props.semiBold && classes.semiBold,
		props.light && classes.light,
		props.ultraLight && classes.ultraLight,
		props.extraLarge && classes.extraLarge,
		props.large && classes.large,
		props.medium2M && classes.medium2M,
		props.extraMedium && classes.extraMedium,
		props.medium && classes.medium,
		props.small && classes.small,
		props.tiny && classes.tiny,
		props.extraTiny && classes.extraTiny,
		props.white && classes.white,
		props.error && classes.error,
		props.success && classes.success,
		props.customClass && props.customClass
	);

	const stylesObj = createTextStylesObject({
		color: props.color,
		fontSize: props.fontSize,
		lineHeight: props.lineHeight,
	});

	const Text = props.variant;

	return (
		<Text className={classNames} style={stylesObj}>
			{props.children}
		</Text>
	);
}

export default Text;
