import React, { useEffect } from 'react';
import Text from '@components/ui/Text';
import { NotFoundIcon } from '@src/hoc/withIconStyles';
import Button from '@components/ui/Button';
import { useNavigate } from 'react-router';
import { INBOX_PATH, mixPanelEvents } from '@utils/constants';
import classes from './PageNotFound.styles.module.scss';
import useWindowSize from '@hooks/useWindow';
import OnboardingLayout from '@components/OnboardingLayout';
import mixpanelActions from '@utils/mixpanel';

function PageNotFound() {
	const { isMobile } = useWindowSize();

	useEffect(() => {
		mixpanelActions.trackPageNotFound(mixPanelEvents.PAGE_NOT_FOUND_SHOWN);
	}, []);

	const navigate = useNavigate();

	const handleHomeBtnClick = () => {
		navigate(INBOX_PATH, { replace: true });
	};

	return (
		<OnboardingLayout>
			<section className={classes.container}>
				<section className={classes.card}>
					<NotFoundIcon size={isMobile ? 6.4 : 12} />
					<Text variant="h1" large={!isMobile} medium={isMobile} semiBold>
						{'Page not found'}
					</Text>
					<Text variant="p" secondary medium={!isMobile} small={isMobile} light>
						{`Sorry, the page you are looking for doesn’t exist, or have been moved.`}
					</Text>
					<Button
						btnText={
							<Text variant="span" white medium={!isMobile} small={isMobile} semiBold>
								{'Go to homepage'}
							</Text>
						}
						primary
						onClick={handleHomeBtnClick}
						customClass={classes.homeBtn}
					/>
				</section>
			</section>
		</OnboardingLayout>
	);
}

export default PageNotFound;
