import {
	IDiscoveryListApiResponse,
	IDiscoveryListObjApiResponse,
	IGetDiscoveryListObjPayload,
	IUserDetailsLoginApiResponse,
	IUserDetailsRegisterApiResponse,
	IUserLinkedInProfileMetaDataApiResponse,
	IUserLoginPayload,
	IUserMetaDataAPIResponse,
	IUserRegisterPayload,
	IUsernameExistAPIResponse,
} from './auth.types';
import { appAxiosAuthInstance, appAxiosAuthInstanceV2 } from '@config/axios';
import {
	getStructuredDiscoveryList,
	getStructuredDiscoveryListObj,
	getStructuredDraftUser,
	getStructuredUser,
	getStructuredUserLinkedProfileInMetaData,
	getStructuredUserMetaData,
} from '@src/models/user';

export const loginUser = async (payload: IUserLoginPayload) => {
	const res = await appAxiosAuthInstance.post('/login/', payload, {
		headers: {
			['Login-Type']: payload.login_type,
		},
	});
	const data: IUserDetailsLoginApiResponse = res.data;
	return {
		jwtToken: data.jwt_token,
		userData: getStructuredUser(data.user),
		draftUser: getStructuredDraftUser(data.draft_user),
		draftJWTToken: data.draft_jwt_token,
	};
};

export const registerUser = async (payload: IUserRegisterPayload) => {
	const res = await appAxiosAuthInstance.post('/login/register', payload);

	const data: IUserDetailsRegisterApiResponse = res.data;

	return {
		jwtToken: data.jwt_token,
		userData: getStructuredUser(data.user),
	};
};

export const usernameExists = async (username: string) => {
	const payload = {
		username,
	};

	const res = await appAxiosAuthInstance.post('/login/username-exists', payload);
	return res.data as IUsernameExistAPIResponse;
};

export const getUserMetaData = async (username: string) => {
	const payload = {
		username,
	};
	const res = await appAxiosAuthInstance.post('/users/meta-data/fetch', payload);
	const data: IUserMetaDataAPIResponse = res.data;

	return getStructuredUserMetaData(data);
};

export const generateOTP = async (email: string) => {
	const payload = {
		email: email,
	};

	const res = await appAxiosAuthInstance.post('/login/generate-otp', payload);

	return res.data;
};

export const getLinkedInProfileMetaData = async (payload: IUserRegisterPayload) => {
	const res = await appAxiosAuthInstance.post('/login/fetch-linkedin-profile', payload);

	const data = res.data as IUserLinkedInProfileMetaDataApiResponse;

	return getStructuredUserLinkedProfileInMetaData(data);
};

export const getHomePageDiscoveryList = async () => {
	const res = await appAxiosAuthInstance.post('/discovery/home-page');

	const data = res.data as IDiscoveryListApiResponse[];

	return getStructuredDiscoveryList(data);
};

export const getDiscoveryListObj = async (payload: IGetDiscoveryListObjPayload) => {
	const res = await appAxiosAuthInstanceV2.post('/discovery/', payload);

	const data = res.data as IDiscoveryListObjApiResponse;

	return getStructuredDiscoveryListObj(data);
};
