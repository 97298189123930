import React, { useEffect } from 'react';
import Text from '@components/ui/Text';
import classes from './ErrorBoundary.styles.module.scss';
import Button from '@components/ui/Button';
import mixpanelActions from '@utils/mixpanel';
import { REFRESH_TIMESTAMP, mixPanelEvents } from '@utils/constants';

function ErrorBoundary() {
	useEffect(() => {
		mixpanelActions.trackAppCrash(mixPanelEvents.APP_CRASH_ERROR_SHOWN);
	}, []);

	const handleBtnClick = () => {
		const urlSearchParams = new URLSearchParams(window.location.search);

		urlSearchParams.set(REFRESH_TIMESTAMP, Date.now().toString());

		const updatedURL =
			window.location.origin + window.location.pathname + '?' + urlSearchParams.toString();

		window.location.replace(updatedURL);
	};

	return (
		<main className={classes.errorBoundaryContainer}>
			<Text variant="h1" large bold>
				Oops, something went wrong!
			</Text>
			<Text variant="p" medium light secondary>
				Please reload the app...
			</Text>
			<Button
				btnText={
					<Text variant="span" white semiBold>
						Reload
					</Text>
				}
				onClick={handleBtnClick}
				primary
			/>
		</main>
	);
}

export default ErrorBoundary;
