import React from 'react';
import * as RadixAvatar from '@radix-ui/react-avatar';
import classes from './Avatar.styles.module.scss';
import { IAvatarProps } from './Aavatar.types';
import clsx from 'clsx';

function Avatar({
	profilePicURL,
	size,
	customRootClass,
	fallbackIcon: FallbackIcon,
}: IAvatarProps) {
	return (
		<RadixAvatar.Root
			className={clsx(customRootClass && customRootClass)}
			style={{
				height: `${size}rem`,
				width: `${size}rem`,
				minHeight: `${size}rem`,
				minWidth: `${size}rem`,
				display: 'inline-block',
			}}
		>
			<RadixAvatar.Image className={classes.avatar} src={profilePicURL} alt={'profile pic'} />
			<RadixAvatar.Fallback
				className={clsx(customRootClass && customRootClass)}
				style={{
					height: `${size}rem`,
					width: `${size}rem`,
					minHeight: `${size}rem`,
					minWidth: `${size}rem`,
					display: 'inline-block',
				}}
			>
				{FallbackIcon && <FallbackIcon height={'100%'} width={'100%'} />}
			</RadixAvatar.Fallback>
		</RadixAvatar.Root>
	);
}

export default Avatar;
