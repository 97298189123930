import React, { useMemo } from 'react';
import classes from './ChatsSkeletonLoader.styles.module.scss';
import ChatsUserSkeletonLoader from './ChatsUserSkeletonLoader';

function ChatsSkeletonLoader() {
	const skeletons = useMemo(() => {
		return new Array(3).fill(0);
	}, []);

	return (
		<div className={classes.loaderListContainer}>
			{skeletons.map((_, index) => (
				<div key={index} className={classes.chatLoaderContainer}>
					<ChatsUserSkeletonLoader />
					<div className={classes.recentMessageLoader}></div>
				</div>
			))}
		</div>
	);
}

export default ChatsSkeletonLoader;
