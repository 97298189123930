import React from 'react';
import Button from '@components/ui/Button';
import Text from '@components/ui/Text';
import classes from './HeaderContainer.styles.module.scss';
import { IHeaderContainerProps } from './HeaderContainer.types';
import { CloseIcon } from '@src/hoc/withIconStyles';

function HeaderContainer(
	{ headerText, onCloseIconClick, rightActions }: IHeaderContainerProps,
	ref: React.ForwardedRef<HTMLDivElement | null>
) {
	const handleCloseIconClick = () => {
		!!onCloseIconClick && onCloseIconClick();
	};

	return (
		<div className={classes.headerContainer} ref={ref}>
			{!!onCloseIconClick && (
				<Button
					btnText={<CloseIcon size={2.4} className={classes.closeIcon} />}
					onClick={handleCloseIconClick}
				/>
			)}
			<Text variant="h2" medium semiBold>
				{headerText}
			</Text>
			{rightActions}
		</div>
	);
}

export default React.forwardRef(HeaderContainer);
