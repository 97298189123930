import React from 'react';
import clsx from 'clsx';
import Avatar from '@components/ui/Avatar';
import FallbackPicIcon from '@public/icons/fallbackPicIcon.svg';
import { IDiscoveryCardProps } from './DiscoveryCard.types';
import classes from './DiscoveryCard.styles.module.scss';
import Text from '@components/ui/Text';
import Button from '@components/ui/Button';
import { useNavigate } from 'react-router-dom';
import useWindowSize from '@hooks/useWindow';
import { CHAT_SEARCH_PARAM, CURRENT_CLICKED_DISCOVERY_USER, INBOX_PATH } from '@utils/constants';
import { setSessionStorage } from '@utils/sessionStorage';

function DiscoveryCard({
	bio,
	fullName,
	profilePicURL,
	updatesTextList,
	username,
	onClickSendDM,
	customRootClass,
	hideSendDMButton,
}: IDiscoveryCardProps) {
	const { isMobile } = useWindowSize();

	const navigate = useNavigate();

	const handleClickSendSuperDM = (username: string) => () => {
		if (!onClickSendDM) return;

		onClickSendDM(username);

		setSessionStorage(CURRENT_CLICKED_DISCOVERY_USER, username);

		const searchParams = new URLSearchParams();
		searchParams.set(CHAT_SEARCH_PARAM, encodeURIComponent(username));

		navigate(`${INBOX_PATH}?${searchParams.toString()}`);
	};

	const updateTextToDisplay = updatesTextList[0] || '';

	return (
		<div
			className={clsx(classes.discoveryCard, !!customRootClass && customRootClass)}
			tabIndex={0}
			onClick={handleClickSendSuperDM(username)}
		>
			<Avatar
				size={isMobile ? 8 : 9.6}
				profilePicURL={profilePicURL}
				fallbackIcon={FallbackPicIcon}
				customRootClass={classes.avatar}
			/>
			<Text variant={'h2'} medium lineHeight={2.4}>
				{fullName}
			</Text>
			<Text variant={'p'} small lineHeight={2} light tertiary>
				{bio}
			</Text>

			{!!updateTextToDisplay && (
				<Text
					variant="span"
					small
					lineHeight={isMobile ? 1.8 : 2}
					color={'#0F9E6F'}
					customClass={classes.updatesText}
				>
					<Text variant="span" lineHeight={2.2} customClass={classes.dot} />
					{updateTextToDisplay}
				</Text>
			)}

			{!hideSendDMButton && (
				<Button
					btnText={
						<Text variant="span" lineHeight={2.2} white>
							{'Send a SuperDM'}
						</Text>
					}
					onClick={handleClickSendSuperDM(username)}
					customClass={classes.sendSuperDMButton}
				/>
			)}
		</div>
	);
}

export default DiscoveryCard;
