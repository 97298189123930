import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/index';
import {
	getUserDetailsFromGlobalState,
	setDiscoveryListObj,
} from '@store/userDetailsSlice/userDetailsSlice';
import useFetch from '@hooks/useFetch';
import Offline from '@components/Offline';
import { getDiscoveryListObj } from '@api/auth';
import DotsLoader from '@components/ui/DotsLoader';
import Text from '@components/ui/Text';
import Button from '@components/ui/Button';
import { getFullNameUserMetaDataFunc, isProUserFunc } from '@src/models/user';
import { LogoWithWhiteBgIcon } from '@src/hoc/withIconStyles';
import {
	DISCOVER_PAGE_LOCATION_PARAM,
	DISCOVER_PAGE_TAB_PARAM,
	DISCOVERY_PAGE_LOCATION_VALUES_MAP,
	EARLY_ACCESS_FORM,
	mixPanelEvents,
} from '@utils/constants';
import classes from './DiscoverPage.styles.module.scss';
import useWindowSize from '@hooks/useWindow';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DiscoveryCard from './DiscoveryCard';
import mixpanelActions from '@utils/mixpanel';
import { areURLSearchParamsEqual, getValidUpdatedDiscoverPageSearchToUpdate } from '@utils/common';
import { IGetDiscoveryListObjPayload } from '@api/auth/auth.types';
import useDelayedLoader from '@hooks/useDelayedLoader';

function DiscoverPage() {
	const { isMobile } = useWindowSize();
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const [searchParams, setSearchParams] = useSearchParams();

	const [selectedTab, setSelectedTab] = React.useState<string | null>(null);
	const layoutRef = React.useRef<HTMLDivElement | null>(null);
	const { discoveryListObj, data: userDetails } = useSelector(getUserDetailsFromGlobalState);

	const { callApi: callDiscoveryListObj, status: callDiscoveryListObjStatus } =
		useFetch(getDiscoveryListObj);

	const updatedSearchParamsState = useMemo(() => {
		const searchParamsToUpdate = getValidUpdatedDiscoverPageSearchToUpdate(searchParams);

		return {
			searchParamsToUpdate,
			areURLSearchParamsValid: areURLSearchParamsEqual(searchParams, searchParamsToUpdate),
		};
	}, [searchParams]);

	const isLoading =
		(callDiscoveryListObjStatus === 'loading' || callDiscoveryListObjStatus === 'idle') &&
		!discoveryListObj;
	const showLoader = useDelayedLoader(isLoading);
	const isError = callDiscoveryListObjStatus === 'error';

	const isProUser = isProUserFunc(userDetails);
	const showEarlyAccessBtn = !isProUser;

	const showTabs = discoveryListObj?.tabsRequired;

	const tabsToDisplay = useMemo(() => {
		if (!discoveryListObj) return [];

		return discoveryListObj.tabs.map((listItem) => listItem.tabName);
	}, [discoveryListObj]);

	const usersListToDisplay = useMemo(() => {
		if (!selectedTab || !discoveryListObj) return [];

		const selectedTabData = discoveryListObj.tabs.find(
			(listItem) => listItem.tabName === selectedTab
		);

		if (!selectedTabData) return [];

		return [...selectedTabData.items];
	}, [selectedTab, discoveryListObj]);

	const handleClickEarlyAccessBtn = () => {
		mixpanelActions.trackDiscover({
			eventName: mixPanelEvents.DISCOVER_PAGE_EARLY_ACCESS_CLICKED,
		});
		window.open(EARLY_ACCESS_FORM, '_blank');
	};

	const handleOnClickTab = (tabName: string) => () => {
		mixpanelActions.trackDiscover({
			eventName: mixPanelEvents.DISCOVER_PAGE_TAB_CLICKED,
			tabName,
		});

		setSearchParams((prevSearchParams) => {
			const searchParamsToUpdate = new URLSearchParams(prevSearchParams);
			searchParamsToUpdate.set(
				DISCOVER_PAGE_TAB_PARAM,
				encodeURIComponent(tabName.toLocaleLowerCase())
			);
			return searchParamsToUpdate;
		});
	};

	const handleLogoClick = () => {
		// meaning the navigation stack has one path
		const isPathRenderedAsInitialRoute = window.history.state?.idx === 0;

		isPathRenderedAsInitialRoute ? navigate('/') : navigate(-1);
	};

	// handling mixpanel event for page open
	React.useEffect(() => {
		mixpanelActions.trackDiscover({
			eventName: mixPanelEvents.DISCOVER_PAGE_OPENED,
		});
	}, []);

	// handling call to fetch discovery list
	React.useEffect(() => {
		if (discoveryListObj) return;

		(async function () {
			if (!updatedSearchParamsState.areURLSearchParamsValid) {
				setSearchParams(updatedSearchParamsState.searchParamsToUpdate, {
					replace: true,
				});
				return;
			}

			try {
				const locationParamFromUrl = searchParams
					.get(DISCOVER_PAGE_LOCATION_PARAM)
					?.toLocaleLowerCase();

				const payload: IGetDiscoveryListObjPayload = {
					location_from_url: locationParamFromUrl
						? DISCOVERY_PAGE_LOCATION_VALUES_MAP[locationParamFromUrl] ?? null
						: null,
				};

				const resData = await callDiscoveryListObj(payload);
				dispatch(setDiscoveryListObj({ discoveryListObj: resData }));
			} catch {
				// handle error
			}
		})();
	}, [updatedSearchParamsState]);

	// handling selected tab
	React.useEffect(() => {
		if (!tabsToDisplay.length) return;

		const tabNameFromURL = searchParams.get(DISCOVER_PAGE_TAB_PARAM)?.toLocaleLowerCase();

		for (const tabName of tabsToDisplay) {
			if (tabName.toLocaleLowerCase() === tabNameFromURL) {
				setSelectedTab(tabName);
				return;
			}
		}

		setSelectedTab(tabsToDisplay[0]);
	}, [tabsToDisplay, searchParams]);

	if (isError) {
		return (
			<main className={classes.errorContainer}>
				<Offline />
			</main>
		);
	}

	if (isLoading) {
		return <main className={classes.loadingContainer}>{showLoader && <DotsLoader />}</main>;
	}

	return (
		<div className={classes.discoveryLayout} ref={layoutRef}>
			<header className={classes.header}>
				<Button
					btnText={<LogoWithWhiteBgIcon size={isMobile ? 2.8 : 4} />}
					onClick={handleLogoClick}
					customClass={classes.logoBtn}
				/>

				{showEarlyAccessBtn && (
					<Button
						btnText={
							<Text variant={'span'} tiny={isMobile} lineHeight={isMobile ? 1.6 : 2.2}>
								{'Get early access'}
							</Text>
						}
						onClick={handleClickEarlyAccessBtn}
						customClass={classes.earlyAccessBtn}
					/>
				)}
			</header>
			<main>
				<Text
					variant="h1"
					fontSize={isMobile ? 2.4 : 4.8}
					lineHeight={isMobile ? 2.8 : 5.8}
					semiBold
					customClass={classes.mainHeaderText}
				>
					{'Folks who are a DM away.'}
				</Text>
				{showTabs && (
					<section className={classes.tabsContainer}>
						{tabsToDisplay.map((tabName) => {
							const isSelectedTab = selectedTab === tabName;
							return (
								<Button
									key={tabName}
									btnText={
										<Text
											variant="span"
											small={isMobile}
											lineHeight={isMobile ? 1.8 : 2.2}
											color={isSelectedTab ? '#E3F0FE' : '#2B333B'}
										>
											{tabName}
										</Text>
									}
									onClick={handleOnClickTab(tabName)}
									customClass={clsx(classes.tabBtn, isSelectedTab && classes.activeTabBtn)}
								/>
							);
						})}
					</section>
				)}
				<section
					className={clsx(classes.usersListContainer, !showTabs && classes.userListContainerMargin)}
					key={`${selectedTab}`}
				>
					{usersListToDisplay.map((user) => {
						return (
							<DiscoveryCard
								key={`${user.username} - ${selectedTab}`}
								bio={user.bio}
								username={user.username}
								profilePicURL={user.imageURL}
								fullName={getFullNameUserMetaDataFunc(user)}
								updatesTextList={user.socialProof}
								onClickSendDM={(username) => {
									mixpanelActions.trackDiscover({
										eventName: mixPanelEvents.DISCOVER_PAGE_CARD_CLICKED,
										chatUsername: username,
									});
								}}
							/>
						);
					})}
				</section>
			</main>
		</div>
	);
}

export default DiscoverPage;
