import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import {
	IFetchChatMessagesActionCreatorParams,
	IFetchMoreChatMessagesActionCreatorParams,
	ISelectedChatState,
} from './selectedChatSlice.types';
import { getChatMessages } from '@api/chats';
import { IFetchChatMessagesPayload } from '@api/chats/chats.types';
import { FETCH_CHATS_MESSAGES_PAGE_SIZE } from '@utils/constants';
import { ForwardToEmailApiResponse } from '@src/models/message';

const initialState: ISelectedChatState = {
	selectedChat: null,
	selectedInboxCategory: null,
	allChatMessages: [],
	cursorPagination: null,
	fetchChatMessagesStatus: 'idle',
	fetchChatMessagesError: null,
	fetchMoreChatMessagesStatus: 'idle',
	fetchMoreChatMessagesError: null,
	chatLastMessageIdFromList: null,
	selectedChatMessageToRetry: null,
	showBookmarkNudgeMessageId: null,
	triggerMessagesToScrollBottom: false,
	currFetchRequestId: null,
	currSessionInitiatedChatsIdMap: {},
};

export const fetchMoreChatMessages = createAsyncThunk(
	'selectedChat/fetchMoreChatMessages',
	async ({
		chatId,
		lastMessageId,
		username,
		userId,
	}: IFetchMoreChatMessagesActionCreatorParams) => {
		const payload: IFetchChatMessagesPayload = {
			chat_id: chatId,
			cursor: {
				last_message_id: lastMessageId,
				page_size: FETCH_CHATS_MESSAGES_PAGE_SIZE,
			},
			username: username,
			user_id: userId,
		};
		const data = await getChatMessages(payload);
		return data;
	},
	{
		condition: (_, { getState }) => {
			const state = getState() as RootState;
			const { cursorPagination, fetchMoreChatMessagesStatus } = state.selectedChat;

			const hasMoreChats = cursorPagination?.hasNextMessage;

			if (!hasMoreChats || fetchMoreChatMessagesStatus !== 'idle') return false;

			return true;
		},
		dispatchConditionRejection: true,
	}
);

export const fetchChatMessages = createAsyncThunk(
	'selectedChat/fetchChatMessages',
	async ({ chatId, username, userId }: IFetchChatMessagesActionCreatorParams) => {
		const payload: IFetchChatMessagesPayload = {
			chat_id: chatId,
			cursor: {
				last_message_id: null,
				page_size: FETCH_CHATS_MESSAGES_PAGE_SIZE,
			},
			username: username,
			user_id: userId,
		};

		const data = await getChatMessages(payload);
		return data;
	}
);

const selectedChatSlice = createSlice({
	name: 'selectedChat',
	initialState: initialState,
	reducers: {
		addCurrMessage(state, action) {
			const messageToAdd = action.payload.messageToAdd;
			if (
				state.selectedChat &&
				(state.selectedChat.chatId === messageToAdd?.chatId || state.selectedChat.chatId === null)
			) {
				state.allChatMessages.push(messageToAdd);
				state.selectedChat.replied = true;
			}
		},
		setMessageEmailSignifier(state, action) {
			const forwardToEmailData = action.payload.forwardToEmailData as ForwardToEmailApiResponse;

			const messageId = forwardToEmailData.forwardAfterMessageId;
			const emailSignifier = forwardToEmailData.emailSignifier;

			for (let i = state.allChatMessages.length - 1; i >= 0; i--) {
				if (state.allChatMessages[i].id === messageId) {
					state.allChatMessages[i].emailSignifier = emailSignifier;
					break;
				}
			}
		},
		setMessageStatus(state, action) {
			const { messageId, status, updatedMessageData } = action.payload;

			for (let i = state.allChatMessages.length - 1; i >= 0; i--) {
				if (state.allChatMessages[i].id === messageId) {
					if (updatedMessageData) {
						state.allChatMessages[i] = { ...updatedMessageData };
					}

					state.allChatMessages[i].status = status;

					if (status === 'SENT') {
						const message = state.allChatMessages[i];
						state.allChatMessages.splice(i, 1);
						state.allChatMessages.push(message);
					}
					break;
				}
			}
		},
		setBookmarkNudgeMessageId(state, action) {
			const { messageId } = action.payload;
			state.showBookmarkNudgeMessageId = messageId;
		},
		setCurrChatMessages(state, action) {
			if (state.selectedChat && state.selectedChat.chatId === action.payload.chatId) {
				const reverseArr = action.payload.polledChatMessages.reverse();
				state.allChatMessages = [...state.allChatMessages, ...reverseArr];
				state.selectedChat.receiverReplied = true;
			}
		},
		setSelectedChat(state, action) {
			state.selectedChat = action.payload.selectedChatData ?? null;
			state.selectedInboxCategory = action.payload.selectedChatData?.label ?? null;
			state.chatLastMessageIdFromList = action.payload.latestMessageId ?? null;
			state.allChatMessages = initialState.allChatMessages;
			state.cursorPagination = initialState.cursorPagination;
			state.fetchChatMessagesStatus = initialState.fetchChatMessagesStatus;
			state.fetchChatMessagesError = initialState.fetchChatMessagesError;
			state.fetchMoreChatMessagesStatus = initialState.fetchMoreChatMessagesStatus;
			state.fetchMoreChatMessagesError = initialState.fetchMoreChatMessagesError;
			state.selectedChatMessageToRetry = initialState.selectedChatMessageToRetry;
			state.showBookmarkNudgeMessageId = initialState.showBookmarkNudgeMessageId;
			state.triggerMessagesToScrollBottom = initialState.triggerMessagesToScrollBottom;
			state.currFetchRequestId = initialState.currFetchRequestId;
			state.currSessionInitiatedChatsIdMap = initialState.currSessionInitiatedChatsIdMap;
		},
		setSelectedChatId(state, action) {
			if (state.selectedChat && state.selectedChat?.chatId === null) {
				state.selectedChat.chatId = action.payload.chatId;
				state.selectedChat.label = state.selectedInboxCategory ?? '';
				state.currSessionInitiatedChatsIdMap[action.payload.chatId] = true;
			}
		},
		setAllChatMessages(state, action) {
			state.allChatMessages = action.payload.allChatMessages;
		},
		setSelectedInboxCategory(state, action) {
			state.selectedInboxCategory = action.payload.selectedInboxCategory;
		},
		setSelectedChatMessageToRetry(state, action) {
			state.selectedChatMessageToRetry = action.payload.selectedChatMessageToRetry;
		},
		triggerMessagesToScrollBottom(state) {
			state.triggerMessagesToScrollBottom = !state.triggerMessagesToScrollBottom;
		},
		clearSelectedChat() {
			return initialState;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchChatMessages.pending, (state, action) => {
				state.fetchChatMessagesStatus = 'loading';
				state.currFetchRequestId = action.meta.requestId;
			})
			.addCase(fetchChatMessages.fulfilled, (state, action) => {
				const requestId = action.meta.requestId;
				const currRequestId = state.currFetchRequestId;

				if (requestId === currRequestId) {
					state.fetchChatMessagesStatus = 'success';
					const reverseArr = (action.payload?.messages ?? []).reverse();
					state.allChatMessages = reverseArr;
					state.cursorPagination = action.payload?.cursor ?? null;
					state.currFetchRequestId = null;
				}
			})
			.addCase(fetchChatMessages.rejected, (state, { error, meta }) => {
				const requestId = meta.requestId;
				const currRequestId = state.currFetchRequestId;

				if (requestId === currRequestId) {
					state.fetchChatMessagesStatus = 'error';
					state.fetchChatMessagesError =
						error.message ?? 'There was some problem while fetching chat messages data...';
					state.currFetchRequestId = null;
				}
			})
			.addCase(fetchMoreChatMessages.pending, (state, action) => {
				state.fetchMoreChatMessagesStatus = 'loading';
				state.currFetchRequestId = action.meta.requestId;
			})
			.addCase(fetchMoreChatMessages.fulfilled, (state, action) => {
				const requestId = action.meta.requestId;
				const currRequestId = state.currFetchRequestId;

				if (requestId === currRequestId) {
					state.fetchMoreChatMessagesStatus = 'idle';
					const reversedArr = (action.payload?.messages ?? []).reverse();
					state.allChatMessages.unshift(...reversedArr);
					state.cursorPagination = action.payload?.cursor ?? null;
					state.currFetchRequestId = null;
				}
			})
			.addCase(fetchMoreChatMessages.rejected, (state, { error, meta }) => {
				const requestId = meta.requestId;
				const currRequestId = state.currFetchRequestId;

				if (requestId === currRequestId) {
					state.fetchMoreChatMessagesStatus = 'idle';
					state.fetchMoreChatMessagesError =
						error.message ?? 'There was some problem while fetching chat messages data...';
					state.currFetchRequestId = null;
				}
			});
	},
});

export const {
	clearSelectedChat,
	setMessageEmailSignifier,
	setSelectedChat,
	addCurrMessage,
	setMessageStatus,
	setBookmarkNudgeMessageId,
	setCurrChatMessages,
	setSelectedInboxCategory,
	setSelectedChatMessageToRetry,
	triggerMessagesToScrollBottom,
	setSelectedChatId,
	setAllChatMessages,
} = selectedChatSlice.actions;

export const getSelectedChatDetailsFromGlobalState = (state: RootState) => state.selectedChat;

export default selectedChatSlice.reducer;
