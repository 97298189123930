import { createRoot } from 'react-dom/client';
import App from '@src/App';
import registerServiceWorker from './serviceWorkerRegistration';

const rootEle = document.getElementById('root');

if (rootEle) {
	const root = createRoot(rootEle);

	root.render(App());
} else {
	console.error('element not found');
}

registerServiceWorker();
