import React from 'react';
import clsx from 'clsx';
import { CheckIcon } from '@src/hoc/withIconStyles';
import Text from '@components/ui/Text';
import { ISelectItemChildProps } from './SelectItemChild.types';
import classes from './SelectItemChild.styles.module.scss';

function SelectItemChild({ displayName, isSelected, version }: ISelectItemChildProps) {
	return (
		<div className={classes.container}>
			<div
				className={clsx(
					classes.itemCommonStyles,
					version === 'checkbox' && classes.checkBoxItem,
					version === 'radio' && classes.radioItem,
					version === 'select all' && classes.selectAllItem,
					isSelected && classes.itemActiveCommonStyles,
					version === 'radio' && isSelected && classes.radioItemActive,
					version === 'select all' && isSelected && classes.selectAllItemActive
				)}
			>
				{version === 'checkbox' && isSelected && <CheckIcon size={1.2} />}
				{version === 'select all' && isSelected && (
					<CheckIcon size={1.2} className={classes.selectAllCheckIcon} />
				)}
			</div>
			<Text variant="span" light small>
				{displayName}
			</Text>
		</div>
	);
}

export default SelectItemChild;
