import React from 'react';
import clsx from 'clsx';
import classes from './LinkedInProfileOverview.styles.module.scss';
import { useSelector } from 'react-redux';
import { getSelectedChatDetailsFromGlobalState } from '@store/selectedChatSlice/selectedChatSlice';
import Avatar from '@components/ui/Avatar';
import Text from '@components/ui/Text';
import { getMonthsDifference, getTotalYearsMonthText } from '@utils/date';
import Button from '@components/ui/Button';
import FallbackPicIcon from '@public/icons/fallbackPicIcon.svg';
import ExperienceFallbackIcon from '@public/icons/experienceFallbackIcon.svg';
import EducationFallbackIcon from '@public/icons/educationFallbackIcon.svg';
import { useNavigate } from 'react-router-dom';
import { PROFILE_PATH, mixPanelEvents } from '@utils/constants';
import mixpanelActions from '@utils/mixpanel';
import { UnverifiedIcon } from '@src/hoc/withIconStyles';
import {
	getFullNameSenderProfileFunc,
	getTimePeriodTextEdFunc,
	isLinkedInVerificationPendingChatSenderFunc,
} from '@src/models/inbox';
import Tooltip from '@components/ui/Tooltip';

function LinkedInProfileOverview() {
	const navigate = useNavigate();
	const { selectedChat } = useSelector(getSelectedChatDetailsFromGlobalState);

	const selectedChatUserProfileData = selectedChat?.senderDetails?.profileData;
	const selectedChatLinkedInVerificationPending = selectedChat?.senderDetails
		? isLinkedInVerificationPendingChatSenderFunc(selectedChat.senderDetails)
		: false;

	const experienceArrMap = selectedChatUserProfileData?.experience ?? {};

	const experienceArrCompanyOrder = selectedChatUserProfileData?.experienceCompanyOrder ?? [];

	const expArrToDisplay = experienceArrCompanyOrder.slice(0, 2);
	const moreExpText =
		experienceArrCompanyOrder.length > 2 ? `+${experienceArrCompanyOrder.length - 2} more` : '';

	const educationArr = selectedChatUserProfileData?.education ?? [];
	const educationArrToDisplay = educationArr.slice(0, 1);
	const moreEducationText = educationArr.length > 1 ? `+${educationArr.length - 1} more` : '';

	const selectedChatUsername = selectedChat?.senderDetails?.username;

	const handleViewProfileBtnClick = () => {
		mixpanelActions.trackChatInboxCategory(
			mixPanelEvents.INBOX_VIEW_FULL_PROFILE_CLICKED,
			selectedChatUsername ?? '',
			'no'
		);
		navigate(PROFILE_PATH + `/${selectedChat?.senderDetails?.username}`);
	};

	return (
		<article className={classes.profileSection} onClick={handleViewProfileBtnClick}>
			<section className={classes.Intro}>
				<Avatar
					size={5.2}
					profilePicURL={selectedChatUserProfileData?.profilePicture}
					fallbackIcon={FallbackPicIcon}
				/>
				<div className={classes.nameWithIcon} onClick={(e) => e.stopPropagation()}>
					<Text variant="h2" semiBold>
						{selectedChatUserProfileData
							? getFullNameSenderProfileFunc(selectedChatUserProfileData)
							: ''}
					</Text>
					{selectedChatLinkedInVerificationPending && (
						<Tooltip
							content={
								<Text variant="span" small light>
									{`This user's profile was created via their LinkedIn URL. They are yet to verify that the LinkedIn account for that URL belongs to them. `}
								</Text>
							}
						>
							<UnverifiedIcon size={1.8} className={classes.pendingIcon} />
						</Tooltip>
					)}
				</div>

				<Text variant="p" light tiny customClass={classes.headline}>
					{selectedChatUserProfileData?.headline ?? ''}
				</Text>
				<Text variant="p" light tertiary tiny customClass={classes.location}>
					{selectedChatUserProfileData?.location?.default ?? ''}
				</Text>
			</section>

			{!!expArrToDisplay.length && (
				<section className={classes.experienceSection}>
					<Text variant="h2" extraTiny tertiary semiBold>
						{'EXPERIENCE'}
					</Text>
					{expArrToDisplay.map((companyNameInOrder) => {
						const experience = experienceArrMap[companyNameInOrder];

						if (!experience) return null;

						const companyExperienceArr = experience.allExperiences;
						const company = companyExperienceArr[0];

						const totalCompanyExpMonths = companyExperienceArr.reduce((totalMonths, eachExp) => {
							return (
								totalMonths +
								getMonthsDifference(eachExp.startDate ?? null, eachExp.endDate ?? null)
							);
						}, 0);

						const totalYearsMonthText = getTotalYearsMonthText(totalCompanyExpMonths);

						const moreCompanyExpText =
							companyExperienceArr.length > 1
								? ` and ${companyExperienceArr.length - 1} other ${
										companyExperienceArr.length - 1 > 1 ? 'roles' : 'role'
										// eslint-disable-next-line no-mixed-spaces-and-tabs
								  }`
								: '';

						return (
							<div className={classes.experienceInfo} key={companyNameInOrder}>
								<Avatar
									size={3.6}
									profilePicURL={company.companyImageURL}
									customRootClass={classes.experienceLogo}
									fallbackIcon={ExperienceFallbackIcon}
								/>

								<div className={classes.experienceContent}>
									<Text variant="h3" tiny>
										{company.company}
									</Text>

									<Text
										variant="p"
										light
										tertiary
										tiny
										customClass={clsx(totalYearsMonthText && classes.yearsMonthText)}
									>
										{`${company.title}${moreCompanyExpText}`}
										{totalYearsMonthText && (
											<Text variant="span" light tertiary tiny>
												{totalYearsMonthText}
											</Text>
										)}
									</Text>
								</div>
							</div>
						);
					})}
					{moreExpText && (
						<Text variant="span" tiny light tertiaryV0>
							{moreExpText}
						</Text>
					)}
				</section>
			)}

			{educationArrToDisplay.length > 0 && (
				<section className={classes.educationSection}>
					<Text variant="h2" extraTiny tertiary semiBold>
						{'EDUCATION'}
					</Text>
					{educationArrToDisplay.map((education, index) => {
						return (
							<div className={classes.educationInfo} key={index}>
								<Avatar
									size={3.6}
									profilePicURL={education.logo}
									customRootClass={classes.educationLogo}
									fallbackIcon={EducationFallbackIcon}
								/>
								<div className={classes.educationContent}>
									<Text variant="h3" tiny>
										{education.schoolName}
									</Text>
									<Text variant="p" tertiary tiny light>
										{getTimePeriodTextEdFunc(education)}
									</Text>
								</div>
							</div>
						);
					})}
					{moreEducationText && (
						<Text variant="span" tiny light tertiaryV0>
							{moreEducationText}
						</Text>
					)}
				</section>
			)}

			<Button
				btnText={
					<Text variant="span" small brandPrimaryColor semiBold>
						{'View full profile'}
					</Text>
				}
				onClick={handleViewProfileBtnClick}
				customClass={classes.viewProfileBtn}
			/>
		</article>
	);
}

export default React.memo(LinkedInProfileOverview);
