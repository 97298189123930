import React from 'react';
import Button from '@components/ui/Button';
import Text from '@components/ui/Text';
import { UnverifiedIconV2 } from '@src/hoc/withIconStyles';
import classes from './VerifyLinkedInAlertTopHeader.styles.module.scss';
import mixpanelActions from '@utils/mixpanel';
import { mixPanelEvents } from '@utils/constants';
import useVerifyLinkedInContext from '@hooks/useVerifyLinkedInContext';

function VerifyLinkedInAlertTopHeader() {
	const { setModalState: setVerifyLinkedInModalState } = useVerifyLinkedInContext();

	const handleOnClickVerifyLinkedIn = () => {
		mixpanelActions.trackVerifyLinkedIn({
			eventName: mixPanelEvents.LINKED_VERIFY_TOP_BAR_CLICKED,
		});
		setVerifyLinkedInModalState({
			isOpen: true,
		});
		mixpanelActions.trackVerifyLinkedIn({
			eventName: mixPanelEvents.LINKEDIN_VERIFY_SHOWN,
			trigger: 'topbar',
		});
	};

	return (
		<div className={classes.verifyLinkedInAlert}>
			<UnverifiedIconV2 size={1.6} />
			<Text
				variant="p"
				white
				tiny
				lineHeight={1.6}
			>{`Complete verification so that people know your profile is authentic.`}</Text>
			<Button
				btnText={
					<Text variant="span" white small lineHeight={2} semiBold>
						{'Verify now'}
					</Text>
				}
				onClick={handleOnClickVerifyLinkedIn}
				customClass={classes.verifyNowBtn}
			/>
		</div>
	);
}

export default VerifyLinkedInAlertTopHeader;
