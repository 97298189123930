import React from 'react';
import clsx from 'clsx';
import Text from '@components/ui/Text';
import Button from '@components/ui/Button';
import { ArrowUpRightIcon, CloseIcon, DeleteIconV2, LabelTickIcon } from '@src/hoc/withIconStyles';
import useWindowSize from '@hooks/useWindow';
import { IAddViewProps } from './AddView.types';
import classes from './AddView.styles.module.scss';
import { mixPanelEvents, PERSONAL_LABEL_CHAR_LIMIT } from '@utils/constants';
import DeleteView from '../DeleteView';
import EmptyStateView from '../EmptyStateView';
import mixpanelActions from '@utils/mixpanel';

function AddView({
	onClickBackBtn,
	onClickCancelBtn,
	onClickAddOrDeleteBtn,
	personalLabels,
	isLoading,
}: IAddViewProps) {
	const { isMobile } = useWindowSize();

	const [editData, setEditData] = React.useState({
		name: '',
		error: '',
	});

	const [selectedLabel, setSelectedLabel] = React.useState<string | null>(null);

	const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);
	const [showEmptyState, setShowEmptyState] = React.useState(true);

	const inputRef = React.useRef<HTMLInputElement | null>(null);
	const labelsListRef = React.useRef<HTMLDivElement | null>(null);

	const handleOnChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (isLoading) return;

		setEditData({
			name: e.target.value,
			error: '',
		});
	};

	const handleBackClick = () => {
		if (isLoading) return;

		onClickBackBtn();
	};

	const handleCloseClick = () => {
		if (isLoading) return;

		onClickCancelBtn();
	};

	const handleAddLabel = () => {
		const labelToAdd = editData.name.toLocaleLowerCase().trim();

		if (labelToAdd.length > PERSONAL_LABEL_CHAR_LIMIT) {
			setEditData({
				...editData,
				error: `Label cannot be longer than ${PERSONAL_LABEL_CHAR_LIMIT} characters`,
			});
			inputRef.current?.focus();
			return;
		}

		for (let i = 0; i < personalLabels.length; i++) {
			if (personalLabels[i].toLocaleLowerCase() === labelToAdd) {
				setEditData({
					...editData,
					error: 'Label name already exists',
				});
				inputRef.current?.focus();
				return;
			}
		}

		onClickAddOrDeleteBtn([editData.name, ...personalLabels], 'ADD', () => {
			setEditData({
				name: '',
				error: '',
			});
		});
	};

	const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (buttonDisabled || isLoading) return;

		if (e.key === 'Enter') {
			handleAddLabel();
		}
	};

	const handleOnClickDeleteBack = () => {
		if (isLoading) return;

		setShowDeleteConfirmation(false);
		setSelectedLabel(null);
	};

	const handleOnClickConfirmDelete = () => {
		if (isLoading) return;

		const updatedLabels = personalLabels.filter((label) => label !== selectedLabel);

		onClickAddOrDeleteBtn(updatedLabels, 'DELETE', () => {
			setShowDeleteConfirmation(false);
			setSelectedLabel(null);
		});
	};

	const handleDeleteLabel = (labelName: string) => {
		if (isLoading) return;

		mixpanelActions.trackLabel(mixPanelEvents.DELETE_LABEL_ICON_CLICKED);

		setShowDeleteConfirmation(true);
		setSelectedLabel(labelName);
	};

	const handleListScroll = () => {
		const listEle = labelsListRef.current;

		if (!listEle) return;

		if (listEle.scrollTop > 0) {
			listEle.classList.add(classes.labelsListContainerWithBorder);
		} else {
			listEle.classList.remove(classes.labelsListContainerWithBorder);
		}
	};

	const handleEmptyStateAddLabelClick = () => {
		setShowEmptyState(false);
	};

	const buttonDisabled = editData.name.trim().length === 0;

	if (personalLabels.length === 0 && showEmptyState) {
		return (
			<EmptyStateView
				onAddLabelClick={handleEmptyStateAddLabelClick}
				onClickCancel={onClickCancelBtn}
			/>
		);
	}

	if (showDeleteConfirmation) {
		return (
			<DeleteView
				onClickBack={handleOnClickDeleteBack}
				onClickDelete={handleOnClickConfirmDelete}
				isLoading={isLoading}
			/>
		);
	}

	return (
		<div className={classes.addViewContainer}>
			<div className={classes.header}>
				{isMobile && (
					<Button
						btnText={<ArrowUpRightIcon size={2.4} className={classes.rightIcon} />}
						onClick={handleBackClick}
					/>
				)}
				<Text variant="h3" semiBold lineHeight={2.4} medium>
					{'Labels'}
				</Text>
				{!isMobile && (
					<Button
						btnText={<CloseIcon size={2.4} className={classes.closeIcon} />}
						onClick={handleCloseClick}
					/>
				)}
			</div>
			<div className={classes.inputContainer}>
				<input
					className={classes.input}
					type="text"
					value={editData.name}
					onChange={handleOnChangeName}
					placeholder={'Add new label...'}
					autoFocus
					autoComplete="off"
					onKeyDown={handleInputKeyDown}
					ref={inputRef}
				/>
				<Button
					btnText={
						<LabelTickIcon
							size={2.4}
							className={clsx(
								buttonDisabled && classes.iconDisabled,
								!buttonDisabled && classes.iconActive
							)}
						/>
					}
					onClick={handleAddLabel}
					disabled={buttonDisabled}
					isLoading={isLoading}
				/>
			</div>
			{editData.error && (
				<Text variant="p" lineHeight={1.6} tiny error customClass={classes.errorText}>
					{editData.error}
				</Text>
			)}
			<div className={classes.labelsListContainer} ref={labelsListRef} onScroll={handleListScroll}>
				{personalLabels.map((label) => {
					return (
						<div className={classes.label} key={label}>
							<Text variant="p" lineHeight={isMobile ? 2.2 : 2.4} semiBold={!isMobile}>
								{label}
							</Text>
							<Button
								btnText={<DeleteIconV2 size={2.4} />}
								onClick={() => handleDeleteLabel(label)}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default AddView;
