import React, { useEffect } from 'react';
import clsx from 'clsx';
import Text from '@components/ui/Text';
import Button from '@components/ui/Button';
import { EditIconV3, GreenTickIcon } from '@src/hoc/withIconStyles';
import { IStepMiniInfoProps } from './StepMiniInfo.types';
import classes from './StepMiniInfo.styles.module.scss';

function StepMiniInfo({
	infoText,
	onClickEditBtn,
	shouldTrimInfoText,
	withAnimation,
	onAnimationEnd,
	onDisplayInfo,
}: IStepMiniInfoProps) {
	useEffect(() => {
		setTimeout(() => {
			onAnimationEnd();
		}, 600);
	}, [onAnimationEnd]);

	useEffect(() => {
		onDisplayInfo();
	}, []);

	return (
		<div className={classes.stepMiniInfoContainer}>
			<Text variant="p" fontSize={1.3} lineHeight={1.7} light tertiary>
				{shouldTrimInfoText ? infoText.slice(0, 30).concat('...') : infoText}
			</Text>
			{onClickEditBtn && <Button btnText={<EditIconV3 size={2} />} onClick={onClickEditBtn} />}
			<GreenTickIcon size={2} className={clsx(withAnimation && classes.iconWithAnimation)} />
		</div>
	);
}

export default StepMiniInfo;
