import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFetch from '@hooks/useFetch';
import { bulkMarkAsRead } from '@api/chats';
import { IBulkMarkAsReadPayload } from '@api/chats/chats.types';
import Toast from '@components/ui/Toast';
import Modal from '@components/ui/Modal';
import Text from '@components/ui/Text';
import Button from '@components/ui/Button';
import Loader from '@components/ui/Loader';
import { IRefProps } from '@components/ui/Toast/Toast.types';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import {
	clearChatsData,
	getFiltersObj,
	getInboxDataFromGlobalState,
} from '@store/inboxSlice/inboxSlice';
import { IMarkAllReadModalProps } from './MarkAllReadModal.types';
import classes from './MarkAllReadModal.styles.module.scss';
import { AlertIcon } from '@src/hoc/withIconStyles';
import mixpanelActions from '@utils/mixpanel';
import { mixPanelEvents } from '@utils/constants';
import useChatActionsToastContext from '@hooks/useChatActionsToastContext';
import { AppDispatch } from '@store/index';

function MarkAllReadModal({ onCloseModal, showModal, onSuccess }: IMarkAllReadModalProps) {
	const [toastError, setToastError] = useState('');
	const toastRef = useRef<IRefProps>(null);

	const dispatch = useDispatch<AppDispatch>();
	const { setToastData } = useChatActionsToastContext();

	const {
		chatsDataTotalCount,
		selectedSingleFilters,
		selectedMultiFilters,
		filters,
		currFetchRequestTimestamp,
	} = useSelector(getInboxDataFromGlobalState);
	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);

	const { callApi: callBulkMarkAsRead, status: callBulkMarkAsReadStatus } =
		useFetch(bulkMarkAsRead);

	const isLoading = callBulkMarkAsReadStatus === 'loading';

	const showCountTextToDisplay = `${chatsDataTotalCount} ${chatsDataTotalCount > 1 ? 'DMs' : 'DM'}`;

	const userId = userDetails?.userId;

	const handleCloseModal = () => {
		if (isLoading) return;

		onCloseModal();
	};

	const handleCancelClick = () => {
		if (isLoading) return;

		mixpanelActions.trackBulkMarkAsRead(mixPanelEvents.MARK_ALL_AS_READ_CANCEL_CLICKED);

		onCloseModal();
	};

	const handleConfirmClick = async () => {
		if (isLoading || !userId) return;

		toastRef.current?.unPublish();

		mixpanelActions.trackBulkMarkAsRead(mixPanelEvents.MARK_ALL_AS_READ_YES_CLICKED);

		try {
			const payload: IBulkMarkAsReadPayload = {
				user_id: userId,
				applied_filters: getFiltersObj(selectedSingleFilters, selectedMultiFilters, filters),
				last_fetch_timestamp: currFetchRequestTimestamp,
			};

			await callBulkMarkAsRead(payload);

			mixpanelActions.trackBulkMarkAsRead(mixPanelEvents.MARK_ALL_AS_READ_SUCCESS);

			dispatch(clearChatsData());

			setToastData({
				toastStatus: 'success',
				toastText: `${showCountTextToDisplay} marked as read.`,
			});

			onSuccess();
		} catch (error) {
			setToastError(error);
			toastRef.current?.publish();
		}
	};

	return (
		<>
			<Modal bottomInMobile noPadding showModal={showModal} onCloseModal={handleCloseModal}>
				<div className={classes.confirmContainer}>
					<Text variant="h2" lineHeight={2.4} medium semiBold>
						{'Are you sure?'}
					</Text>

					<Text
						variant="p"
						lineHeight={2}
						small
						light
						secondary
					>{`${showCountTextToDisplay} will be marked as read.`}</Text>

					<div className={classes.btnsContainer}>
						<Button
							btnText={
								<Text variant="span" lineHeight={2.2} semiBold>
									{'Cancel'}
								</Text>
							}
							onClick={handleCancelClick}
						/>
						<Button
							btnText={
								<Text variant="span" lineHeight={2.2} semiBold white>
									{'Yes'}
								</Text>
							}
							isLoading={isLoading}
							customLoader={<Loader allWhite />}
							primary
							onClick={handleConfirmClick}
						/>
					</div>
				</div>
			</Modal>
			<Toast
				ref={toastRef}
				toastType={'ERROR'}
				header={toastError}
				icon={<AlertIcon size={1.8} className={classes.toastIcon} />}
			/>
		</>
	);
}

export default MarkAllReadModal;
