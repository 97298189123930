import React from 'react';
import { CloseIcon, LabelColorIcon, PlusIconV2 } from '@src/hoc/withIconStyles';
import Button from '@components/ui/Button';
import Text from '@components/ui/Text';
import classes from './EmptyStateView.styles.module.scss';
import useWindowSize from '@hooks/useWindow';
import { IEmptyStateViewProps } from './EmptyStateView.types';

function EmptyStateView({ onAddLabelClick, onClickCancel }: IEmptyStateViewProps) {
	const { isMobile } = useWindowSize();

	const handleClickCancel = () => {
		onClickCancel();
	};

	const handleAddLabelClick = () => {
		onAddLabelClick();
	};

	return (
		<div className={classes.emptyStateContainer}>
			{!isMobile && (
				<Button
					btnText={<CloseIcon size={2.4} className={classes.closeIcon} />}
					onClick={handleClickCancel}
				/>
			)}
			<div className={classes.labelIconContainer}>
				<LabelColorIcon size={4.2} />
			</div>
			<Text variant="h3" semiBold lineHeight={2.4} medium>
				{'Add your first label'}
			</Text>
			<Text variant="p" tertiary light lineHeight={2} small>
				{'Labels make it easy to find something later'}
			</Text>
			<Button
				prefixIcon={<PlusIconV2 size={1.4} className={classes.plusIcon} />}
				btnText={
					<Text variant="span" semiBold lineHeight={2.2} white>
						{'Add a label'}
					</Text>
				}
				onClick={handleAddLabelClick}
				primary
			/>
		</div>
	);
}

export default EmptyStateView;
