import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import classes from './CannedResponse.styles.module.scss';
import { IUserCannedResponse } from '@api/user/user.types';
import { useSelector } from 'react-redux';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import Text from '@components/ui/Text';
import { EditIconV2 } from '@src/hoc/withIconStyles';
import { ICannedResponseProps } from './CannedResponse.types';
import { cannedResponseReplaceWords } from '@utils/constants';

import CannedResponseModal from '../CannedResponseModal';
import useWindowSize from '@hooks/useWindow';

function CannedResponse({
	senderFirstName,
	onSelectCannedResponse,
	onCloseCannedResponse,
}: ICannedResponseProps) {
	const { isMobile } = useWindowSize();
	const containerRef = useRef<HTMLDivElement | null>(null);
	const divRefsToFocused = useRef<{ [key: number]: HTMLDivElement | null }>({});

	const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
	const [showManageCannedResponseModal, setShowManageCannedResponseModal] = useState(false);

	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);

	const userCannedResponses = userDetails?.cannedResponses;

	// handling close on clicking outside cannedResponse
	useEffect(() => {
		if (showManageCannedResponseModal) return;

		if (!containerRef.current) return;

		const mainContainerRef = containerRef.current;

		const handleClickOutside = (e: MouseEvent) => {
			const targetNode = e.target as Node;
			if (mainContainerRef && !mainContainerRef.contains(targetNode)) {
				onCloseCannedResponse();
			}
		};

		window.addEventListener('mousedown', handleClickOutside);

		return () => {
			window.removeEventListener('mousedown', handleClickOutside);
		};
	}, [onCloseCannedResponse, showManageCannedResponseModal]);

	// handling keyboard traversing
	useEffect(() => {
		if (showManageCannedResponseModal) return;

		const handleKeyPress = (event: KeyboardEvent) => {
			const noCannedResponses = !userCannedResponses?.length;

			switch (event.key) {
				case 'ArrowUp':
					event.preventDefault();
					if (noCannedResponses) return;
					setFocusedIndex((prevIndex) => {
						if (prevIndex === 0) {
							return null;
						}

						if (prevIndex === null) {
							return userCannedResponses.length - 1;
						}

						return prevIndex - 1;
					});
					break;
				case 'ArrowDown':
					event.preventDefault();
					if (noCannedResponses) return;
					setFocusedIndex((prevIndex) => {
						if (prevIndex === null) {
							return 0;
						}

						if (prevIndex === userCannedResponses.length - 1) {
							return null;
						}
						return prevIndex + 1;
					});
					break;
				case 'Enter':
					event.preventDefault();
					if (focusedIndex !== null && !noCannedResponses) {
						handleClick(userCannedResponses[focusedIndex]);
					} else {
						handleOnClickManageCannedResponses();
					}
					break;
				default:
					break;
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [userCannedResponses, focusedIndex, showManageCannedResponseModal]);

	// handling focusing elements to auto scroll into view
	useEffect(() => {
		if (isMobile) return;
		divRefsToFocused.current[focusedIndex ?? -1]?.scrollIntoView({
			behavior: 'instant',
			inline: 'end',
			block: 'end',
		});
	}, [focusedIndex, isMobile]);

	// handling focused index on data
	useEffect(() => {
		if (isMobile) return;
		if (!userCannedResponses?.length) {
			setFocusedIndex(null);
			return;
		}

		setFocusedIndex(0);
	}, [userCannedResponses, isMobile]);

	const handleClick = (selectedCannedResponse: IUserCannedResponse) => {
		const cannedResponseText = selectedCannedResponse.text.replace(
			cannedResponseReplaceWords.firstName,
			senderFirstName
		);
		onSelectCannedResponse(
			cannedResponseText,
			selectedCannedResponse.name,
			selectedCannedResponse.bookmark_when_used ?? true
		);
	};

	const handleOnClickManageCannedResponses = () => {
		if (isMobile) return;
		setShowManageCannedResponseModal(true);
	};

	const handleCloseManageCannedResponseModal = async () => {
		setShowManageCannedResponseModal(false);
		onCloseCannedResponse();
	};

	return (
		<div className={classes.cannedResponsesListContainer} ref={containerRef}>
			{userCannedResponses?.map((cannedResponse, index) => {
				const isFocused = index === focusedIndex;
				return (
					<div
						tabIndex={0}
						key={cannedResponse.name}
						className={`${classes.cannedResponse} ${isFocused ? classes.focused : ''}`}
						onClick={(e) => {
							e.stopPropagation();
							handleClick(cannedResponse);
						}}
						ref={(el) => {
							divRefsToFocused.current[index] = el;
						}}
					>
						<Text variant="h2" small>
							{cannedResponse.name}
						</Text>
						<Text variant="p" small secondary light>
							{cannedResponse.text.replace(cannedResponseReplaceWords.firstName, senderFirstName)}
						</Text>
					</div>
				);
			})}

			{!isMobile && (
				<div
					tabIndex={0}
					onFocus={(e) => {
						e.stopPropagation();
						setFocusedIndex(null);
					}}
					className={clsx(
						classes.manageCannedResponsesBtn,
						focusedIndex === null && classes.focused
					)}
					onClick={handleOnClickManageCannedResponses}
					ref={(el) => {
						divRefsToFocused.current[-1] = el;
					}}
				>
					<EditIconV2 size={1.6} />
					<Text variant="h2" small secondary light>
						{'Add or edit quick replies'}
					</Text>
				</div>
			)}

			{showManageCannedResponseModal && (
				<CannedResponseModal
					cannedResponseData={userCannedResponses ?? []}
					userId={userDetails?.userId}
					showModal={showManageCannedResponseModal}
					onCloseModal={handleCloseManageCannedResponseModal}
				/>
			)}
		</div>
	);
}

export default CannedResponse;
