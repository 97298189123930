import React from 'react';
import classes from './EmptyState.styles.modules.scss';
import { PlusIconV2, QuicKReplyFilledIcon, CloseIcon } from '@src/hoc/withIconStyles';
import Text from '@components/ui/Text';
import Button from '@components/ui/Button';
import { IEmptyStateProps } from './EmptyState.types';


function EmptyState({ onClickAdd, onClickCancel }: IEmptyStateProps) {


	const handleAddQuickReplyClick = () => {
		onClickAdd();
	};

	const handleCancelQuickReplyClick = () => {
		if (onClickCancel) {
			onClickCancel();
		}
	}


	return (
		<div className={classes.emptyStateMainContainer}>
			{ onClickCancel && (
					<Button
						btnText={<CloseIcon size={1.8} className={classes.closeIcon} />}
						onClick={handleCancelQuickReplyClick}
						customClass={classes.closeIconBtn}
					/>
				)}
			<div className={classes.emptyStateContainer}>
				<div className={classes.emptyIcon}>
					<QuicKReplyFilledIcon size={5.4} />
				</div>
				<Text variant="h2" medium semiBold>
					{'Reply in a tap'}
				</Text>
				<Text variant="p" small light tertiary>
					{'by saving your most common replies'}
				</Text>
			</div>
			<div className={classes.buttonsContainerWrapper}>
				<div className={classes.buttonsContainer}>
					<Button
						btnText={
							<Text variant="span" white semiBold>
								{'Add a quick reply'}
							</Text>
						}
						prefixIcon={<PlusIconV2 size={1.3} />}
						primary
						onClick={handleAddQuickReplyClick}
						customClass={classes.addEmptyStateBtn}
					/>
				</div>
			</div>
		</div>
	);
}

export default EmptyState;
