/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import {
	archivePreAppliedFilters,
	archiveOverviewHeading,
	ALL,
	UNREAD,
	mixPanelEvents,
} from '@utils/constants';
import Inbox from '@components/Inbox/Inbox';
import Filters from '@components/Filters';
import MainLayout from '@components/MainLayout';
import Chats from '@components/Chats';
import classes from './InboxLayoutDesktop.styles.module.scss';
import ChatsSkeletonLoader from '../ChatsSkeletonLoader';
import Text from '@components/ui/Text';
import Button from '@components/ui/Button';
import { ChevronRightIcon, ListViewIcon, RowsIcon } from '@src/hoc/withIconStyles';
import MainFilters from '../MainFilters';
import useInboxLayoutContext from '@hooks/useInboxLayoutContext';
import { Tabs } from '@src/models/user';
import { useDispatch, useSelector } from 'react-redux';
import { getInboxDataFromGlobalState, setCurrChatsListView } from '@store/inboxSlice/inboxSlice';
import { IAppliedFiltersPayload } from '@api/chats/chats.types';
import ScrollChatCards from '@components/ScrollChatCards';
import Overview from '../Overview/Overview';
import mixpanelActions from '@utils/mixpanel';
import { AppDispatch } from '@store/index';
import InboxMoreActions from '../InboxMoreActions';

export function InboxLayoutDesktop() {
	const dispatch = useDispatch<AppDispatch>();

	const {
		isLoadingData,
		showLoader,
		selectedChatUsernameFromURL,
		isFiltersChanged,
		filtersWithOptions,
		mainFilters,
		savedFiltersFromInboxOverview,
		isTabDefaultFiltersChanged,
		isProUser,
		onClickMainFilter,
		onClickMultiFilter,
		onClickSingleFilter,
		onClickOverviewFilter,
		onClickOverviewFilterDesktop,
	} = useInboxLayoutContext();
	const {
		newMainFilterOverviewDetails,
		selectedMainFilter,
		chatsData,
		chatsDataTotalCount,
		archivedChatsDataTotalCount,
		selectedMultiFilters,
		selectedSingleFilters,
		currChatsListView,
	} = useSelector(getInboxDataFromGlobalState);

	const backgroundModeFiltersRef = useRef<{ [key: string]: string }>({});
	const layoutWithFiltersRef = useRef<HTMLDivElement | null>(null);

	const showMainFilters = !!mainFilters.length;

	const showSaveFiltersAsTab = isFiltersChanged;

	const filtersToDisplay = filtersWithOptions;

	const showFilters = showMainFilters && filtersToDisplay.length > 0;

	const isUnreadFilterSelected = selectedSingleFilters[UNREAD] === UNREAD;

	const scrollCardMode = isUnreadFilterSelected && currChatsListView === 'SCROLL_CARD';

	const showCardView = scrollCardMode && !selectedChatUsernameFromURL;

	const applyAllTabInboxStyles = showCardView && chatsData.length > 0;

	const applyAllTabLayoutStyles = selectedMainFilter?.name === ALL;

	const isOverviewDetailsAvailable =
		newMainFilterOverviewDetails &&
		newMainFilterOverviewDetails.overview &&
		(newMainFilterOverviewDetails.overview.categories.length > 0 ||
			newMainFilterOverviewDetails.overview.savedFilters.length > 0);

	const showOverviewData =
		!!isOverviewDetailsAvailable &&
		!selectedChatUsernameFromURL &&
		!isTabDefaultFiltersChanged &&
		selectedMainFilter?.name === ALL &&
		!!chatsData.length;

	// TODO this variable name can be changed to scroll mode
	const scrollMode = !!chatsData.length && !selectedChatUsernameFromURL && !isLoadingData;

	const showCountText =
		!!chatsDataTotalCount &&
		selectedMainFilter?.name === ALL &&
		!selectedChatUsernameFromURL &&
		showMainFilters;

	const showCountTextToDisplay = `Showing ${chatsDataTotalCount} ${
		chatsDataTotalCount > 1 ? 'DMs' : 'DM'
	}`;

	const showArchivedCountCTA = showCountText && !!archivedChatsDataTotalCount;

	const archivedTextToDisplay = `${archivedChatsDataTotalCount} archived`;

	const handleOnClickMainFilter = (mainFilterToSelect: Tabs) => {
		onClickMainFilter(mainFilterToSelect);
		backgroundModeFiltersRef.current = {};
	};

	const handleOnClickSingleFilter = (filterName: string, optionId: string, isSelected: boolean) => {
		onClickSingleFilter(filterName, optionId, isSelected);
		backgroundModeFiltersRef.current[filterName] = filterName;
	};

	const handleOverviewFilterClick = (preAppliedFiltersObj: IAppliedFiltersPayload) => {
		onClickOverviewFilter(preAppliedFiltersObj);
	};

	const handleOnClickMultiFilter = (filterName: string, updatedOptionsIds: string[]) => {
		onClickMultiFilter(filterName, updatedOptionsIds);
	};

	const handleOnSaveFilters = () => {
		if (!selectedMainFilter) return;
		handleOnClickMainFilter(selectedMainFilter);
	};

	const handleScroll = () => {
		const ele = layoutWithFiltersRef.current;

		if (!ele) return;

		if (ele.scrollTop > 0 && !ele.classList.contains(classes.inboxLayoutScroll)) {
			ele.classList.add(classes.inboxLayoutScroll);
		}

		if (ele.scrollTop <= 0 && ele.classList.contains(classes.inboxLayoutScroll)) {
			ele.classList.remove(classes.inboxLayoutScroll);
		}
	};

	useEffect(() => {
		const ele = document.getElementById('root');

		if (ele) {
			if (applyAllTabLayoutStyles) {
				ele.classList.add(classes.rootLayoutScrollCardViewDesktop);
			} else {
				ele.classList.add(classes.rootLayoutNonScrollCardViewDesktop);
			}
		}

		return () => {
			if (ele) {
				ele.classList.remove(classes.rootLayoutScrollCardViewDesktop);
				ele.classList.remove(classes.rootLayoutNonScrollCardViewDesktop);
			}
		};
	}, [applyAllTabLayoutStyles]);

	return (
		<MainLayout withSearch>
			<section className={classes.messageLayout}>
				{showMainFilters && (
					<MainFilters
						mainFilters={mainFilters}
						onSelectMainFilter={handleOnClickMainFilter}
						selectedMainFilter={selectedMainFilter}
					/>
				)}
				<section className={classes.inboxMainLayoutWrapper}>
					<section
						className={clsx(
							classes.inboxMainLayout,
							applyAllTabInboxStyles && classes.inboxMainLayoutScrollCardView,
							!isProUser && classes.liteUserInboxLayout,
							scrollMode && classes.inboxMainLayoutScrollMode,
							isLoadingData && classes.inboxMainLayoutLoading,
							!isLoadingData && classes.inboxMainLayoutLoaded
						)}
						ref={layoutWithFiltersRef}
						onScroll={handleScroll}
					>
						<div className={classes.loadingContainer}>{showLoader && <ChatsSkeletonLoader />}</div>

						{showFilters && (
							<Filters
								filters={filtersToDisplay}
								isLoading={isLoadingData}
								onClickMultiFilter={handleOnClickMultiFilter}
								onClickSingleFilter={handleOnClickSingleFilter}
								savedFiltersFromInboxOverview={savedFiltersFromInboxOverview}
								selectedMainFilter={selectedMainFilter}
								selectedMultiFilters={selectedMultiFilters}
								selectedSingleFilters={selectedSingleFilters}
								showSaveFiltersAsTab={showSaveFiltersAsTab}
								onSaveFilters={handleOnSaveFilters}
								backgroundModeFiltersNameMap={backgroundModeFiltersRef.current}
								showClearFilters={isTabDefaultFiltersChanged}
								onClickClearFilters={() => {
									selectedMainFilter && handleOnClickMainFilter(selectedMainFilter);
								}}
							/>
						)}
						{showOverviewData && (
							<Overview
								overviewDetails={newMainFilterOverviewDetails}
								onClickFilter={handleOverviewFilterClick}
								cardView={applyAllTabInboxStyles}
							/>
						)}
						{showCountText && (
							<div
								className={clsx(
									classes.countTextContainerWrapper,
									!showOverviewData && classes.countTextContainerWrapperMargin,
									showCardView && classes.countTextContainerCardView
								)}
							>
								<div className={classes.countTextContainer}>
									<Text variant="p" fontSize={1.3} lineHeight={1.8} semiBold secondary>
										{showCountTextToDisplay}
									</Text>
									{showArchivedCountCTA && (
										<Button
											onClick={() => {
												onClickOverviewFilterDesktop(
													archivePreAppliedFilters,
													archiveOverviewHeading
												);
											}}
											btnText={
												<Text
													variant="span"
													tiny
													lineHeight={1.6}
													color="#AFB6BD"
													customClass={classes.archiveCtaText}
												>
													{archivedTextToDisplay}
												</Text>
											}
											suffixIcon={
												<ChevronRightIcon size={1.4} className={classes.archiveChevron} />
											}
										/>
									)}
								</div>
								{isUnreadFilterSelected && (
									<div className={classes.btnsContainer}>
										{!showCardView && (
											<Button
												btnText={<RowsIcon size={3.2} />}
												onClick={() => {
													dispatch(setCurrChatsListView({ chatsListView: 'SCROLL_CARD' }));
													mixpanelActions.trackCardLayoutChange(
														mixPanelEvents.CARD_LAYOUT_CHANGED,
														'SCROLL_CARD'
													);
												}}
											/>
										)}

										{showCardView && (
											<Button
												btnText={<ListViewIcon size={3.2} />}
												onClick={() => {
													dispatch(setCurrChatsListView({ chatsListView: 'LIST_VIEW' }));
													mixpanelActions.trackCardLayoutChange(
														mixPanelEvents.CARD_LAYOUT_CHANGED,
														'LIST_VIEW'
													);
												}}
											/>
										)}

										<InboxMoreActions />
									</div>
								)}
							</div>
						)}
						{!showCardView && (
							<div
								className={clsx(
									classes.inboxMainChild,
									scrollMode && classes.inboxMainChildOverviewMode,
									selectedChatUsernameFromURL && classes.inboxMainChildBorder
								)}
							>
								<Inbox scrollMode={!!scrollMode} isFiltersDisplayed={showFilters} />
								<div
									className={clsx(classes.chats, selectedChatUsernameFromURL && classes.showChats)}
								>
									{selectedChatUsernameFromURL && <Chats />}
								</div>
							</div>
						)}
						{showCardView && (
							<ScrollChatCards isFiltersDisplayed={showFilters} parentRef={layoutWithFiltersRef} />
						)}
					</section>
				</section>
			</section>
		</MainLayout>
	);
}

export default InboxLayoutDesktop;
