import { clearInboxState } from '@store/inboxSlice/inboxSlice';
import { store } from '@store/index';
import { clearSelectedChat } from '@store/selectedChatSlice/selectedChatSlice';
import { clearUserDetails, setAppUpdateRequired } from '@store/userDetailsSlice/userDetailsSlice';
import { isIphone, isPWAApp, isSuperDMApp } from './common';
import { getLocalStorage } from './localStorage';
import { NOTIFICATION_DEVICE_TOKEN } from './constants';
import { logout } from '@api/user';
import { ILogoutPayload } from '@api/user/user.types';

export function clearRootState() {
	const dispatch = store.dispatch;
	// clear all redux state slices
	dispatch(clearUserDetails());
	dispatch(clearInboxState());
	dispatch(clearSelectedChat());
}

export function setAppUpdateState(isAppUpdateAvailable: boolean) {
	const dispatch = store.dispatch;
	dispatch(setAppUpdateRequired({ appUpdateRequired: isAppUpdateAvailable }));
}

// calling this api for revoking notifications device token in superDM app and PWA
export async function callLogoutApi() {
	try {
		const isPWAMobileApp = isPWAApp();
		const isSuperDMMobileApp = isSuperDMApp();

		if (!isPWAMobileApp && !isSuperDMMobileApp) return;

		const { data: userDetails } = store.getState().userDetails;
		const userId = userDetails?.userId;
		const userAllowedNotificationDevices = userDetails?.devicesInfo ?? [];
		const notificationDeviceToken = getLocalStorage(NOTIFICATION_DEVICE_TOKEN);

		const isDeviceTokenExist = userAllowedNotificationDevices.find(
			(deviceInfo) => deviceInfo.fcmToken === notificationDeviceToken
		);

		if (!isDeviceTokenExist?.fcmToken || !userId) return;

		const payload: ILogoutPayload = {
			device_type: isPWAMobileApp ? 'MOBILE_PWA' : isIphone() ? 'MOBILE_IOS' : 'MOBILE_ANDROID',
			notification_allowed: false,
			token: notificationDeviceToken,
			user_id: userId,
		};

		await logout(payload);
	} catch (error) {
		console.error('logout error', error);
	}
}
