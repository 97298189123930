import React, { useEffect, useRef, useState } from 'react';
import SettingsSection from '../SettingsSection';
import IIntroMessageProps from './IntroMessage.types';
import classes from './IntroMessage.styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	getUserDetailsFromGlobalState,
	setUserSettings,
} from '@store/userDetailsSlice/userDetailsSlice';
import Text from '@components/ui/Text';
import { IRefProps } from '@components/ui/Toast/Toast.types';
import Toast from '@components/ui/Toast';
import { AlertIcon, CheckCircleIcon } from '@src/hoc/withIconStyles';
import useFetch from '@hooks/useFetch';
import { editUserSettings } from '@api/user';
import { IEditUserSettingsPayload } from '@api/user/user.types';
import { AppDispatch } from '@store/index';
import Linkify from 'linkify-react';
import { INTRO_MESSAGE_CHARACTER_LIMIT } from '@utils/constants';
import { useLeavingModalContext } from '@hooks/leavingModalContext';

function IntroMessage({ setCurrEditSection, currEditSection }: IIntroMessageProps) {
	const dispatch = useDispatch<AppDispatch>();
	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);
	const { setDirty } = useLeavingModalContext();

	const [editMode, setEditMode] = useState(false);
	const [newUserPreferences, setNewUserPreferences] = useState('');
	const toastRef = useRef<IRefProps>(null);

	const {
		callApi: callEditUserPreferences,
		error: editUserPreferencesError,
		status: editUserPreferencesStatus,
	} = useFetch(editUserSettings);

	const isCurrSectionEditMode = currEditSection === 'INTRO_MESSAGE';
	const preferences = userDetails?.preferences ?? '';

	const isEditBtnDisabled = currEditSection !== null && currEditSection !== 'INTRO_MESSAGE';

	const handleOnChangeUserPreference = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setNewUserPreferences(e.target.value);
	};

	const handleOnClickEdit = () => {
		setCurrEditSection('INTRO_MESSAGE');
		setNewUserPreferences(preferences);
	};

	const handleOnClickCancel = () => {
		setNewUserPreferences(preferences);
		setCurrEditSection(null);
	};

	const handleOnClickSave = async () => {
		toastRef.current?.unPublish();
		if (!userDetails) return;
		const payload: IEditUserSettingsPayload = {
			user_id: userDetails.userId,
			preferences: newUserPreferences,
		};
		try {
			const res = await callEditUserPreferences(payload);
			dispatch(
				setUserSettings({
					inboxCategories: res.inboxCategories,
					preferences: res.preferences,
				})
			);
			setCurrEditSection(null);
			toastRef.current?.publish();
			setEditMode(false);
		} catch (error) {
			toastRef.current?.publish();
		}
	};

	const isSaveBtnDisabled =
		preferences.trim() === newUserPreferences.trim() ||
		newUserPreferences.length > INTRO_MESSAGE_CHARACTER_LIMIT;

	useEffect(() => {
		setDirty(!isSaveBtnDisabled);
	}, [isSaveBtnDisabled, setDirty]);

	return (
		<SettingsSection
			header={'Welcome Message'}
			headerSubText={'People will see this message before they reach-out to you.'}
			editBtnDisabled={isEditBtnDisabled}
			saveBtnDisabled={isSaveBtnDisabled}
			onClickCancel={handleOnClickCancel}
			onClickEdit={handleOnClickEdit}
			onClickSave={handleOnClickSave}
			editMode={editMode}
			setEditMode={setEditMode}
		>
			{!isCurrSectionEditMode && (
				<Text variant="p" small light customClass={classes.introMessageBox}>
					<Linkify options={{ target: '_blank' }}>{preferences}</Linkify>
				</Text>
			)}
			{isCurrSectionEditMode && (
				<div className={classes.introMessageContainer}>
					<textarea
						className={classes.input}
						placeholder={'Type your welcome message here'}
						value={newUserPreferences}
						onChange={handleOnChangeUserPreference}
						autoComplete="off"
					/>
					<Text
						variant="p"
						tiny
						light
						tertiary
					>{`${newUserPreferences.length}/${INTRO_MESSAGE_CHARACTER_LIMIT} characters`}</Text>
				</div>
			)}
			<Toast
				ref={toastRef}
				toastType={editUserPreferencesStatus === 'success' ? 'INFO' : 'ERROR'}
				icon={
					editUserPreferencesStatus === 'success' ? (
						<CheckCircleIcon size={1.8} />
					) : (
						<AlertIcon size={1.8} className={classes.toastIcon} />
					)
				}
				header={
					editUserPreferencesStatus === 'success'
						? 'Welcome message updated'
						: editUserPreferencesError ?? 'Something  went wrong, please try again'
				}
			/>
		</SettingsSection>
	);
}

export default React.memo(IntroMessage);
