import { isAccountPendingFunc, isAccountVerifiedFunc } from '@src/models/user';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import { CHAT_SEARCH_PARAM, MAIN_PATH, REDIRECT_URL } from '@utils/constants';
import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';

function withAuth<T extends React.JSX.IntrinsicAttributes>(Component: ComponentType<T>) {
	function WrappedComponent(props: T) {
		const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);
		const [searchParams] = useSearchParams();

		const selectedUsernameFromURL = decodeURIComponent(searchParams.get(CHAT_SEARCH_PARAM) ?? '');

		const isAuthenticated = isAccountVerifiedFunc(userDetails) || isAccountPendingFunc(userDetails);

		const navigateToShareableLink = !!selectedUsernameFromURL && !isAuthenticated;

		const navigateToLanding = !selectedUsernameFromURL && !isAuthenticated;

		if (navigateToLanding) {
			const searchParams = new URLSearchParams();
			searchParams.set(
				REDIRECT_URL,
				encodeURIComponent(window.location.href.replace(window.location.origin, ''))
			);

			return <Navigate to={`${MAIN_PATH}?${searchParams.toString()}`} replace />;
		}

		if (navigateToShareableLink) {
			return <Navigate to={`/${selectedUsernameFromURL}`} replace />;
		}

		return <Component {...props} />;
	}

	return WrappedComponent;
}

export default withAuth;
