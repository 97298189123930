import React from 'react';
import clsx from 'clsx';
import Text from '@components/ui/Text';
import { IUserInfoProps } from './UserInfo.types';
import classes from './UserInfo.styles.module.scss';
import Avatar from '../Avatar';
import useWindowSize from '@hooks/useWindow';
import FallbackPicIcon from '@public/icons/fallbackPicIcon.svg';

function UserInfo(
	{
		headline,
		name,
		readStatus,
		profilePicURL,
		customClass,
		isUserSelected,
		recentMessage,
		recentMessageDate,
		assignedPersonalLabels,
		...restProps
	}: IUserInfoProps,
	forwardRef: React.ForwardedRef<HTMLElement>
) {
	const { isMobile } = useWindowSize();

	const assignedPersonalLabel = assignedPersonalLabels?.[0];
	const assignedPersonalLabelsExtraCount =
		assignedPersonalLabels && assignedPersonalLabels.length > 1
			? assignedPersonalLabels.length - 1
			: 0;

	return (
		<article
			className={clsx(
				classes.userInfo,
				isUserSelected && classes.userSelected,
				customClass && customClass
			)}
			{...restProps}
			ref={forwardRef}
		>
			<div className={classes.readStatusWithImage}>
				<span className={clsx(classes.readStatus, !readStatus && classes.readStatusActive)} />
				<Avatar
					size={isMobile ? 5.2 : 5.6}
					profilePicURL={profilePicURL}
					fallbackIcon={FallbackPicIcon}
				/>
			</div>
			<div className={classes.name}>
				<Text
					variant="h2"
					lineHeight={isMobile ? 2 : 1.8}
					fontSize={isMobile ? 1.6 : 1.4}
					semiBold={!isMobile}
				>
					{name}
				</Text>
				{recentMessageDate && isMobile && (
					<Text variant="span" tiny ultraLight tertiaryV0 lineHeight={1.6}>
						{recentMessageDate}
					</Text>
				)}
			</div>
			<div className={classes.headline}>
				<Text
					variant="p"
					light
					secondary={!isMobile}
					fontSize={isMobile ? 1.4 : 1.2}
					lineHeight={isMobile ? 1.8 : 1.6}
				>
					{headline}
				</Text>
				{!!assignedPersonalLabel && !isMobile && (
					<Text variant="span" lineHeight={1.6} fontSize={1.1} color={'#4A00C4'}>
						{assignedPersonalLabel}
						{assignedPersonalLabelsExtraCount > 0 && (
							<Text
								variant="span"
								lineHeight={1}
								fontSize={1}
								color={'#4A00C4'}
								customClass={classes.labelCountText}
							>
								{`+${assignedPersonalLabelsExtraCount}`}
							</Text>
						)}
					</Text>
				)}
				{recentMessage && isMobile && (
					<Text variant="p" small light lineHeight={1.8} secondary>
						{!!assignedPersonalLabel && (
							<Text
								variant="span"
								lineHeight={1.8}
								fontSize={1.4}
								color={'#4A00C4'}
								customClass={classes.assignedLabelTextWithDot}
							>
								{assignedPersonalLabel}
								{assignedPersonalLabelsExtraCount > 0 && (
									<Text
										variant="span"
										lineHeight={1.4}
										fontSize={1.2}
										color={'#4A00C4'}
										customClass={classes.labelCountText}
									>
										{`+${assignedPersonalLabelsExtraCount}`}
									</Text>
								)}
							</Text>
						)}
						{recentMessage}
					</Text>
				)}
			</div>
		</article>
	);
}

export default React.forwardRef(UserInfo);
