/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/index';
import Text from '@components/ui/Text';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import clsx from 'clsx';
import MainLayout from '@components/MainLayout';
import {
	clearSelectedChat,
	getSelectedChatDetailsFromGlobalState,
} from '@store/selectedChatSlice/selectedChatSlice';
import { isProUserFunc } from '@src/models/user';
import withAuth from '@src/hoc/withAuth';
import Chats from '@components/Chats';
import {
	ADVANCED,
	ARCHIVED,
	EXPERIENCE_MAX,
	EXPERIENCE_MIN,
	INBOX_PATH,
	SORT,
	TIME_PERIOD,
	UNREAD,
} from '@utils/constants';
import classes from './OverviewPage.styles.module.scss';
import {
	fetchTabOverviewInboxData,
	getAllFiltersFromPreAppliedFilters,
	getInboxDataFromGlobalState,
	setAdvancedFilterOptions,
	setCurrChatsDataSelectedChatVisited,
	setSelectedFilters,
	setTabOverviewArchiveDetails,
} from '@store/inboxSlice/inboxSlice';
import useWindowSize from '@hooks/useWindow';
import Button from '@components/ui/Button';
import { ArrowLeftIcon } from '@src/hoc/withIconStyles';
import OverviewInbox from './OverviewInbox';
import { IFiltersRefObj } from '@components/Filters/Filters.types';
import Filters from '@components/Filters';
import ChatsSkeletonLoader from '@components/InboxLayout/ChatsSkeletonLoader';
import useFetch from '@hooks/useFetch';
import ArchivedInfo from './ArchivedInfo';
import { getArchiveData } from '@api/user';
import { IUserFetchArchivalReasonsPayload } from '@api/user/user.types';
import useDelayedLoader from '@hooks/useDelayedLoader';

export function OverviewPage() {
	const { isMobile } = useWindowSize();
	const navigate = useNavigate();

	const dispatch = useDispatch<AppDispatch>();

	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);
	const {
		tabOverviewDetails,
		selectedMainFilter,
		selectedMultiFilters,
		selectedSingleFilters,
		tabOverviewChatsData,
		tabOverviewFilters,
		tabOverviewFetchStatus,
		tabOverviewChatsDataTotalCount,
		currChatsDataSelectedChatId,
		currChatsDataSelectedChatInboxType,
		currChatDataSelectedChatVisited,
		tabOverviewArchiveDetails,
	} = useSelector(getInboxDataFromGlobalState);

	const { selectedChat } = useSelector(getSelectedChatDetailsFromGlobalState);

	const [renderChats, setRenderChats] = useState<boolean>(false);

	const filtersRefObj = useRef<IFiltersRefObj>({} as IFiltersRefObj);
	const recentSelectedFilterRef = useRef<string>('');
	const backgroundModeFiltersRef = useRef<{ [key: string]: string }>({});
	const layoutWithFiltersRef = useRef<HTMLElement | null>(null);

	const filterName = tabOverviewDetails?.filterName ?? '';

	const { callApi: callArchiveData, status: callArchiveDataStatus } = useFetch(getArchiveData);

	const isProUser = isProUserFunc(userDetails) ?? false;

	useEffect(() => {
		dispatch(clearSelectedChat());
		setRenderChats(true);
	}, []);

	useEffect(() => {
		if (!tabOverviewDetails) return;

		if (
			currChatsDataSelectedChatId &&
			currChatsDataSelectedChatInboxType === 'TAB_OVERVIEW_VIEW' &&
			currChatDataSelectedChatVisited
		) {
			dispatch(setCurrChatsDataSelectedChatVisited({ isVisited: false }));
			return;
		}

		const updatedPreAppliedFilters = { ...tabOverviewDetails.preAppliedFilters };

		const { selectedSingleFilters, selectedMultiFilters } =
			getAllFiltersFromPreAppliedFilters(updatedPreAppliedFilters);

		dispatch(
			setAdvancedFilterOptions({
				preAppliedFilters: updatedPreAppliedFilters,
				inboxType: 'TAB_OVERVIEW_VIEW',
			})
		);

		dispatch(
			setSelectedFilters({
				singleSelectedFilters: selectedSingleFilters,
				multiSelectedFilters: selectedMultiFilters,
				inboxType: 'TAB_OVERVIEW_VIEW',
				reArrangeFilters: isMobile,
			})
		);

		dispatch(
			fetchTabOverviewInboxData({
				filterReloadRequired: isProUser,
				lastFiveMessagesRequired: selectedSingleFilters[UNREAD] === UNREAD && isMobile,
			})
		);

		(async function () {
			if (updatedPreAppliedFilters[ARCHIVED]) {
				const userId = userDetails?.userId;

				if (!userId) return;

				const payload: IUserFetchArchivalReasonsPayload = {
					user_id: userId,
				};

				try {
					const data = await callArchiveData(payload);
					dispatch(setTabOverviewArchiveDetails({ tabOverviewArchiveDetails: data }));
				} catch {
					// handle error
				}
			}
		})();
	}, [tabOverviewDetails]);

	const handleOnClickMultiFilter = (filterName: string, updatedOptionsIds: string[]) => {
		const selectedSingleFiltersCopy = { ...selectedSingleFilters };
		const selectedMultiFiltersCopy = { ...selectedMultiFilters };

		selectedMultiFiltersCopy[filterName] = updatedOptionsIds;

		dispatch(
			setSelectedFilters({
				singleSelectedFilters: selectedSingleFiltersCopy,
				multiSelectedFilters: selectedMultiFiltersCopy,
				inboxType: 'TAB_OVERVIEW_VIEW',
				reArrangeFilters: isMobile,
			})
		);

		dispatch(
			fetchTabOverviewInboxData({
				filterReloadRequired: isProUser,
				lastFiveMessagesRequired: selectedSingleFilters[UNREAD] === UNREAD && isMobile,
			})
		);

		recentSelectedFilterRef.current = updatedOptionsIds.length > 0 ? filterName : '';
		dispatch(clearSelectedChat());
	};

	const handleOnClickSingleFilter = (filterName: string, optionId: string, isSelected: boolean) => {
		const minExpValue = selectedSingleFilters[EXPERIENCE_MIN]
			? Number(selectedSingleFilters[EXPERIENCE_MIN])
			: null;
		const maxExpValue = selectedSingleFilters[EXPERIENCE_MAX]
			? Number(selectedSingleFilters[EXPERIENCE_MAX])
			: null;

		if (filterName === EXPERIENCE_MAX && minExpValue && minExpValue > Number(optionId)) {
			return;
		}

		if (filterName === EXPERIENCE_MIN && maxExpValue && Number(optionId) > maxExpValue) {
			return;
		}

		const selectedSingleFiltersCopy = { ...selectedSingleFilters };
		const selectedMultiFiltersCopy = { ...selectedMultiFilters };

		selectedSingleFiltersCopy[filterName] = isSelected ? optionId : null;

		dispatch(
			setSelectedFilters({
				singleSelectedFilters: selectedSingleFiltersCopy,
				multiSelectedFilters: selectedMultiFiltersCopy,
				inboxType: 'TAB_OVERVIEW_VIEW',
				reArrangeFilters: isMobile,
			})
		);

		dispatch(
			fetchTabOverviewInboxData({
				filterReloadRequired: isProUser,
				lastFiveMessagesRequired: selectedSingleFilters[UNREAD] === UNREAD && isMobile,
			})
		);

		recentSelectedFilterRef.current = isSelected ? filterName : '';
		backgroundModeFiltersRef.current[filterName] = filterName;
		dispatch(clearSelectedChat());
	};

	const isLoadingData =
		tabOverviewFetchStatus === 'loading' ||
		tabOverviewFetchStatus === 'idle' ||
		callArchiveDataStatus === 'loading';

	const showLoader = useDelayedLoader(isLoadingData);

	const isTabOverviewChatsDataEmpty = !isLoadingData && tabOverviewChatsData.length === 0;

	const filtersWithOptions = useMemo(() => {
		if (!tabOverviewFilters.length || !isProUser) return [];

		return tabOverviewFilters.filter((eachFilter) => {
			const hasFilterOptions =
				Object.keys(eachFilter.options ?? {}).length > 0 && eachFilter.filterName !== ADVANCED;
			let shouldIncludeFilter = isTabOverviewChatsDataEmpty
				? !!selectedSingleFilters[eachFilter.filterName] ||
				  selectedMultiFilters[eachFilter.filterName]?.length > 0
				: true;

			if (eachFilter.filterName === UNREAD) {
				shouldIncludeFilter = !!selectedMainFilter?.unreadFilterRequired;
			}

			if (eachFilter.filterName === SORT) {
				shouldIncludeFilter = !isTabOverviewChatsDataEmpty;
			}

			if (eachFilter.filterName === TIME_PERIOD) {
				shouldIncludeFilter = !!selectedMainFilter?.showTimePeriodFilter;
			}
			return hasFilterOptions && shouldIncludeFilter;
		});
	}, [tabOverviewFilters, isTabOverviewChatsDataEmpty, isProUser]);

	useEffect(() => {
		const recentSelectedFilterName = recentSelectedFilterRef.current;

		if (!filtersWithOptions.length) return;

		const filtersRef =
			filtersRefObj.current?.getFiltersRef && filtersRefObj.current?.getFiltersRef();
		const filtersDivRef =
			filtersRefObj.current?.getFiltersDivRef && filtersRefObj.current?.getFiltersDivRef();

		const filterToBeFocused = filtersRef?.[recentSelectedFilterName];

		const shouldFocusFilter = filterToBeFocused && isMobile;

		if (shouldFocusFilter) {
			filterToBeFocused.scrollIntoView({ behavior: 'instant', inline: 'center' });
			layoutWithFiltersRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
		} else {
			filtersDivRef?.scrollTo({ behavior: 'instant', left: 0 });
		}
	}, [filtersWithOptions]);

	useEffect(() => {
		if (!isProUser || !tabOverviewDetails) return;

		const ele = document.getElementById('root');

		if (ele) {
			ele.classList.add(classes.rootLayoutViewDesktop);
		}

		return () => {
			if (ele) {
				ele.classList.remove(classes.rootLayoutViewDesktop);
			}
		};
	}, [isProUser, tabOverviewDetails]);

	const handleBack = () => {
		navigate(-1);
	};

	const inChatOpenMode = renderChats && !!selectedChat;

	const filtersToDisplay = filtersWithOptions;

	const showFilters = isMobile
		? !selectedChat && filtersToDisplay.length > 0
		: filtersToDisplay.length > 0;

	// TODO this variable name can be changed to scroll mode
	const overviewMode = !!tabOverviewChatsData.length && !selectedChat && !isLoadingData;

	const showCountText = !!tabOverviewChatsDataTotalCount && !selectedChat;

	const showArchiveData =
		overviewMode &&
		selectedSingleFilters[ARCHIVED] === ARCHIVED &&
		tabOverviewArchiveDetails &&
		tabOverviewArchiveDetails.length > 0;

	if (!isProUser || !tabOverviewDetails) {
		return <Navigate to={INBOX_PATH} replace={true} />;
	}

	return (
		<MainLayout headerTxt={filterName}>
			<section className={classes.messageLayout}>
				{!isMobile && (
					<div className={classes.headerContainer}>
						<Button
							btnText={<ArrowLeftIcon size={isMobile ? 2 : 2.4} />}
							onClick={handleBack}
							customClass={classes.backBtn}
						/>

						<Text variant="h2" medium={!isMobile} light customClass={classes.text}>
							{`${filterName}`}
						</Text>
					</div>
				)}
				<section className={classes.inboxMainLayoutWrapper}>
					<section
						className={clsx(
							classes.inboxMainLayout,
							overviewMode && classes.inboxMainLayoutOverviewMode,
							isLoadingData && classes.inboxMainLayoutLoading,
							!isLoadingData && classes.inboxMainLayoutLoaded
						)}
						ref={layoutWithFiltersRef}
					>
						<div className={classes.loadingContainer}>{showLoader && <ChatsSkeletonLoader />}</div>

						{showArchiveData && <ArchivedInfo infoArr={tabOverviewArchiveDetails} />}

						{showFilters && (
							<Filters
								filters={filtersToDisplay}
								isLoading={tabOverviewFetchStatus === 'loading'}
								onClickMultiFilter={handleOnClickMultiFilter}
								onClickSingleFilter={handleOnClickSingleFilter}
								savedFiltersFromInboxOverview={[]}
								selectedMainFilter={selectedMainFilter}
								selectedMultiFilters={selectedMultiFilters}
								selectedSingleFilters={selectedSingleFilters}
								showSaveFiltersAsTab={false}
								backgroundModeFiltersNameMap={backgroundModeFiltersRef.current}
								showClearFilters={false}
								onClickClearFilters={() => {}}
								ref={filtersRefObj}
							/>
						)}

						{showCountText && (
							<div className={classes.countTextContainer}>
								<Text variant="p" fontSize={1.3} lineHeight={1.8} semiBold secondary>
									{`Showing ${tabOverviewChatsDataTotalCount} ${
										tabOverviewChatsDataTotalCount > 1 ? 'DMs' : 'DM'
									}`}
								</Text>
							</div>
						)}

						<div
							className={clsx(
								classes.inboxMainChild,
								overviewMode && classes.inboxMainChildOverviewMode,
								selectedChat && classes.inboxMainChildBorder
							)}
						>
							<OverviewInbox overviewMode={!!overviewMode} filtersDisplayed={showFilters} />
							<div className={clsx(classes.chats, selectedChat && classes.showChats)}>
								{inChatOpenMode && <Chats />}
							</div>
						</div>
					</section>
				</section>
			</section>
		</MainLayout>
	);
}

export default withAuth(OverviewPage);
