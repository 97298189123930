import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import * as Popover from '@radix-ui/react-popover';
import Text from '@components/ui/Text';
import { CloseIcon, DropDownIcon } from '@src/hoc/withIconStyles';
import { ISingleSelectProps } from './SingleSelect.types';
import classes from './SingleSelect.styles.module.scss';
import SelectItemChild from '../SelectItemChild';
import useWindowSize from '@hooks/useWindow';
import Modal from '../Modal';
import Button from '../Button';

function SingleSelect<T>({
	header,
	onSelectOption,
	optionsMap,
	selectedOptionId,
	getOptionDisplayName,
	getOptionId,
	version,
	onClickSelect,
	getOptionDisplay,
	canChangeBackgroundMode,
	ignoreDeselectOption,
	customHeader,
}: ISingleSelectProps<T>) {
	const { isMobile } = useWindowSize();
	const [isOpen, setIsOpen] = useState(false);
	const popoverTriggerRef = useRef<HTMLButtonElement | null>(null);

	const handleValueChange = (optionId: string, selected: boolean) => {
		const ignoreOptionDeselection =
			ignoreDeselectOption && !selected && optionId === selectedOptionId;

		if (ignoreOptionDeselection) return;

		setIsOpen(false);
		if (!optionsMap) return;
		onSelectOption(optionsMap[optionId], selected);
	};

	const selectedOption = useMemo(() => {
		if (!selectedOptionId || !optionsMap) return null;
		return optionsMap[selectedOptionId];
	}, [selectedOptionId, optionsMap]);

	const options = Object.entries(optionsMap ?? {});

	const filterHeader = customHeader ? (
		customHeader
	) : (
		<button
			className={clsx(
				classes.selectTrigger,
				isOpen && !isMobile && classes.selectButtonActive,
				selectedOption && canChangeBackgroundMode && classes.selectTriggerActive
			)}
		>
			<Text variant="span">
				{!!selectedOption && canChangeBackgroundMode && getOptionDisplayName(selectedOption)}
				{!canChangeBackgroundMode &&
					!!selectedOption &&
					`${header} ${getOptionDisplayName(selectedOption)}`}
				{!selectedOption && canChangeBackgroundMode && `${header}`}
			</Text>

			<span
				aria-disabled
				className={clsx(
					classes.selectIcon,
					selectedOption && canChangeBackgroundMode && classes.selectIconActive
				)}
			>
				<DropDownIcon size={1.2} />
			</span>
		</button>
	);

	const filtersOptionsContainer = (
		<div className={classes.optionsContainer}>
			{options.map(([key, option]) => {
				const optionId = getOptionId(option);
				const displayName = getOptionDisplayName(option);
				const isSelectedOption = selectedOptionId === optionId;
				const display = getOptionDisplay(option, isSelectedOption);
				return (
					<div
						role="option"
						aria-selected={isSelectedOption}
						aria-label={displayName}
						key={key}
						className={classes.selectItem}
						onClick={() => handleValueChange(optionId, !isSelectedOption)}
					>
						<SelectItemChild
							displayName={display}
							isSelected={isSelectedOption}
							version={version}
						/>
					</div>
				);
			})}
		</div>
	);

	const handleCloseModal = () => {
		setIsOpen(false);
	};

	useEffect(() => {
		const obsEle = popoverTriggerRef.current;

		if (!obsEle || isMobile) return;

		const obsOptions = {
			root: null,
			threshold: 1,
		};

		const obsCallback = (entries: IntersectionObserverEntry[]) => {
			const [entry] = entries;

			if (!entry.isIntersecting && isOpen) {
				setIsOpen(false);
			}
		};

		const observer = new IntersectionObserver(obsCallback, obsOptions);

		observer.observe(obsEle);

		return () => {
			if (!obsEle) return;

			observer.unobserve(obsEle);
		};
	}, [isOpen, isMobile]);

	return (
		<>
			<Popover.Root
				open={isOpen}
				onOpenChange={(open) => {
					setIsOpen(open);
					open && onClickSelect && onClickSelect();
				}}
			>
				<Popover.Trigger asChild ref={popoverTriggerRef}>
					{filterHeader}
				</Popover.Trigger>
				{!isMobile && (
					<Popover.Portal>
						<Popover.Content side="bottom" align="start" className={classes.selectContent}>
							{filtersOptionsContainer}
						</Popover.Content>
					</Popover.Portal>
				)}
			</Popover.Root>
			{isMobile && (
				<Modal
					onCloseModal={handleCloseModal}
					showModal={isOpen}
					bottomInMobile
					noPadding
					customClass={classes.modalContainer}
				>
					<div className={classes.header}>
						<Text variant="h2" semiBold medium>
							{header}
						</Text>
						<Button
							btnText={<CloseIcon size={2} className={classes.closeIcon} />}
							onClick={handleCloseModal}
						/>
					</div>
					{filtersOptionsContainer}
				</Modal>
			)}
		</>
	);
}

export default SingleSelect;
