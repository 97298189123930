import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import Text from '@components/ui/Text';
import { CloseIcon, DropDownIcon, SearchIcon } from '@src/hoc/withIconStyles';
import classes from './MultiSelect.styles.module.scss';
import SelectItemChild from '../SelectItemChild';
import { IMultiSelectProps } from './MultiSelect.types';
import clsx from 'clsx';
import useWindowSize from '@hooks/useWindow';
import Modal from '../Modal';
import Button from '../Button';
import DotsLoader from '../DotsLoader';

function MultiSelect<T>({
	getOptionDisplayName,
	getOptionId,
	header,
	onSelectOption,
	onClickShowResults,
	optionsMap,
	selectedOptionsIds,
	version,
	onClickMultiSelect,
	getOptionDisplay,
	isLoading,
}: IMultiSelectProps<T>) {
	const { isMobile } = useWindowSize();
	const [searchStr, setSearchStr] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [showResultsBtnDisability, setShowResultsBtnDisability] = useState(true);
	const [updatedSelectedOptionsIds, setUpdatedSelectedOptionsIds] = useState<string[]>([]);

	const inputRef = useRef<HTMLInputElement>(null);
	const optionsContainerRef = useRef<HTMLDivElement | null>(null);
	const popoverTriggerRef = useRef<HTMLButtonElement | null>(null);

	useEffect(() => {
		setUpdatedSelectedOptionsIds(selectedOptionsIds);
	}, [selectedOptionsIds]);

	useEffect(() => {
		if (!optionsContainerRef.current) return;

		optionsContainerRef.current.scrollTo({ top: 0, behavior: 'instant' });
	}, [searchStr]);

	const handleValueChange = (optionId: string, selected: boolean) => {
		let optionsIdsToUpdate: string[] = [...updatedSelectedOptionsIds];

		if (selected) {
			optionsIdsToUpdate.push(optionId);
		} else {
			optionsIdsToUpdate = optionsIdsToUpdate.filter((id) => id !== optionId);
		}

		setUpdatedSelectedOptionsIds(optionsIdsToUpdate);

		setShowResultsBtnDisability(false);

		if (optionsMap) onSelectOption(optionsMap[optionId], selected);

		onClickShowResults(optionsIdsToUpdate);
	};

	const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchStr(e.target.value.toLocaleLowerCase());
	};

	const handleOnClickSearchBox = () => {
		inputRef.current?.focus();
	};

	const optionsState = useMemo(() => {
		const optionsStateObj = {
			selectedOptions: [] as T[],
			unselectedOptions: [] as T[],
			reArrangedOptions: [] as T[],
		};

		if (!optionsMap) return optionsStateObj;

		Object.values(optionsMap).map((option) => {
			const optionId = getOptionId(option);
			const displayName = getOptionDisplayName(option);

			let canAddOptionToList = true;

			if (searchStr) {
				canAddOptionToList = displayName.toLocaleLowerCase().includes(searchStr);
			}

			if (selectedOptionsIds?.includes(optionId)) {
				canAddOptionToList && optionsStateObj.selectedOptions.push(option);
			} else {
				canAddOptionToList && optionsStateObj.unselectedOptions.push(option);
			}
		});

		optionsStateObj.reArrangedOptions = [
			...optionsStateObj.selectedOptions,
			...optionsStateObj.unselectedOptions,
		];

		return optionsStateObj;
	}, [selectedOptionsIds, optionsMap, searchStr]);

	const showSearchBox = Object.keys(optionsMap ?? {}).length > 10;

	const reArrangedOptions = optionsState.reArrangedOptions;

	const inSearchMode = searchStr.length > 0;
	const searchResultsLength = reArrangedOptions.length;

	const selectAllBtnState = useMemo(() => {
		return {
			isAllOptionsSelected: reArrangedOptions.every((option) =>
				updatedSelectedOptionsIds.includes(getOptionId(option))
			),
			shouldDisplaySelectState: reArrangedOptions.length > 0,
			btnText: inSearchMode ? 'Select all matching' : 'Select all',
		};
	}, [updatedSelectedOptionsIds, inSearchMode, reArrangedOptions]);

	const handleSelectAllBtnClick = (isSelected: boolean) => {
		setShowResultsBtnDisability(false);

		let optionsIdsToUpdate: string[] = [...updatedSelectedOptionsIds];

		reArrangedOptions.forEach((option) => {
			const optionId = getOptionId(option);
			if (isSelected) {
				if (!optionsIdsToUpdate.includes(optionId)) {
					optionsIdsToUpdate.push(optionId);
				}
			} else {
				optionsIdsToUpdate = optionsIdsToUpdate.filter((id) => id !== optionId);
			}
		});

		setUpdatedSelectedOptionsIds(optionsIdsToUpdate);
		onClickShowResults(optionsIdsToUpdate);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		setShowResultsBtnDisability(true);
	};

	const filterHeader = (
		<button
			className={clsx(
				classes.selectTrigger,
				isOpen && !isMobile && classes.selectButtonActive,
				selectedOptionsIds.length > 0 && classes.selectTriggerActive
			)}
		>
			<Text variant="span">{header}</Text>
			{selectedOptionsIds.length > 0 && (
				<div className={classes.countContainer}>
					<Text variant="span" white customClass={classes.count}>
						{selectedOptionsIds.length}
					</Text>
				</div>
			)}

			<span
				aria-disabled
				className={clsx(
					classes.selectIcon,
					selectedOptionsIds.length > 0 && classes.selectIconActive
				)}
			>
				<DropDownIcon size={1.2} />
			</span>
		</button>
	);

	const filtersOptionsContainer = (
		<>
			{showSearchBox && (
				<div className={classes.searchContainer} role="searchbox" onClick={handleOnClickSearchBox}>
					<SearchIcon size={1.4} />
					<input
						className={classes.input}
						placeholder="Search"
						value={searchStr}
						onChange={handleSearchInputChange}
						ref={inputRef}
						autoComplete="off"
					/>
				</div>
			)}

			<div className={classes.optionsContainer} ref={optionsContainerRef}>
				{selectAllBtnState.shouldDisplaySelectState && (
					<div
						role="option"
						aria-selected={selectAllBtnState.isAllOptionsSelected}
						aria-label={selectAllBtnState.btnText}
						className={classes.selectItem}
						onClick={() => handleSelectAllBtnClick(!selectAllBtnState.isAllOptionsSelected)}
					>
						<SelectItemChild
							displayName={selectAllBtnState.btnText}
							isSelected={selectAllBtnState.isAllOptionsSelected}
							version={'select all'}
						/>
					</div>
				)}

				{inSearchMode && !searchResultsLength && (
					<Text variant="p" tertiary small light customClass={classes.noOptionsText}>
						{'No options available'}
					</Text>
				)}

				{reArrangedOptions.length > 0 &&
					reArrangedOptions.map((option) => {
						const optionId = getOptionId(option);
						const isSelectedOption = updatedSelectedOptionsIds.includes(optionId);
						const isInitialSelectedOption = selectedOptionsIds.includes(optionId);
						const displayName = getOptionDisplayName(option);
						const display = getOptionDisplay(option, isInitialSelectedOption);

						return (
							<div
								role="option"
								aria-selected={isSelectedOption}
								aria-label={displayName}
								key={optionId}
								className={classes.selectItem}
								onClick={() => handleValueChange(optionId, !isSelectedOption)}
							>
								<SelectItemChild
									displayName={display}
									isSelected={isSelectedOption}
									version={version}
								/>
							</div>
						);
					})}
			</div>
		</>
	);

	useEffect(() => {
		const obsEle = popoverTriggerRef.current;

		if (!obsEle || isMobile) return;

		const obsOptions = {
			root: null,
			threshold: 1,
		};

		const obsCallback = (entries: IntersectionObserverEntry[]) => {
			const [entry] = entries;

			if (!entry.isIntersecting && isOpen) {
				setIsOpen(false);
			}
		};

		const observer = new IntersectionObserver(obsCallback, obsOptions);

		observer.observe(obsEle);

		return () => {
			if (!obsEle) return;

			observer.unobserve(obsEle);
		};
	}, [isOpen, isMobile]);

	return (
		<>
			<Popover.Root
				open={isOpen}
				onOpenChange={(open) => {
					setIsOpen(open);
					open && onClickMultiSelect && onClickMultiSelect();
				}}
			>
				<Popover.Trigger asChild ref={popoverTriggerRef}>
					{filterHeader}
				</Popover.Trigger>
				{!isMobile && (
					<Popover.Portal>
						<Popover.Content
							side="bottom"
							align="start"
							className={clsx(classes.selectContent, isLoading && classes.hideSelectContent)}
						>
							{filtersOptionsContainer}
						</Popover.Content>
					</Popover.Portal>
				)}
			</Popover.Root>
			{isMobile && (
				<Modal
					onCloseModal={handleCloseModal}
					showModal={isOpen}
					bottomInMobile
					noPadding
					customClass={clsx(classes.modalContainer, showSearchBox && classes.modalMinHeight)}
				>
					{isLoading && (
						<div className={classes.modalLoaderContainer}>
							<DotsLoader />
						</div>
					)}

					<div className={classes.header}>
						<Text variant="h2" semiBold medium>
							{header}
						</Text>
						<Button
							btnText={<CloseIcon size={2} className={classes.closeIcon} />}
							onClick={handleCloseModal}
						/>
					</div>
					{filtersOptionsContainer}
					<Button
						btnText={
							<Text
								variant="span"
								small
								semiBold
								white={!showResultsBtnDisability}
								disabled={showResultsBtnDisability}
							>
								{'Show Results'}
							</Text>
						}
						onClick={handleCloseModal}
						customClass={classes.showResultsBtn}
						primary
						disabled={showResultsBtnDisability}
					/>
				</Modal>
			)}
		</>
	);
}

export default MultiSelect;
