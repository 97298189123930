import React from 'react';
import clsx from 'clsx';
import Text from '@components/ui/Text';
import { IMainFiltersProps } from './MainFilters.types';
import classes from './MainFilters.styles.module.scss';
import { ALL, BOOKMARKS, mixPanelEvents } from '@utils/constants';
import Button from '@components/ui/Button';
import mixpanelActions from '@utils/mixpanel';
import { Tabs } from '@src/models/user';

function MainFilters({
	mainFilters,
	onSelectMainFilter,
	selectedMainFilter,
	mobileView,
}: IMainFiltersProps) {
	const handleOnClickMainFilter = (mainFilterToSelect: Tabs) => {
		mixpanelActions.trackInboxMainFilters(
			mixPanelEvents.INBOX_TAB_CLICKED,
			selectedMainFilter?.name ?? '',
			mainFilterToSelect.name
		);
		onSelectMainFilter(mainFilterToSelect);
	};

	return (
		<div className={classes.mainFiltersContainer}>
			{mainFilters.map((mainFilter) => {
				const isSelectedMainFilter = selectedMainFilter?.name === mainFilter.name;
				const displayTabName = mainFilter.displayName;

				const isAllFilter = mainFilter.name === ALL;
				const isBookmarkFilter = mainFilter.name === BOOKMARKS;

				return (
					<Button
						customClass={clsx(
							classes.mainFilter,
							isAllFilter && classes.allFilter,
							isBookmarkFilter && classes.bookmarkFilter
						)}
						btnText={
							<Text
								variant="span"
								brandTertiaryColor
								light={!isSelectedMainFilter}
								semiBold={isSelectedMainFilter}
								white={isSelectedMainFilter}
								medium={!mobileView}
							>
								{displayTabName}
							</Text>
						}
						key={mainFilter.name}
						onClick={() => handleOnClickMainFilter(mainFilter)}
					/>
				);
			})}
		</div>
	);
}

export default MainFilters;
