import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Modal from '@components/ui/Modal';
import { IAuthModalProps, TAuthModalStates } from './AuthModal.types';
import Button from '@components/ui/Button';
import Text from '@components/ui/Text';
import { GoogleIcon } from '@src/hoc/withIconStyles';
import classes from './AuthModal.styles.module.scss';
import { isSuperDMApp, isValidEmail, shouldShowWebviewErrorModal } from '@utils/common';
import useWindowSize from '@hooks/useWindow';
import useFetch from '@hooks/useFetch';
import { generateOTP } from '@api/auth';
import { getGoogleOAuthURL } from '@utils/oauth';
import { setSessionStorage } from '@utils/sessionStorage';
import { oauthRedirectPath } from '@utils/constants';

const OTP_LENGTH = 6;

function AuthModal({
	modalHeader,
	onCloseModal,
	showModal,
	onSuccessAuth,
	modalError = '',
	isLoadingAuth,
	onClickContinueEmailBtn,
	onClickGoogleBtn,
	onOTPInputShown,
	onOTPSubmitted,
}: IAuthModalProps) {
	const { isMobile } = useWindowSize();

	const [inputEmailState, setInputEmailState] = useState({
		value: '',
		error: '',
	});
	const [otpState, setOtpState] = useState({
		value: Array(OTP_LENGTH).fill(''),
		error: '',
	});

	const [modalState, setModalState] = useState<TAuthModalStates>('LOGIN');

	const emailInputRef = useRef<HTMLInputElement | null>(null);
	const otpInputsRef = useRef<(HTMLInputElement | null)[]>(Array(OTP_LENGTH).fill(null));
	const divToScrollEnd = useRef<HTMLDivElement | null>(null);

	const { callApi: callGenerateOTP, status: callGenerateOTPStatus } = useFetch(generateOTP);

	const isLoading = isLoadingAuth || callGenerateOTPStatus === 'loading';

	useEffect(() => {
		if (modalError.length > 0) {
			setOtpState((prevState) => {
				return {
					...prevState,
					error: modalError,
				};
			});
		}
	}, [modalError]);

	const shouldHideGoogleBtn = shouldShowWebviewErrorModal(isMobile) || isSuperDMApp();

	const handleGoogleBtnClick = () => {
		if (isLoading) return;
		onClickGoogleBtn();
		setSessionStorage(oauthRedirectPath, window.location.href.replace(window.location.origin, ''));
		const googleOAuthURL = getGoogleOAuthURL();
		window.open(googleOAuthURL, '_self');
	};

	const handleInputEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (isLoading) return;
		setInputEmailState({
			value: e.target.value,
			error: '',
		});
	};

	const handleInputEmailKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (isLoading) return;
		if (e.key === 'Enter') {
			handleContinue();
		}
	};

	const handleBack = () => {
		if (isLoading) return;
		setOtpState({
			error: '',
			value: Array(OTP_LENGTH).fill(''),
		});
		setModalState('LOGIN');
	};

	const handleContinue = async () => {
		if (isLoading) return;
		emailInputRef.current?.focus();
		const inputEmail = inputEmailState.value;

		if (!isValidEmail(inputEmail)) {
			setInputEmailState((prevState) => {
				return {
					...prevState,
					error: `That doesn’t seem right`,
				};
			});
			return;
		}

		onClickContinueEmailBtn(inputEmail);

		try {
			await callGenerateOTP(inputEmail);
			setModalState('OPT_VERIFY');
			onOTPInputShown();
		} catch (error) {
			setInputEmailState((prevState) => {
				return {
					...prevState,
					error: error,
				};
			});
		}
	};

	const handleOTPChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		if (isLoading) return;
		const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters

		let newOTP = [...otpState.value];

		if (value.length === OTP_LENGTH) {
			newOTP = value.split('');
		} else {
			const digitValueToAdd = value.slice(-1);
			newOTP[index] = digitValueToAdd;
			!!digitValueToAdd && otpInputsRef.current[index + 1]?.focus();
		}

		if (newOTP.join('').length === OTP_LENGTH) {
			handleVerifyOtp(newOTP);
		}

		setOtpState({
			value: newOTP,
			error: '',
		});
	};

	const handleVerifyOtp = (otpValue?: string[]) => {
		if (isLoading) return;
		const otpDigitsValue = otpValue ? otpValue.join('') : otpState.value.join('');

		const otpFocusIndex =
			otpDigitsValue.length === OTP_LENGTH ? otpDigitsValue.length - 1 : otpDigitsValue.length;

		otpInputsRef.current[otpFocusIndex]?.focus();

		if (otpDigitsValue.length !== OTP_LENGTH) {
			setOtpState((prevState) => {
				return {
					...prevState,
					error: `That doesn’t seem right`,
				};
			});
			return;
		}

		onOTPSubmitted();
		const formattedOTP = Number(otpDigitsValue);
		onSuccessAuth({ email: inputEmailState.value, otp: formattedOTP });
	};

	const handleOTPKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
		if (isLoading) return;
		if (e.key === 'Enter') {
			handleVerifyOtp();
		}

		if (e.key === 'Backspace') {
			!otpState.value[index] && otpInputsRef.current[index - 1]?.focus();
		}
	};

	const handleCloseModal = () => {
		if (isLoading) return;

		onCloseModal();
	};

	const isEmailInputError = !!inputEmailState.error;
	const isOtpInputError = !!otpState.error;

	useEffect(() => {
		const handleResize = () => {
			const otpDigitsValue = otpState.value.join('');

			if (otpDigitsValue.length > 0 && otpDigitsValue.length < OTP_LENGTH - 1) return;

			// for ios
			window.scrollTo({
				behavior: 'instant',
				top: document.documentElement.scrollHeight - (window.visualViewport?.height ?? 0),
			});

			const ele = document.getElementById('root');

			// for android
			ele?.scrollIntoView({ behavior: 'smooth', block: 'end' });
		};

		window.visualViewport?.addEventListener('resize', handleResize);

		return () => {
			window.visualViewport?.removeEventListener('resize', handleResize);
		};
	}, [otpState]);

	return (
		<Modal showModal={showModal} onCloseModal={handleCloseModal} bottomInMobile noPadding>
			<div className={classes.header}>
				<Text variant="h2" medium>
					{modalState === 'LOGIN' && modalHeader}
					{modalState === 'OPT_VERIFY' && `Enter the OTP`}
				</Text>
			</div>
			{modalState === 'LOGIN' && (
				<div className={classes.authModalContentContainer} ref={divToScrollEnd}>
					{!shouldHideGoogleBtn && (
						<>
							<Button
								btnText={
									<Text variant="span" small semiBold white>
										{'Continue with Google'}
									</Text>
								}
								onClick={handleGoogleBtnClick}
								prefixIcon={<GoogleIcon size={2.4} />}
								primary
								customClass={clsx(classes.googleBtnSpacing, classes.googleBtn)}
								isLoading={isLoadingAuth}
							/>
							<Text variant="span" tertiary tiny customClass={classes.orText}>
								{'OR'}
							</Text>
						</>
					)}

					<input
						type="email"
						className={clsx(classes.input, isEmailInputError && classes.inputErrorState)}
						value={inputEmailState.value}
						onChange={handleInputEmailChange}
						placeholder={'Enter your email id'}
						onKeyDown={handleInputEmailKeyDown}
						autoFocus={shouldHideGoogleBtn || !!inputEmailState.value}
						ref={emailInputRef}
						autoComplete="off"
					/>
					<Text variant="p" error light tiny customClass={classes.errorText}>
						{isEmailInputError && inputEmailState.error}
					</Text>

					<Button
						btnText={
							<Text variant="span" small white semiBold>
								{'Next'}
							</Text>
						}
						onClick={handleContinue}
						customClass={classes.continueBtn}
						primary
						isLoading={callGenerateOTPStatus === 'loading'}
					/>
				</div>
			)}
			{modalState === 'OPT_VERIFY' && (
				<div className={classes.authModalVerifyOTPContentContainer} ref={divToScrollEnd}>
					<div className={classes.mailTextContainer}>
						<Text
							variant="p"
							small
							ultraLight
							secondary
							customClass={classes.mailText}
						>{`Sent to ${inputEmailState.value}`}</Text>
						<Button
							btnText={
								<Text variant="span" tiny secondary light>
									{'Change'}
								</Text>
							}
							onClick={handleBack}
							customClass={classes.backBtn}
						/>
					</div>
					<div className={classes.otpInputsContainer}>
						{otpState.value.map((otpDigit, index) => {
							return (
								<input
									key={index}
									type="text"
									className={clsx(classes.otpInput, isOtpInputError && classes.otpInputErrorState)}
									onChange={(e) => handleOTPChange(e, index)}
									value={otpDigit}
									onKeyDown={(e) => handleOTPKeyDown(e, index)}
									inputMode="numeric"
									autoFocus={index === 0}
									ref={(el) => {
										otpInputsRef.current[index] = el;
									}}
									autoComplete="off"
								/>
							);
						})}
					</div>

					<Text variant="p" error light tiny customClass={classes.errorText}>
						{isOtpInputError && otpState.error}
					</Text>
					<Button
						btnText={
							<Text variant="span" small white semiBold>
								{'Submit'}
							</Text>
						}
						onClick={() => handleVerifyOtp()}
						customClass={classes.verifyBtn}
						primary
						isLoading={isLoadingAuth}
					/>
				</div>
			)}
		</Modal>
	);
}

export default AuthModal;
