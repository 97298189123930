import React, { ForwardedRef, useEffect, useImperativeHandle, useRef } from 'react';
import SaveFilters from '@components/InboxLayout/SaveFilters';
import Text from '@components/ui/Text';
import classes from './Filters.styles.module.scss';
import { IChevronState, IFiltersProps, IFiltersRefObj } from './Filters.types';
import Button from '@components/ui/Button';
import { ChevronRightIcon, CloseIcon } from '@src/hoc/withIconStyles';
import mixpanelActions from '@utils/mixpanel';
import { SORT, TIME_PERIOD, UNREAD, mixPanelEvents } from '@utils/constants';
import SingleSelect from '@components/ui/SingleSelect';
import { FilterOption } from '@src/models/inbox';
import MultiSelect from '@components/ui/MutliSelect';
import useWindowSize from '@hooks/useWindow';
import SingleClickSelect from '@components/ui/SingleClickSelect';
import clsx from 'clsx';

function Filters(
	{
		filters,
		onClickMultiFilter,
		onClickSingleFilter,
		selectedMultiFilters,
		selectedSingleFilters,
		showSaveFiltersAsTab,
		savedFiltersFromInboxOverview,
		onSaveFilters,
		selectedMainFilter,
		isLoading,
		backgroundModeFiltersNameMap,
		onClickClearFilters,
		showClearFilters,
	}: IFiltersProps,
	ref: ForwardedRef<IFiltersRefObj>
) {
	const { isMobile } = useWindowSize();

	const filtersChevronRef = useRef<IChevronState>({
		chevronLeftDiv: null,
		chevronRightDiv: null,
		currScrolledPos: 0,
		scrollableContainer: null,
		scrollableContainerWrapper: null,
	});
	const filtersRef = useRef<{ [key: string]: HTMLDivElement | null }>({});
	const filtersDivRef = useRef<HTMLDivElement | null>(null);

	useImperativeHandle(
		ref,
		() => ({
			getFiltersRef: () => filtersRef.current,
			getFiltersDivRef: () => filtersDivRef.current,
		}),
		[]
	);

	useEffect(() => {
		handleFiltersDivMouseLeave();
	}, [filters]);

	const handleFiltersDivMouseEnter = () => {
		if (isMobile) return;

		const scrollEle = filtersChevronRef.current.scrollableContainer;
		const chevronLeft = filtersChevronRef.current.chevronLeftDiv;
		const chevronRight = filtersChevronRef.current.chevronRightDiv;
		const scrollPos = filtersChevronRef.current.currScrolledPos;
		const scrollEleWrapper = filtersChevronRef.current.scrollableContainerWrapper;

		if (!scrollEle || !scrollEleWrapper) return;

		const shouldShowRightChevron = scrollEleWrapper.scrollWidth + scrollPos < scrollEle.scrollWidth;

		shouldShowRightChevron
			? chevronRight?.classList.add(classes.showChevron)
			: chevronRight?.classList.remove(classes.showChevron);

		const shouldShowLeftChevron = scrollPos > 0;
		shouldShowLeftChevron
			? chevronLeft?.classList.add(classes.showChevron)
			: chevronLeft?.classList.remove(classes.showChevron);
	};

	const handleFiltersDivMouseLeave = () => {
		if (isMobile) return;
		const chevronLeft = filtersChevronRef.current.chevronLeftDiv;
		const chevronRight = filtersChevronRef.current.chevronRightDiv;

		chevronRight?.classList.remove(classes.showChevron);
		chevronLeft?.classList.remove(classes.showChevron);
	};

	const handleClickRightChevronBtn = () => {
		if (isMobile) return;
		const scrollEle = filtersChevronRef.current.scrollableContainer;
		const scrollEleWrapper = filtersChevronRef.current.scrollableContainerWrapper;
		const scrollPos = filtersChevronRef.current.currScrolledPos;

		if (!scrollEle || !scrollEleWrapper) return;

		const scrollToUpdate = Math.ceil(scrollPos + scrollEleWrapper.scrollWidth);

		if (scrollToUpdate > scrollEle.scrollWidth) return;

		scrollEle.scrollTo({ behavior: 'smooth', left: scrollToUpdate });
		filtersChevronRef.current.currScrolledPos = scrollToUpdate;
	};

	const handleClickLeftChevronBtn = () => {
		if (isMobile) return;
		const scrollEle = filtersChevronRef.current.scrollableContainer;
		const scrollEleWrapper = filtersChevronRef.current.scrollableContainerWrapper;
		const scrollPos = filtersChevronRef.current.currScrolledPos;

		if (!scrollEle || !scrollEleWrapper) return;

		if (scrollPos <= 0) return;

		const scrollToUpdate = Math.ceil(scrollPos - scrollEleWrapper.scrollWidth);

		scrollEle.scrollTo({ behavior: 'smooth', left: scrollToUpdate });
		filtersChevronRef.current.currScrolledPos = scrollToUpdate;
	};

	const handleFiltersDivScroll = () => {
		if (isMobile) return;
		const scrollEle = filtersChevronRef.current.scrollableContainer;
		if (!scrollEle) return;
		filtersChevronRef.current.currScrolledPos = Math.ceil(scrollEle.scrollLeft);
		handleFiltersDivMouseEnter();
	};

	const actionsContainer = (
		<div className={classes.actionsContainer}>
			{showSaveFiltersAsTab && (
				<SaveFilters savedFilters={savedFiltersFromInboxOverview} onSaveFilters={onSaveFilters} />
			)}
			{showClearFilters && !isMobile && showSaveFiltersAsTab && <div className={classes.divider} />}
			{showClearFilters && !isMobile && (
				<Button
					btnText={
						<Text variant="span" bold brandPrimaryColor>
							{'Clear'}
						</Text>
					}
					onClick={onClickClearFilters}
					customClass={classes.clearFiltersBtn}
				/>
			)}
		</div>
	);

	const clearFiltersMobile = isMobile && showClearFilters && (
		<Button
			btnText={<CloseIcon size={1.6} />}
			onClick={onClickClearFilters}
			customClass={classes.clearFiltersBtnMobile}
		/>
	);

	return (
		<div className={classes.allFiltersMainContainer} id="filters">
			<div
				className={classes.filtersContainerWithChevron}
				ref={(el) => {
					filtersChevronRef.current.scrollableContainerWrapper = el;
				}}
				onMouseEnter={handleFiltersDivMouseEnter}
				onMouseLeave={handleFiltersDivMouseLeave}
			>
				<div
					className={classes.chevronLeft}
					ref={(el) => {
						filtersChevronRef.current.chevronLeftDiv = el;
					}}
				>
					<Button
						btnText={<ChevronRightIcon size={1.6} className={classes.icon} />}
						onClick={handleClickLeftChevronBtn}
					/>
				</div>

				<div
					className={classes.allFiltersContainer}
					ref={(el) => {
						filtersChevronRef.current.scrollableContainer = el;
						filtersDivRef.current = el;
					}}
					onScroll={handleFiltersDivScroll}
				>
					{filters.map((filter) => {
						const isUnreadFilter = filter.filterName === UNREAD;
						const showUnreadFilterWithBorder = isUnreadFilter && filters.length > 1;

						return (
							<React.Fragment key={filter.filterName}>
								{filter.filterType === 'multi' && (
									<div
										ref={(el) => {
											filtersRef.current[filter.filterName] = el;
										}}
										className={classes.filterContainer}
									>
										<MultiSelect<FilterOption>
											getOptionDisplayName={(option) => option.value}
											getOptionDisplay={(option, isSelected) => (
												<span>
													{option.value}
													{!isSelected && (
														<Text
															variant="span"
															tertiary
															tiny
															light
															customClass={classes.optionCount}
														>
															{option.count ? ` (${option.count})` : ``}
														</Text>
													)}
												</span>
											)}
											getOptionId={(option) => option.value}
											header={filter.filterHeader}
											onSelectOption={(option, selected) => {
												if (selected) {
													mixpanelActions.trackFilters({
														eventName: mixPanelEvents.INBOX_FILTER_SELECTED,
														tabName: selectedMainFilter?.name ?? '',
														selectedValue: option.value,
														labelName: filter.filterHeader,
													});
												}
											}}
											onClickShowResults={(updatedSelectedOptions) => {
												onClickMultiFilter(filter.filterName, updatedSelectedOptions);
											}}
											optionsMap={filter.options}
											selectedOptionsIds={selectedMultiFilters[filter.filterName] ?? []}
											version={filter.filterType === 'multi' ? 'checkbox' : 'radio'}
											onClickMultiSelect={() => {
												mixpanelActions.trackFilters({
													eventName: mixPanelEvents.INBOX_FILTER_CLICKED,
													tabName: selectedMainFilter?.name ?? '',
													labelName: filter.filterHeader,
												});
											}}
											isLoading={isLoading}
										/>
									</div>
								)}
								{filter.filterType === 'singleClick' && (
									<div
										ref={(el) => {
											filtersRef.current[filter.filterName] = el;
										}}
										className={clsx(
											classes.filterContainer,
											showUnreadFilterWithBorder && classes.unreadWithBorder
										)}
									>
										<SingleClickSelect<FilterOption>
											getOptionId={(option) => option.value}
											header={filter.filterHeader}
											onSelectOption={(option, selected) => {
												if (selected) {
													mixpanelActions.trackFilters({
														eventName: mixPanelEvents.INBOX_FILTER_SELECTED,
														tabName: selectedMainFilter?.name ?? '',
														selectedValue: option.value,
														labelName: filter.filterHeader,
													});
												}
												onClickSingleFilter(filter.filterName, option.value, selected);
											}}
											optionsMap={filter.options}
											selectedOptionId={
												selectedSingleFilters[filter.filterName]
													? selectedSingleFilters[filter.filterName]
													: null
											}
											onClickSelect={() => {
												mixpanelActions.trackFilters({
													eventName: mixPanelEvents.INBOX_FILTER_CLICKED,
													tabName: selectedMainFilter?.name ?? '',
													labelName: filter.filterHeader,
												});
											}}
											checkSelectedOptionActive={(selectedOption) =>
												selectedOption.isActiveOption ?? false
											}
											filterView={isUnreadFilter ? 'SWITCH' : 'BOX'}
										/>
									</div>
								)}
								{filter.filterType === 'single' && (
									<div
										ref={(el) => {
											filtersRef.current[filter.filterName] = el;
										}}
										className={classes.filterContainer}
									>
										<SingleSelect<FilterOption>
											getOptionDisplayName={(option) => option.valueDisplay ?? option.value}
											getOptionDisplay={(option, isSelected) => (
												<span>
													{option.valueDisplay ?? option.value}
													{!isSelected && (
														<Text
															variant="span"
															tertiary
															tiny
															light
															customClass={classes.optionCount}
														>
															{option.count ? ` (${option.count})` : ``}
														</Text>
													)}
												</span>
											)}
											getOptionId={(option) => option.value}
											header={filter.filterHeader}
											onSelectOption={(option, selected) => {
												if (selected) {
													if (filter.filterName === SORT) {
														mixpanelActions.trackFilters({
															eventName: mixPanelEvents.INBOX_SORT_SELECTED,
															tabName: selectedMainFilter?.name ?? '',
															selectedValue: option.value,
														});
													} else {
														mixpanelActions.trackFilters({
															eventName: mixPanelEvents.INBOX_FILTER_SELECTED,
															tabName: selectedMainFilter?.name ?? '',
															selectedValue: option.value,
															labelName: filter.filterHeader,
														});
													}
												}
												onClickSingleFilter(filter.filterName, option.value, selected);
											}}
											optionsMap={filter.options}
											selectedOptionId={
												selectedSingleFilters[filter.filterName]
													? selectedSingleFilters[filter.filterName]
													: null
											}
											version={
												filter.filterType === 'single' &&
												(filter.filterName === SORT || filter.filterName === TIME_PERIOD)
													? 'radio'
													: 'checkbox'
											}
											onClickSelect={() => {
												if (filter.filterName === SORT) {
													mixpanelActions.trackFilters({
														eventName: mixPanelEvents.INBOX_SORT_CLICKED,
														tabName: selectedMainFilter?.name ?? '',
														currentSort: selectedSingleFilters[filter.filterName] ?? 'null',
													});
												} else {
													mixpanelActions.trackFilters({
														eventName: mixPanelEvents.INBOX_FILTER_CLICKED,
														tabName: selectedMainFilter?.name ?? '',
														labelName: filter.filterHeader,
													});
												}
											}}
											canChangeBackgroundMode={
												filter.filterName === SORT
													? !!backgroundModeFiltersNameMap[filter.filterName]
													: true
											}
											ignoreDeselectOption={
												filter.filterName === SORT || filter.filterName === TIME_PERIOD
											}
										/>
									</div>
								)}
								{isUnreadFilter && clearFiltersMobile}
							</React.Fragment>
						);
					})}
					{isMobile && actionsContainer}
				</div>

				<div
					className={classes.chevronRight}
					ref={(el) => {
						filtersChevronRef.current.chevronRightDiv = el;
					}}
				>
					<Button
						btnText={<ChevronRightIcon size={1.6} className={classes.icon} />}
						onClick={handleClickRightChevronBtn}
					/>
				</div>
			</div>
			{!isMobile && actionsContainer}
		</div>
	);
}

export default React.forwardRef(Filters);
