import React from 'react';
import classes from './ChatsUserSkeletonLoader.styles.module.scss';

function ChatsUserSkeletonLoader() {
	return (
		<div className={classes.chatUserInfoLoaderContainer}>
			<div className={classes.imageContainer} />
			<div className={classes.nameContainer} />
			<div className={classes.bioContainer} />
		</div>
	);
}

export default ChatsUserSkeletonLoader;
