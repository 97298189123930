import React, { useEffect, useMemo, useRef } from 'react';
import classes from './SwipeChatCards.styles.module.scss';
import { ISwipeActionsRefObj, TSwipeDirection } from './SwipeableChatCard/SwipeableChatCard.type';
import { SenderChatData } from '@src/models/inbox';
import SwipeableChatCard from './SwipeableChatCard';
import SwipeableChatCardContent from './SwipeableChatCardContent';
import { useDispatch, useSelector } from 'react-redux';
import {
	addToChatCardActionsMap,
	fetchPaginatedInboxData,
	getInboxDataFromGlobalState,
	removeFromChatCardActionsMap,
	setCurrChatsDataSelectedChatId,
	setCurrReadChatsMap,
	setReadChatActionCount,
} from '@store/inboxSlice/inboxSlice';
import { markChatAsRead } from '@api/chats';
import useFetch from '@hooks/useFetch';
import {
	getUserDetailsFromGlobalState,
	setSwipeEducationDone,
} from '@store/userDetailsSlice/userDetailsSlice';
import { IMarkAsReadPayload } from '@api/chats/chats.types';
import mixpanelActions from '@utils/mixpanel';
import {
	CHAT_SEARCH_PARAM,
	LOADING_SWIPE_CARD_INDEX_TO_SUBTRACT,
	UNREAD,
	mixPanelEvents,
} from '@utils/constants';
import DotsLoader from '@components/ui/DotsLoader';
import Button from '@components/ui/Button';
import Text from '@components/ui/Text';
import useInboxLayoutContext from '@hooks/useInboxLayoutContext';
import { ArrowBendUpLeftIcon, UndoIcon } from '@src/hoc/withIconStyles';
import { markSwipeEducationDone } from '@api/user';
import { IUserMarkSwipeEducationDonePayload } from '@api/user/user.types';
import { AppDispatch } from '@store/index';
import { useSearchParams } from 'react-router-dom';
import InboxError from '@components/InboxError';
import NoResultsFound from '@components/NoResultsFound';
import useWindowSize from '@hooks/useWindow';
import { setSelectedChat } from '@store/selectedChatSlice/selectedChatSlice';
import { getEmptyStatesData } from '@utils/common';

function SwipeCardsContainer() {
	const dispatch = useDispatch<AppDispatch>();
	const { isMobile } = useWindowSize();
	const [searchParams, setSearchParams] = useSearchParams();
	const {
		chatCardActionsMap,
		currReadChatsMap,
		currReportChatsMap,
		currBlockChatsMap,
		chatsDataTotalCount,
		selectedMainFilter,
		chatsData,
		paginationDetails,
		fetchStatus,
		selectedSingleFilters,
		tapToRefresh,
		currChatsLatestMessagesMap,
	} = useSelector(getInboxDataFromGlobalState);
	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);
	const { isTabDefaultFiltersChanged, onClickMainFilter, onClickSingleFilter } =
		useInboxLayoutContext();

	const { callApi: callMarkChatAsRead, status: callMarkChatAsReadStatus } =
		useFetch(markChatAsRead);
	const { callApi: callMarkSwipeEducationDone } = useFetch(markSwipeEducationDone);

	const [swipeMode, setSwipeMode] = React.useState(true);
	const recentSwipedCardChatData = useRef<SenderChatData | null>(null);
	const recentSwipedCardDirection = useRef<TSwipeDirection | null>(null);
	const undoClicked = useRef<boolean>(false);

	const swipeActionsObjRef = useRef<ISwipeActionsRefObj | null>(null);

	const chatsDataToDisplay = useMemo(() => {
		if (!chatsData.length) return [];

		return chatsData
			.filter((chatData) => {
				const chatId = chatData.chatId;
				const canDisplayCard =
					!!chatId &&
					!chatCardActionsMap[chatId] &&
					!currReportChatsMap[chatId] &&
					!currBlockChatsMap[chatId];
				return canDisplayCard;
			})
			.slice(0, 2)
			.reverse();
	}, [chatsData, chatCardActionsMap, currReportChatsMap, currBlockChatsMap]);

	const showUndoButton = recentSwipedCardChatData.current !== null && !undoClicked.current;
	const showTotalChatsCount = chatsDataTotalCount > 0;

	const isLoadingPaginatedData = paginationDetails?.hasMoreChats ?? false;
	const selectedChatUsernameFromURL = decodeURIComponent(searchParams.get(CHAT_SEARCH_PARAM) ?? '');
	const showEmptyState =
		!chatsData.length &&
		fetchStatus !== 'loading' &&
		fetchStatus !== 'idle' &&
		!selectedChatUsernameFromURL;
	const showErrorState = fetchStatus === 'error';

	const handleMarkSwipeEducationDone = async () => {
		dispatch(setSwipeEducationDone());
		if (!userDetails?.userId) return;
		try {
			const payload: IUserMarkSwipeEducationDonePayload = {
				user_id: userDetails.userId,
			};
			await callMarkSwipeEducationDone(payload);
		} catch (error) {
			// handle error
		}
	};

	const handleMarkChatAsRead = async (chatData: SenderChatData, isChatRead: boolean) => {
		const { chatId, read, senderDetails } = chatData;
		const senderId = senderDetails?.userId;
		const userId = userDetails?.userId;
		const chatRead = currReadChatsMap[chatId ?? -1] ?? read;

		if (!(chatId && senderId && userId && callMarkChatAsReadStatus !== 'loading')) return;

		const payload: IMarkAsReadPayload = {
			chat_id: chatId,
			sender_id: senderId,
			user_id: userId,
			read: isChatRead ?? !chatRead,
		};

		try {
			await callMarkChatAsRead(payload);
			dispatch(setReadChatActionCount({ chatRead: payload.read, chatId: chatId }));
			dispatch(setCurrReadChatsMap({ chatId: chatId, chatRead: payload.read }));
		} catch (error) {
			// handle error
		}
	};

	const handleOpenChat = (selectedChatData: SenderChatData) => {
		const selectedChatDataSenderDetails = selectedChatData.senderDetails;

		if (!selectedChatDataSenderDetails) return;

		mixpanelActions.trackInboxChat(
			mixPanelEvents.INBOX_CHAT_OPENED,
			selectedChatDataSenderDetails.username ?? '',
			`${selectedChatData.read ? 'true' : 'false'}`,
			''
		);

		dispatch(
			setCurrChatsDataSelectedChatId({
				chatId: selectedChatData.chatId,
				index: chatsData.length,
				inboxType: 'INBOX_VIEW',
			})
		);

		const latestMessageId =
			currChatsLatestMessagesMap[selectedChatData.chatId ?? -1]?.slice(-1)[0]?.id ??
			selectedChatData.lastFiveMessages.slice(-1)[0]?.id ??
			null;

		dispatch(
			setSelectedChat({
				selectedChatData: selectedChatData,
				latestMessageId: latestMessageId,
			})
		);

		setSearchParams(
			(prevParams) => {
				const searchParamsToUpdate = new URLSearchParams(prevParams);
				searchParamsToUpdate.set(
					CHAT_SEARCH_PARAM,
					encodeURIComponent(selectedChatDataSenderDetails.username)
				);
				return searchParamsToUpdate;
			},
			{ replace: !!selectedChatUsernameFromURL }
		);
	};

	const handleCardDismiss = (swipeDirection: TSwipeDirection, chatData: SenderChatData) => {
		recentSwipedCardChatData.current = chatData;
		recentSwipedCardDirection.current = swipeDirection;
		undoClicked.current = false;

		dispatch(
			addToChatCardActionsMap({
				chatId: chatData.chatId,
				swipeActionDirection: swipeDirection,
				inboxChatsDataType: 'INBOX_VIEW',
			})
		);

		if (swipeDirection === 'right') {
			mixpanelActions.trackCardSwipe(mixPanelEvents.CARD_RIGHT_SWIPE_DONE);
			handleMarkChatAsRead(chatData, true);
		} else if (swipeDirection === 'left') {
			mixpanelActions.trackCardSwipe(mixPanelEvents.CARD_LEFT_SWIPE_DONE);
			handleMarkChatAsRead(chatData, false);
		}

		const loadingSwipeCardIndex = chatsData.length - LOADING_SWIPE_CARD_INDEX_TO_SUBTRACT;

		if (chatsData[loadingSwipeCardIndex]?.chatId === chatData.chatId) {
			if (isLoadingPaginatedData) {
				dispatch(
					fetchPaginatedInboxData({
						lastFiveMessagesRequired: selectedSingleFilters[UNREAD] === UNREAD && isMobile,
						topPicksThreshold: userDetails?.topPicksThreshold,
					})
				);
			}
		}
	};

	const handleUndo = () => {
		if (!recentSwipedCardChatData.current) return;

		mixpanelActions.trackCardSwipe(mixPanelEvents.CARD_SWIPE_UNDO_CLICKED);

		const swipedCardChatData = recentSwipedCardChatData.current;

		dispatch(
			removeFromChatCardActionsMap({
				chatId: swipedCardChatData.chatId ?? -1,
				inboxChatsDataType: 'INBOX_VIEW',
				lastChatActionsCard: recentSwipedCardChatData.current,
			})
		);

		if (recentSwipedCardDirection.current === 'right') {
			handleMarkChatAsRead(recentSwipedCardChatData.current, false);
		}

		undoClicked.current = true;
	};

	useEffect(() => {
		mixpanelActions.trackInbox(mixPanelEvents.INBOX_OPENED, `${chatsData.length}`, 'no');
	}, [chatsData]);

	useEffect(() => {
		recentSwipedCardChatData.current = null;
		recentSwipedCardDirection.current = null;
		undoClicked.current = false;
	}, [searchParams]);

	if (showErrorState) {
		return <InboxError />;
	}

	if (showEmptyState) {
		const emptyStateData = getEmptyStatesData({
			selectedMainFilterName: selectedMainFilter?.name,
			isMobile: isMobile,
			isFiltersDisplayed: false,
			isUnreadFilterSelected: selectedSingleFilters[UNREAD] === UNREAD,
		});

		const Icon = emptyStateData.icon;

		const ctaText = isTabDefaultFiltersChanged
			? emptyStateData.clearFiltersCTAText
			: tapToRefresh
			? emptyStateData.tapToRefreshCTAText
			: '';

		return (
			<>
				<NoResultsFound
					heading={emptyStateData.heading}
					subText={emptyStateData.subText}
					styles={emptyStateData.containerStyles}
					icon={<Icon size={emptyStateData.iconSize} style={emptyStateData.iconStyles} />}
					ctaActionText={ctaText}
					ctaAction={() => {
						if (isTabDefaultFiltersChanged) {
							selectedMainFilter && onClickMainFilter(selectedMainFilter);
						} else {
							onClickSingleFilter('tap_to_refresh', 'true', true);
						}
					}}
				/>
				{showUndoButton && (
					<Button
						btnText={
							<Text variant="span" fontSize={1.3} lineHeight={1.8} color={'#4A6B89'}>
								{'Undo last swipe'}
							</Text>
						}
						prefixIcon={<UndoIcon size={1.5} />}
						onClick={handleUndo}
						customClass={classes.emptyStateUndoBtn}
					/>
				)}
			</>
		);
	}

	return (
		<main className={classes.swipeChatCardsContainer}>
			{showTotalChatsCount && (
				<Text
					variant="h3"
					fontSize={1.3}
					lineHeight={1.4}
					secondary
					semiBold
					customClass={classes.unreadCount}
				>
					{`${chatsDataTotalCount} ${chatsDataTotalCount > 1 ? 'DMs' : 'DM'}`}
				</Text>
			)}
			<div className={classes.cardsContainer}>
				{isLoadingPaginatedData && (
					<SwipeableChatCard
						isTopCard={false}
						isTopSecondCard={true}
						isSwipedCard={false}
						onCardDismiss={() => {}}
						onClickCard={() => {}}
						customClassName={classes.swipeableLoadingChatCard}
					>
						<DotsLoader />
					</SwipeableChatCard>
				)}
				{chatsDataToDisplay.map((chatData, index) => {
					const chatId = chatData.chatId;
					const isTopCard = index === chatsDataToDisplay.length - 1;
					const isTopSecondCard = index === chatsDataToDisplay.length - 2;
					const isCardSwiped = recentSwipedCardChatData.current?.chatId === chatId;
					const cardSwipedDirection =
						recentSwipedCardChatData.current?.chatId === chatId
							? recentSwipedCardDirection.current
							: null;

					return (
						<SwipeableChatCard
							onCardDismiss={(swipeDirection) => handleCardDismiss(swipeDirection, chatData)}
							isTopCard={isTopCard}
							isSwipedCard={isCardSwiped}
							swipedCardDirection={cardSwipedDirection}
							onClickCard={() => handleOpenChat(chatData)}
							isTopSecondCard={isTopSecondCard}
							displayFirstTimeEducation={!userDetails?.swipeEducationDone}
							onCloseFirstTimeEducation={() => handleMarkSwipeEducationDone()}
							swipeMode={swipeMode}
							ref={isTopCard ? swipeActionsObjRef : null}
							key={chatId}
						>
							<SwipeableChatCardContent
								chatData={chatData}
								onOpenChatClick={() => handleOpenChat(chatData)}
								onMessageContentScroll={() => {
									setSwipeMode(false);
								}}
								onMessageContentEndScroll={() => {
									setSwipeMode(true);
								}}
							/>
						</SwipeableChatCard>
					);
				})}
			</div>
			<div className={classes.actionsContainer}>
				<Button
					prefixIcon={<ArrowBendUpLeftIcon size={2} />}
					btnText={
						<Text variant="span" small lineHeight={2} semiBold color="#4A6B89">
							{'Keep unread'}
						</Text>
					}
					onClick={() => {
						const swipeFunction = swipeActionsObjRef.current?.callSwipeAction;

						if (!swipeFunction) return;

						swipeFunction(-100, 'left');
					}}
				/>
				{showUndoButton && <Button btnText={<UndoIcon size={2.2} />} onClick={handleUndo} />}
				<Button
					suffixIcon={<ArrowBendUpLeftIcon size={2} />}
					btnText={
						<Text variant="span" small lineHeight={2} semiBold color="#4A6B89">
							{'Mark as read'}
						</Text>
					}
					onClick={() => {
						const swipeFunction = swipeActionsObjRef.current?.callSwipeAction;

						if (!swipeFunction) return;

						swipeFunction(100, 'right');
					}}
				/>
			</div>
		</main>
	);
}

export default SwipeCardsContainer;
