import React, { useState } from 'react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { IRefProps, IToastProps } from './Toast.types';
import classes from './Toast.styles.module.scss';
import { createPortal } from 'react-dom';
import clsx from 'clsx';
import Text from '../Text';
import { CloseIcon } from '@src/hoc/withIconStyles';

function Toast(props: IToastProps, forwardedRef: React.ForwardedRef<IRefProps>) {
	const { toastType, header, icon, content, ...toastProps } = props;
	const [open, setOpen] = useState(false);

	React.useImperativeHandle(forwardedRef, () => ({
		publish: () => {
			setOpen(true);
		},
		unPublish: () => {
			setOpen(false);
		},
	}));

	const containerClassName = clsx(
		classes.toastContainer,
		toastType === 'INFO' && classes.toastInfoContainer,
		toastType === 'INFO' && content && classes.toastInfoWithContentContainer,
		toastType === 'ERROR' && classes.toastErrorContainer,
		toastType === 'ERROR' && content && classes.toastErrorWithContentContainer,
		toastType === 'SUCCESS' && classes.toastSuccessContainer,
		toastType === 'SUCCESS' && content && classes.toastSuccessWithContentContainer
	);

	const cancelIconClassName = clsx(
		classes.cancelIcon,
		toastType === 'INFO' && classes.cancelIconInfo,
		toastType === 'ERROR' && classes.cancelIconError,
		toastType === 'SUCCESS' && classes.cancelIconSuccess
	);

	const handleOnClickCancelIcon = (e: React.MouseEvent<HTMLDivElement | MouseEvent>) => {
		e.stopPropagation();
		setOpen(false);
	};

	const ele = (
		<ToastPrimitive.Provider>
			<ToastPrimitive.Root
				className={classes.ToastRoot}
				open={open}
				onOpenChange={setOpen}
				{...toastProps}
			>
				<div className={containerClassName}>
					<div className={classes.toastIcon}>{icon}</div>
					<Text variant="h2" small white customClass={classes.toastHeader}>
						{header}
					</Text>
					{content && (
						<Text variant="p" small white customClass={classes.toastContent}>
							{content}
						</Text>
					)}
					<div className={classes.toastCancelIcon} tabIndex={0} onClick={handleOnClickCancelIcon}>
						<CloseIcon size={1.8} className={cancelIconClassName} />
					</div>
				</div>
			</ToastPrimitive.Root>
			<ToastPrimitive.Viewport className={classes.ToastViewport} />
		</ToastPrimitive.Provider>
	);

	const domEle = document.getElementById('notificationsRoot');

	return domEle && createPortal(ele, domEle);
}

export default React.forwardRef(Toast);
