import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import Button from '@components/ui/Button';
import Text from '@components/ui/Text';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import HeaderContainer from '../HeaderContainer';
import ActionsContainer from '../ActionsContainer';
import { ChevronRightIcon, TickIconColored } from '@src/hoc/withIconStyles';
import { CANNED_RESPONSE_MESSAGE_CHARACTER_LIMIT, mixPanelEvents } from '@utils/constants';
import useWindowSize from '@hooks/useWindow';
import { ICannedResponseChevronState, IReplyViewProps } from './ReplyView.types';
import { IUserCannedResponse } from '@api/user/user.types';
import classes from './ReplyView.styles.module.scss';
import mixpanelActions from '@utils/mixpanel';

function ReplyView({
	onClickBack,
	onClickCloseIcon,
	onClickNext,
	initialBookmark,
	initialReplyText,
}: IReplyViewProps) {
	const [inputState, setInputState] = React.useState({
		info: 'Use {first_name} to auto-pick name',
		error: '',
		value: initialReplyText ?? '',
		bookmark: initialBookmark ?? false,
	});

	const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
	const cannedResponseChevronRef = useRef<ICannedResponseChevronState>({
		chevronLeftDiv: null,
		chevronRightDiv: null,
		currScrolledPos: 0,
		scrollableContainer: null,
		scrollableContainerWrapper: null,
		leftBoxShadowRef: null,
		rightBoxShadowRef: null,
	});

	const { isMobile } = useWindowSize();

	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);

	const cannedResponses = useMemo(() => {
		return userDetails?.cannedResponses ?? [];
	}, [userDetails]);

	const inputStateValue = inputState.value;

	const handleOnChangeInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const value = e.target.value;

		setInputState((prevState) => {
			return {
				...prevState,
				value: value,
				error: '',
			};
		});
	};

	const handleBookmarkAction = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation();
		setInputState((prevState) => {
			return {
				...prevState,
				bookmark: !prevState.bookmark,
			};
		});
	};

	const handleOnClickCannedResponse = (cannedResponse: IUserCannedResponse) => {
		setInputState((prevState) => {
			return {
				...prevState,
				value: cannedResponse.text,
				bookmark: cannedResponse.bookmark_when_used ?? false,
				error: '',
			};
		});
		textAreaRef.current?.focus();
	};

	const handleNextClick = () => {
		mixpanelActions.trackBulkReply(mixPanelEvents.BULK_REPLY_NEXT_CLICKED);

		const trimmedText = inputState.value.trim();
		const maxCharLimitToCheck = CANNED_RESPONSE_MESSAGE_CHARACTER_LIMIT;

		const isEmpty = trimmedText.length === 0;
		const isCharMoreThanMax = trimmedText.length > maxCharLimitToCheck;

		if (isEmpty || isCharMoreThanMax) {
			setInputState((prevState) => {
				return {
					...prevState,
					error: isEmpty
						? 'Reply cannot be empty'
						: `Message cannot be longer than ${maxCharLimitToCheck} characters`,
				};
			});
			textAreaRef.current?.focus();
			return;
		}

		onClickNext(trimmedText, inputState.bookmark);
	};

	const handleCloseIconClick = () => {
		onClickCloseIcon();
	};

	const handleBackClick = () => {
		mixpanelActions.trackBulkReply(mixPanelEvents.BULK_REPLY_BACK_CLICKED);
		const trimmedText = inputState.value.trim();
		onClickBack(trimmedText, inputState.bookmark);
	};

	const handleClickLeftChevronBtn = () => {
		if (isMobile) return;
		const scrollEle = cannedResponseChevronRef.current.scrollableContainer;
		const scrollEleWrapper = cannedResponseChevronRef.current.scrollableContainerWrapper;
		const scrollPos = cannedResponseChevronRef.current.currScrolledPos;

		if (!scrollEle || !scrollEleWrapper) return;

		if (scrollPos <= 0) return;

		const scrollToUpdate = Math.ceil(scrollPos - scrollEleWrapper.scrollWidth);

		scrollEle.scrollTo({ behavior: 'smooth', left: scrollToUpdate });
		cannedResponseChevronRef.current.currScrolledPos = scrollToUpdate;
	};

	const handleClickRightChevronBtn = () => {
		if (isMobile) return;
		const scrollEle = cannedResponseChevronRef.current.scrollableContainer;
		const scrollEleWrapper = cannedResponseChevronRef.current.scrollableContainerWrapper;
		const scrollPos = cannedResponseChevronRef.current.currScrolledPos;

		if (!scrollEle || !scrollEleWrapper) return;

		const scrollToUpdate = Math.ceil(scrollPos + scrollEleWrapper.scrollWidth);

		if (scrollToUpdate > scrollEle.scrollWidth) return;

		scrollEle.scrollTo({ behavior: 'smooth', left: scrollToUpdate });
		cannedResponseChevronRef.current.currScrolledPos = scrollToUpdate;
	};

	const handleCannedResponseDivMouseEnter = () => {
		if (isMobile) return;

		const scrollEle = cannedResponseChevronRef.current.scrollableContainer;
		const chevronLeft = cannedResponseChevronRef.current.chevronLeftDiv;
		const chevronRight = cannedResponseChevronRef.current.chevronRightDiv;
		const scrollPos = cannedResponseChevronRef.current.currScrolledPos;
		const scrollEleWrapper = cannedResponseChevronRef.current.scrollableContainerWrapper;

		if (!scrollEle || !scrollEleWrapper) return;

		const shouldShowRightChevron = scrollEleWrapper.scrollWidth + scrollPos < scrollEle.scrollWidth;

		shouldShowRightChevron
			? chevronRight?.classList.add(classes.showChevron)
			: chevronRight?.classList.remove(classes.showChevron);

		const shouldShowLeftChevron = scrollPos > 0;
		shouldShowLeftChevron
			? chevronLeft?.classList.add(classes.showChevron)
			: chevronLeft?.classList.remove(classes.showChevron);
	};

	const handleCannedResponseDivMouseLeave = () => {
		if (isMobile) return;
		const chevronLeft = cannedResponseChevronRef.current.chevronLeftDiv;
		const chevronRight = cannedResponseChevronRef.current.chevronRightDiv;

		chevronRight?.classList.remove(classes.showChevron);
		chevronLeft?.classList.remove(classes.showChevron);
	};

	const handleCannedResponseDivScroll = () => {
		const leftBoxShadow = cannedResponseChevronRef.current.leftBoxShadowRef;
		const rightBoxShadow = cannedResponseChevronRef.current.rightBoxShadowRef;
		const scrollEleWrapper = cannedResponseChevronRef.current.scrollableContainerWrapper;
		const scrollEle = cannedResponseChevronRef.current.scrollableContainer;

		if (!leftBoxShadow || !rightBoxShadow || !scrollEleWrapper || !scrollEle) return;

		if (scrollEle.scrollLeft > 0) {
			leftBoxShadow.classList.add(classes.showBoxShadow);
		} else {
			leftBoxShadow.classList.remove(classes.showBoxShadow);
		}

		if (
			Math.round(scrollEle.scrollLeft) + Math.round(scrollEleWrapper.clientWidth) <
			Math.round(scrollEle.scrollWidth)
		) {
			rightBoxShadow.classList.add(classes.showBoxShadow);
		} else {
			rightBoxShadow.classList.remove(classes.showBoxShadow);
		}

		if (isMobile) return;
		cannedResponseChevronRef.current.currScrolledPos = Math.ceil(scrollEle.scrollLeft);
		handleCannedResponseDivMouseEnter();
	};

	useEffect(() => {
		const scrollEle = cannedResponseChevronRef.current.scrollableContainer;
		const scrollEleWrapper = cannedResponseChevronRef.current.scrollableContainerWrapper;
		const rightBoxShadow = cannedResponseChevronRef.current.rightBoxShadowRef;

		if (!scrollEle || !scrollEleWrapper || !rightBoxShadow) return;

		if (scrollEle.scrollWidth > scrollEleWrapper.clientWidth) {
			rightBoxShadow.classList.add(classes.showBoxShadow);
		}
	}, [isMobile]);

	useEffect(() => {
		const textAreaEle = textAreaRef.current;

		if (!textAreaEle) return;

		textAreaEle.style.height = '144px';

		if (inputStateValue.length > 0) {
			textAreaEle.style.height = `${textAreaEle.scrollHeight}px`;
		}
	}, [inputStateValue]);

	useEffect(() => {
		mixpanelActions.trackBulkReply(mixPanelEvents.BULK_REPLY_STEP_TWO_SHOWN);
	}, []);

	return (
		<>
			<HeaderContainer headerText={'Enter your reply'} onCloseIconClick={handleCloseIconClick} />
			<div className={classes.replyViewContainer}>
				<div className={classes.inputContainer}>
					<textarea
						id="cannedResponseText"
						className={clsx(classes.textAreaInput, inputState.error && classes.textAreaInputError)}
						value={inputState.value}
						onChange={handleOnChangeInput}
						placeholder={'Type your reply here...'}
						ref={textAreaRef}
						autoComplete="off"
					/>
				</div>
				{!!inputState.error && (
					<Text variant="p" tiny error customClass={classes.errorText}>
						{inputState.error}
					</Text>
				)}
				{!inputState.error && (
					<Text variant="p" tiny secondary light customClass={classes.infoText}>
						{inputState.info}
					</Text>
				)}
				{!inputState.value && (
					<div
						className={classes.cannedResponseContainerWithChevron}
						ref={(el) => {
							cannedResponseChevronRef.current.scrollableContainerWrapper = el;
						}}
						onMouseEnter={handleCannedResponseDivMouseEnter}
						onMouseLeave={handleCannedResponseDivMouseLeave}
					>
						<div
							className={classes.leftBoxShadow}
							ref={(el) => {
								cannedResponseChevronRef.current.leftBoxShadowRef = el;
							}}
						/>
						<div
							className={classes.chevronLeft}
							ref={(el) => {
								cannedResponseChevronRef.current.chevronLeftDiv = el;
							}}
						>
							<Button
								btnText={<ChevronRightIcon size={1.4} className={classes.icon} />}
								onClick={handleClickLeftChevronBtn}
							/>
						</div>
						<div
							className={classes.cannedResponseContainer}
							onScroll={handleCannedResponseDivScroll}
							ref={(el) => {
								cannedResponseChevronRef.current.scrollableContainer = el;
							}}
						>
							{cannedResponses.map((cannedResponse) => {
								return (
									<Button
										key={cannedResponse.name}
										customClass={classes.cannedResponse}
										btnText={
											<Text variant="p" brandPrimaryColor lineHeight={1.6} tiny>
												{cannedResponse.name}
											</Text>
										}
										onClick={() => {
											handleOnClickCannedResponse(cannedResponse);
										}}
									/>
								);
							})}
						</div>
						<div
							className={classes.chevronRight}
							ref={(el) => {
								cannedResponseChevronRef.current.chevronRightDiv = el;
							}}
						>
							<Button
								btnText={<ChevronRightIcon size={1.4} className={classes.icon} />}
								onClick={handleClickRightChevronBtn}
							/>
						</div>
						<div
							className={classes.rightBoxShadow}
							ref={(el) => {
								cannedResponseChevronRef.current.rightBoxShadowRef = el;
							}}
						/>
					</div>
				)}
				<div
					tabIndex={0}
					className={clsx(
						classes.bookmarkActionContainer,
						!!inputState.value && classes.bookmarkActionContainerMargin
					)}
					onClick={handleBookmarkAction}
				>
					<div className={clsx(classes.checkbox, inputState.bookmark && classes.checkboxActive)}>
						{inputState.bookmark && <TickIconColored size={1.6} />}
					</div>

					<Text variant="p" tiny light secondary>
						{'Bookmark all people getting this reply'}
					</Text>
				</div>
			</div>
			<ActionsContainer
				buttonsArr={[
					<Button
						btnText={
							<Text variant={'span'} lineHeight={2.2} semiBold>
								{'Back'}
							</Text>
						}
						onClick={handleBackClick}
						customClass={classes.backBtn}
						key={'Back'}
					/>,
					<Button
						btnText={
							<Text variant={'span'} lineHeight={2.2} semiBold white>
								{'Next'}
							</Text>
						}
						primary
						onClick={handleNextClick}
						key={'Next'}
					/>,
				]}
				currModalState={'REPLY_VIEW'}
			/>
		</>
	);
}

export default ReplyView;
