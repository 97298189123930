import { appAxiosInstance } from '@config/axios';
import {
	IAllowNotificationApiResponse,
	IAllowNotificationsPayload,
	IEditUserCannedResponsesPayload,
	IEditUserSettingsPayload,
	IEnableMinMaxCharCountPayload,
	IFetchUserAPIResponse,
	IFetchUserProfileData,
	IGetInboxOverviewApiResponse,
	IGetInboxOverviewPayload,
	IGetTabCountAPIResponse,
	IGetTabCountPayload,
	IGetUserOtherAccountsApiResponse,
	ILinkedInVerificationStatusAPIResponse,
	ILogoutPayload,
	ISwitchUserOtherAccountResponse,
	IUserCannedResponsesApiResponse,
	IUserFetchArchivalReasonsApiResponse,
	IUserFetchArchivalReasonsPayload,
	IUserMarkSwipeEducationDonePayload,
	IUserPersonalLabelsApiResponse,
	IUserSettingsApiResponse,
	IUserCategoriesApiResponse,
	IGetSecondaryUsersApiResponse,
	IEditSecondaryUsersPayload,
	IEditSecondaryUsersApiResponse,
} from './user.types';
import {
	getStructuredInboxCategories,
	getStructuredOverview,
	getStructuredSecondaryUser,
	getStructuredTabsCount,
	getStructuredUser,
	getStructuredUserNotificationAllowedDevices,
	getStructuredUserOtherAccount,
} from '@src/models/user';
import { getStructuredSenderProfile } from '@src/models/inbox';

export const verifyLinkedIn = async (username: string, linkedInAuthCode: string) => {
	const payload = {
		username: username,
		authorisation_code: linkedInAuthCode,
	};

	const res = await appAxiosInstance.post('/users/verify-linkedin', payload, {
		headers: {
			Referer: window.location.origin,
		},
	});

	return res.data as ILinkedInVerificationStatusAPIResponse;
};

export const getUserDetails = async (jwtToken: string) => {
	const payload = {
		jwt_token: jwtToken,
	};

	const res = await appAxiosInstance.post('/users/me', payload);
	const data: IFetchUserAPIResponse = res.data;
	return getStructuredUser(data);
};

export const getUserProfileData = async (username: string) => {
	const payload = {
		username: username,
	};

	const res = await appAxiosInstance.post('/users/fetch-profile', payload);
	const data: IFetchUserProfileData = res.data;
	return getStructuredSenderProfile(data.profile_data, data.linkedin_verification_status, data.last_sync_timestamp);
};

export const editUserSettings = async (payload: IEditUserSettingsPayload) => {
	const res = await appAxiosInstance.post('/users/edit/settings', payload);

	const data = res.data as IUserSettingsApiResponse;

	return {
		inboxCategories:
			data.inbox_categories?.map((category) => getStructuredInboxCategories(category)) ?? [],
		preferences: data.preferences ?? '',
	};
};

export const getUserCannedResponses = async (userId: number, signal: AbortSignal) => {
	const payload = {
		user_id: userId,
	};

	const res = await appAxiosInstance.post('/users/fetch/canned-responses', payload, {
		signal: signal,
	});

	const data = res.data as IUserCannedResponsesApiResponse;

	return data.canned_responses;
};

export const editUserCannedResponses = async (payload: IEditUserCannedResponsesPayload) => {
	const res = await appAxiosInstance.post('/users/edit/canned-responses', payload);

	const data = res.data as IUserCannedResponsesApiResponse;

	return data.canned_responses;
};

export const allowNotifications = async (payload: IAllowNotificationsPayload) => {
	const res = await appAxiosInstance.post('/users/allow-notification', payload);

	const data = res.data as IAllowNotificationApiResponse;

	return (
		data.device_info?.map((deviceInfo) =>
			getStructuredUserNotificationAllowedDevices(deviceInfo)
		) ?? []
	);
};

export const logout = async (payload: ILogoutPayload) => {
	const res = await appAxiosInstance.post('/users/logout', payload);
	return res.data;
};

export const getInboxOverviewDetails = async (
	payload: IGetInboxOverviewPayload,
	signal?: AbortSignal
) => {
	const res = await appAxiosInstance.post('/users/fetch-overview', payload, { signal });

	const data = res.data as IGetInboxOverviewApiResponse;

	return {
		data: data,
		formattedData: getStructuredOverview(data),
	};
};

export const getTabCount = async (payload: IGetTabCountPayload) => {
	const res = await appAxiosInstance.post('/users/fetch-tab-count', payload);

	const data = res.data as IGetTabCountAPIResponse;

	return data.tabs?.map((tab) => getStructuredTabsCount(tab)) ?? [];
};

export const enableMinMaxCharCount = async (payload: IEnableMinMaxCharCountPayload) => {
	const res = await appAxiosInstance.post('/users/enable-min-max-char-count', payload);
	return res.data;
};

export const getArchiveData = async (payload: IUserFetchArchivalReasonsPayload) => {
	const res = await appAxiosInstance.post('users/fetch-archival-reasons', payload);

	const data = res.data as IUserFetchArchivalReasonsApiResponse;

	return data?.reasons ?? [];
};

export const markSwipeEducationDone = async (payload: IUserMarkSwipeEducationDonePayload) => {
	const res = await appAxiosInstance.post('/users/mark-swipe-education-done', payload);
	return res.data;
};

export const getUserOtherAccounts = async () => {
	const res = await appAxiosInstance.post('/users/fetch-other-accounts');

	const data = res.data as IGetUserOtherAccountsApiResponse;

	return (data.accounts ?? []).map((otherAccount) => getStructuredUserOtherAccount(otherAccount));
};

export const switchUserOtherAccount = async (userId: number) => {
	const res = await appAxiosInstance.post('/users/fetch-token-for-other-account', {
		user_id: userId,
	});

	const data = res.data as ISwitchUserOtherAccountResponse;

	return data.jwt_token;
};

export const getUserPersonalLabels = async (userId: number) => {
	const res = await appAxiosInstance.post('/users/fetch/personal-tags', {
		user_id: userId,
	});

	const data = res.data as IUserPersonalLabelsApiResponse;

	return data.tags;
};

export const editUserPersonalLabels = async (userId: number, tags: string[]) => {
	const res = await appAxiosInstance.post('/users/edit/personal-tags', {
		user_id: userId,
		tags: tags,
	});

	const data = res.data as IUserPersonalLabelsApiResponse;

	return data.tags;
};

export const getUserExtraCategories = async (userId: number) => {
	const res = await appAxiosInstance.post('/users/fetch/extra-categories', {
		user_id: userId,
	});

	const data = res.data as IUserCategoriesApiResponse;

	return data.categories;
};

export const getSecondaryUsers = async (userId: number) => {
	const res = await appAxiosInstance.post('/users/fetch/secondary-users', {
		user_id: userId,
	});

	const data = res.data as IGetSecondaryUsersApiResponse;

	return (
		data.secondary_users?.map((secondaryUser) => {
			return getStructuredSecondaryUser(secondaryUser);
		}) ?? []
	);
};

export const editSecondaryUsers = async (payload: IEditSecondaryUsersPayload) => {
	const res = await appAxiosInstance.post('/users/edit/secondary-users', payload);

	const data = res.data as IEditSecondaryUsersApiResponse;

	return (
		data.secondary_users?.map((secondaryUser) => {
			return getStructuredSecondaryUser(secondaryUser);
		}) ?? []
	);
};


export const syncProfile = async (userId: number) => {
    const payload = {
        user_id: userId,
    };

    const res = await appAxiosInstance.post('/users/sync-profile', payload, {
    });

    return res.data.message;
};