import React, { useEffect } from 'react';
import clsx from 'clsx';
import * as Popover from '@radix-ui/react-popover';
import useWindowSize from '@hooks/useWindow';
import Button from '@components/ui/Button';
import Modal from '@components/ui/Modal';
import Text from '@components/ui/Text';
import { ThreeDotsIconV2 } from '@src/hoc/withIconStyles';
import { mixPanelEvents } from '@utils/constants';
import mixpanelActions from '@utils/mixpanel';
import { IInboxMoreActionsProps } from './InboxMoreActions.types';
import classes from './InboxMoreActions.styles.module.scss';
import useInboxBulkActionsContext from '@hooks/useInboxBulkActions';
import BulkActionsSVGIcon from '@public/icons/bulkActionNudgeIcon.svg';

function InboxMoreActions({ nudgeMode, chatsView }: IInboxMoreActionsProps) {
	const { isMobile } = useWindowSize();

	const [openPopover, setOpenPopover] = React.useState(false);
	const [openModal, setOpenModal] = React.useState(false);

	const popoverTriggerRef = React.useRef<HTMLButtonElement | null>(null);

	const {
		handleBulkReply,
		handleMarkAllRead,
		setBulkReplyMoreHandlers,
		setMarkAllReadMoreHandlers,
		chatsDataTotalCount,
	} = useInboxBulkActionsContext();

	const displayPopOver = !isMobile && !nudgeMode;
	const displayButton = isMobile && !nudgeMode;
	const displayNudgeMode = !!nudgeMode;

	const handleOpenModalOrPopover = (isOpen: boolean) => {
		if (isMobile && !displayNudgeMode) {
			setOpenModal(isOpen);
		} else {
			setOpenPopover(isOpen);
		}
	};

	const handleClickBulkReply = () => {
		mixpanelActions.trackBulkReply(
			mixPanelEvents.REPLY_TO_ALL_CLICKED,
			displayNudgeMode ? 'NUDGE' : 'MORE_ACTIONS'
		);
		handleOpenModalOrPopover(false);
		handleBulkReply();

		setBulkReplyMoreHandlers({
			onCloseBulkReplyModal: () => {
				handleOpenModalOrPopover(true);
			},
			onBulkReplyResponse: () => {
				handleOpenModalOrPopover(false);
			},
			onCloseBulkReplyLimitModal: () => {
				handleOpenModalOrPopover(false);
			},
			onCloseEmptyStateBulkReplyModal: () => {
				handleOpenModalOrPopover(false);
			},
		});
	};

	const handleClickMarkAllRead = () => {
		mixpanelActions.trackBulkMarkAsRead(
			mixPanelEvents.MARK_ALL_AS_READ_CLICKED,
			displayNudgeMode ? 'NUDGE' : 'MORE_ACTIONS'
		);
		handleOpenModalOrPopover(false);
		handleMarkAllRead();

		setMarkAllReadMoreHandlers({
			onCloseMarkAllReadModal: () => {
				handleOpenModalOrPopover(true);
			},
			onSuccessMarkAllRead: () => {
				handleOpenModalOrPopover(false);
			},
		});
	};

	const options = [
		<Button
			btnText={
				<Text
					variant="span"
					lineHeight={displayNudgeMode ? 2 : isMobile ? 2.2 : 1.8}
					small={!isMobile || displayNudgeMode}
					semiBold={displayNudgeMode}
				>
					{'Reply to all'}
				</Text>
			}
			onClick={handleClickBulkReply}
			key={'replyAll'}
		/>,
		<Button
			btnText={
				<Text
					variant="span"
					lineHeight={displayNudgeMode ? 2 : isMobile ? 2.2 : 1.8}
					small={!isMobile || displayNudgeMode}
					semiBold={displayNudgeMode}
				>
					{'Mark all as read'}
				</Text>
			}
			onClick={handleClickMarkAllRead}
			key={'markAllRead'}
		/>,
	];

	useEffect(() => {
		const obsEle = popoverTriggerRef.current;

		if (!obsEle) return;

		const obsOptions = {
			root: null,
			rootMargin: '-220px 0px -10px 0px',
			threshold: 0.1,
		};

		const obsCallback = (entries: IntersectionObserverEntry[]) => {
			const [entry] = entries;

			if (!entry.isIntersecting && openPopover) {
				setOpenPopover(false);
			}
		};

		const observer = new IntersectionObserver(obsCallback, obsOptions);

		observer.observe(obsEle);

		return () => {
			if (!obsEle) return;

			observer.unobserve(obsEle);
		};
	}, [openPopover]);

	useEffect(() => {
		if (!displayNudgeMode) return;

		mixpanelActions.trackEvent(mixPanelEvents.BULK_ACTIONS_NUDGE_SHOWN);
	}, [displayNudgeMode]);

	return (
		<>
			{displayPopOver && (
				<Popover.Root open={openPopover} onOpenChange={setOpenPopover}>
					<Popover.Trigger asChild ref={popoverTriggerRef}>
						<button
							className={classes.moreOptionsBtn}
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<ThreeDotsIconV2 size={3.2} />
						</button>
					</Popover.Trigger>
					<Popover.Portal>
						<Popover.Content
							align="end"
							side="bottom"
							className={classes.moreOptionsPopoverContent}
						>
							{...options}
						</Popover.Content>
					</Popover.Portal>
				</Popover.Root>
			)}

			{displayButton && (
				<Button
					className={classes.moreOptionsBtn}
					onClick={() => {
						setOpenModal(true);
					}}
					btnText={<ThreeDotsIconV2 size={3.2} />}
				/>
			)}

			{displayNudgeMode && (
				<div
					className={clsx(
						classes.nudgeContainer,
						chatsView === 'LIST_VIEW' && classes.nudgeContainerListView
					)}
				>
					<BulkActionsSVGIcon />
					<Text variant={'h3'} semiBold medium>
						{`All caught up`}
					</Text>
					<Text
						variant={'p'}
						light
						small
						secondary
					>{`Mark all ${chatsDataTotalCount} DMs above as read`}</Text>
					<div className={classes.btnsContainer}>{...options}</div>
				</div>
			)}

			<Modal
				onCloseModal={() => {
					handleOpenModalOrPopover(false);
				}}
				showModal={openModal}
				bottomInMobile
				noPadding
			>
				<div className={classes.optionsContainerMobile}>{...options}</div>
			</Modal>
		</>
	);
}

export default InboxMoreActions;
