import React, { useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { IStatusBarStepsProps } from './StatusBarSteps.types';
import classes from './StatusBarSteps.styles.module.scss';

function StatusBarSteps({ currModalState }: IStatusBarStepsProps) {
	const statusBarSteps = useMemo(() => ['LIST_VIEW', 'REPLY_VIEW', 'CONFIRM_VIEW'], []);

	const statusBarsRefsMap = useRef<{ [key: string]: HTMLDivElement | null }>({});

	useEffect(() => {
		const currStepIndex = statusBarSteps.indexOf(currModalState);

		statusBarSteps.forEach((step, index) => {
			const ele = statusBarsRefsMap.current[step];

			if (!ele) return;

			if (index <= currStepIndex) {
				ele.classList.add(classes.fillStatusBar);
			} else {
				ele.classList.remove(classes.fillStatusBar);
			}
		});
	}, [currModalState, statusBarSteps]);

	return (
		<div className={classes.statusBarContainer}>
			{statusBarSteps.map((step) => (
				<div
					key={step}
					className={clsx(
						classes.statusBar,
						step === statusBarSteps[0] && classes.fillStatusBarNoAnimation
					)}
					ref={(el) => {
						statusBarsRefsMap.current[step] = el;
					}}
				>
					<span />
				</div>
			))}
		</div>
	);
}

export default StatusBarSteps;
