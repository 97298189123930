import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { createPortal } from 'react-dom';
import Text from '@components/ui/Text';
import IModalProps from './Modal.types';
import classes from './Modal.styles.module.scss';
import useWindowSize from '@hooks/useWindow';

function Modal({ onCloseModal, ...props }: IModalProps) {
	const { isMobile } = useWindowSize();
	const modalContainerRef = useRef<HTMLDivElement | null>(null);
	const modalMainContainerRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (!modalMainContainerRef.current) return;

		const mainContainerRef = modalMainContainerRef.current;

		const handleClickOutside = (e: MouseEvent) => {
			const targetNode = e.target as Node;
			if (modalContainerRef.current && !modalContainerRef.current.contains(targetNode)) {
				onCloseModal && onCloseModal();
			}
		};

		modalMainContainerRef.current.addEventListener('mousedown', handleClickOutside);

		return () => {
			if (!mainContainerRef) return;
			mainContainerRef.removeEventListener('mousedown', handleClickOutside);
		};
	}, [onCloseModal, props.showModal]);

	const element = (
		<div
			role="dialog"
			aria-label="pop-up"
			className={clsx(
				classes.modalContainer,
				props.bottomInMobile && classes.modalContainerMobile,
				props.customClass && props.customClass
			)}
			ref={modalMainContainerRef}
			onClick={(e) => e.stopPropagation()}
		>
			<div
				className={clsx(
					classes.modalContentContainer,
					props.bottomInMobile && classes.modalContentContainerMobile,
					props.noPadding && classes.noPadding
				)}
				ref={modalContainerRef}
			>
				{props.header ? (
					<>
						<div
							className={clsx(classes.headerSection, props.noPadding && classes.modalHeaderSpacing)}
						>
							<Text variant="h2" semiBold medium={!isMobile}>
								{props.header}
							</Text>
						</div>
						<div className={classes.content}>{props.children}</div>
					</>
				) : (
					<div className={classes.content}>{props.children}</div>
				)}
			</div>
		</div>
	);

	const domEle = document.getElementById('modalRoot');

	return props.showModal && domEle && createPortal(element, domEle);
}

export default Modal;
