import React from 'react';
import classes from './VerifyLinkedInModal.styles.module.scss';
import Text from '@components/ui/Text';
import LinkedInMiniInfo from '@components/LinkedInMiniInfo';
import useWindowSize from '@hooks/useWindow';
import Button from '@components/ui/Button';
import { LinkedInIcon } from '@src/hoc/withIconStyles';
import { IVerifyLinkedInModalProps } from './VerifyLinkedInModal.types';
import mixpanelActions from '@utils/mixpanel';
import { mixPanelEvents, oauthRedirectPath } from '@utils/constants';
import Modal from '@components/ui/Modal';
import { setSessionStorage } from '@utils/sessionStorage';
import { getLinkedInOAuthURL } from '@utils/oauth';

function VerifyLinkedInModal({
	fullName,
	headline,
	location,
	profilePicURL,
	header,
	onCloseModal,
	showModal,
	noPadding,
}: IVerifyLinkedInModalProps) {
	const { isMobile } = useWindowSize();

	const handleVerifyLinkedInBtnClick = () => {
		mixpanelActions.trackVerifyLinkedIn({
			eventName: mixPanelEvents.LINKEDIN_VERIFY_CONTINUE_CLICKED,
		});
		setSessionStorage(oauthRedirectPath, window.location.href.replace(window.location.origin, ''));
		const linkedinOAuthURL = getLinkedInOAuthURL();
		window.open(linkedinOAuthURL, '_self');
	};

	const handleCloseLinkedInVerifyModal = () => {
		onCloseModal && onCloseModal();
	};

	return (
		<>
			{showModal && (
				<Modal
					header={
						<Text
							variant="h2"
							semiBold
							fontSize={isMobile ? 1.6 : 2}
							lineHeight={isMobile ? 2.2 : 2.4}
						>
							{header}
						</Text>
					}
					onCloseModal={handleCloseLinkedInVerifyModal}
					showModal={showModal}
					noPadding={noPadding}
					customClass={classes.modalCustomClass}
				>
					<div className={classes.verifyLinkedInModalContentContainer}>
						<div className={classes.textWithInfoContainer}>
							<LinkedInMiniInfo
								fullName={fullName}
								headline={headline}
								profilePicURL={profilePicURL}
								location={location}
							/>
						</div>
						<div className={classes.textWithBtnContainer}>
							<Text
								variant="p"
								fontSize={isMobile ? 1.4 : 1.6}
								lineHeight={isMobile ? 1.8 : 2.2}
								semiBold
							>
								{
									'We need a one-time LinkedIn sign-in to verify that the profile fetched above belongs to you.'
								}
							</Text>
							<Button
								btnText={
									<Text
										variant="span"
										white
										fontSize={isMobile ? 1.4 : 1.6}
										lineHeight={isMobile ? 1.8 : 2.2}
										semiBold
									>
										{'Continue with Linkedin'}
									</Text>
								}
								prefixIcon={<LinkedInIcon size={2.2} className={classes.linkedInIcon} />}
								onClick={handleVerifyLinkedInBtnClick}
								customClass={classes.verifyLinkedInModalBtn}
							/>
						</div>
					</div>
				</Modal>
			)}
		</>
	);
}

export default VerifyLinkedInModal;
