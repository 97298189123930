import React from 'react';
import classes from './Loader.styles.module.scss';
import ILoaderProps from './Loader.types';
import clsx from 'clsx';

function Loader(props: ILoaderProps) {
	return (
		<div
			style={{
				height: `${props.size ?? 2}rem`,
				width: `${props.size ?? 2}rem`,
				borderWidth: `${props.customBorderWidth ?? props.size ?? 20 / 10}px`,
			}}
			className={clsx(
				classes.loaderContainer,
				props.white && classes.white,
				props.allWhite && classes.allWhite,
				props.black && classes.black
			)}
		/>
	);
}

export default Loader;
