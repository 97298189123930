import React, { useState } from 'react';
import * as Slider from '@radix-ui/react-slider';
import classes from './CustomScrollBar.styles.module.scss';
import { ICustomScrollBarProps } from './CustomScrollBar.types';
import { CaretUpDownIcon } from '@src/hoc/withIconStyles';

const CustomScrollBar = ({ onScroll, onTouchEnd, onTouchStart }: ICustomScrollBarProps) => {
	// to avoid sudden shift of thumb when pressed on the thumb
	const [disabled, setDisabled] = useState(true);

	const handleValueChange = (value: number[]) => {
		onScroll(value[0]);
	};

	const handleTouchStart = () => {
		setDisabled(false);
		onTouchStart && onTouchStart();
	};

	const handleTouchEnd = () => {
		setDisabled(true);
		onTouchEnd && onTouchEnd();
	};

	return (
		<Slider.Root
			className={classes.SliderRoot}
			defaultValue={[1]}
			orientation="vertical"
			onValueChange={handleValueChange}
			min={1}
			max={100}
			onTouchStart={handleTouchStart}
			onTouchEnd={handleTouchEnd}
			disabled={disabled}
		>
			<Slider.Track className={classes.SliderTrack}>
				<Slider.Range className={classes.SliderRange} />
			</Slider.Track>
			<Slider.Thumb className={classes.SliderThumb}>
				<CaretUpDownIcon size={3.6} />
			</Slider.Thumb>
		</Slider.Root>
	);
};

export default CustomScrollBar;
