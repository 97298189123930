import React, { useRef, useState } from 'react';
import classes from './SaveFilters.styles.module.scss';
import Text from '@components/ui/Text';
import { mixPanelEvents } from '@utils/constants';
import Button from '@components/ui/Button';
import { useSelector } from 'react-redux';
import Modal from '@components/ui/Modal';
import Toast from '@components/ui/Toast';
import { IRefProps } from '@components/ui/Toast/Toast.types';
import { AlertIcon, CheckCircleIcon } from '@src/hoc/withIconStyles';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import mixpanelActions from '@utils/mixpanel';
import { ISaveFiltersProps } from './SaveFilters.types';
import { ISaveInboxFiltersPayload } from '@api/chats/chats.types';
import { getFiltersObj, getInboxDataFromGlobalState } from '@store/inboxSlice/inboxSlice';
import useFetch from '@hooks/useFetch';
import { saveInboxFilters } from '@api/chats';

const CHARACTER_LIMIT = 15;

function SaveFilters({ savedFilters, onSaveFilters }: ISaveFiltersProps) {
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [newSaveFilterName, setNewSaveFilterName] = useState('');
	const [toastContent, setToastContent] = useState({
		error: '',
		info: '',
	});
	const toastRef = useRef<IRefProps>(null);

	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);
	const { selectedSingleFilters, selectedMultiFilters, filters } = useSelector(
		getInboxDataFromGlobalState
	);
	const { callApi: callSaveInboxFilters, status: callSaveInboxFiltersStatus } =
		useFetch(saveInboxFilters);

	const isModalConfirmBtnDisabled = newSaveFilterName.length === 0;

	const handleCloseConfirmModal = () => {
		if (callSaveInboxFiltersStatus === 'loading') return;
		setShowConfirmModal(false);
	};

	const handleNewTabInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value.length > CHARACTER_LIMIT) return;
		setNewSaveFilterName(e.target.value);
	};

	const callSaveInboxFiltersApi = async () => {
		toastRef.current?.unPublish();
		if (!userDetails) return;

		const saveFilterNameExist = savedFilters.find((filter) => {
			return filter.name.toLocaleLowerCase().trim() == newSaveFilterName.toLocaleLowerCase().trim();
		});

		if (saveFilterNameExist) {
			setToastContent({ error: 'Name already exists', info: '' });
			toastRef.current?.publish();
			return;
		}

		let priorityToSet = 0;

		const allFiltersToSave = [
			...savedFilters.map((savedFilter) => {
				priorityToSet = savedFilter.priority > priorityToSet ? savedFilter.priority : priorityToSet;
				return {
					applied_filter: savedFilter.appliedFilters,
					count: savedFilter.count,
					name: savedFilter.name,
					priority: savedFilter.priority,
				};
			}),
			{
				applied_filter: getFiltersObj(selectedSingleFilters, selectedMultiFilters, filters),
				count: 0,
				name: newSaveFilterName.trim(),
				priority: priorityToSet + 1,
			},
		];

		const payload: ISaveInboxFiltersPayload = {
			saved_filters: allFiltersToSave,
			user_id: userDetails.userId,
		};

		try {
			await callSaveInboxFilters(payload);
			mixpanelActions.trackSaveFilter(mixPanelEvents.SAVE_FILTER_SUCCESS, newSaveFilterName);
			handleCloseConfirmModal();
			setToastContent({ info: 'Filters saved!', error: '' });
			toastRef.current?.publish();
			setTimeout(() => {
				!!onSaveFilters && onSaveFilters();
			}, 500);
		} catch (error) {
			setToastContent({ error: error, info: '' });
			toastRef.current?.publish();
		}
	};

	const handleSaveFilterClicked = () => {
		mixpanelActions.trackSaveFilter(mixPanelEvents.SAVE_FILTER_CLICKED);
		setShowConfirmModal(true);
	};

	return (
		<>
			<button className={classes.selectTrigger} onClick={handleSaveFilterClicked}>
				<Text variant="span" brandPrimaryColor bold customClass={classes.selectHeader}>
					{'Save filters'}
				</Text>
			</button>
			{showConfirmModal && (
				<Modal
					onCloseModal={handleCloseConfirmModal}
					showModal={showConfirmModal}
					bottomInMobile
					noPadding
				>
					<div className={classes.confirmModalContentContainer}>
						<Text variant="h2" medium lineHeight={2.4} semiBold>
							{'Set a name to save filters'}
						</Text>
						<input
							value={newSaveFilterName}
							onChange={handleNewTabInputChange}
							className={classes.input}
							placeholder={'Enter name here...'}
							autoComplete="off"
						/>
						<div className={classes.buttonsContainer}>
							<Button
								btnText={
									<Text variant="span" small semiBold>
										{'Cancel'}
									</Text>
								}
								onClick={handleCloseConfirmModal}
								customClass={classes.modalCancelBtn}
								disabled={callSaveInboxFiltersStatus === 'loading'}
							/>
							<Button
								btnText={
									<Text
										variant="span"
										white={!isModalConfirmBtnDisabled}
										disabled={isModalConfirmBtnDisabled}
										small
										semiBold
									>
										Save
									</Text>
								}
								primary
								onClick={callSaveInboxFiltersApi}
								customClass={classes.modalContinueBtn}
								disabled={isModalConfirmBtnDisabled}
								isLoading={callSaveInboxFiltersStatus === 'loading'}
							/>
						</div>
					</div>
				</Modal>
			)}
			<Toast
				ref={toastRef}
				toastType={toastContent.error ? 'ERROR' : 'INFO'}
				icon={
					toastContent.error ? (
						<AlertIcon size={1.8} className={classes.toastIcon} />
					) : (
						<CheckCircleIcon size={1.6} />
					)
				}
				header={toastContent.error ? toastContent.error : toastContent.info}
			/>
		</>
	);
}

export default SaveFilters;
