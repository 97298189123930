import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import MainLayoutHeader from './MainLayoutHeader';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import { isPendingMessageSentFunc, isProUserFunc } from '@src/models/user';
import VerifyLinkedInAlertTopHeader from '@components/VerifyLinkedInAlertTopHeader';
import { IMainLayoutProps } from './MainLayout.types';
import classes from './MainLayout.styles.module.scss';

function MainLayout({ children, withSearch, withSearchMobile, headerTxt }: IMainLayoutProps) {
	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);
	const isProUser = isProUserFunc(userDetails);

	const showVerifyLinkedInAlert = isPendingMessageSentFunc(userDetails);

	return (
		<div className={classes.mainLayoutContainer}>
			{showVerifyLinkedInAlert && <VerifyLinkedInAlertTopHeader />}
			<header
				className={clsx(classes.mainHeaderContainer, isProUser && classes.proUserMainHeaderHeight)}
			>
				<MainLayoutHeader
					withSearch={withSearch}
					withSearchMobile={withSearchMobile}
					headerTxt={headerTxt}
				/>
			</header>

			<main
				className={clsx(classes.mainContainer, isProUser && classes.proUserMainContainerHeight)}
			>
				{children}
			</main>
		</div>
	);
}

export default MainLayout;
