export const setSessionStorage = (key: string, value: string) => {
	sessionStorage.setItem(key, value);
};

export const getSessionStorage = (key: string) => {
	const storedValue = sessionStorage.getItem(key);
	return storedValue ? storedValue : null;
};

export const removeSessionStorage = (key: string) => {
	sessionStorage.removeItem(key);
};

export const clearSessionStorage = () => {
	sessionStorage.clear();
};
