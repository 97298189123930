import React from 'react';
import classes from './OnboardingLayout.styles.module.scss';
import { IOnboardingLayoutProps } from './OnboardingLayout.types';
import useWindowSize from '@hooks/useWindow';
import { LogoTextIcon } from '@src/hoc/withIconStyles';

function OnboardingLayout({ addFooterAtBottom, children }: IOnboardingLayoutProps) {
	const { isMobile } = useWindowSize();
	return (
		<div className={classes.onboardingLayoutContainer}>
			<header className={classes.header}>
				<LogoTextIcon size={isMobile ? 2.8 : 4} />
			</header>
			<main className={classes.main}>{children}</main>
			{addFooterAtBottom && <footer className={classes.footer} />}
		</div>
	);
}

export default OnboardingLayout;
