import React from 'react';
import clsx from 'clsx';
import Text from '@components/ui/Text';
import Button from '@components/ui/Button';
import { IListViewProps } from './ListView.types';
import { IUserCannedResponse } from '@api/user/user.types';
import classes from './ListView.styles.module.scss';
import { ChevronRightIcon, PlusIconV2 } from '@src/hoc/withIconStyles';
import { cannedResponseReplaceWords } from '@utils/constants';
import useWindowSize from '@hooks/useWindow';

function ListView({
	cannedResponses,
	onClickAddOrEdit,
	senderFirstName,
	onClickCannedResponse,
}: IListViewProps) {
	const { isMobile } = useWindowSize();
	const handleAddQuickReplyClick = () => {
		onClickAddOrEdit();
	};

	const handleClickCannedResponse = (cannedResponseToSelect: IUserCannedResponse) => {
		onClickCannedResponse(cannedResponseToSelect);
	};

	return (
		<div className={classes.listViewContainer}>
			<div className={classes.content}>
				{cannedResponses.map((cannedResponse) => {
					return (
						<div
							className={classes.cannedResponseOption}
							key={cannedResponse.name}
							tabIndex={0}
							onClick={(e) => {
								e.stopPropagation();
								e.currentTarget.classList.add(classes.cannedResponseBlinkEffect);
								// to show click animation effect
								setTimeout(() => {
									handleClickCannedResponse(cannedResponse);
								}, 150);
							}}
						>
							<Text variant="h3" semiBold customClass={classes.heading}>
								{cannedResponse.name}
							</Text>
							{isMobile && (
								<Text variant="p" secondary light small customClass={classes.info}>
									{cannedResponse.text.replace(
										cannedResponseReplaceWords.firstName,
										senderFirstName
									)}
								</Text>
							)}
							<div className={classes.arrowContainer}>
								<ChevronRightIcon size={2} />
							</div>
						</div>
					);
				})}
			</div>
			<div
				className={clsx(classes.buttonsContainerWrapper, {
					[classes.withBorder]: (!isMobile && cannedResponses.length > 5) || isMobile,
				})}
			>
				<div className={classes.buttonsContainer}>
					<Button
						btnText={
							<Text variant="span" brandPrimaryColor semiBold>
								{'Add or edit quick replies'}
							</Text>
						}
						prefixIcon={<PlusIconV2 size={1.3} />}
						onClick={handleAddQuickReplyClick}
						customClass={classes.addBtnWithIcon}
					/>
				</div>
			</div>
		</div>
	);
}

export default ListView;
