import React, { forwardRef, ForwardedRef } from 'react';
import Text from '@components/ui/Text';
import { OverviewCategories, OverviewInsights, OverviewSavedFilters } from '@src/models/user';
import classes from './InsightCard.styles.module.scss';
import { IOverviewInsightProps } from './InsightCard.types';
import clsx from 'clsx';

const InsightCard: React.ForwardRefRenderFunction<HTMLDivElement, IOverviewInsightProps> = (
	{ insightData, onClickInsightCard, wideMode },
	ref: ForwardedRef<HTMLDivElement>
) => {
	const insightCardData = insightData as OverviewInsights;
	const savedFilterCardData = insightData as OverviewSavedFilters;
	const categoryCardData = insightData as OverviewCategories;

	const name =
		insightData.displayCardType === 'INSIGHTS'
			? insightCardData.resultsName
			: insightCardData.displayCardType === 'SAVED'
			? savedFilterCardData.name
			: categoryCardData.name;

	const isSaveFilter = insightData.displayCardType === 'SAVED';

	const displayCount = insightCardData.count ?? 0;
	const displayCountText = displayCount > 1 ? `${displayCount} DMs` : `${displayCount} DM`;

	return (
		<div
			className={clsx(classes.insightCard, !!wideMode && classes.wideMode)}
			tabIndex={0}
			role="button"
			aria-label={`click to apply ${name} filters`}
			onClick={() => onClickInsightCard(insightData)}
			ref={ref}
		>
			<Text variant="h3" small lineHeight={1.8} color={'#3c5e7c'} semiBold>
				{name}
			</Text>

			<div className={classes.countWithTag}>
				{!!displayCount && (
					<Text variant="p" fontSize={1.3} lineHeight={1.8} color={'#6787A1'}>
						{displayCountText}
					</Text>
				)}

				{isSaveFilter && (
					<Text
						variant="span"
						extraTiny
						lineHeight={1.4}
						semiBold
						color={'#3c5e7c'}
						customClass={classes.tagText}
					>
						{'SAVED BY YOU'}
					</Text>
				)}
			</div>
		</div>
	);
};

export default forwardRef(InsightCard);
