import React, { useEffect } from 'react';
import Text from '@components/ui/Text';
import { NotFoundIcon } from '@src/hoc/withIconStyles';
import useWindowSize from '@hooks/useWindow';
import OnboardingLayout from '@components/OnboardingLayout';
import classes from './VpnError.styles.module.scss';
import { mixPanelEvents } from '@utils/constants';
import mixpanelActions from '@utils/mixpanel';

function VpnError() {
	const { isMobile } = useWindowSize();

	useEffect(() => {
		mixpanelActions.trackPageNotFound(mixPanelEvents.NETWORK_ISSUE_PAGE_SHOWN);
	}, []);

	return (
		<OnboardingLayout>
			<section className={classes.container}>
				<section className={classes.card}>
					<NotFoundIcon size={isMobile ? 6.4 : 12} />
					<Text variant="h1" large={!isMobile} medium={isMobile} semiBold>
						{'Something went wrong'}
					</Text>
					<Text
						variant="p"
						secondary
						medium={!isMobile}
						small={isMobile}
						light
						customClass={classes.errorText}
					>
						{`It seems like there was a problem with the network. If you are using VPN, please try turning it off or try a different WiFi network.`}
					</Text>
					<Text
						variant="p"
						secondary
						medium={!isMobile}
						small={isMobile}
						light
						customClass={classes.supportText}
					>
						{'If the issue persists, please reach us at support@superdm.com'}
					</Text>
				</section>
			</section>
		</OnboardingLayout>
	);
}

export default VpnError;
