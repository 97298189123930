import React, { useCallback, useEffect, useState } from 'react';
import { ILeavingModalContext, ILeavingModalProviderProps } from './LeavingModalProvider.types';
import { useBeforeUnload, useBlocker } from 'react-router-dom';

export const LeavingModalContext = React.createContext<ILeavingModalContext>({
	isDirty: false,
	setDirty: () => {},
	promptLeavingModal: () => {},
});

function LeavingModalProvider(props: ILeavingModalProviderProps) {
	const [isDirty, setIsDirty] = useState(false);

	// Block navigation if there are unsaved changes
	const blocker = useBlocker(({ currentLocation, nextLocation }) => {
		return isDirty && currentLocation.pathname !== nextLocation.pathname;
	});

	const isBlocked = blocker.state === 'blocked';

	const promptLeavingModal = useCallback((onConfirm?: () => void, onCancel?: () => void) => {
		const userConfirmed = confirm(
			'It looks like you have been editing something.\nIf you leave before saving your changes will be lost.'
		);
		if (userConfirmed) {
			setIsDirty(false);
			onConfirm && onConfirm();
		} else {
			setIsDirty(true);
			onCancel && onCancel();
		}
	}, []);

	// Prompt user before leaving if there are unsaved changes
	useBeforeUnload(
		useCallback(
			(event) => {
				if (isDirty) {
					const message =
						'It looks like you have been editing something.\nIf you leave before saving your changes will be lost.';
					event.returnValue = message; // Standard for most browsers
					return message; // For some older browsers
				}
			},
			[isDirty]
		)
	);

	useEffect(() => {
		if (isBlocked && isDirty) {
			promptLeavingModal(
				() => {
					blocker.proceed();
				},
				() => {
					blocker.reset();
				}
			);
		}

		if (isBlocked && !isDirty) {
			blocker.proceed();
			blocker.reset();
		}
	}, [isBlocked, isDirty, blocker, promptLeavingModal]);

	return (
		<LeavingModalContext.Provider value={{ isDirty, setDirty: setIsDirty, promptLeavingModal }}>
			{props.children}
		</LeavingModalContext.Provider>
	);
}

export default LeavingModalProvider;
