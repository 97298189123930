import React from 'react';
import Lottie from 'react-lottie';
import animationData from '@src/lottie/dotsLoader.json';
import useWindowSize from '@hooks/useWindow';
import { IDotsLoaderProps } from './DotsLoader.types';

function DotsLoader({ customDesktopSize, customMobileSize, customMargin }: IDotsLoaderProps) {
	const { isMobile } = useWindowSize();

	const lottieDefaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	return (
		<div style={{ margin: customMargin ?? '0px auto' }}>
			<Lottie
				options={lottieDefaultOptions}
				width={isMobile ? customMobileSize ?? 80 : customDesktopSize ?? 80}
				height={isMobile ? customMobileSize ?? 80 : customDesktopSize ?? 80}
			/>
		</div>
	);
}

export default DotsLoader;
