import React, { useRef } from 'react';
import classes from './Overview.styles.module.scss';
import { IChevronState, IOverviewProps } from './Overview.types';
import { ChevronRightIcon } from '@src/hoc/withIconStyles';
import { OverviewCategories, OverviewSavedFilters } from '@src/models/user';
import useWindowSize from '@hooks/useWindow';
import mixpanelActions from '@utils/mixpanel';
import { mixPanelEvents } from '@utils/constants';
import Button from '@components/ui/Button';
import InsightCard from './InsightCard';
import clsx from 'clsx';

function Overview({ overviewDetails, onClickFilter, cardView }: IOverviewProps) {
	const { isMobile } = useWindowSize();
	const { overview: overviewData } = overviewDetails;
	const insightsWithTopicsChevronRef = useRef<IChevronState>({
		chevronLeftDiv: null,
		chevronRightDiv: null,
		currScrolledPos: 0,
		scrollableContainer: null,
		scrollableContainerWrapper: null,
	});

	// saved filters
	const savedFilters = overviewData?.savedFilters ?? [];

	// categories
	const categories = overviewData?.categories ?? [];

	const handleSavedFilterClick = (savedFilter: OverviewSavedFilters) => {
		mixpanelActions.trackOverview({
			eventName: mixPanelEvents.WIDGET_CLICKED,
			tabOverviewFilterName: savedFilter.name ?? 'Saved Filter',
			tabOverviewFilterType: 'Saved Filters',
		});
		savedFilter.appliedFilters && onClickFilter(savedFilter.appliedFilters, savedFilter.name);
	};

	const handleOnClickCategory = (category: OverviewCategories) => {
		mixpanelActions.trackOverview({
			eventName: mixPanelEvents.WIDGET_CLICKED,
			tabOverviewFilterName: category.name ?? 'Category Filter',
			tabOverviewFilterType: 'Category',
		});
		category.appliedFilters && onClickFilter(category.appliedFilters, category.name);
	};

	const handleInsightsDivMouseEnter = () => {
		const scrollEle = insightsWithTopicsChevronRef.current.scrollableContainer;
		const chevronLeft = insightsWithTopicsChevronRef.current.chevronLeftDiv;
		const chevronRight = insightsWithTopicsChevronRef.current.chevronRightDiv;
		const scrollPos = insightsWithTopicsChevronRef.current.currScrolledPos;
		const scrollEleWrapper = insightsWithTopicsChevronRef.current.scrollableContainerWrapper;

		if (!scrollEle || !scrollEleWrapper) return;

		const shouldShowRightChevron = scrollEleWrapper.scrollWidth + scrollPos < scrollEle.scrollWidth;

		shouldShowRightChevron
			? chevronRight?.classList.add(classes.showChevron)
			: chevronRight?.classList.remove(classes.showChevron);

		const shouldShowLeftChevron = scrollPos > 0;
		shouldShowLeftChevron
			? chevronLeft?.classList.add(classes.showChevron)
			: chevronLeft?.classList.remove(classes.showChevron);
	};

	const handleInsightsDivMouseLeave = () => {
		const chevronLeft = insightsWithTopicsChevronRef.current.chevronLeftDiv;
		const chevronRight = insightsWithTopicsChevronRef.current.chevronRightDiv;

		chevronRight?.classList.remove(classes.showChevron);
		chevronLeft?.classList.remove(classes.showChevron);
	};

	const handleClickRightChevronBtn = () => {
		const scrollToValue = 280;

		const scrollEle = insightsWithTopicsChevronRef.current.scrollableContainer;
		const scrollEleWrapper = insightsWithTopicsChevronRef.current.scrollableContainerWrapper;
		const scrollPos = insightsWithTopicsChevronRef.current.currScrolledPos;
		const scrollToUpdate = Math.ceil(scrollPos + scrollToValue);

		if (!scrollEle || !scrollEleWrapper) return;

		if (scrollEleWrapper.scrollWidth + scrollPos > scrollEle.scrollWidth) return;

		scrollEle.scrollTo({ behavior: 'smooth', left: scrollToUpdate });
		insightsWithTopicsChevronRef.current.currScrolledPos = scrollToUpdate;
	};

	const handleClickLeftChevronBtn = () => {
		const scrollToValue = 280;

		const scrollEle = insightsWithTopicsChevronRef.current.scrollableContainer;
		const scrollEleWrapper = insightsWithTopicsChevronRef.current.scrollableContainerWrapper;
		const scrollPos = insightsWithTopicsChevronRef.current.currScrolledPos;
		const scrollToUpdate = Math.ceil(scrollPos - scrollToValue);

		if (!scrollEle || !scrollEleWrapper) return;

		if (scrollPos <= 0) return;

		scrollEle.scrollTo({ behavior: 'smooth', left: scrollToUpdate });
		insightsWithTopicsChevronRef.current.currScrolledPos = scrollToUpdate;
	};

	const handleInsightsDivScroll = () => {
		const scrollEle = insightsWithTopicsChevronRef.current.scrollableContainer;
		if (!scrollEle) return;
		insightsWithTopicsChevronRef.current.currScrolledPos = Math.ceil(scrollEle.scrollLeft);
		handleInsightsDivMouseEnter();
	};

	const insightsSectionFilters = [...savedFilters, ...categories];

	const showChevrons = !isMobile;

	return (
		<div className={classes.overviewContainer}>
			<div
				className={classes.insightsWithTopPicksWrapper}
				onMouseEnter={handleInsightsDivMouseEnter}
				onMouseLeave={handleInsightsDivMouseLeave}
				ref={(el) => {
					insightsWithTopicsChevronRef.current.scrollableContainerWrapper = el;
				}}
			>
				{showChevrons && (
					<div
						className={clsx(classes.chevronLeft, !!cardView && classes.chevronLeftCardView)}
						ref={(el) => {
							insightsWithTopicsChevronRef.current.chevronLeftDiv = el;
						}}
					>
						<Button
							btnText={<ChevronRightIcon size={1.6} className={classes.icon} />}
							onClick={handleClickLeftChevronBtn}
						/>
					</div>
				)}
				<div
					className={classes.insightsWithTopPicksSection}
					ref={(el) => {
						insightsWithTopicsChevronRef.current.scrollableContainer = el;
					}}
					onScroll={handleInsightsDivScroll}
				>
					{insightsSectionFilters.map((savedFilter, index) => {
						return (
							<InsightCard
								insightData={savedFilter}
								onClickInsightCard={(insightData) => {
									if (insightData.displayCardType === 'SAVED') {
										const data = insightData as OverviewSavedFilters;
										handleSavedFilterClick(data);
									}
									if (insightData.displayCardType === 'CATEGORIES') {
										const data = insightData as OverviewCategories;
										handleOnClickCategory(data);
									}
								}}
								wideMode={insightsSectionFilters.length < 3}
								key={index}
							/>
						);
					})}
				</div>
				{showChevrons && (
					<div
						className={clsx(classes.chevronRight, !!cardView && classes.chevronRightCardView)}
						ref={(el) => {
							insightsWithTopicsChevronRef.current.chevronRightDiv = el;
						}}
					>
						<Button
							btnText={<ChevronRightIcon size={1.6} className={classes.icon} />}
							onClick={handleClickRightChevronBtn}
						/>
					</div>
				)}
			</div>
		</div>
	);
}

export default Overview;
