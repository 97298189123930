import React from 'react';
import classes from './ActionsContainer.styles.module.scss';
import { IActionsContainerProps } from './ActionsContainer.types';
import StatusBarSteps from '../StatusBarSteps';

function ActionsContainer({ buttonsArr, currModalState }: IActionsContainerProps) {
	return (
		<div className={classes.actionsContainer}>
			<StatusBarSteps currModalState={currModalState} />
			<div className={classes.btnsContainer}>{...buttonsArr}</div>
		</div>
	);
}

export default ActionsContainer;
