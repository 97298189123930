import React from 'react';
import clsx from 'clsx';
import IWithIconProps from './withIconStyles.types';
import classes from './withIconStyles.styles.module.scss';

function withIconStyles(Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>) {
	const SVGWrapper = ({ size, disabled, ...restProps }: IWithIconProps) => {
		const styles: React.CSSProperties = {
			fontSize: `${size}rem`,
		};

		return (
			<span className={clsx(classes.svgWrapper, disabled && classes.disabled)} style={styles}>
				<Icon {...restProps} />
			</span>
		);
	};

	return SVGWrapper;
}

export default withIconStyles;
