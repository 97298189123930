import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import * as Popover from '@radix-ui/react-popover';
import Text from '@components/ui/Text';
import { IInboxSelectProps } from './InboxSelect.types';
import classes from './InboxSelect.styles.module.scss';
import { ArrowDownIconV2 } from '@src/hoc/withIconStyles';

function InboxSelect<T>({
	header,
	onSelectOption,
	optionsMap,
	selectedOptionId,
	getOptionDisplayName,
	getOptionId,
	name,
}: IInboxSelectProps<T>) {
	const [isOpen, setIsOpen] = useState(false);

	const handleValueChange = (optionId: string, selected: boolean) => {
		setIsOpen(false);
		if (!optionsMap) return;
		onSelectOption(optionsMap[optionId], selected);
	};

	const selectedOption = useMemo(() => {
		if (!selectedOptionId || !optionsMap) return null;
		return optionsMap[selectedOptionId];
	}, [selectedOptionId, optionsMap]);

	const options = Object.entries(optionsMap ?? {});

	return (
		<Popover.Root open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
			<Popover.Trigger asChild>
				<button className={clsx(classes.selectTrigger, isOpen && classes.selectButtonActive)}>
					<Text variant="span" tiny lineHeight={1.6} customClass={classes.selectHeader}>
						{`${selectedOption ? getOptionDisplayName(selectedOption) : header}`}
					</Text>

					<span className={clsx(classes.selectIcon, selectedOption && classes.selectIconActive)}>
						<ArrowDownIconV2 size={1.4} />
					</span>
				</button>
			</Popover.Trigger>
			<Popover.Portal>
				<Popover.Content side="top" align="start" className={classes.selectContent}>
					<Text variant="p" small light customClass={classes.infoContainer}>
						{`This helps ${name} to know your intent upfront. To keep the platform safe, we might block accounts that select misleading categories.`}
					</Text>
					{options.map(([key, option]) => {
						const optionId = getOptionId(option);
						const displayName = getOptionDisplayName(option);
						const isSelectedOption = selectedOptionId === optionId;
						return (
							<div
								role="option"
								aria-selected={isSelectedOption}
								aria-label={displayName}
								key={key}
								className={classes.selectItem}
								onClick={() => handleValueChange(optionId, !isSelectedOption)}
							>
								<div className={classes.container}>
									<div
										className={clsx(
											classes.itemCommonStyles,
											classes.radioItem,
											isSelectedOption && classes.itemActiveCommonStyles,
											isSelectedOption && classes.radioItemActive
										)}
									></div>
									<Text variant="span" light={!isSelectedOption} small>
										{displayName}
									</Text>
								</div>
							</div>
						);
					})}
				</Popover.Content>
			</Popover.Portal>
		</Popover.Root>
	);
}

export default InboxSelect;
