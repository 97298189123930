import React from 'react';
import { TErrorMessage, TErrorType, getErrorDetails } from '@config/axios';

interface IState<T> {
	response: T | null;
	isLoading: boolean;
	error: TErrorMessage;
	errorStatus: number | null;
	status: TStatus;
	errorType: TErrorType | null;
}

function useFetch<T>(func: (...args: unknown[]) => Promise<T>) {
	const [requestState, setRequestState] = React.useState<IState<T>>({
		response: null,
		isLoading: false,
		error: null,
		errorStatus: null,
		status: 'idle',
		errorType: null,
	});

	const callApi = React.useCallback(
		async function (...args: unknown[]) {
			try {
				setRequestState((prevState) => {
					return {
						...prevState,
						isLoading: true,
						error: null,
						response: null,
						status: 'loading',
						errorStatus: null,
						errorType: null,
					};
				});
				//call the function
				const response: T = await func.apply(this, args);
				setRequestState((prevState) => {
					return {
						...prevState,
						response,
						isLoading: false,
						error: null,
						status: 'success',
						errorStatus: null,
						errorType: null,
					};
				});
				return response;
			} catch (error) {
				const { errorMessage, errorType, errorStatusCode } = getErrorDetails(error);

				setRequestState((prevState) => {
					return {
						...prevState,
						response: null,
						isLoading: false,
						error: errorMessage,
						errorStatus: errorStatusCode,
						status: 'error',
						errorType: errorType,
					};
				});

				return Promise.reject(errorMessage);
			}
		},
		[func]
	);

	return {
		...requestState,
		callApi,
	};
}

export default useFetch;
