import {
	IAttachmentMetaData,
	IChatMessage,
	ICursorPagination,
	IEmailSignifier,
	IFetchChatMessagesResponse,
	IForwardToEmailApiResponse,
	IPollMessagesResponse,
	TAttachmentType,
	TChatMessageContentType,
	TChatMessageStatus,
	TReactionType,
} from '@api/chats/chats.types';

export interface AttachmentMetaData {
	contentType: string;
	name: string;
	id: string;
}

export function getStructuredAttachmentMetaData(obj?: IAttachmentMetaData) {
	if (!obj) return null;
	const structuredObj: AttachmentMetaData = {
		contentType: obj.content_type,
		name: obj.name,
		id: obj.id,
	};

	return structuredObj;
}

export function getStructuredEmailSignifier(obj: IEmailSignifier) {
	const structuredObj: EmailSignifier = {
		emails: obj.emails,
		senderIncluded: obj.sender_included,
		forwardBy: obj.forward_by,
		forwardedAt: obj.forwarded_at,
	};

	return structuredObj;
}

export interface ChatMessage {
	id: string;
	senderId: number;
	receiverId: number;
	contentType: TChatMessageContentType;
	content: string;
	createdAt: string;
	status: TChatMessageStatus;
	chatId?: number;
	attachmentType?: TAttachmentType;
	attachmentMetaData?: AttachmentMetaData | null;
	reaction?: TReactionType;
	canBookmarkChat?: boolean;
	isCannedResponse?: boolean;
	emailSignifier?: EmailSignifier[];
}

export function getStructuredChatMessage(obj: IChatMessage) {
	const structuredObj: ChatMessage = {
		id: obj.id,
		senderId: obj.sender_id,
		receiverId: obj.receiver_id,
		contentType: obj.content_type,
		content: obj.content,
		createdAt: obj.created_at,
		status: obj.status,
		chatId: obj.chat_id,
		attachmentType: obj.attachment_type,
		attachmentMetaData: getStructuredAttachmentMetaData(obj.attachment_metadata),
		reaction: obj.reaction,
		emailSignifier:
			obj.email_signifier?.map((emailSignifier) => getStructuredEmailSignifier(emailSignifier)) ??
			[],
	};
	return structuredObj;
}

export interface CursorPagination {
	lastMessageId: null | string;
	pageSize: null | number;
	hasNextMessage: boolean;
}

export function getStructuredCursorPagination(obj: ICursorPagination) {
	const structuredObj: CursorPagination = {
		hasNextMessage: obj.has_next_message,
		lastMessageId: obj.last_message_id,
		pageSize: obj.page_size,
	};

	return structuredObj;
}

export interface ChatMessages {
	messages: ChatMessage[];
	cursor: CursorPagination;
}

export function getStructuredChatMessages(obj: IFetchChatMessagesResponse) {
	if (!obj) return null;

	const structuredObj: ChatMessages = {
		cursor: getStructuredCursorPagination(obj.cursor),
		messages: obj.messages?.map((chatMessage) => getStructuredChatMessage(chatMessage)) ?? [],
	};

	return structuredObj;
}

export interface PollChatMessages {
	messages: ChatMessage[];
	chatId?: number;
}

export function getStructuredPollChatMessages(obj: IPollMessagesResponse) {
	const structuredObj: PollChatMessages = {
		messages: obj.messages?.map((chatMessage) => getStructuredChatMessage(chatMessage)) ?? [],
		chatId: obj.chat_id,
	};

	return structuredObj;
}

export interface EmailSignifier {
	emails: string[];
	senderIncluded: boolean;
	forwardBy: number;
	forwardedAt: string;
}

export interface ForwardToEmailApiResponse {
	forwardAfterMessageId: string;
	emailSignifier: EmailSignifier[];
}

export const getStructuredForwardToEmailApiResponse = (obj: IForwardToEmailApiResponse) => {
	const structuredObj: ForwardToEmailApiResponse = {
		forwardAfterMessageId: obj.forward_after_message_id,
		emailSignifier:
			obj.email_signifier?.map((emailSignifier) => getStructuredEmailSignifier(emailSignifier)) ??
			[],
	};

	return structuredObj;
};
