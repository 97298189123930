import React, { useEffect, useState } from 'react';
import classes from './LinkedCallback.styles.module.scss';
import mixpanelActions from '@utils/mixpanel';
import {
	ACCOUNT_UNDER_REVIEW,
	LINKEDIN_OAUTH_ERROR_DESCRIPTION_PARAM,
	LINKEDIN_OAUTH_ERROR_PARAM,
	LINKEDIN_OAUTH_STATE_PARAM,
	LINKEDIN_OAUTH_STATE_PARAM_VALUE,
	LINKEDIN_OAUTH_TOKEN_PARAM,
	MAIN_PATH,
	mixPanelEvents,
	oauthRedirectPath,
} from '@utils/constants';
import withAuth from '@src/hoc/withAuth';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	getUserDetailsFromGlobalState,
	setLinkedInStatus,
} from '@store/userDetailsSlice/userDetailsSlice';
import { verifyLinkedIn } from '@api/user';
import useFetch from '@hooks/useFetch';
import { AppDispatch } from '@store/index';
import Lottie from 'react-lottie';
import animationData from '@src/lottie/success.json';
import Text from '@components/ui/Text';
import DotsLoader from '@components/ui/DotsLoader';
import { getSessionStorage, removeSessionStorage } from '@utils/sessionStorage';
import { ErrorIconV2 } from '@src/hoc/withIconStyles';
import useDelayedLoader from '@hooks/useDelayedLoader';

function LinkedInCallback() {
	const navigate = useNavigate();

	const dispatch = useDispatch<AppDispatch>();
	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);

	const { callApi: verifyLinkedInAccount } = useFetch(verifyLinkedIn);

	const [linkedinAuthState, setLinkedInAuthState] = useState({
		success: '',
		error: '',
	});
	const [countdown, setCountdown] = useState<number>(2);

	const [redirectURL, setRedirectURL] = useState<string | null>(null);

	const username = userDetails?.username;

	const isLoading = !linkedinAuthState.success && !linkedinAuthState.error;
	const showLoader = useDelayedLoader(isLoading);

	useEffect(() => {
		mixpanelActions.trackEvent(mixPanelEvents.LINKED_IN_OAUTH_LOADING_SCREEN_SHOWN);
	}, []);

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);

		const code = searchParams.get(LINKEDIN_OAUTH_TOKEN_PARAM);
		const state = searchParams.get(LINKEDIN_OAUTH_STATE_PARAM);
		const error = searchParams.get(LINKEDIN_OAUTH_ERROR_PARAM);
		const errorDescription = searchParams.get(LINKEDIN_OAUTH_ERROR_DESCRIPTION_PARAM);

		if (!username) return;

		const urlPathFromSessionStorage = getSessionStorage(oauthRedirectPath) ?? '';

		const redirectURLToSet = urlPathFromSessionStorage ? urlPathFromSessionStorage : MAIN_PATH;

		if (state !== LINKEDIN_OAUTH_STATE_PARAM_VALUE || !!error) {
			mixpanelActions.trackVerifyLinkedIn({
				eventName: mixPanelEvents.LINKEDIN_VERIFY_ERROR_SHOWN,
				errorReason: `error from linked in oauth : ${errorDescription ?? ''}`,
			});
			setLinkedInAuthState({
				success: '',
				error: 'Something went wrong',
			});
			setRedirectURL(redirectURLToSet);
			removeSessionStorage(oauthRedirectPath);
			return;
		}

		(async function () {
			try {
				const resData = await verifyLinkedInAccount(username, code);
				if (!resData?.status) {
					throw new Error('Something went wrong');
				}

				dispatch(setLinkedInStatus({ linkedInVerificationStatus: resData.status }));

				if (resData.status === 'MANUAL_VERIFICATION_PENDING' || resData.status === 'REJECTED') {
					mixpanelActions.trackVerifyLinkedIn({
						eventName: mixPanelEvents.VERIFICATION_PENDING_SHOWN,
					});
					navigate(ACCOUNT_UNDER_REVIEW, { replace: true });
				} else {
					mixpanelActions.trackVerifyLinkedIn({
						eventName: mixPanelEvents.LINKEDIN_VERIFY_SUCCESS_SHOWN,
					});
					mixpanelActions.setUserLinkedInProperties(userDetails.email, true);
					setLinkedInAuthState({
						error: '',
						success: 'Verification successful!',
					});
					setRedirectURL(redirectURLToSet);
					removeSessionStorage(oauthRedirectPath);
				}
			} catch (error) {
				mixpanelActions.trackVerifyLinkedIn({
					eventName: mixPanelEvents.LINKEDIN_VERIFY_ERROR_SHOWN,
					errorReason: `error from api : ${error}`,
				});
				setLinkedInAuthState({
					success: '',
					error: 'Something went wrong',
				});
				setRedirectURL(redirectURLToSet);
				removeSessionStorage(oauthRedirectPath);
			}
		})();
	}, [username]);

	useEffect(() => {
		if (!redirectURL) return;

		if (countdown === 0) {
			navigate(redirectURL, { replace: true });
			return;
		}

		const timer = setInterval(() => {
			setCountdown((prev) => {
				return prev - 1;
			});
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, [redirectURL, countdown]);

	const lottieDefaultOptions = {
		loop: false,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	return (
		<main className={classes.callbackContainer}>
			{showLoader && <DotsLoader />}

			{linkedinAuthState.success && (
				<div className={classes.feedbackContainer}>
					<div className={classes.iconContainer}>
						<Lottie options={lottieDefaultOptions} height={76} width={76} />
					</div>

					<Text variant="h1" medium>
						{linkedinAuthState.success}
					</Text>

					<Text variant="p" small light secondary>
						Redirecting in {countdown} seconds...
					</Text>
				</div>
			)}

			{linkedinAuthState.error && (
				<div className={classes.feedbackContainer}>
					<div className={classes.iconContainer}>
						<ErrorIconV2 size={9.6} />
					</div>

					<Text variant="h1" medium>
						{linkedinAuthState.error}
					</Text>

					<Text variant="p" small light secondary>
						Redirecting in {countdown} seconds...
					</Text>
				</div>
			)}
		</main>
	);
}

export default withAuth(LinkedInCallback);
