import React from 'react';
import {
	IBulkReplyState,
	IInboxBulkActionsContext,
	IInboxBulkActionsProviderProps,
	IMarkAllReadState,
} from './InboxBulkActionsProvider.types';
import { IRefProps } from '@components/ui/Toast/Toast.types';
import { AppDispatch } from '@store/index';
import { useDispatch, useSelector } from 'react-redux';
import useInboxLayoutContext from '@hooks/useInboxLayoutContext';
import {
	clearBulkReplyChatsData,
	fetchBulkReplyInboxData,
	getInboxDataFromGlobalState,
} from '@store/inboxSlice/inboxSlice';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import { BULK_REPLY_CHATS_THRESHOLD, mixPanelEvents } from '@utils/constants';
import mixpanelActions from '@utils/mixpanel';
import MarkAllReadModal from '@components/InboxLayout/MarkAllReadModal/MarkAllReadModal';
import BulkReplyModal from '@components/InboxLayout/BulkReplyModal';
import Modal from '@components/ui/Modal';
import BulkReplyPrompt from '@components/InboxBulkActionsProvider/BulkReplyPrompt';
import Toast from '@components/ui/Toast';
import { AlertIcon } from '@src/hoc/withIconStyles';
import classes from './InboxBulkActionsProvider.styles.module.scss';

export const InboxBulkActionsContext = React.createContext<IInboxBulkActionsContext>({
	handleBulkReply: () => {},
	handleMarkAllRead: () => {},
	setBulkReplyMoreHandlers: () => {},
	setMarkAllReadMoreHandlers: () => {},
	chatsDataTotalCount: null,
});

function InboxBulkActionsProvider({ children }: IInboxBulkActionsProviderProps) {
	const [openMarkAllReadModal, setOpenMarkAllReadModal] = React.useState(false);

	const [openEmptyStateBulkReplyModal, setOpenEmptyStateBulkReplyModal] = React.useState(false);
	const [openBulkReplyModal, setOpenBulkReplyModal] = React.useState(false);
	const [openBulkReplyLimitModal, setOpenBulkReplyLimitModal] = React.useState(false);
	const [bulkReplyMoreHandlers, setBulkReplyMoreHandlers] = React.useState<IBulkReplyState>({
		onBulkReplyResponse: () => {},
		onCloseBulkReplyLimitModal: () => {},
		onCloseBulkReplyModal: () => {},
		onCloseEmptyStateBulkReplyModal: () => {},
	});
	const [markAllReadMoreHandlers, setMarkAllReadMoreHandlers] = React.useState<IMarkAllReadState>({
		onCloseMarkAllReadModal: () => {},
		onSuccessMarkAllRead: () => {},
	});

	const toastRef = React.useRef<IRefProps>(null);

	const dispatch = useDispatch<AppDispatch>();

	const { onClickSingleFilter } = useInboxLayoutContext();

	const {
		bulkReplyChatsData,
		bulkReplyChatsDataTotalCount,
		bulkReplyFetchStatus,
		chatsDataTotalCount,
	} = useSelector(getInboxDataFromGlobalState);

	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);

	const topPicksThreshold = userDetails?.topPicksThreshold;

	const isFetchingChatsData = bulkReplyFetchStatus === 'loading' || bulkReplyFetchStatus === 'idle';
	const showFetchingChatsDataError = bulkReplyFetchStatus === 'error';
	const showEmptyState = !bulkReplyChatsData.length && !isFetchingChatsData;
	const showBulkReplyLimitExceeded = bulkReplyChatsDataTotalCount > BULK_REPLY_CHATS_THRESHOLD;
	const showBulkReplyModal = !!bulkReplyChatsData.length;

	const handleClickBulkReply = React.useCallback(() => {
		dispatch(clearBulkReplyChatsData());
		toastRef.current?.unPublish();

		dispatch(
			fetchBulkReplyInboxData({
				archivedCountRequired: false,
				filterReloadRequired: false,
				lastFiveMessagesRequired: false,
				topPicksThreshold: topPicksThreshold,
			})
		);
	}, [dispatch, topPicksThreshold]);

	const handleCloseBulkReplyModal = () => {
		bulkReplyMoreHandlers.onCloseBulkReplyModal();
		setOpenBulkReplyModal(false);
		dispatch(clearBulkReplyChatsData());
	};

	const handleBulkReplyResponse = () => {
		bulkReplyMoreHandlers.onBulkReplyResponse();
		setOpenBulkReplyModal(false);
		dispatch(clearBulkReplyChatsData());

		onClickSingleFilter('tap_to_refresh', 'true', true);
	};

	const handleOnCloseBulkReplyLimitModal = () => {
		bulkReplyMoreHandlers.onCloseBulkReplyLimitModal();
		setOpenBulkReplyLimitModal(false);
		dispatch(clearBulkReplyChatsData());
	};

	const handleOnCloseEmptyStateBulkReplyModal = () => {
		bulkReplyMoreHandlers.onCloseEmptyStateBulkReplyModal();
		setOpenEmptyStateBulkReplyModal(false);
		dispatch(clearBulkReplyChatsData());
	};

	const handleClickMarkAllRead = () => {
		setOpenMarkAllReadModal(true);
	};

	const handleCloseMarkAllReadModal = () => {
		markAllReadMoreHandlers.onCloseMarkAllReadModal();
		setOpenMarkAllReadModal(false);
	};

	const handleOnSuccessMarkAllRead = () => {
		markAllReadMoreHandlers.onSuccessMarkAllRead();
		setOpenMarkAllReadModal(false);
	};

	React.useEffect(() => {
		if (showFetchingChatsDataError) {
			mixpanelActions.trackBulkReply(mixPanelEvents.BULK_REPLY_API_ERROR_SHOWN);
			toastRef.current?.publish();
			return;
		}

		if (showEmptyState) {
			mixpanelActions.trackBulkReply(mixPanelEvents.BULK_REPLY_EMPTY_ERROR_SHOWN);
			setOpenEmptyStateBulkReplyModal(true);
			return;
		}
		if (showBulkReplyLimitExceeded) {
			mixpanelActions.trackBulkReply(mixPanelEvents.BULK_REPLY_LIMIT_ERROR_SHOWN);
			setOpenBulkReplyLimitModal(true);
			return;
		}

		if (showBulkReplyModal) {
			setOpenBulkReplyModal(true);
			return;
		}
	}, [
		showFetchingChatsDataError,
		showBulkReplyLimitExceeded,
		showBulkReplyModal,
		showEmptyState,
		isFetchingChatsData,
	]);

	React.useEffect(() => {
		return () => {
			dispatch(clearBulkReplyChatsData());
		};
	}, []);

	return (
		<InboxBulkActionsContext.Provider
			value={{
				handleBulkReply: handleClickBulkReply,
				handleMarkAllRead: handleClickMarkAllRead,
				setBulkReplyMoreHandlers: setBulkReplyMoreHandlers,
				setMarkAllReadMoreHandlers: setMarkAllReadMoreHandlers,
				chatsDataTotalCount: chatsDataTotalCount,
			}}
		>
			{children}
			{openMarkAllReadModal && (
				<MarkAllReadModal
					onCloseModal={handleCloseMarkAllReadModal}
					showModal={openMarkAllReadModal}
					onSuccess={handleOnSuccessMarkAllRead}
				/>
			)}

			{openBulkReplyModal && (
				<BulkReplyModal
					onCloseModal={handleCloseBulkReplyModal}
					showModal={openBulkReplyModal}
					onBulkApiResponse={handleBulkReplyResponse}
				/>
			)}

			<Modal
				noPadding
				bottomInMobile
				onCloseModal={handleOnCloseBulkReplyLimitModal}
				showModal={openBulkReplyLimitModal}
			>
				<BulkReplyPrompt
					headerText={'Too many DMs'}
					subHeaderText={
						'You can reply to a maximum of 500 DMs at once. Use filters to reduce the number.'
					}
					ctaText={'Got it'}
					onClickCTA={handleOnCloseBulkReplyLimitModal}
				/>
			</Modal>

			<Modal
				noPadding
				bottomInMobile
				onCloseModal={handleOnCloseEmptyStateBulkReplyModal}
				showModal={openEmptyStateBulkReplyModal}
			>
				<BulkReplyPrompt
					headerText={'No eligible DMs'}
					subHeaderText={'Only DMs you have never replied to are eligible for bulk replies.'}
					ctaText={'Got it'}
					onClickCTA={handleOnCloseEmptyStateBulkReplyModal}
				/>
			</Modal>

			<Toast
				ref={toastRef}
				toastType={'ERROR'}
				header={'There was some problem, please try again.'}
				icon={<AlertIcon size={1.8} className={classes.toastIcon} />}
			/>
		</InboxBulkActionsContext.Provider>
	);
}

export default InboxBulkActionsProvider;
