import { getGoogleOAuthURL } from '@utils/oauth';
import React, { useEffect, useState } from 'react';

interface ICallbackData {
	token: string | undefined;
}

function useGoogleOAuth(isOnboarding?: boolean) {
	const [data, setData] = useState<ICallbackData>({
		token: '',
	});

	const childWindowRef = React.useRef<Window | null>(null);

	useEffect(() => {
		const messageEventCallback = function (e: MessageEvent<Window>) {
			if (!(e.origin === window.location.origin && e.source === childWindowRef.current)) return;
			const callbackData: unknown = e.data;
			const typedCallbackData = callbackData as {
				token: string | undefined;
			};
			setData(typedCallbackData);
		};
		window.addEventListener('message', messageEventCallback);

		return () => {
			window.removeEventListener('message', messageEventCallback);
		};
	}, []);

	const openGoogleOAuthScreen = React.useCallback(() => {
		setData({
			token: '',
		});
		const url = getGoogleOAuthURL(isOnboarding);
		childWindowRef.current = window.open(url, 'GOOGLE_POP_UP');
	}, [isOnboarding]);

	return {
		openGoogleOAuthScreen,
		...data,
	};
}

export default useGoogleOAuth;
