import { useState, useEffect } from 'react';

function useDelayedLoader(isLoading: boolean, delay: number = 1000): boolean {
	const [showLoader, setShowLoader] = useState<boolean>(false);

	useEffect(() => {
		let timer: NodeJS.Timeout | undefined = undefined;

		if (isLoading) {
			// Start the delay timer
			timer = setTimeout(() => {
				setShowLoader(true); // Show loader after the delay
			}, delay);
		} else {
			// If loading finishes before the delay, clear the timer and hide the loader
			clearTimeout(timer);
			setShowLoader(false);
		}

		// Cleanup the timer when the component unmounts or dependencies change
		return () => clearTimeout(timer);
	}, [isLoading, delay]);

	return showLoader;
}

export default useDelayedLoader;
