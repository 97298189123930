import React from 'react';
import Text from '@components/ui/Text';
import classes from './InboxError.styles.module.scss';

function InboxError() {
	return (
		<section className={classes.inboxErrorContainer}>
			<Text variant="h1" medium>
				{'Something went wrong'}
			</Text>

			<Text variant="p" small light secondary>
				{'Please check your network or try again later'}
			</Text>
		</section>
	);
}

export default InboxError;
