import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import classes from './EditSendView.styles.modules.scss';
import Text from '@components/ui/Text';
import { IEditSendViewProps } from './EditSendView.types';
import {
	CANNED_RESPONSE_MESSAGE_CHARACTER_LIMIT,
	cannedResponseReplaceWords,
} from '@utils/constants';
import Button from '@components/ui/Button';
import { CloseIcon, TickIconColored } from '@src/hoc/withIconStyles';
import useWindowSize from '@hooks/useWindow';

function EditSendView({
	selectedCannedResponse,
	isChatBookmarked,
	senderFirstName,
	maxCharLimit,
	minCharLimit,
	onClickSend,
	onClickBack,
	showCloseIcon,
}: IEditSendViewProps) {
	const [cannedResponseTextState, setCannedResponseTextState] = useState({
		value:
			selectedCannedResponse?.text.replace(cannedResponseReplaceWords.firstName, senderFirstName) ??
			'',
		bookmark: (selectedCannedResponse?.bookmark_when_used && !isChatBookmarked) ?? false,
		error: '',
	});
	const divToScrollEndRef = useRef<HTMLDivElement | null>(null);
	const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

	const textAreaFocused = useRef<boolean>(false);
	const { isMobile } = useWindowSize();

	const handleFocus = () => {
		setTimeout(() => {
			// for ios
			window.scrollTo({
				behavior: 'smooth',
				top: document.documentElement.scrollHeight - (window.visualViewport?.height ?? 0),
			});

			// for android
			divToScrollEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
		}, 750);
		textAreaFocused.current = true;
	};

	const handleOnChangeCannedResponseText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const value = e.target.value;

		setCannedResponseTextState((prevState) => {
			return {
				...prevState,
				value: value,
				error: '',
			};
		});
	};

	const handleBookmarkAction = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation();
		setCannedResponseTextState((prevState) => {
			return {
				...prevState,
				bookmark: !prevState.bookmark,
			};
		});
		textAreaFocused.current = false;
	};

	const handleBackClick = () => {
		onClickBack();
	};

	const handleSendReplyClick = () => {
		const trimmedText = cannedResponseTextState.value.trim();
		const maxCharLimitToCheck = maxCharLimit ?? CANNED_RESPONSE_MESSAGE_CHARACTER_LIMIT;

		const isEmpty = trimmedText.length === 0;
		const isCharMoreThanMax = trimmedText.length > maxCharLimitToCheck;
		const isCharLessThanMin = minCharLimit && trimmedText.length < minCharLimit;

		if (isEmpty || isCharMoreThanMax || isCharLessThanMin) {
			setCannedResponseTextState((prevState) => {
				return {
					...prevState,
					error: isEmpty
						? 'Reply cannot be empty'
						: isCharMoreThanMax
						? `Message cannot be longer than ${maxCharLimitToCheck} characters`
						: `Message cannot be fewer than ${minCharLimit} characters`,
				};
			});
			textAreaFocused.current && textAreaRef.current?.focus();
			return;
		}
		onClickSend(trimmedText, cannedResponseTextState.bookmark);
		return;
	};

	useEffect(() => {
		if (!selectedCannedResponse || !textAreaRef.current) return;

		const textLength = selectedCannedResponse.text.length;

		textAreaRef.current.setSelectionRange(textLength, textLength);
	}, [selectedCannedResponse]);


	const showBookmarkEdit = selectedCannedResponse?.bookmark_when_used && !isChatBookmarked;

	return (
		<div className={classes.editSendViewContainer} ref={divToScrollEndRef}>
			<div className={classes.mainHeader}>
				<Text variant="h2" medium semiBold>
					{'Confirm send'}
				</Text>
				{showCloseIcon && (
					<Button
						btnText={<CloseIcon size={isMobile ? 2.4 : 1.8} className={classes.closeIcon} />}
						onClick={handleBackClick}
						customClass={classes.closeIconBtn}
					/>
				)}
			</div>
			<div className={classes.contentEditMode}>
				<textarea
					id="cannedResponseText"
					className={clsx(
						classes.textAreaInput,
						cannedResponseTextState.error && classes.textAreaInputError
					)}
					value={cannedResponseTextState.value}
					onChange={handleOnChangeCannedResponseText}
					placeholder={'Type your message here'}
					onFocus={handleFocus}
					ref={textAreaRef}
					autoComplete="off"
				/>
				<Text variant="p" tiny error customClass={classes.errorText}>
					{cannedResponseTextState.error}
				</Text>
			</div>
			<div className={classes.buttonsContainerWrapper}>
				{showBookmarkEdit && (
					<div
						tabIndex={0}
						className={classes.bookmarkActionContainer}
						onClick={handleBookmarkAction}
					>
						<div
							className={clsx(
								classes.checkbox,
								cannedResponseTextState.bookmark && classes.checkboxActive
							)}
						>
							{cannedResponseTextState.bookmark && <TickIconColored size={1.6} />}
						</div>

						<Text variant="p" tiny light secondary>
							{'Bookmark this chat for quick access'}
						</Text>
					</div>
				)}
				<div className={classes.buttonsContainer}>
					{!showCloseIcon  &&(
						<Button
							btnText={
								<Text variant="span" brandPrimaryColor semiBold>
									{'Back'}
								</Text>
							}
							onClick={handleBackClick}
							customClass={classes.editBtn}
						/>
					)}
					<Button
						btnText={
							<Text variant="span" white semiBold>
								{'Send'}
							</Text>
						}
						primary
						onClick={handleSendReplyClick}
						customClass={classes.sendBtn}
					/>
				</div>
			</div>
		</div>
	);
}

export default EditSendView;
