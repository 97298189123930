import React from 'react';
import Text from '@components/ui/Text';
import Button from '@components/ui/Button';
import { EditIconV4, PlusIconV2 } from '@src/hoc/withIconStyles';
import { IUserCannedResponse } from '@api/user/user.types';
import { cannedResponseReplaceWords } from '@utils/constants';
import { IEditListViewProps } from './EditListView.types';
import classes from './EditListView.styles.module.scss';

function EditListView({
	cannedResponses,
	onClickAdd,
	onClickBack,
	onClickCannedResponse,
	senderFirstName,
	shouldShowBackBtn,
}: IEditListViewProps) {
	const handleAddQuickReplyClick = () => {
		onClickAdd();
	};

	const handleClickCannedResponse = (cannedResponseToSelect: IUserCannedResponse) => {
		onClickCannedResponse(cannedResponseToSelect);
	};

	const handleBackClick = () => {
		onClickBack && onClickBack();
	};

	return (
		<div className={classes.editListViewContainer}>
			<div className={classes.content}>
				{cannedResponses.map((cannedResponse) => {
					return (
						<div
							className={classes.cannedResponseOption}
							key={cannedResponse.name}
							tabIndex={0}
							onClick={(e) => {
								e.stopPropagation();
								e.currentTarget.classList.add(classes.cannedResponseBlinkEffect);
								// to show click animation effect
								setTimeout(() => {
									handleClickCannedResponse(cannedResponse);
								}, 150);
							}}
						>
							<Text variant="h3" semiBold customClass={classes.heading}>
								{cannedResponse.name}
							</Text>
							<Text variant="p" secondary light small customClass={classes.info}>
								{cannedResponse.text.replace(cannedResponseReplaceWords.firstName, senderFirstName)}
							</Text>
							<div className={classes.iconContainer}>
								<EditIconV4 size={1.8} />
							</div>
						</div>
					);
				})}
			</div>
			<div className={classes.buttonsContainerWrapper}>
				<div className={classes.buttonsContainer}>
					{shouldShowBackBtn && (
						<Button
							btnText={
								<Text variant="span" brandPrimaryColor semiBold lineHeight={2.2}>
									{'Back'}
								</Text>
							}
							onClick={handleBackClick}
							customClass={classes.backBtn}
						/>
					)}
					<Button
						btnText={
							<Text variant="span" white semiBold lineHeight={2.2}>
								{'Add new'}
							</Text>
						}
						primary
						prefixIcon={<PlusIconV2 size={1.3} />}
						onClick={handleAddQuickReplyClick}
						customClass={classes.addBtnWithIcon}
					/>
				</div>
			</div>
		</div>
	);
}

export default EditListView;
