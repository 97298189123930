import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Text from '@components/ui/Text';
import Button from '@components/ui/Button';
import { DeleteIconV2, TickIconColored } from '@src/hoc/withIconStyles';
import { IUserCannedResponse } from '@api/user/user.types';
import {
	CANNED_RESPONSE_LABEL_CHARACTER_LIMIT,
	CANNED_RESPONSE_MESSAGE_CHARACTER_LIMIT,
	cannedResponseReplaceWords,
} from '@utils/constants';
import DeleteView from '../DeleteView';
import { IAddOrEditViewProps } from './AddOrEditView.types';
import classes from './AddOrEditView.styles.module.scss';
import { TAddOrUpdateState } from '../CannedResponseMobile.types';

function AddOrEditView({
	onClickBack,
	onClickSaveUpdatedCannedResponses,
	cannedResponses,
	selectedCannedResponseToEdit,
	isLoading,
}: IAddOrEditViewProps) {
	const [editData, setEditData] = useState({
		name: selectedCannedResponseToEdit?.name ?? '',
		text: selectedCannedResponseToEdit?.text ?? '',
		bookmark: selectedCannedResponseToEdit?.bookmark_when_used ?? false,
	});
	const [editErrorState, setEditErrorState] = useState({
		name: '',
		text: '',
	});
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

	const divToScrollEndRef = useRef<HTMLDivElement | null>(null);
	const recentFocusedInputRef = useRef<HTMLTextAreaElement | HTMLInputElement | null>(null);
	const buttonsContainerRef = useRef<HTMLDivElement | null>(null);

	const editMode = !!selectedCannedResponseToEdit;

	const handleOnChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (isLoading) return;

		const value = e.target.value;

		if (value.length > CANNED_RESPONSE_LABEL_CHARACTER_LIMIT) return;

		setEditData((prevState) => {
			return {
				...prevState,
				name: value,
			};
		});
		setEditErrorState((prevState) => {
			return {
				...prevState,
				name: '',
			};
		});
	};

	const handleOnChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		if (isLoading) return;

		setEditData((prevState) => {
			return {
				...prevState,
				text: e.target.value,
			};
		});
		setEditErrorState((prevState) => {
			return {
				...prevState,
				text: '',
			};
		});
	};

	const handleBookmarkAction = (e: React.MouseEvent<HTMLDivElement | MouseEvent>) => {
		e.stopPropagation();

		if (isLoading) return;

		setEditData((prevState) => {
			return {
				...prevState,
				bookmark: !editData.bookmark,
			};
		});
		recentFocusedInputRef.current = null;
	};

	const handleBackClick = () => {
		if (isLoading) return;
		onClickBack();
	};

	const handleSaveClick = () => {
		if (isLoading) return;

		const isEdiDataNameEmpty = editData.name.length === 0;
		const isEditDataTextEmpty = editData.text.length === 0;

		const isCharExceeded = editData.text.trim().length > CANNED_RESPONSE_MESSAGE_CHARACTER_LIMIT;

		recentFocusedInputRef.current?.focus();

		if (isEdiDataNameEmpty || isEditDataTextEmpty) {
			setEditErrorState({
				name: isEdiDataNameEmpty ? 'Label cannot be empty.' : editErrorState.name,
				text: isEditDataTextEmpty ? 'Reply cannot be empty.' : editErrorState.text,
			});
			return;
		}

		if (isCharExceeded) {
			setEditErrorState((prevState) => {
				return {
					...prevState,
					text: `Reply cannot be longer than ${CANNED_RESPONSE_MESSAGE_CHARACTER_LIMIT} characters`,
				};
			});
			return;
		}

		let nameExist = false;

		for (const cannedResponse of cannedResponses) {
			const editDataNameLowerCase = editData.name.toLocaleLowerCase().trim();
			const cannedResponseNameLowerCase = cannedResponse.name.toLocaleLowerCase().trim();
			const selectedCannedResponseNameLowerCase = selectedCannedResponseToEdit?.name
				.toLocaleLowerCase()
				.trim();

			if (!editMode && cannedResponseNameLowerCase === editDataNameLowerCase) {
				nameExist = true;
				break;
			}

			if (
				editMode &&
				cannedResponseNameLowerCase === editDataNameLowerCase &&
				cannedResponseNameLowerCase !== selectedCannedResponseNameLowerCase
			) {
				nameExist = true;
				break;
			}
		}

		if (nameExist) {
			setEditErrorState((prevState) => {
				return {
					...prevState,
					name: 'Label name already exists',
				};
			});
			return;
		}

		if (editMode) {
			handleUpdatedCannedResponses('UPDATE');
		} else {
			handleUpdatedCannedResponses('ADD');
		}
	};

	const handleDeleteCannedResponse = () => {
		if (isLoading) return;

		setShowDeleteConfirmation(true);
	};

	const handleOnClickDeleteBack = () => {
		setShowDeleteConfirmation(false);
	};

	const handleOnClickConfirmDelete = () => {
		handleUpdatedCannedResponses('DELETE');
	};

	const handleUpdatedCannedResponses = (state: TAddOrUpdateState) => {
		if (state === 'ADD') {
			let max_priority = cannedResponses[0]?.priority ?? 0;

			const newCannedData: IUserCannedResponse[] = cannedResponses.map((cannedResponse) => {
				if (cannedResponse.priority > max_priority) {
					max_priority = cannedResponse.priority;
				}
				return { ...cannedResponse };
			});

			const cannedResponseToAdd = {
				name: editData.name.trim(),
				text: editData.text.trim(),
				priority: max_priority + 1,
				bookmark_when_used: editData.bookmark,
			};
			newCannedData.unshift(cannedResponseToAdd);

			onClickSaveUpdatedCannedResponses(newCannedData, 'ADD');

			return;
		}

		if (state === 'UPDATE') {
			const newCannedData = cannedResponses.map((cannedResponse) => {
				if (cannedResponse.name === selectedCannedResponseToEdit?.name) {
					return {
						...cannedResponse,
						name: editData.name.trim(),
						text: editData.text.trim(),
						bookmark_when_used: editData.bookmark,
					};
				}
				return { ...cannedResponse };
			});

			onClickSaveUpdatedCannedResponses(newCannedData, 'UPDATE');

			return;
		}

		if (state === 'DELETE') {
			const newCannedData = cannedResponses.filter(
				(cannedResponse) => cannedResponse.name !== selectedCannedResponseToEdit?.name
			);

			onClickSaveUpdatedCannedResponses(newCannedData, 'DELETE');

			return;
		}
	};

	const handleFocus = () => {
		setTimeout(() => {
			// for ios
			window.scrollTo({
				behavior: 'smooth',
				top: document.documentElement.scrollHeight - (window.visualViewport?.height ?? 0),
			});

			// for android
			divToScrollEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
		}, 50);
	};

	const handleTextAreaFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
		const textToAdd = `Hi ${cannedResponseReplaceWords.firstName}`;
		const ele = e.currentTarget;

		if (!editData.text) {
			setEditData((prevState) => {
				return { ...prevState, text: textToAdd };
			});
			setEditErrorState((prevState) => {
				return {
					...prevState,
					text: '',
				};
			});
		}

		setTimeout(() => {
			ele?.setSelectionRange(textToAdd.length, textToAdd.length);
		}, 0);
	};

	useEffect(() => {
		const handleVisualViewResize = () => {
			const heightToUpdate = window.visualViewport?.height;

			if (heightToUpdate && divToScrollEndRef.current) {
				const divClientHeight = divToScrollEndRef.current.clientHeight;

				if (divClientHeight > heightToUpdate) {
					buttonsContainerRef.current?.classList.add(classes.scrollBorderTop);
				} else {
					buttonsContainerRef.current?.classList.remove(classes.scrollBorderTop);
				}

				divToScrollEndRef.current.style.maxHeight = `${heightToUpdate}px`;
				divToScrollEndRef.current.style.marginBottom = `${
					document.documentElement.scrollHeight - heightToUpdate
				}`;
				window.scrollTo({
					behavior: 'instant',
					top: document.documentElement.scrollHeight - heightToUpdate,
				});
				divToScrollEndRef.current?.scrollIntoView({ behavior: 'instant', block: 'end' });
			}
		};

		window.visualViewport?.addEventListener('resize', handleVisualViewResize);

		return () => {
			window.visualViewport?.removeEventListener('resize', handleVisualViewResize);
		};
	}, []);

	if (showDeleteConfirmation) {
		return (
			<DeleteView
				onClickBack={handleOnClickDeleteBack}
				onClickDelete={handleOnClickConfirmDelete}
				isLoading={isLoading}
			/>
		);
	}

	return (
		<div className={classes.addOrEditViewContainer} ref={divToScrollEndRef}>
			<div className={classes.inputsContainerWrapper}>
				<div className={classes.inputContainer}>
					<label htmlFor="cannedResponseName" className={classes.label}>
						<Text variant="span">{'Label'}</Text>
						{editMode && (
							<Button
								btnText={<DeleteIconV2 size={2.2} />}
								onClick={handleDeleteCannedResponse}
								customClass={classes.deleteBtn}
							/>
						)}
					</label>
					<input
						id="cannedResponseName"
						className={clsx(classes.input, editErrorState.name && classes.inputErrorState)}
						type="text"
						value={editData.name}
						onChange={handleOnChangeName}
						placeholder={'Enter a short internal name...'}
						autoFocus={!editMode}
						autoComplete="off"
						onFocus={(e) => {
							recentFocusedInputRef.current = e.currentTarget;
							e.currentTarget.scrollIntoView({ behavior: 'instant', block: 'center' });
							handleFocus();
						}}
					/>
					<Text variant="p" tiny error customClass={classes.errorText}>
						{editErrorState.name && editErrorState.name}
					</Text>
				</div>
				<div className={classes.inputContainer}>
					<label htmlFor="cannedResponseTex" className={classes.label}>
						<Text variant="span">{'Reply'}</Text>
						<Text variant="p" secondary tiny light>
							{`Use {first_name} to auto-pick name`}
						</Text>
					</label>
					<textarea
						id="cannedResponseTex"
						className={clsx(classes.textAreaInput, editErrorState.text && classes.inputErrorState)}
						value={editData.text}
						onChange={handleOnChangeText}
						placeholder={'Enter quick-reply text...'}
						autoComplete="off"
						onFocus={(e) => {
							recentFocusedInputRef.current = e.currentTarget;
							e.currentTarget.scrollIntoView({ behavior: 'instant', block: 'center' });
							handleFocus();
							handleTextAreaFocus(e);
						}}
					/>

					<Text variant="p" tiny error customClass={classes.errorText}>
						{editErrorState.text && editErrorState.text}
					</Text>
				</div>
			</div>
			<div className={classes.buttonsContainerWrapper} ref={buttonsContainerRef}>
				<div
					tabIndex={0}
					className={classes.bookmarkActionContainer}
					onClick={handleBookmarkAction}
				>
					<div className={clsx(classes.checkbox, editData.bookmark && classes.checkboxActive)}>
						{editData.bookmark && <TickIconColored size={1.6} />}
					</div>

					<Text variant="p" tiny light secondary>
						{'Bookmark the chat automatically when using this template'}
					</Text>
				</div>
				<div className={classes.buttonsContainer}>
					<Button
						btnText={
							<Text variant="span" brandPrimaryColor semiBold>
								{'Back'}
							</Text>
						}
						onClick={handleBackClick}
						customClass={classes.backBtn}
					/>
					<Button
						btnText={
							<Text variant="span" white semiBold>
								{'Save'}
							</Text>
						}
						primary
						onClick={handleSaveClick}
						customClass={classes.saveBtn}
						isLoading={isLoading}
					/>
				</div>
			</div>
		</div>
	);
}

export default AddOrEditView;
