import React, { useMemo } from 'react';
import {
	IVerifyLinkedInContext,
	IVerifyLinkedInProviderProps,
	IVerifyModalState,
} from './VerifyLinkedinProvider.types';
import VerifyLinkedInModal from '@components/VerifyLinkedInModal';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import { useSelector } from 'react-redux';
import { getFullNameFunc } from '@src/models/user';
import { getSelectedChatDetailsFromGlobalState } from '@store/selectedChatSlice/selectedChatSlice';

export const VerifyLinkedInContext = React.createContext<IVerifyLinkedInContext>({
	setModalState: () => {},
	isOpen: false,
	customHeader: '',
});

function VerifyLinkedinProvider(props: IVerifyLinkedInProviderProps) {
	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);
	const { selectedChat } = useSelector(getSelectedChatDetailsFromGlobalState);

	const [modalState, setModalState] = React.useState<IVerifyModalState>({
		isOpen: false,
		onClose: () => {},
		customHeader: '',
	});

	const senderFirstName = selectedChat?.senderDetails?.profileData?.firstName ?? 'people';

	const isModalOpen = modalState.isOpen;

	const handleCloseLinkedInVerifyModal = () => {
		modalState.onClose && modalState.onClose();
		setModalState({
			isOpen: false,
			onClose: () => {},
			customHeader: '',
		});
	};

	const setModalStateData = ({ isOpen, onClose, customHeader = '' }: IVerifyModalState) => {
		setModalState({
			isOpen,
			onClose,
			customHeader,
		});
	};

	const value = useMemo(() => {
		return {
			setModalState: setModalStateData,
			isOpen: isModalOpen,
			customHeader: modalState.customHeader || '',
		};
	}, [isModalOpen, modalState.customHeader]);

	return (
		<VerifyLinkedInContext.Provider value={value}>
			{props.children}
			{isModalOpen && (
				<VerifyLinkedInModal
					fullName={getFullNameFunc(userDetails) ?? ''}
					headline={userDetails?.headline ?? ''}
					profilePicURL={userDetails?.imageURL ?? ''}
					location={userDetails?.location?.default ?? ''}
					header={modalState.customHeader || `Verify your account for ${senderFirstName} to know your profile is authentic`}
					onCloseModal={handleCloseLinkedInVerifyModal}
					showModal={isModalOpen}
					noPadding
				/>
			)}
		</VerifyLinkedInContext.Provider>
	);
}

export default VerifyLinkedinProvider;