import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Text from '@components/ui/Text';
import { INBOX_PATH, mixPanelEvents } from '@utils/constants';
import useWindowSize from '@hooks/useWindow';
import Lottie from 'react-lottie';
import animationData from '@src/lottie/success.json';
import { AppDispatch } from '@store/index';
import { useDispatch } from 'react-redux';
import {
	clearDraftData,
	clearOnboardingInputsState,
	setDisplayVerifyLinkedInModal,
	setLinkedInStatus,
} from '@store/userDetailsSlice/userDetailsSlice';
import { IAccountCreatedStepProps } from './AccountCreatedStep.types';
import classes from './AccountCreatedStep.styles.module.scss';
import mixpanelActions from '@utils/mixpanel';

function AccountCreatedStep({ redirectURL, receiverUsername }: IAccountCreatedStepProps) {
	const { isMobile } = useWindowSize();
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();

	const [count, setCount] = useState(3);

	useEffect(() => {
		mixpanelActions.trackOnboarding(mixPanelEvents.INTRO_PAGE_SENT_SHOWN, receiverUsername);
	}, []);

	useEffect(() => {
		if (count === 1) {
			dispatch(clearOnboardingInputsState());
			dispatch(clearDraftData());
			dispatch(setLinkedInStatus({ linkedInVerificationStatus: 'PENDING_MESSAGE_SENT' }));
			dispatch(setDisplayVerifyLinkedInModal({ displayVerifyLinkedInModal: true }));
			window.history.replaceState({ idx: 0 }, '');
			navigate(redirectURL ? redirectURL : INBOX_PATH, {
				replace: true,
			});
			return;
		}

		const intervalId = setInterval(() => {
			setCount((prevCount) => {
				return prevCount - 1;
			});
		}, 1000);

		return () => {
			clearInterval(intervalId);
		};
	}, [count, redirectURL]);

	const lottieDefaultOptions = {
		loop: false,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	return (
		<section className={classes.accountCreatedContainer}>
			<Lottie
				options={lottieDefaultOptions}
				height={isMobile ? 60 : 80}
				width={isMobile ? 60 : 80}
			/>
			<Text
				variant="h1"
				fontSize={isMobile ? 2.4 : 3.2}
				lineHeight={isMobile ? 3.2 : 4}
			>{`Your DM was sent`}</Text>
			<Text
				variant="p"
				fontSize={isMobile ? 1.4 : 1.6}
				lineHeight={isMobile ? 1.8 : 2}
				secondary
			>{`Redirecting you in ${count}...`}</Text>
		</section>
	);
}

export default AccountCreatedStep;
