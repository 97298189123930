import { appAxiosInstance } from '@config/axios';

import {
	IAssignChatToLabelApiResponse,
	IAssignChatToLabelPayload,
	IBookmarkChatPayload,
	IChatMessage,
	IDeleteMessagePayload,
	IDownloadPdfPayload,
	IEmailSuggestionsPayload,
	IFetchChatMessagesPayload,
	IFetchChatMessagesResponse,
	IFetchInboxAPIPayload,
	IFetchInboxDataAPIResponse,
	IFetchInboxSearchAPIPayload,
	IFetchSenderChatDetailsPayload,
	IFetchSenderChatDetailsResponse,
	IForwardToEmailApiResponse,
	IForwardToEmailPayload,
	IGetTabOverviewApiResponse,
	IGetTabOverviewPayload,
	IMarkAsReadPayload,
	IPollMessagesPayload,
	IPollMessagesResponse,
	IReactToMessagePayload,
	IReportChartUserResponse,
	IReportChatUserPayload,
	ISaveInboxFiltersPayload,
	ISendChatMessagePayload,
	IBlockChatUserPayload,
	IBlockChatUserResponse,
	IBulkMarkAsReadPayload,
	IBulkReplyPayload,
} from './chats.types';
import {
	getStructuredInboxData,
	getStructuredSenderChatData,
	getStructuredTabOverview,
} from '@src/models/inbox';
import {
	getStructuredChatMessage,
	getStructuredChatMessages,
	getStructuredForwardToEmailApiResponse,
	getStructuredPollChatMessages,
} from '@src/models/message';

export const getInboxData = async (payload: IFetchInboxAPIPayload, signal?: AbortSignal) => {
	const res = await appAxiosInstance.post('/inbox/fetch/chats', payload, { signal: signal });
	const data: IFetchInboxDataAPIResponse = res.data;
	return getStructuredInboxData(data);
};

export const getInboxSearchData = async (
	payload: IFetchInboxSearchAPIPayload,
	signal?: AbortSignal
) => {
	const res = await appAxiosInstance.post('/inbox/search', payload, { signal: signal });

	const data: IFetchInboxDataAPIResponse = res.data;
	return getStructuredInboxData(data);
};

export const getChatMessages = async (payload: IFetchChatMessagesPayload) => {
	const res = await appAxiosInstance.post('/chat/fetch-messages', payload);

	const data: IFetchChatMessagesResponse = res.data;

	return getStructuredChatMessages(data);
};

export const sendChatMessage = async (payload: ISendChatMessagePayload) => {
	const res = await appAxiosInstance.post('/chat/send-message', payload);
	const data: IChatMessage = res.data;

	return getStructuredChatMessage(data);
};

export const sendChatMessageInFormFormat = async (payload: ISendChatMessagePayload) => {
	const payloadFormData = new FormData();

	if (payload.chat_id) {
		payloadFormData.append('chatId', `${payload.chat_id}`);
	}
	if (payload.attachment) {
		payloadFormData.append('attachment', payload.attachment);
	}
	if (payload.label) {
		payloadFormData.append('label', `${payload.label}`);
	}
	payloadFormData.append('senderId', `${payload.sender_id}`);
	payloadFormData.append('receiverId', `${payload.receiver_id}`);
	payloadFormData.append('content', `${payload.content}`);
	payloadFormData.append('contentType', `${payload.content_type}`);
	payloadFormData.append('timestamp', `${payload.timestamp}`);

	const res = await appAxiosInstance.post('/chat/send-message-with-attachment', payloadFormData, {
		headers: { ['Content-Type']: 'multipart/form-data' },
	});

	const data: IChatMessage = res.data;

	return getStructuredChatMessage(data);
};

export const markChatAsRead = async (payload: IMarkAsReadPayload) => {
	const res = await appAxiosInstance.post('/chat/mark-as-read', payload);
	const data: string = res.data;
	return data;
};

// block

export const blockChatUser = async (
	payload: IBlockChatUserPayload
): Promise<IBlockChatUserResponse> => {
	const res = await appAxiosInstance.post('/chat/block', payload);
	return res.data as IBlockChatUserResponse;
};

export const getSenderChatDetails = async (
	payload: IFetchSenderChatDetailsPayload,
	signal?: AbortSignal
) => {
	const res = await appAxiosInstance.post('/inbox/fetch/chat-with-user', payload, { signal });

	const data: IFetchSenderChatDetailsResponse = res.data;

	return getStructuredSenderChatData(data);
};

export const pollChatMessages = async (payload: IPollMessagesPayload, signal?: AbortSignal) => {
	const res = await appAxiosInstance.post('/chat/poll-messages', payload, { signal: signal });

	const data: IPollMessagesResponse = res.data;

	return getStructuredPollChatMessages(data);
};

export const reportChatUser = async (payload: IReportChatUserPayload) => {
	const res = await appAxiosInstance.post('/inbox/report/chat', payload);

	const data: IReportChartUserResponse = res.data;
	return data;
};

export const bookmarkChat = async (payload: IBookmarkChatPayload) => {
	const res = await appAxiosInstance.post('/inbox/bookmark/chat', payload);

	const data: string = res.data?.message ?? 'chat bookmarked';
	return data;
};

export const getPdfDataToDownload = async (payload: IDownloadPdfPayload) => {
	const res = await appAxiosInstance.post('/chat/download-attachment', payload, {
		responseType: 'blob',
	});

	const data: string = res.data;

	return data;
};

export const reactToMessage = async (payload: IReactToMessagePayload) => {
	const res = await appAxiosInstance.post('/chat/react-on-message', payload);

	return res.data;
};

export const forwardToEmail = async (payload: IForwardToEmailPayload) => {
	const res = await appAxiosInstance.post('/chat/forward-chat', payload);

	const data = res.data as IForwardToEmailApiResponse;

	return getStructuredForwardToEmailApiResponse(data ?? {});
};

export const getEmailSuggestions = async (payload: IEmailSuggestionsPayload) => {
	const res = await appAxiosInstance.post('/chat/fetch-email-preferences', payload);

	return res.data ? (res.data.emails_used as string[]) : null;
};

export const deleteMessage = async (payload: IDeleteMessagePayload) => {
	const res = await appAxiosInstance.post('/chat/delete-message', payload);

	return res.data;
};

export const saveInboxFilters = async (payload: ISaveInboxFiltersPayload) => {
	const res = await appAxiosInstance.post('/inbox/update/saved-filters', payload);

	return res.data;
};

export const getTabOverview = async (payload: IGetTabOverviewPayload, signal?: AbortSignal) => {
	const res = await appAxiosInstance.post('/inbox/fetch/tab-overview', payload, { signal });

	const data = res.data as IGetTabOverviewApiResponse;

	return getStructuredTabOverview(data);
};

export const assignLabelToChat = async (payload: IAssignChatToLabelPayload) => {
	const res = await appAxiosInstance.post('/chat/update-personal-label', payload);

	const data = res.data as IAssignChatToLabelApiResponse;

	return data;
};

export const bulkMarkAsRead = async (payload: IBulkMarkAsReadPayload) => {
	const res = await appAxiosInstance.post('/inbox/bulk-mark-as-read', payload);

	return res.data;
};

export const bulkReply = async (payload: IBulkReplyPayload) => {
	const res = await appAxiosInstance.post('/inbox/bulk-reply', payload);

	return res.data;
};
