import { UserNotificationAllowedDevices } from './../models/user';
import { allowNotifications } from '@api/user';
import { IAllowNotificationsPayload } from '@api/user/user.types';
import { NOTIFICATION_DEVICE_TOKEN } from '@utils/constants';
import { setLocalStorage } from '@utils/localStorage';
import { initializeApp, getApp, getApps } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';

const isProd = process.env.ENVIRONMENT === 'PROD';

const firebaseConfigDev = {
	apiKey: 'AIzaSyBJvK1nd5ukXWzfeVBNNBXDsJ07iiWGCbU',
	authDomain: 'openreachsandbox.firebaseapp.com',
	projectId: 'openreachsandbox',
	storageBucket: 'openreachsandbox.appspot.com',
	messagingSenderId: '79591590715',
	appId: '1:79591590715:web:6d9a7f9789fd2173afc51e',
	measurementId: 'G-YB897PDF20',
};
const firebaseConfigProd = {
	apiKey: 'AIzaSyBxTzhduk-AhDXO-d5EKNqskgh3w6PJCqQ',
	authDomain: 'openreach-401006.firebaseapp.com',
	projectId: 'openreach-401006',
	storageBucket: 'openreach-401006.appspot.com',
	messagingSenderId: '739451192957',
	appId: '1:739451192957:web:d1d2bf353e23527922d314',
	measurementId: 'G-X0SN32GWBE',
};

const firebaseConfig = isProd ? firebaseConfigProd : firebaseConfigDev;

const firebaseVapidKey = isProd
	? 'BFdoIF4KBsuEIw41VuVO4L96-YTRQcOTLmyxbfWNI57KjJjCAVGB-q8FppXVI29IgZceQzlZmJBxbYOeJD3KkSc'
	: 'BDFtWHTrraEovU10wIIEiAn84qa-lKKKzml7m6UHXFSIUv3Icu2UUyU0WThllBJp_KNna1vrYan10I_yUrXTg34';

export const requestForToken = async (
	userId: number,
	serviceWorkerRegistration: ServiceWorkerRegistration,
	userNotificationAllowedDevices: UserNotificationAllowedDevices[],
	onAllowNotifications: (
		updatedAllowedNotificationsDevicesInfo: UserNotificationAllowedDevices[]
	) => void
) => {
	try {
		// Initialize Firebase

		const canInitializeFirebaseApp = await isSupported();

		if (canInitializeFirebaseApp) {
			const firebaseApps = getApps();
			let firebaseApp = firebaseApps.length > 0 ? getApp() : null;

			if (firebaseApps.length === 0 || firebaseApp === null) {
				firebaseApp = initializeApp(firebaseConfig);
			}

			const messaging = getMessaging(firebaseApp);

			// Check if permission is already granted
			const currentPermission = await Notification.requestPermission();

			if (currentPermission === 'granted') {
				// Permission already granted, proceed with getting token
				const token = await getToken(messaging, {
					vapidKey: firebaseVapidKey,
					serviceWorkerRegistration: serviceWorkerRegistration,
				});

				if (token) {
					const isDeviceTokenExist = userNotificationAllowedDevices.find(
						(deviceInfo) => deviceInfo.fcmToken === token
					);

					if (isDeviceTokenExist?.fcmToken) return;

					const payload: IAllowNotificationsPayload = {
						device_type: 'MOBILE_PWA',
						notification_allowed: true,
						token: token,
						user_id: userId,
					};

					const allowedNotificationsDevicesInfo = await allowNotifications(payload);
					onAllowNotifications(allowedNotificationsDevicesInfo);

					setLocalStorage(NOTIFICATION_DEVICE_TOKEN, token);

					onMessage(messaging, (payload) => {
						console.log('Message received. ', payload);
					});
				} else {
					console.error('No permission given for notification');
				}
			} else if (currentPermission === 'denied') {
				console.error('Notification permission denied');
				// Your custom logic to show a prompt or UI element for manual permission request
			} else {
				console.error('Notification permission not granted or denied');
				// Your custom logic to show a prompt or UI element for manual permission request
			}
		} else {
			console.error('Firebase app initialization not supported');
		}
	} catch (error) {
		console.error('Firebase get token error: ', error);
	}
};
