import React from 'react';
import InboxLayoutDesktop from './InboxLayoutDesktop';
import InboxLayoutProvider from './InboxLayoutProvider';
import useWindowSize from '@hooks/useWindow';
import InboxLayoutMobile from './InboxLayoutMobile';
import withAuth from '@src/hoc/withAuth';
import ChatActionsToastProvider from '@components/ChatActionsToastProvider';
import InboxBulkActionsProvider from '@components/InboxBulkActionsProvider';

function InboxLayout() {
	const { isMobile } = useWindowSize();

	return (
		<ChatActionsToastProvider>
			<InboxLayoutProvider>
				<InboxBulkActionsProvider>
					{isMobile ? <InboxLayoutMobile /> : <InboxLayoutDesktop />}
				</InboxBulkActionsProvider>
			</InboxLayoutProvider>
		</ChatActionsToastProvider>
	);
}

export default withAuth(InboxLayout);
