import React, { useRef } from 'react';
import Button from '@components/ui/Button';
import Text from '@components/ui/Text';
import { PlusIconV2 } from '@src/hoc/withIconStyles';
import classes from './ListView.styles.module.scss';
import SelectItemChild from '@components/ui/SelectItemChild';
import { ILabelsListViewProps } from './ListView.types';
import useWindowSize from '@hooks/useWindow';
import DotsLoader from '@components/ui/DotsLoader';

function ListView({
	onClickAddLabelBtn,
	onClickLabel,
	personalLabels,
	assignedChatLabels,
	isLoading,
}: ILabelsListViewProps) {
	const { isMobile } = useWindowSize();

	const addNewBtnRef = useRef<HTMLButtonElement | null>(null);
	const listContainerRef = useRef<HTMLDivElement | null>(null);

	const handleListScroll = () => {
		const listEle = listContainerRef.current;
		const btnEle = addNewBtnRef.current;

		if (!listEle || !btnEle) return;

		if (listEle.scrollTop > 0) {
			btnEle.classList.add(classes.addNewLabelBtnWithBorder);
		} else {
			btnEle.classList.remove(classes.addNewLabelBtnWithBorder);
		}
	};

	return (
		<div className={classes.labelsListContainerWrapper}>
			{isLoading && (
				<div className={classes.loadingContainer}>
					<DotsLoader />
				</div>
			)}
			<div
				className={classes.labelsListContainer}
				onScroll={handleListScroll}
				ref={listContainerRef}
			>
				{personalLabels.map((personalLabel) => {
					const isSelectedOption = assignedChatLabels.includes(personalLabel);
					const displayName = personalLabel;

					return (
						<div
							role="option"
							aria-selected={isSelectedOption}
							aria-label={displayName}
							key={displayName}
							className={classes.labelSelectItem}
							onClick={() => onClickLabel(displayName, !isSelectedOption)}
						>
							<SelectItemChild
								displayName={
									<Text
										variant={'span'}
										lineHeight={isMobile ? 2.2 : 1.8}
										fontSize={isMobile ? 1.6 : 1.4}
									>
										{displayName}
									</Text>
								}
								isSelected={isSelectedOption}
								version={'checkbox'}
							/>
						</div>
					);
				})}
			</div>
			<Button
				btnText={
					<Text
						variant="span"
						semiBold
						lineHeight={isMobile ? 2.2 : 1.8}
						fontSize={isMobile ? 1.6 : 1.4}
						brandPrimaryColor
					>
						{'Add or manage labels'}
					</Text>
				}
				prefixIcon={<PlusIconV2 size={1.4} />}
				onClick={onClickAddLabelBtn}
				customClass={classes.addNewLabelBtn}
				ref={addNewBtnRef}
			/>
		</div>
	);
}

export default ListView;
