import { getDeviceDimensionsInfo } from '@utils/common';
import React, { useMemo } from 'react';

function useWindowSize() {
	const [size, setSize] = React.useState<{ height: number; width: number }>({
		height: window.innerHeight,
		width: window.innerWidth,
	});

	React.useEffect(() => {
		function resizeCallback() {
			setSize({
				height: window.innerHeight,
				width: window.innerWidth,
			});
		}

		window.addEventListener('resize', resizeCallback);

		return () => {
			window.removeEventListener('resize', resizeCallback);
		};
	}, []);

	const deviceDimensionsInfo = useMemo(() => {
		return getDeviceDimensionsInfo(size.height, size.width);
	}, [size]);

	return { ...deviceDimensionsInfo };
}

export default useWindowSize;
