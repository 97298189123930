import React, { useMemo } from 'react';
import clsx from 'clsx';
import Modal from '@components/ui/Modal';
import Button from '@components/ui/Button';
import Text from '@components/ui/Text';
import { IEducationModalProps } from './EducationModal.types';
import classes from './EducationModal.styles.module.scss';
import { PlusIconV2 } from '@src/hoc/withIconStyles';
import mixpanelActions from '@utils/mixpanel';
import { mixPanelEvents } from '@utils/constants';
import EducationImageOne from '@public/images/onboardingEducation1.png';
import EducationImageTwo from '@public/images/onboardingEducation2.png';
import EducationImageThree from '@public/images/onboardingEducation3.png';
import EducationImageFour from '@public/images/onboardingEducation4.png';

function EducationModal({ onClose, showModal }: IEducationModalProps) {
	const slides = useMemo(() => {
		return [
			{
				id: 0,
				header: `SuperDM is a new and better way to reach-out to people`,
				subText: `Maximise your chances of getting a reply by writing to their preferred inbox.`,
				bgImage: EducationImageOne,
			},
			{
				id: 1,
				header: 'No more guessing',
				subText:
					'Before you reach out, the intro-message tells you what kind of DMs are they interested (and not interested) to receive.',
				bgImage: EducationImageTwo,
			},
			{
				id: 2,
				header: 'Get noticed faster',
				subText:
					'Choose the right category when reaching out to make it faster to know what you are looking for.',
				bgImage: EducationImageThree,
			},
			{
				id: 3,
				header: 'Stand-out with profile previews',
				subText:
					'Connect your LinkedIn and your DM will show up with a profile preview to make it easier for people to reply.',
				bgImage: EducationImageFour,
			},
		];
	}, []);

	const [selectedSlide, setSelectedSlide] = React.useState(slides[0]);

	const handleOnClickBack = () => {
		mixpanelActions.trackOnboardingEducation(
			mixPanelEvents.INTRO_PAGE_EDUCATION_BACK_CLICKED,
			selectedSlide.header
		);
		const currentIndex = selectedSlide.id;
		setSelectedSlide(slides[currentIndex - 1]);
	};

	const handleOnClickNext = (index: number) => {
		mixpanelActions.trackOnboardingEducation(
			mixPanelEvents.INTRO_PAGE_EDUCATION_NEXT_CLICKED,
			selectedSlide.header
		);

		if (index === slides.length - 1) {
			onClose();
			return;
		}

		const nextSlide = slides[index + 1];

		setSelectedSlide(nextSlide);
	};

	const handleOnClickClose = () => {
		onClose();
	};

	const nextBtnText = selectedSlide.id === slides.length - 1 ? 'Got it' : 'Next';

	return (
		<Modal
			noPadding
			bottomInMobile
			onCloseModal={onClose}
			showModal={showModal}
			customClass={classes.modalCustomClass}
		>
			<div className={classes.educationContainer}>
				<Button
					btnText={<PlusIconV2 size={1.4} className={classes.closeIcon} />}
					onClick={handleOnClickClose}
					customClass={classes.closeBtn}
				/>
				<div className={classes.educationContentWrapper}>
					<div className={classes.imageContainer}>
						<img src={selectedSlide.bgImage} alt={selectedSlide.header} className={classes.image} />
					</div>
					<div className={classes.educationContentContainer}>
						<Text variant="h2">{selectedSlide.header}</Text>
						<Text variant="p" tertiary>
							{selectedSlide.subText}
						</Text>
					</div>
				</div>
				<div className={classes.educationFooterContainer}>
					<Button
						btnText={
							<Text variant="span" secondary>
								{'Back'}
							</Text>
						}
						onClick={handleOnClickBack}
						disabled={selectedSlide.id === 0}
						customClass={clsx(selectedSlide.id === 0 && classes.backBtnDisabled)}
					/>
					<div className={classes.dotsContainer}>
						{slides.map((slide) => {
							return (
								<div
									key={slide.id}
									className={clsx(classes.dot, selectedSlide.id === slide.id && classes.activeDot)}
								/>
							);
						})}
					</div>
					<Button
						btnText={
							<Text variant="span" white>
								{nextBtnText}
							</Text>
						}
						primary
						onClick={() => {
							handleOnClickNext(selectedSlide.id);
						}}
					/>
				</div>
			</div>
		</Modal>
	);
}

export default EducationModal;
