import React, { useState } from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import classes from './Tooltip.styles.module.scss';
import { ITooltipProps } from './Tooltip.types';

function Tooltip({ children, content, open, ...props }: ITooltipProps) {
	const [isOpen, setIsOpen] = useState(false);

	const handleClickTooltip = () => {
		setIsOpen(!isOpen);
	};

	return (
		<TooltipPrimitive.Provider>
			<TooltipPrimitive.Root delayDuration={0} open={open || isOpen} onOpenChange={setIsOpen}>
				<TooltipPrimitive.Trigger asChild>
					<span onClick={handleClickTooltip}>{children}</span>
				</TooltipPrimitive.Trigger>
				<TooltipPrimitive.Content
					className={classes.tooltipContent}
					side="top"
					align="center"
					{...props}
				>
					{content}
					<TooltipPrimitive.Arrow className={classes.tooltipArrow} width={11} height={5} />
				</TooltipPrimitive.Content>
			</TooltipPrimitive.Root>
		</TooltipPrimitive.Provider>
	);
}

export default Tooltip;
