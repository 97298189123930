import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getSelectedChatDetailsFromGlobalState } from '@store/selectedChatSlice/selectedChatSlice';
import {
	IEmailSuggestionsPayload,
	IForwardToEmailPayload,
	IForwardToEmailPayloadAttachment,
} from '@api/chats/chats.types';
import useFetch from '@hooks/useFetch';
import { forwardToEmail, getEmailSuggestions } from '@api/chats';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import Modal from '@components/ui/Modal';
import classes from './ForwardToEmailModal.styles.module.scss';
import Text from '@components/ui/Text';
import Button from '@components/ui/Button';
import {
	AlertIcon,
	CheckCircleIcon,
	CloseIcon,
	ImageIcon,
	PdfFileIcon,
} from '@src/hoc/withIconStyles';
import { IForwardToEmailModalProps } from './ForwardToEmailModal.types';
import { isValidEmail } from '@utils/common';
import Toast from '@components/ui/Toast';
import { IRefProps } from '@components/ui/Toast/Toast.types';
import useWindowSize from '@hooks/useWindow';
import { getAmPmTimeFromUTC, getMonthDayFromUTC } from '@utils/date';
import mixpanelActions from '@utils/mixpanel';
import { mixPanelEvents } from '@utils/constants';
import { createPortal } from 'react-dom';

function ForwardToEmailModal({ onCloseModal, showModal, onSuccess }: IForwardToEmailModalProps) {
	const { isMobile, height, width } = useWindowSize();
	const { selectedChat, allChatMessages, cursorPagination } = useSelector(
		getSelectedChatDetailsFromGlobalState
	);
	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);

	const [attachments, setAttachments] = useState<IForwardToEmailPayloadAttachment[]>([]);
	const [emailBody, setEmailBody] = useState('');
	const [forwardToEmailArr, setForwardToEmailArr] = useState<string[]>([]);
	const [inputEmail, setInputEmail] = useState('');
	const [toastState, setToastState] = useState({ error: '', info: '' });
	const [openEmailSuggestions, setOpenEmailSuggestions] = React.useState(false);
	const [focusedIndex, setFocusedIndex] = useState<number>(-1);

	const inputRef = useRef<HTMLInputElement | null>(null);
	const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
	const toastRef = React.useRef<IRefProps>(null);
	const divRefsToFocused = useRef<{ [key: number]: HTMLDivElement | null }>({});
	const optionsContainerRef = useRef<HTMLDivElement | null>(null);

	const userId = userDetails?.userId;

	const { callApi: callForwardToEmail, isLoading: isLoadingForwardToEmail } =
		useFetch(forwardToEmail);

	const { callApi: callEmailSuggestions, response: emailSuggestions } =
		useFetch(getEmailSuggestions);

	const recentMessages = useMemo(() => {
		return allChatMessages.slice(-10);
	}, [allChatMessages]);

	const emailSuggestionResults = useMemo(() => {
		if (!inputEmail.trim()) return [];
		if (!emailSuggestions) return [];
		if (emailSuggestions.length === 0) return [];
		return emailSuggestions.filter((email) => {
			return email.toLocaleLowerCase().startsWith(inputEmail.trim().toLocaleLowerCase());
		});
	}, [inputEmail, emailSuggestions]);

	const shouldDisplayEmailSuggestion = openEmailSuggestions && emailSuggestionResults.length > 0;

	useEffect(() => {
		if (!userId) return;
		(async function () {
			try {
				const payload: IEmailSuggestionsPayload = {
					user_id: userId,
				};
				await callEmailSuggestions(payload);
			} catch (error) {
				// handle error
			}
		})();
	}, [userId]);

	useEffect(() => {
		if (!selectedChat?.senderDetails?.email || !userDetails?.email) return;

		const emailsToAdd = [userDetails.email, selectedChat.senderDetails.email];

		setForwardToEmailArr(emailsToAdd);
	}, [selectedChat, userDetails]);

	// handling email body text
	useEffect(() => {
		if (!recentMessages.length) return;

		const senderLinkedInURL = selectedChat?.senderDetails?.profileData?.linkedInURL;
		const senderEmail = selectedChat?.senderDetails?.email;
		const currUserName = userDetails?.firstName ?? '';
		const selectedChatUserName = selectedChat?.senderDetails?.profileData?.firstName ?? '';

		const hasMoreChatsText = cursorPagination?.hasNextMessage ? '(Last 10 Messages)' : '';

		let recentMessagesText = `\n\n----- SuperDM Chat Below ${hasMoreChatsText} -----`;

		const attachments: IForwardToEmailPayloadAttachment[] = [];

		for (const message of recentMessages) {
			if (message.attachmentType && message.attachmentMetaData) {
				const newAttachmentMetaData: IForwardToEmailPayloadAttachment = {
					content_type: message.attachmentMetaData.contentType,
					id: message.attachmentMetaData.id,
					message_id: message.id,
					name: message.attachmentMetaData.name,
				};

				attachments.push(newAttachmentMetaData);
			}
			const isSameUserSent = userDetails?.userId === message.senderId;

			const monthDayFormat = getMonthDayFromUTC(message.createdAt);
			const timeAmPmFormat = getAmPmTimeFromUTC(message.createdAt);

			const timeDisplayText = `${monthDayFormat ?? ''}, ${timeAmPmFormat}`;

			const textToAppend = `\n\n[${timeDisplayText}] ${
				isSameUserSent ? currUserName : selectedChatUserName
			} : \n${message.content}`;

			recentMessagesText = recentMessagesText + textToAppend;
		}
		recentMessagesText = recentMessagesText + `\n\n----- End of Chat -----`;

		if (senderLinkedInURL) {
			recentMessagesText =
				recentMessagesText + `\n\n ${selectedChatUserName}'s LinkedIn : ${senderLinkedInURL}`;
		}

		if (senderEmail) {
			recentMessagesText =
				recentMessagesText + `\n ${selectedChatUserName}'s Email : ${senderEmail}`;
		}

		setAttachments(attachments);
		setEmailBody(recentMessagesText);
	}, [recentMessages, selectedChat, userDetails, cursorPagination]);

	// email suggestion useEffects
	useEffect(() => {
		if (!emailSuggestionResults.length) return;
		setFocusedIndex(0);
	}, [emailSuggestionResults]);

	// handling focusing elements to auto scroll into view
	useEffect(() => {
		if (isMobile) return;
		divRefsToFocused.current[focusedIndex ?? -1]?.scrollIntoView({
			behavior: 'instant',
			inline: 'end',
			block: 'end',
		});
	}, [focusedIndex, isMobile]);

	// handling email suggestions options positioning
	useEffect(() => {
		if (!inputRef.current || !optionsContainerRef.current) return;

		const inputRect = inputRef.current.getBoundingClientRect();
		const viewportHeight = height;
		const viewportWidth = width;
		const optionsContainer = optionsContainerRef.current;

		const scrollTop = window.scrollY || document.documentElement.scrollTop;

		const optionsContainerMaxWidth = 400;
		const optionsContainerMaxHeight = 250;

		// Handling positioning top
		if (inputRect.bottom + optionsContainerMaxHeight > viewportHeight) {
			const updatedHeight = Math.min(viewportHeight, optionsContainerMaxHeight);
			optionsContainer.style.height = 'auto';
			optionsContainer.style.maxHeight = updatedHeight + 'px';
			optionsContainer.style.top = viewportHeight - updatedHeight + scrollTop + 'px';
		} else {
			optionsContainer.style.height = 'auto';
			optionsContainer.style.top = inputRect.bottom + scrollTop + 'px';
		}

		// Handling positioning left

		if (inputRect.left + optionsContainerMaxWidth > viewportWidth) {
			const updatedWidth = Math.min(viewportWidth, optionsContainerMaxWidth);
			optionsContainer.style.width = updatedWidth + 'px';
			optionsContainer.style.left = viewportWidth - updatedWidth + 'px';
		} else {
			optionsContainer.style.width = 'auto';
			optionsContainer.style.left = inputRect.left + 'px';
		}

		const windowClickHandler = (e: MouseEvent) => {
			const targetNode = e.target as Node;

			if (optionsContainerRef.current?.contains(targetNode)) return;
			setOpenEmailSuggestions(false);
		};

		window.addEventListener('mousedown', windowClickHandler);

		return () => {
			window.removeEventListener('mousedown', windowClickHandler);
		};
	}, [shouldDisplayEmailSuggestion, height, width, isMobile]);

	const handleCallForwardToEmail = async () => {
		toastRef.current?.unPublish();
		if (!selectedChat?.chatId || !userDetails?.userId) return;

		if (forwardToEmailArr.length === 0) {
			setToastState({ error: 'Please enter an email', info: '' });
			toastRef.current?.publish();
			return;
		}

		if (emailBody.trim().length === 0) {
			setToastState({ error: 'Please enter something in the body to send', info: '' });
			toastRef.current?.publish();
			return;
		}

		try {
			const payload: IForwardToEmailPayload = {
				chat_id: selectedChat.chatId,
				attachments: attachments,
				content: emailBody,
				forward_to: forwardToEmailArr,
				user_id: userDetails.userId,
			};

			const data = await callForwardToEmail(payload);

			onSuccess('Email sent', data);
			mixpanelActions.trackSendAsEmail(
				mixPanelEvents.SEND_AS_EMAIL_SUCCESS,
				selectedChat?.senderDetails?.username ?? 'N/A',
				forwardToEmailArr
			);
			handleCloseModal();
		} catch (error) {
			setToastState({ info: '', error: error ?? 'Sorry, Something went wrong' });
			toastRef.current?.publish();
			mixpanelActions.trackSendAsEmail(
				mixPanelEvents.SEND_AS_EMAIL_FAILURE,
				selectedChat?.senderDetails?.username ?? 'N/A',
				forwardToEmailArr
			);
		}
	};

	const handleCloseModal = () => {
		if (isLoadingForwardToEmail) return;
		onCloseModal();
	};

	const handleRemoveAttachment = (attachmentId: string) => {
		const updatedAttachments = attachments.filter((attachment) => attachment.id !== attachmentId);
		setAttachments(updatedAttachments);
	};

	const handleRemoveEmail = (mailId: string) => {
		const updatedForwardEmails = forwardToEmailArr.filter((email) => email !== mailId);
		setForwardToEmailArr(updatedForwardEmails);
		inputRef.current?.focus();
	};

	const isEmailAlreadyExists = (emailToCheck: string) => {
		return !!forwardToEmailArr.find(
			(email) => email.toLocaleLowerCase() === emailToCheck.toLocaleLowerCase()
		);
	};

	const checkAddEmailConditions = (selectedSuggestedEmail?: string) => {
		toastRef.current?.unPublish();
		const emailToAdd =
			selectedSuggestedEmail?.trim().toLocaleLowerCase() ?? inputEmail.trim().toLocaleLowerCase();

		if (forwardToEmailArr.length >= 5) {
			setToastState({ error: 'A maximum of 5 emails can be added', info: '' });
			toastRef.current?.publish();
			return;
		}

		if (!isValidEmail(emailToAdd)) {
			setToastState({ error: 'Invalid email', info: '' });
			toastRef.current?.publish();
			return;
		}

		if (isEmailAlreadyExists(emailToAdd)) {
			setToastState({ error: 'This email is already added', info: '' });
			toastRef.current?.publish();
			return;
		}

		const updatedEmails = [...forwardToEmailArr, emailToAdd];
		setForwardToEmailArr(updatedEmails);
		setInputEmail('');
	};

	const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		setOpenEmailSuggestions(true);

		if (e.key === 'Enter' || e.key === ' ' || e.key === ',') {
			if (shouldDisplayEmailSuggestion) {
				setOpenEmailSuggestions(false);
			}
			checkAddEmailConditions(emailSuggestionResults[focusedIndex]);
			e.preventDefault();
			return;
		}

		// Handle arrow up and down keys
		if ((e.key === 'ArrowUp' || e.key === 'ArrowDown') && shouldDisplayEmailSuggestion) {
			e.preventDefault();
			const suggestionCount = emailSuggestionResults.length;

			if (suggestionCount > 0) {
				let newFocusedIndex = focusedIndex !== null ? focusedIndex : -1;

				if (e.key === 'ArrowUp') {
					newFocusedIndex = (newFocusedIndex - 1 + suggestionCount) % suggestionCount;
				} else {
					newFocusedIndex = (newFocusedIndex + 1) % suggestionCount;
				}

				setFocusedIndex(newFocusedIndex);
			}
			return;
		}
		if (e.key === 'Backspace' && inputEmail.length === 0 && forwardToEmailArr.length > 0) {
			const updatedEmails = forwardToEmailArr.slice(0, forwardToEmailArr.length - 1);
			setForwardToEmailArr(updatedEmails);
		}
	};

	const handleMailsContainerClick = () => {
		inputRef.current?.focus();
	};

	const handleInputEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;

		if (value.endsWith(',') || value.endsWith(' ')) {
			if (shouldDisplayEmailSuggestion) {
				setOpenEmailSuggestions(false);
			}
			checkAddEmailConditions(value.slice(0, -1));
			return;
		}

		setInputEmail(e.target.value);
	};

	return (
		<>
			<Modal
				noPadding
				bottomInMobile
				showModal={showModal}
				customClass={classes.modalContainer}
				onCloseModal={handleCloseModal}
			>
				<div className={classes.header}>
					<Text variant="h2" semiBold medium2M={!isMobile} medium={isMobile}>
						{'Forward to Email'}
					</Text>
					<Button btnText={<CloseIcon size={isMobile ? 2 : 2.4} />} onClick={handleCloseModal} />
				</div>
				<div className={classes.content}>
					<div className={classes.mailsMainContainer} onClick={handleMailsContainerClick}>
						<Text variant="h3" secondary>
							{'To'}
						</Text>
						<div className={classes.mailsContainer}>
							{forwardToEmailArr.map((email) => {
								return (
									<div
										className={classes.mailContainer}
										key={email}
										onClick={(e) => e.stopPropagation()}
									>
										<Text variant="span" small>
											{email}
										</Text>
										<Button
											btnText={<CloseIcon size={1.4} />}
											onClick={() => handleRemoveEmail(email)}
											customClass={classes.closeBtn}
										/>
									</div>
								);
							})}

							<input
								name="inputEmail"
								className={classes.input}
								type="text"
								value={inputEmail}
								onChange={handleInputEmailChange}
								ref={inputRef}
								onKeyDown={handleInputKeyDown}
								placeholder={forwardToEmailArr.length === 0 ? 'Add recipient emails' : ''}
								autoFocus={!isMobile}
								onBlur={() => {
									if (shouldDisplayEmailSuggestion) return;
									if (isMobile && inputEmail.length > 0) {
										checkAddEmailConditions();
										textAreaRef.current?.focus();
										textAreaRef.current?.setSelectionRange(0, 0);
										setInputEmail('');
									}
									if (forwardToEmailArr.length > 0) {
										setInputEmail('');
									}
								}}
								autoComplete="off"
							/>
						</div>
					</div>
					<textarea
						className={classes.textContainer}
						name="emailBodyText"
						value={emailBody}
						onChange={(e) => setEmailBody(e.target.value)}
						ref={textAreaRef}
						autoComplete="off"
					/>
					{attachments.length > 0 && (
						<div className={classes.attachmentsContainer}>
							{attachments.map((attachment) => {
								return (
									<div className={classes.selectedAttachmentContainer} key={attachment.id}>
										{attachment.content_type === 'application/pdf' && <PdfFileIcon size={1.4} />}
										{(attachment.content_type === 'image/png' ||
											attachment.content_type === 'image/jpeg') && <ImageIcon size={1.4} />}
										<Text variant="span" small customClass={classes.name}>
											{attachment.name}
										</Text>
										<Button
											btnText={<CloseIcon size={1.4} />}
											onClick={() => handleRemoveAttachment(attachment.id)}
											customClass={classes.closeBtn}
										/>
									</div>
								);
							})}
						</div>
					)}
				</div>
				<div className={classes.footer}>
					<Button
						btnText={
							<Text variant="span" semiBold white>
								{'Send'}
							</Text>
						}
						isLoading={isLoadingForwardToEmail}
						primary
						onClick={handleCallForwardToEmail}
						customClass={classes.sendBtn}
					/>
				</div>
			</Modal>
			{createPortal(
				<div
					className={clsx(
						classes.emailSuggestionContent,
						shouldDisplayEmailSuggestion && classes.displayEmailSuggestionContent
					)}
					ref={optionsContainerRef}
				>
					{emailSuggestionResults.map((email, index) => {
						return (
							<div
								key={email}
								ref={(el) => {
									divRefsToFocused.current[index] = el;
								}}
								className={clsx(
									classes.suggestionEmail,
									focusedIndex === index && classes.suggestionEmailSelected
								)}
								onClick={() => {
									setOpenEmailSuggestions(false);
									checkAddEmailConditions(email);
									inputRef.current?.focus();
								}}
							>
								<Text variant="p" small customClass={classes.suggestionEmailText}>
									{email}
								</Text>
							</div>
						);
					})}
				</div>,
				document.body
			)}
			<Toast
				ref={toastRef}
				toastType={toastState.info ? 'SUCCESS' : 'ERROR'}
				header={toastState.info ? toastState.info : toastState.error}
				icon={
					toastState.info ? (
						<CheckCircleIcon size={1.8} />
					) : (
						<AlertIcon size={1.8} className={classes.toastIcon} />
					)
				}
			/>
		</>
	);
}

export default ForwardToEmailModal;
