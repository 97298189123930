import React, { useEffect } from 'react';
import Text from '@components/ui/Text';
import Button from '@components/ui/Button';
import useWindowSize from '@hooks/useWindow';
import classes from './Offline.styles.module.scss';

function Offline() {
	const { isMobile } = useWindowSize();
	const [isOnline, setIsOnline] = React.useState(window.navigator.onLine);
	const buttonRef = React.useRef<HTMLButtonElement>(null);

	useEffect(() => {
		const handleOnlineEvent = () => {
			window.location.reload();
			setIsOnline(true);
		};

		window.addEventListener('online', handleOnlineEvent);

		return () => {
			window.removeEventListener('online', handleOnlineEvent);
		};
	}, []);

	const handleRetryBtnClick = () => {
		buttonRef.current?.classList.add(classes.offlineBtnBlinkEffect);
		// to show click animation effect
		setTimeout(() => {
			buttonRef.current?.classList.remove(classes.offlineBtnBlinkEffect);
			isOnline && window.location.reload();
		}, 150);
	};

	return (
		<div className={classes.offlineContainer}>
			<Text variant="h1" medium={isMobile} medium2M={!isMobile} semiBold>
				{'Something went wrong'}
			</Text>
			<Text variant="p" small={isMobile} light secondary>
				{'Please check your network or try again later'}
			</Text>
			<Button
				btnText={
					<Text variant="span" semiBold white>
						{'Retry'}
					</Text>
				}
				primary
				ref={buttonRef}
				onClick={handleRetryBtnClick}
			/>
		</div>
	);
}

export default Offline;
