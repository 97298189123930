/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useRef, useState } from 'react';
import classes from './ChatsHeader.styles.module.scss';
import Text from '@components/ui/Text';
import useWindowSize from '@hooks/useWindow';
import Avatar from '@components/ui/Avatar';
import FallbackPicIcon from '@public/icons/fallbackPicIcon.svg';
import Button from '@components/ui/Button';
import Modal from '@components/ui/Modal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
	ALL,
	CHATS,
	INBOX_PATH,
	INBOX_TAB_SEARCH_PARAM,
	PROFILE_PATH,
	mixPanelEvents,
	CHAT_SEARCH_PARAM,
} from '@utils/constants';
import {
	BookmarkIcon,
	CheckCircleIcon,
	UnverifiedIcon,
	BookmarkIconColored,
	ForwardToEmailIconV2,
	ArrowLeftIcon,
	BlockIconSVG,
	ThreedotsIcon,
	AlertIcon,
} from '@src/hoc/withIconStyles';
import { clearSelectedChat } from '@store/selectedChatSlice/selectedChatSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/index';
import { setCurrBlockChatsMap, setBlockChatActionCount } from '@store/inboxSlice/inboxSlice';
import {
	getSelectedChatDetailsFromGlobalState,
	setMessageEmailSignifier,
} from '@store/selectedChatSlice/selectedChatSlice';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';

import { IBookmarkChatPayload } from '@api/chats/chats.types';
import useFetch from '@hooks/useFetch';
import { bookmarkChat } from '@api/chats';
import { IRefProps } from '@components/ui/Toast/Toast.types';
import Toast from '@components/ui/Toast';
import clsx from 'clsx';
import { blockChatUser } from '@api/chats/index';
import { IBlockChatUserPayload } from '@api/chats/chats.types';
import mixpanelActions from '@utils/mixpanel';
import { isProUserFunc } from '@src/models/user';
import ForwardToEmailModal from './ForwardToEmailModal';
import {
	getInboxDataFromGlobalState,
	setBookmarkChatActionCount,
	setCurrBookmarkChatsMap,
} from '@store/inboxSlice/inboxSlice';
import {
	getFullNameSenderProfileFunc,
	isLinkedInVerificationPendingChatSenderFunc,
} from '@src/models/inbox';
import Tooltip from '@components/ui/Tooltip';
import Label from '../ChatsContent/Label';
import * as Popover from '@radix-ui/react-popover';
import Loader from '@components/ui/Loader';

function ChatsHeader() {
	const { isMobile } = useWindowSize();
	const navigate = useNavigate();
	const toastRef = useRef<IRefProps>(null);
	const { currChatsDataSelectedChatInboxType } = useSelector(getInboxDataFromGlobalState);

	const chatHeaderRef = useRef<HTMLDivElement | null>(null);
	const chatHeaderInfoContentRef = useRef<HTMLDivElement | null>(null);

	const dispatch = useDispatch<AppDispatch>();
	const { currBookmarkChatsMap, currAssignedLabelsChatsMap } = useSelector(
		getInboxDataFromGlobalState
	);
	const { selectedChat } = useSelector(getSelectedChatDetailsFromGlobalState);
	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);

	const { callApi: callBookmarkChat, status: callBookmarkChatStatus } = useFetch(bookmarkChat);
	const { callApi: blockChat, status: blockChatUserStatus } = useFetch(blockChatUser);
	const [toastState, setToastState] = useState({ error: '', info: '' });
	const [showForwardToEmailModal, setShowForwardToEmailModal] = useState(false);
	const [showBlockModal, setShowBlockModal] = useState(false);

	const [searchParams, setSearchParams] = useSearchParams();

	// menu actions dropdown
	const [isOpenDropdownPopover, setIsOpenDropdownPopover] = useState(false);
	const [isDropdownModalOpen, setIsDropdownModalOpen] = useState(false);

	const profileDetails = selectedChat?.senderDetails?.profileData;
	const name = profileDetails ? getFullNameSenderProfileFunc(profileDetails) : '';
	const isSelectedChatLinkedInAccountVerified = selectedChat?.senderDetails
		? isLinkedInVerificationPendingChatSenderFunc(selectedChat.senderDetails)
		: false;
	const senderUsername = selectedChat?.senderDetails?.username ?? '';
	const profilePicURL = profileDetails?.profilePicture;
	const selectedChatUserId = selectedChat?.senderDetails?.userId ?? null;
	const currUserId = userDetails?.userId ?? null;
	const selectedChatId = selectedChat?.chatId;
	const chatBookmarked =
		currBookmarkChatsMap[selectedChatId ?? -1] ?? selectedChat?.bookmark ?? false;
	const isChatStartedBySameUser = selectedChat?.startedBy === userDetails?.userId;

	const userPersonalLabels = userDetails?.personalLabels ?? [];
	const assignedPersonalLabels = (
		currAssignedLabelsChatsMap[selectedChatId ?? -1] ??
		selectedChat?.personalLabels ??
		[]
	).filter((label) => {
		return userPersonalLabels.includes(label);
	});

	const selectedCategory = selectedChat?.label;

	const showForwardToEmailOptionDesktop = !isMobile && !isChatStartedBySameUser;
	const showForwardToEmailOptionMobile = isMobile && !isChatStartedBySameUser;

	const handleHeaderInfoClick = () => {
		mixpanelActions.trackInboxChat(
			mixPanelEvents.INBOX_PROFILE_NAME_CLICKED,
			senderUsername,
			'',
			''
		);
		navigate(PROFILE_PATH + `/${senderUsername}`);
	};

	const handleBookmarkChat = async () => {
		if (!(selectedChatUserId && currUserId && selectedChatId)) return;
		if (callBookmarkChatStatus === 'loading') return;

		const payload: IBookmarkChatPayload = {
			chat_id: selectedChatId,
			sender_id: selectedChatUserId,
			user_id: currUserId,
			bookmark: !chatBookmarked,
		};

		try {
			await callBookmarkChat(payload);
			// handling bookmark chat state in redux
			dispatch(
				setBookmarkChatActionCount({
					chatBookmarked: payload.bookmark,
					chatId: payload.chat_id,
				})
			);
			dispatch(
				setCurrBookmarkChatsMap({ chatId: payload.chat_id, chatBookmarked: payload.bookmark })
			);

			if (payload.bookmark) {
				mixpanelActions.trackChatBookmark(
					mixPanelEvents.CHAT_ADDED_TO_BOOKMARK,
					senderUsername,
					'HEADER'
				);
			} else {
				mixpanelActions.trackChatBookmark(
					mixPanelEvents.CHAT_REMOVED_FROM_BOOKMARK,
					senderUsername,
					'HEADER'
				);
			}
		} catch (error) {
			// handle error
		}
	};

	const handleCloseForwardToEmailModal = () => {
		setShowForwardToEmailModal(false);
	};

	const handleForwardToEmailBtnClick = () => {
		setShowForwardToEmailModal(true);
		mixpanelActions.trackSendAsEmail(
			mixPanelEvents.SEND_AS_EMAIL_CLICKED,
			selectedChat?.senderDetails?.username ?? 'N/A'
		);
	};

	const handleBlockOptionClick = () => {
		mixpanelActions.trackInboxChat(
			mixPanelEvents.INBOX_BLOCK_CLICKED,
			senderUsername ?? '',
			'',
			selectedCategory ?? ''
		);
		setShowBlockModal(true);
	};

	const handleCloseBlockModal = () => {
		if (blockChatUserStatus === 'loading') return;
		setShowBlockModal(false);
	};

	const handleBlockUser = async () => {
		toastRef.current?.unPublish();

		try {
			const chatId = selectedChat?.chatId;
			const userId = userDetails?.userId;

			if (!chatId || !userId || blockChatUserStatus === 'loading') return;

			const payload: IBlockChatUserPayload = {
				chat_id: chatId,
				user_id: userId,
				block: true, // Add the block field here
			};

			await blockChat(payload);

			mixpanelActions.trackInboxChat(
				mixPanelEvents.INBOX_BLOCK_SUBMITTED,
				senderUsername ?? '',
				'',
				selectedCategory ?? ''
			);
			setShowBlockModal(false);
			handleCloseDropdown();
			setTimeout(() => {
				dispatch(
					setBlockChatActionCount({
						chatBlocked: true,
						chatId: chatId,
					})
				);

				dispatch(
					setCurrBlockChatsMap({
						chatBlocked: true,
						chatId: chatId,
						inboxChatsDataType: currChatsDataSelectedChatInboxType,
					})
				);

				dispatch(clearSelectedChat());

				if (searchParams.has(CHAT_SEARCH_PARAM)) {
					setSearchParams(
						(prevParams) => {
							const params = new URLSearchParams(prevParams);
							params.delete(CHAT_SEARCH_PARAM);
							return params;
						},
						{ replace: true }
					);
				} else {
					isMobile && navigate(-1);
				}
			}, 1000);
			setToastState({
				error: '',
				info: `${name} has been blocked`,
			});
			toastRef.current?.publish();
		} catch (error) {
			setToastState({
				error: error,
				info: '',
			});
			toastRef.current?.publish();
		}
	};

	const handleOpenDropdown = () => {
		if (isMobile) {
			setIsDropdownModalOpen(true);
		} else {
			setIsOpenDropdownPopover(true);
		}
	};

	const handleCloseDropdown = () => {
		if (isMobile) {
			setIsDropdownModalOpen(false);
		} else {
			setIsOpenDropdownPopover(false);
		}
	};

	const menuDropdownContentOptions = [
		showForwardToEmailOptionMobile && (
			<Button
				prefixIcon={
					<ForwardToEmailIconV2
						size={isMobile ? 2 : 1.6}
						className={classes.forwardToEmailDropdownIcon}
					/>
				}
				btnText={
					<Text variant="h2" lineHeight={!isMobile ? 1.8 : 2.2} fontSize={!isMobile ? 1.4 : 1.6}>
						{'Forward to Email'}
					</Text>
				}
				onClick={handleForwardToEmailBtnClick}
				customClass={classes.menuItem}
				key={'Forward to Email'}
			/>
		),
		<Button
			prefixIcon={<BlockIconSVG size={isMobile ? 2 : 1.6} />}
			btnText={
				<Text variant="h2" lineHeight={!isMobile ? 1.8 : 2.2} fontSize={!isMobile ? 1.4 : 1.6}>
					{'Block'}
				</Text>
			}
			onClick={handleBlockOptionClick}
			customClass={classes.menuItem}
			key={'Block'}
		/>,
	];

	// TODO create a context and use it in all the places
	const handleAppBack = () => {
		// meaning the navigation stack has one path
		const isPathRenderedAsInitialRoute = window.history.state?.idx === 0;

		const searchParamsToUpdate = new URLSearchParams(searchParams);

		searchParamsToUpdate.forEach((value, key) => {
			// Remove all keys except INBOX_TAB_SEARCH_PARAM
			key !== INBOX_TAB_SEARCH_PARAM && searchParamsToUpdate.delete(key);
		});

		// If INBOX_TAB_SEARCH_PARAM does not exist, add ALL
		if (!searchParamsToUpdate.has(INBOX_TAB_SEARCH_PARAM)) {
			searchParamsToUpdate.set(
				INBOX_TAB_SEARCH_PARAM,
				encodeURIComponent(isProUserFunc(userDetails) ? ALL : CHATS)
			);
		}
		isPathRenderedAsInitialRoute
			? navigate(`${INBOX_PATH}?` + searchParamsToUpdate.toString(), { replace: true })
			: navigate(-1);
	};

	const BookMarkIconToDisplay = chatBookmarked ? BookmarkIconColored : BookmarkIcon;

	const showChatActions = isProUserFunc(userDetails) && selectedChat?.chatId;

	const pendingIconContent = isSelectedChatLinkedInAccountVerified && (
		<div
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			<Tooltip
				content={
					<Text variant="p" small light>
						{`This user's profile was created via their LinkedIn URL. They are yet to verify that the LinkedIn account for that URL belongs to them. `}
					</Text>
				}
			>
				<UnverifiedIcon size={isMobile ? 1.8 : 1.6} className={classes.pendingIcon} />
			</Tooltip>
		</div>
	);

	const assignedLabelsContent = assignedPersonalLabels.join(', ');

	useEffect(() => {
		const chatHeaderInfoContentEle = chatHeaderInfoContentRef.current;
		const chatHeaderEle = chatHeaderRef.current;

		if (!chatHeaderInfoContentEle || !chatHeaderEle) return;

		chatHeaderInfoContentEle.style.maxWidth = `${
			chatHeaderEle.getBoundingClientRect().width - 152
		}px`;
		chatHeaderInfoContentEle.classList.add(classes.showChatHeaderInfoContent);
	}, []);

	return (
		<div className={classes.chatHeader} ref={chatHeaderRef}>
			{isMobile && (
				<div className={classes.chatHeaderInfoContentMobile}>
					<Button
						customClass={classes.backButton}
						btnText={<ArrowLeftIcon size={2.4} />}
						onClick={handleAppBack}
					/>
					<div
						className={classes.mobileHeaderContent}
						role="button"
						aria-label="Open Profile"
						tabIndex={0}
						onClick={handleHeaderInfoClick}
					>
						<Avatar size={3.6} profilePicURL={profilePicURL} fallbackIcon={FallbackPicIcon} />
						<div className={classes.nameWithLabel}>
							<div className={classes.withPending}>
								<Text variant="h2" lineHeight={2.2} white>
									{name}
								</Text>
								{pendingIconContent}
							</div>
							{!!assignedLabelsContent && (
								<Text
									variant="p"
									fontSize={1.2}
									lineHeight={1.4}
									white
									customClass={classes.assignedLabelsContentText}
								>
									{assignedLabelsContent}
								</Text>
							)}
						</div>
					</div>
				</div>
			)}

			{!isMobile && (
				<div className={classes.chatHeaderInfoContent} ref={chatHeaderInfoContentRef}>
					<div className={classes.nameWithLabel}>
						<div className={classes.withPending}>
							<Text variant="h2" lineHeight={1.8} fontSize={1.4} semiBold>
								{name}
							</Text>
							{pendingIconContent}
						</div>
						{!!assignedLabelsContent && (
							<Text
								variant="p"
								fontSize={1.1}
								lineHeight={1.4}
								color={'#4A00C4'}
								customClass={classes.assignedLabelsContentText}
							>
								{assignedLabelsContent}
							</Text>
						)}
					</div>
				</div>
			)}

			{showChatActions && (
				<div className={classes.actionsContainer}>
					<Button
						btnText={
							<BookMarkIconToDisplay
								size={isMobile ? 2.4 : 2}
								className={clsx(classes.bookmarkIcon, chatBookmarked && classes.bookmarkIconActive)}
							/>
						}
						onClick={handleBookmarkChat}
						customClass={classes.bookmarkBtn}
					/>

					<Label />

					{showForwardToEmailOptionDesktop && (
						<Button
							btnText={
								<ForwardToEmailIconV2
									size={isMobile ? 2.4 : 2}
									className={classes.forwardToEmailIcon}
								/>
							}
							onClick={handleForwardToEmailBtnClick}
							customClass={classes.forwardToEmailBtn}
						/>
					)}

					{!isMobile && (
						<Popover.Root open={isOpenDropdownPopover} onOpenChange={setIsOpenDropdownPopover}>
							<Popover.Trigger asChild>
								<button
									onClick={(e) => {
										e.stopPropagation();
									}}
									className={classes.menuButton}
								>
									<ThreedotsIcon size={2} className={classes.threeDotsIcon} />
								</button>
							</Popover.Trigger>
							<Popover.Portal>
								<Popover.Content align="end" side="bottom" className={classes.menuContentDesktop}>
									{...menuDropdownContentOptions}
								</Popover.Content>
							</Popover.Portal>
						</Popover.Root>
					)}

					{isMobile && (
						<Button
							btnText={<ThreedotsIcon size={2.4} className={classes.threeDotsIcon} />}
							onClick={handleOpenDropdown}
							customClass={classes.menuButton}
						/>
					)}
				</div>
			)}

			<Toast
				ref={toastRef}
				toastType={toastState.info ? 'INFO' : 'ERROR'}
				header={toastState.info ? toastState.info : toastState.error}
				icon={
					toastState.info ? (
						<CheckCircleIcon size={1.8} />
					) : (
						<AlertIcon size={1.8} className={classes.toastIcon} />
					)
				}
			/>

			{showForwardToEmailModal && (
				<ForwardToEmailModal
					onCloseModal={handleCloseForwardToEmailModal}
					showModal={showForwardToEmailModal}
					onSuccess={(message, data) => {
						dispatch(setMessageEmailSignifier({ forwardToEmailData: data }));
						handleCloseDropdown();
						setToastState({ error: '', info: message });
						toastRef.current?.publish();
					}}
				/>
			)}

			{showBlockModal && (
				<Modal
					onCloseModal={handleCloseBlockModal}
					showModal={showBlockModal}
					bottomInMobile
					noPadding
				>
					<div className={classes.blockModalContentContainer}>
						<Text variant="h2" lineHeight={2.4} medium semiBold>{`Block ${name}?`}</Text>

						<Text
							variant="p"
							light
							fontSize={isMobile ? 1.4 : 1.6}
							lineHeight={isMobile ? 2 : 2.2}
							secondary
						>
							{
								'They wont know you blocked them. This chat will be permanently removed from your inbox.'
							}
						</Text>

						<div className={classes.buttonsContainer}>
							<Button
								btnText={
									<Text variant="span" lineHeight={2.2} semiBold>
										{'Cancel'}
									</Text>
								}
								onClick={handleCloseBlockModal}
								customClass={classes.modalCancelBtn}
								disabled={blockChatUserStatus === 'loading'}
							/>

							<Button
								btnText={
									<Text variant="span" white lineHeight={2.2} semiBold>
										{'Yes, block'}
									</Text>
								}
								onClick={handleBlockUser}
								customLoader={<Loader allWhite />}
								customClass={classes.modalBlockBtn}
								isLoading={blockChatUserStatus === 'loading'}
							/>
						</div>
					</div>
				</Modal>
			)}

			{isDropdownModalOpen && (
				<Modal onCloseModal={handleCloseDropdown} showModal={isDropdownModalOpen} bottomInMobile>
					{...menuDropdownContentOptions}
				</Modal>
			)}
		</div>
	);
}

export default React.memo(ChatsHeader);
