import React from 'react';
import { IArchivedInfoProps } from './ArchivedInfo.types';
import classes from './ArchivedInfo.styles.module.scss';
import Text from '@components/ui/Text';
import { ArchiveBoxIcon, TickBoldIcon } from '@src/hoc/withIconStyles';

function ArchivedInfo({ infoArr }: IArchivedInfoProps) {
	return (
		<div className={classes.archivedInfoContainer}>
			<Text
				variant="p"
				tertiary
				light
				tiny
			>{`These chats stay archived when new messages are received.`}</Text>
			<div className={classes.archivedInfoListContainer}>
				<Text variant="h3" semiBold>{`Auto-archived DMs`}</Text>
				<div className={classes.archivedInfoList}>
					{infoArr.map((info, index) => (
						<div key={index} className={classes.archivedInfoItem}>
							<TickBoldIcon size={1.6} className={classes.tickIcon} />
							<Text variant="p" small light secondary>
								{info}
							</Text>
						</div>
					))}
				</div>
				<div className={classes.archiveIconBox}>
					<ArchiveBoxIcon size={3.2} />
				</div>
			</div>
		</div>
	);
}

export default ArchivedInfo;
