import React from 'react';
import clsx from 'clsx';
import Modal from '@components/ui/Modal';
import { IBulkReplyModalProps, TBulkReplyModalStates } from './BulkReplyModal.types';
import classes from './BulkReplyModal.styles.module.scss';
import ChatsListView from './ChatsListView';
import ReplyView from './ReplyView';
import ConfirmView from './ConfirmView';
import ReplyStatusView from './ReplyStatusView';
import Text from '@components/ui/Text';
import Button from '@components/ui/Button';
import { useSelector } from 'react-redux';
import { getFiltersObj, getInboxDataFromGlobalState } from '@store/inboxSlice/inboxSlice';
import useFetch from '@hooks/useFetch';
import { bulkReply } from '@api/chats';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import { IBulkReplyPayload } from '@api/chats/chats.types';
import { mixPanelEvents, NOT_REPLIED, REPLIED } from '@utils/constants';
import mixpanelActions from '@utils/mixpanel';

function BulkReplyModal({ onCloseModal, showModal, onBulkApiResponse }: IBulkReplyModalProps) {
	const [modalState, setModalState] = React.useState<TBulkReplyModalStates>('LIST_VIEW');
	const [openConfirmModal, setOpenConfirmModal] = React.useState(false);

	// list view states
	const [selectAllEnabled, setSelectAllEnabled] = React.useState(true);
	const [chatsToIncludeOrExclude, setChatsToIncludeOrExclude] = React.useState<number[]>([]);

	// reply view states
	const [replyText, setReplyText] = React.useState('');
	const [shouldBookmark, setShouldBookmark] = React.useState(false);

	const {
		bulkReplyChatsData,
		bulkReplyChatsDataTotalCount,
		selectedSingleFilters,
		selectedMultiFilters,
		filters,
		currFetchRequestTimestamp,
	} = useSelector(getInboxDataFromGlobalState);
	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);

	const { callApi: callBulkReply, status: callBulkReplyStatus } = useFetch(bulkReply);

	const isLoading = callBulkReplyStatus === 'loading';
	const isError = callBulkReplyStatus === 'error';
	const isSuccess = callBulkReplyStatus === 'success';

	const userId = userDetails?.userId;

	const handleCloseModal = () => {
		if (isLoading || modalState === 'REPLY_STATUS_VIEW') return;

		mixpanelActions.trackBulkReply(mixPanelEvents.BULK_REPLY_EXIT_ALERT_SHOWN);

		setOpenConfirmModal(true);
	};

	const handleChatsListViewNextClick = () => {
		setModalState('REPLY_VIEW');
	};

	const handleChatsListCloseClick = () => {
		handleCloseModal();
	};

	const handleReplyViewNextClick = (replyText: string, shouldBookmark: boolean) => {
		setReplyText(replyText);
		setShouldBookmark(shouldBookmark);
		setModalState('CONFIRM_VIEW');
	};

	const handleReplyViewBackClick = (replyText: string, shouldBookmark: boolean) => {
		setReplyText(replyText);
		setShouldBookmark(shouldBookmark);
		setModalState('LIST_VIEW');
	};

	const handleReplyViewCloseClick = () => {
		handleCloseModal();
	};

	const handleConfirmViewSendClick = async () => {
		if (!userId || isLoading) return;

		try {
			setModalState('REPLY_STATUS_VIEW');

			const selectedSingleFiltersObj = { ...selectedSingleFilters };

			// handling replied filter for bulk reply

			selectedSingleFiltersObj[REPLIED] = NOT_REPLIED;

			const payload: IBulkReplyPayload = {
				user_id: userId,
				applied_filters: getFiltersObj(selectedSingleFiltersObj, selectedMultiFilters, filters),
				reply_text: replyText,
				bookmark: shouldBookmark,
				select_all: selectAllEnabled,
				included_chat_ids: selectAllEnabled ? [] : chatsToIncludeOrExclude,
				excluded_chat_ids: selectAllEnabled ? chatsToIncludeOrExclude : [],
				last_fetch_timestamp: currFetchRequestTimestamp,
			};

			await callBulkReply(payload);
		} catch (error) {
			// handle error
		}
	};

	const handleConfirmViewBackClick = () => {
		setModalState('REPLY_VIEW');
	};

	const handleConfirmViewCloseClick = () => {
		handleCloseModal();
	};

	const handleReplyStatusViewDoneClick = () => {
		onBulkApiResponse();
	};

	const handleConfirmModalBackClick = () => {
		setOpenConfirmModal(false);
	};

	const handleConfirmModalYesClick = () => {
		onCloseModal();
	};

	return (
		<>
			<Modal
				bottomInMobile
				noPadding
				showModal={showModal}
				onCloseModal={handleCloseModal}
				customClass={clsx(
					classes.bulkReplyModalContainer,
					openConfirmModal && classes.hideBulkReplyModal
				)}
			>
				{modalState === 'LIST_VIEW' && (
					<ChatsListView
						onClickNext={handleChatsListViewNextClick}
						onClickCloseIcon={handleChatsListCloseClick}
						isSelectAll={selectAllEnabled}
						setIsSelectAll={setSelectAllEnabled}
						chatsToIncludeOrExclude={chatsToIncludeOrExclude}
						setChatsToIncludeOrExclude={setChatsToIncludeOrExclude}
						chatsDataToDisplay={bulkReplyChatsData}
						chatsDataTotalCount={bulkReplyChatsDataTotalCount}
					/>
				)}
				{modalState === 'REPLY_VIEW' && (
					<ReplyView
						onClickBack={handleReplyViewBackClick}
						onClickCloseIcon={handleReplyViewCloseClick}
						onClickNext={handleReplyViewNextClick}
						initialReplyText={replyText}
						initialBookmark={shouldBookmark}
					/>
				)}
				{modalState === 'CONFIRM_VIEW' && (
					<ConfirmView
						onClickBack={handleConfirmViewBackClick}
						onClickCloseIcon={handleConfirmViewCloseClick}
						onClickSend={handleConfirmViewSendClick}
						chatsToIncludeOrExclude={chatsToIncludeOrExclude}
						replyText={replyText}
						isSelectAll={selectAllEnabled}
						chatsDataToDisplay={bulkReplyChatsData}
						chatsDataTotalCount={bulkReplyChatsDataTotalCount}
					/>
				)}
				{modalState === 'REPLY_STATUS_VIEW' && (
					<ReplyStatusView
						isError={isError}
						isLoading={isLoading}
						isSuccess={isSuccess}
						onClickDone={handleReplyStatusViewDoneClick}
						chatsToIncludeOrExclude={chatsToIncludeOrExclude}
						isSelectAll={selectAllEnabled}
						chatsDataTotalCount={bulkReplyChatsDataTotalCount}
					/>
				)}
			</Modal>
			<Modal
				bottomInMobile
				noPadding
				showModal={openConfirmModal}
				onCloseModal={handleConfirmModalBackClick}
			>
				<div className={classes.confirmContainer}>
					<Text variant="h2" lineHeight={2.4} medium semiBold>
						{'Are you sure you want to exit?'}
					</Text>

					<Text
						variant="p"
						lineHeight={2}
						small
						light
						secondary
					>{`All your progress will be lost.`}</Text>

					<div className={classes.btnsContainer}>
						<Button
							btnText={
								<Text variant="span" lineHeight={2.2} semiBold>
									{'No'}
								</Text>
							}
							onClick={handleConfirmModalBackClick}
						/>
						<Button
							btnText={
								<Text variant="span" lineHeight={2.2} semiBold white>
									{'Yes'}
								</Text>
							}
							primary
							onClick={handleConfirmModalYesClick}
						/>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default BulkReplyModal;
