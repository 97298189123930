import React, { useEffect } from 'react';
import Modal from '@components/ui/Modal';
import Text from '@components/ui/Text';
import { SparkleIcon } from '@src/hoc/withIconStyles';
import classes from './AppUpdateModal.styles.module.scss';
import Button from '@components/ui/Button';
import mixpanelActions from '@utils/mixpanel';
import { REFRESH_TIMESTAMP, mixPanelEvents } from '@utils/constants';

function AppUpdateModal() {
	useEffect(() => {
		mixpanelActions.trackAppUpdateModal(mixPanelEvents.WEB_APP_UPDATE_MODAL_SHOWN);
	}, []);

	const handleAppRelaunch = () => {
		mixpanelActions.trackAppUpdateModal(mixPanelEvents.RELAUNCH_BUTTON_CLICKED);
		const urlSearchParams = new URLSearchParams(window.location.search);

		urlSearchParams.set(REFRESH_TIMESTAMP, Date.now().toString());

		const updatedURL =
			window.location.origin + window.location.pathname + '?' + urlSearchParams.toString();

		window.location.replace(updatedURL);
	};

	return (
		<Modal
			showModal={true}
			onCloseModal={() => {}}
			noPadding
			customClass={classes.modalCustomClass}
		>
			<div className={classes.appUpdateContentContainer}>
				<div className={classes.iconContainer}>
					<SparkleIcon size={4} />
				</div>
				<Text variant="h2" semiBold lineHeight={2.4} medium>
					{'New Update Available'}
				</Text>
				<Text variant="p" light small tertiary lineHeight={2}>
					{'Relaunch to get the latest update. You will not be signed out.'}
				</Text>
				<Button
					btnText={
						<Text variant="span" white semiBold lineHeight={2.4}>
							{'Relaunch now'}
						</Text>
					}
					onClick={handleAppRelaunch}
					primary
					customClass={classes.relaunchButton}
				/>
			</div>
		</Modal>
	);
}

export default AppUpdateModal;
