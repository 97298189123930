import React, { useEffect } from 'react';
import clsx from 'clsx';
import { IBookmarkNudgeProps } from './BookmarkNudge.types';
import classes from './BookmarkNudge.styles.module.scss';
import Text from '@components/ui/Text';
import Button from '@components/ui/Button';
import mixpanelActions from '@utils/mixpanel';
import { mixPanelEvents } from '@utils/constants';
import { bookmarkChat } from '@api/chats';
import useFetch from '@hooks/useFetch';
import { IBookmarkChatPayload } from '@api/chats/chats.types';
import { setBookmarkChatActionCount, setCurrBookmarkChatsMap } from '@store/inboxSlice/inboxSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/index';

function BookmarkNudge({
	customClassName,
	chatBookmarked,
	currUserId,
	selectedChatId,
	selectedChatUserId,
	senderUsername,
	onCancel,
	onSuccess,
}: IBookmarkNudgeProps) {
	const dispatch = useDispatch<AppDispatch>();
	const containerRef = React.useRef<HTMLDivElement | null>(null);
	const { callApi: callBookmarkChat, status: callBookmarkChatStatus } = useFetch(bookmarkChat);

	const handleCancelBookmarkClick = () => {
		onCancel && onCancel();
	};

	const handleBookmarkClick = async () => {
		if (!(selectedChatUserId && currUserId && selectedChatId)) return;
		if (callBookmarkChatStatus === 'loading') return;

		const payload: IBookmarkChatPayload = {
			chat_id: selectedChatId,
			sender_id: selectedChatUserId,
			user_id: currUserId,
			bookmark: !chatBookmarked,
		};

		try {
			await callBookmarkChat(payload);
			// handling bookmark chat state in redux
			dispatch(
				setBookmarkChatActionCount({
					chatBookmarked: payload.bookmark,
					chatId: payload.chat_id,
				})
			);
			dispatch(
				setCurrBookmarkChatsMap({ chatId: payload.chat_id, chatBookmarked: payload.bookmark })
			);

			if (payload.bookmark) {
				mixpanelActions.trackChatBookmark(
					mixPanelEvents.CHAT_ADDED_TO_BOOKMARK,
					senderUsername ?? '',
					'MESSAGES'
				);
			} else {
				mixpanelActions.trackChatBookmark(
					mixPanelEvents.CHAT_REMOVED_FROM_BOOKMARK,
					senderUsername ?? '',
					'MESSAGES'
				);
			}
			onSuccess && onSuccess();
		} catch (error) {
			// handle error
		}
	};

	useEffect(() => {
		const ele = containerRef.current;
		let timerId: NodeJS.Timeout | undefined = undefined;

		if (!ele) return;

		timerId = setTimeout(() => {
			ele.classList.add(classes.displayBookmarkNudgeContainer);
			ele.scrollIntoView({ behavior: 'instant' });
		}, 500);

		return () => {
			clearTimeout(timerId);
		};
	}, [chatBookmarked]);

	if (chatBookmarked) {
		return (
			<Text
				variant="p"
				semiBold
				tiny
				lineHeight={1.6}
				customClass={clsx(classes.bookmarkSuccessContainer, !!customClassName && customClassName)}
			>
				{'Added to Bookmarks'}
			</Text>
		);
	}

	return (
		<div
			className={clsx(classes.bookmarkNudgeContainer, !!customClassName && customClassName)}
			ref={containerRef}
		>
			<Text variant="h3" semiBold lineHeight={2.2}>
				{'Bookmark this chat?'}
			</Text>
			<Text variant="p" secondary lineHeight={1.6} light tiny>
				{'Quickly access this chat later.'}
			</Text>
			<div className={classes.bookmarkNudgeButtonContainer}>
				<Button
					onClick={handleCancelBookmarkClick}
					btnText={
						<Text variant="span" small semiBold lineHeight={2} color={'#422D07'}>
							{'No thanks'}
						</Text>
					}
					customClass={classes.cancelButton}
				/>
				<Button
					onClick={handleBookmarkClick}
					btnText={
						<Text variant="span" small semiBold lineHeight={2} white>
							{'Yes'}
						</Text>
					}
					customClass={classes.bookmarkButton}
				/>
			</div>
		</div>
	);
}

export default BookmarkNudge;
