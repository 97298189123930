import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { IUserDetailsState } from './userDetails.types';

const initialState: IUserDetailsState = {
	data: null,
	draftData: null,
	otherAccounts: [],
	onboardingInputsState: null,
	appUpdateRequired: false,
	displayVerifyLinkedInModal: false,
	discoveryListObj: null,
	homePageDiscoveryList: null,
};

const userDetailsSlice = createSlice({
	name: 'userDetails',
	initialState: initialState,
	reducers: {
		setUserData(state, action) {
			state.data = action.payload.userData;
		},
		setUserDraftData(state, action) {
			state.draftData = {
				...action.payload.userDraftData,
				draftJWTToken: action.payload.draftJWTToken,
			};
		},
		setUserOtherAccounts(state, action) {
			state.otherAccounts = action.payload.otherAccounts;
		},
		setLinkedInStatus(state, action) {
			if (state.data) {
				state.data.linkedInVerificationStatus = action.payload.linkedInVerificationStatus;
			}
		},
		setUserSettings(state, action) {
			if (state.data) {
				state.data.inboxCategories = action.payload.inboxCategories;
				state.data.preferences = action.payload.preferences;
			}
		},
		setUserCannedResponses(state, action) {
			if (state.data) {
				state.data.cannedResponses = [...action.payload.cannedResponses];
			}
		},
		setUserPersonalLabels(state, action) {
			if (state.data) {
				state.data.personalLabels = action.payload.personalLabels;
			}
		},
		setEnableMinMaxCharCount(state, action) {
			if (state.data) {
				state.data.minCharCountEnabled = action.payload.minCharCountEnabled;
				state.data.maxCharCountEnabled = action.payload.maxCharCountEnabled;
			}
		},
		setSwipeEducationDone(state) {
			if (state.data) {
				state.data.swipeEducationDone = true;
			}
		},
		setUserDeviceInformation(state, action) {
			if (state.data) {
				const updatedDevicesInfo = action.payload.updatedDevicesInfo;
				state.data.devicesInfo = [...updatedDevicesInfo];
			}
		},
		// TODO - do not store the pdf file in the state as it is not serializable
		setOnboardingInputsState(state, action) {
			state.onboardingInputsState = {
				selectedCategory: action.payload.selectedCategory,
				message: action.payload.message,
				selectedPdfFile: action.payload.selectedPdfFile,
			};
		},
		setAppUpdateRequired(state, action) {
			state.appUpdateRequired = action.payload.appUpdateRequired;
		},
		setDisplayVerifyLinkedInModal(state, action) {
			state.displayVerifyLinkedInModal = action.payload.displayVerifyLinkedInModal;
		},
		setDiscoveryListObj(state, action) {
			state.discoveryListObj = action.payload.discoveryListObj;
		},
		setHomePageDiscoveryList(state, action) {
			state.homePageDiscoveryList = action.payload.homePageDiscoveryList;
		},
		clearDraftData(state) {
			state.draftData = null;
		},
		clearOnboardingInputsState(state) {
			state.onboardingInputsState = null;
		},
		clearUserDetails() {
			return initialState;
		},
		setSecondaryUsers(state, action) {
			if (state.data) {
				state.data.secondaryUsers = [...action.payload.secondaryUsers];
			}
		},
	},
});

export const {
	setLinkedInStatus,
	setUserDraftData,
	setUserOtherAccounts,
	setUserData,
	setUserSettings,
	setUserCannedResponses,
	setUserPersonalLabels,
	setEnableMinMaxCharCount,
	setAppUpdateRequired,
	setOnboardingInputsState,
	setSwipeEducationDone,
	setUserDeviceInformation,
	setDisplayVerifyLinkedInModal,
	setDiscoveryListObj,
	setHomePageDiscoveryList,
	clearOnboardingInputsState,
	clearUserDetails,
	clearDraftData,
	setSecondaryUsers,
} = userDetailsSlice.actions;

export const getUserDetailsFromGlobalState = (state: RootState) => state.userDetails;

export default userDetailsSlice.reducer;
