/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFullNameSenderProfileFunc } from '@src/models/inbox';
import {
	fetchPaginatedBulkReplyInboxData,
	getInboxDataFromGlobalState,
} from '@store/inboxSlice/inboxSlice';
import { AppDispatch } from '@store/index';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import Button from '@components/ui/Button';
import Text from '@components/ui/Text';
import Avatar from '@components/ui/Avatar';
import FallbackPicIcon from '@public/icons/fallbackPicIcon.svg';
import HeaderContainer from '../HeaderContainer';
import ActionsContainer from '../ActionsContainer';
import { IChatsListViewProps } from './ChatsListView.types';
import classes from './ChatsListView.styles.module.scss';
import clsx from 'clsx';
import { InfoIconColored, TickIconColored } from '@src/hoc/withIconStyles';
import mixpanelActions from '@utils/mixpanel';
import { mixPanelEvents } from '@utils/constants';

function ChatsListView({
	onClickCloseIcon,
	onClickNext,
	isSelectAll,
	setIsSelectAll,
	chatsToIncludeOrExclude,
	setChatsToIncludeOrExclude,
	chatsDataToDisplay,
	chatsDataTotalCount,
}: IChatsListViewProps) {
	const { bulkReplyPaginationDetails } = useSelector(getInboxDataFromGlobalState);
	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);

	const dispatch = useDispatch<AppDispatch>();
	const loadingRef = useRef<HTMLDivElement | null>(null);
	const headerRef = useRef<HTMLDivElement | null>(null);
	const chatsListRef = useRef<HTMLDivElement | null>(null);

	const isLoadingPaginatedData = bulkReplyPaginationDetails?.hasMoreChats ?? false;
	const headerTextToDisplay = isSelectAll
		? `${chatsDataTotalCount - chatsToIncludeOrExclude.length} selected`
		: `${chatsToIncludeOrExclude.length} selected`;
	const isNextButtonDisabled = isSelectAll
		? chatsToIncludeOrExclude.length === chatsDataTotalCount
		: !chatsToIncludeOrExclude.length;

	const handleNextClick = () => {
		mixpanelActions.trackBulkReply(mixPanelEvents.BULK_REPLY_NEXT_CLICKED);

		onClickNext();
	};

	const handleCloseIconClick = () => {
		onClickCloseIcon();
	};

	const handleChatSelect = (chatId: number | null) => {
		if (!chatId) return;

		const isChatExist = chatsToIncludeOrExclude.includes(chatId);

		const updatedChatsToIncludeOrExclude = isChatExist
			? chatsToIncludeOrExclude.filter((id) => id !== chatId)
			: [...chatsToIncludeOrExclude, chatId];

		setChatsToIncludeOrExclude(updatedChatsToIncludeOrExclude);

		if (updatedChatsToIncludeOrExclude.length === chatsDataTotalCount) {
			handleSelectClick(!isSelectAll);
		}
	};

	const handleSelectClick = (selectAll: boolean) => {
		setIsSelectAll(selectAll);
		setChatsToIncludeOrExclude([]);
	};

	const handleChatsListScroll = () => {
		const chatsListEle = chatsListRef.current;
		const headerEle = headerRef.current;

		if (!chatsListEle || !headerEle) return;

		if (chatsListEle.scrollTop > 0) {
			headerEle.classList.add(classes.headerBottomBorder);
		} else {
			headerEle.classList.remove(classes.headerBottomBorder);
		}
	};

	useEffect(() => {
		const obsElement = loadingRef.current;

		if (!obsElement || !chatsDataToDisplay) return;

		const obsOptions = {
			root: null,
			threshold: 0.5,
		};

		const obsCallback = (entries: IntersectionObserverEntry[]) => {
			const [entry] = entries;

			if (entry.isIntersecting) {
				dispatch(
					fetchPaginatedBulkReplyInboxData({
						lastFiveMessagesRequired: false,
						topPicksThreshold: userDetails?.topPicksThreshold,
					})
				);
			}
		};

		const observer = new IntersectionObserver(obsCallback, obsOptions);
		observer.observe(obsElement);

		return () => {
			if (!obsElement) return;
			observer.unobserve(obsElement);
		};
	}, [chatsDataToDisplay]);

	useEffect(() => {
		mixpanelActions.trackBulkReply(mixPanelEvents.BULK_REPLY_STEP_ONE_SHOWN);
	}, []);

	return (
		<>
			<HeaderContainer
				headerText={headerTextToDisplay}
				onCloseIconClick={handleCloseIconClick}
				rightActions={
					<Button
						btnText={
							<Text variant="span" small lineHeight={2} bold brandPrimaryColor>
								{isSelectAll ? 'Unselect all' : 'Select all'}
							</Text>
						}
						onClick={() => {
							handleSelectClick(!isSelectAll);
						}}
						customClass={classes.selectAllButton}
					/>
				}
				ref={headerRef}
			/>
			<div
				className={classes.chatsListContainer}
				onScroll={handleChatsListScroll}
				ref={chatsListRef}
			>
				<div className={classes.infoContainer}>
					<InfoIconColored size={1.8} />
					<Text variant={'p'} fontSize={1.3} lineHeight={1.8} color={'#D9730B'}>
						{'Only chats you have never replied to are eligible for bulk replies.'}
					</Text>
				</div>
				{chatsDataToDisplay.map((chatData) => {
					const { senderDetails, chatId, lastMessage } = chatData;

					const senderProfileData = senderDetails?.profileData;
					const profilePicURL = senderProfileData?.profilePicture;
					const profileName = senderProfileData
						? getFullNameSenderProfileFunc(senderProfileData)
						: '';
					const profileHeadLine = senderProfileData?.headline ?? '';

					const lastMessageContent = lastMessage?.content ?? '';

					const isChatSelected = isSelectAll
						? !chatsToIncludeOrExclude.includes(chatId ?? -1)
						: chatsToIncludeOrExclude.includes(chatId ?? -1);

					return (
						<div
							className={classes.chatWithSelectOption}
							key={chatId}
							tabIndex={0}
							onClick={() => handleChatSelect(chatId)}
						>
							<Avatar
								size={5.2}
								profilePicURL={profilePicURL}
								fallbackIcon={FallbackPicIcon}
								customRootClass={classes.imageContainer}
							/>
							<div className={classes.profileInfoContainer}>
								<Text variant="h3" lineHeight={2}>
									{profileName}
								</Text>
								<Text variant="p" light small>
									{profileHeadLine}
								</Text>
								{lastMessageContent && (
									<Text variant="p" light small secondary>
										{lastMessageContent}
									</Text>
								)}
							</div>
							<div className={classes.actionContainer}>
								<div className={clsx(classes.checkbox, isChatSelected && classes.checkboxActive)}>
									{isChatSelected && <TickIconColored size={1.6} />}
								</div>
							</div>
						</div>
					);
				})}
				<div aria-hidden ref={loadingRef} className={classes.loadingContainer}>
					{isLoadingPaginatedData && (
						<Text variant="p" ultraLight small secondary>
							{'Loading DMs...'}
						</Text>
					)}
				</div>
			</div>
			<ActionsContainer
				buttonsArr={[
					<Button
						btnText={
							<Text
								variant={'span'}
								tertiary={isNextButtonDisabled}
								lineHeight={2.2}
								semiBold
								white={!isNextButtonDisabled}
							>
								{'Next'}
							</Text>
						}
						primary
						onClick={handleNextClick}
						key={'Next'}
						disabled={isNextButtonDisabled}
					/>,
				]}
				currModalState={'LIST_VIEW'}
			/>
		</>
	);
}

export default ChatsListView;
