import React from 'react';
import clsx from 'clsx';
import Text from '@components/ui/Text';
import { ILinkedInMiniInfo } from './LinkedInMiniInfo.types';
import Avatar from '@components/ui/Avatar';
import classes from './LinkedInMiniInfo.styles.module.scss';
import useWindowSize from '@hooks/useWindow';
import FallbackPicIcon from '@public/icons/fallbackPicIcon.svg';

function LinkedInMiniInfo({
	fullName,
	headline,
	profilePicURL,
	customClass,
	location,
}: ILinkedInMiniInfo) {
	const { isMobile } = useWindowSize();

	return (
		<article className={clsx(classes.profileCard, customClass && customClass)}>
			<Avatar
				profilePicURL={profilePicURL}
				size={5.2}
				customRootClass={classes.image}
				fallbackIcon={FallbackPicIcon}
			/>
			<Text
				variant="h2"
				semiBold
				fontSize={isMobile ? 1.4 : 1.6}
				lineHeight={isMobile ? 1.8 : 2.2}
				customClass={classes.name}
			>
				{fullName}
			</Text>
			<Text
				variant="p"
				light
				fontSize={isMobile ? 1.2 : 1.4}
				lineHeight={isMobile ? 1.6 : 2}
				customClass={classes.designation}
			>
				{headline}
			</Text>
			<Text
				variant="p"
				light
				secondary
				fontSize={isMobile ? 1.2 : 1.4}
				lineHeight={isMobile ? 1.6 : 2}
				customClass={classes.location}
			>
				{location}
			</Text>
		</article>
	);
}

export default LinkedInMiniInfo;
