import React from 'react';
import classes from './DeleteView.styles.module.scss';
import { IDeleteViewProps } from './DeleteView.types';
import Text from '@components/ui/Text';
import Button from '@components/ui/Button';

function DeleteView({ onClickBack, onClickDelete, isLoading }: IDeleteViewProps) {
	const handleBackClick = () => {
		if (isLoading) return;

		onClickBack();
	};

	const handleDeleteClick = () => {
		if (isLoading) return;

		onClickDelete();
	};

	return (
		<div className={classes.deleteViewContainer}>
			<Text variant="h1" semiBold medium lineHeight={2.4}>
				{'Are you sure?'}
			</Text>
			<Text variant="p" small lineHeight={2} secondary light>
				{'This quick-reply will permanently be deleted.'}
			</Text>
			<div className={classes.buttonsContainer}>
				<Button
					btnText={
						<Text variant="span" brandPrimaryColor semiBold>
							{'Back'}
						</Text>
					}
					onClick={handleBackClick}
					customClass={classes.backBtn}
				/>
				<Button
					btnText={
						<Text variant="span" white semiBold>
							{'Yes, delete'}
						</Text>
					}
					primary
					onClick={handleDeleteClick}
					customClass={classes.deleteBtn}
					isLoading={isLoading}
				/>
			</div>
		</div>
	);
}

export default DeleteView;
