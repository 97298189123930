import React, { useMemo, useRef } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { AlertIcon, LabelIcon } from '@src/hoc/withIconStyles';
import classes from './Label.styles.module.scss';
import useWindowSize from '@hooks/useWindow';
import ListView from './ListView/ListView';
import { useDispatch, useSelector } from 'react-redux';
import {
	getUserDetailsFromGlobalState,
	setUserPersonalLabels,
} from '@store/userDetailsSlice/userDetailsSlice';
import { TAddOrDeleteState, TLabelModalState } from './Label.types';
import Modal from '@components/ui/Modal';
import Button from '@components/ui/Button';
import AddView from './AddView';
import useFetch from '@hooks/useFetch';
import { editUserPersonalLabels } from '@api/user';
import { IRefProps } from '@components/ui/Toast/Toast.types';
import Toast from '@components/ui/Toast';
import { AppDispatch } from '@store/index';
import { getSelectedChatDetailsFromGlobalState } from '@store/selectedChatSlice/selectedChatSlice';
import { assignLabelToChat } from '@api/chats';
import { IAssignChatToLabelPayload } from '@api/chats/chats.types';
import {
	getInboxDataFromGlobalState,
	setCurrAssignedLabelsChatsMap,
} from '@store/inboxSlice/inboxSlice';
import mixpanelActions from '@utils/mixpanel';
import { mixPanelEvents } from '@utils/constants';

function Label() {
	const { isMobile } = useWindowSize();

	const dispatch = useDispatch<AppDispatch>();

	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);
	const { selectedChat } = useSelector(getSelectedChatDetailsFromGlobalState);
	const { currAssignedLabelsChatsMap } = useSelector(getInboxDataFromGlobalState);

	const personalLabels = useMemo(() => {
		return userDetails?.personalLabels ?? [];
	}, [userDetails]);

	const assignedChatLabels = useMemo(() => {
		const chatId = selectedChat?.chatId;

		if (!!chatId && !!currAssignedLabelsChatsMap[chatId]) {
			return currAssignedLabelsChatsMap[chatId];
		}

		return selectedChat?.personalLabels ?? [];
	}, [selectedChat, currAssignedLabelsChatsMap]);

	const [toastError, setToastError] = React.useState('');
	const toastRef = useRef<IRefProps>(null);

	const [open, setOpen] = React.useState(false);
	const [showPopover, setShowPopover] = React.useState(!isMobile && personalLabels.length > 0);
	const [openModal, setOpenModal] = React.useState(false);
	const [modalState, setModalState] = React.useState<TLabelModalState>(
		personalLabels.length > 0 ? 'LIST_VIEW' : 'ADD_VIEW'
	);

	const { callApi: callEditUserPersonalLabels, status: callEditUserPersonalLabelsStatus } =
		useFetch(editUserPersonalLabels);
	const { callApi: callAssignLabelToChat, status: callAssignLabelToChatStatus } =
		useFetch(assignLabelToChat);

	const userId = userDetails?.userId;
	const chatId = selectedChat?.chatId;
	const isLoading =
		callEditUserPersonalLabelsStatus === 'loading' || callAssignLabelToChatStatus === 'loading';

	const handleOnClickAddLabelBtn = () => {
		if (isLoading) return;

		mixpanelActions.trackLabel(mixPanelEvents.ADD_NEW_LABEL_CLICKED);

		setOpenModal(true);
		setModalState('ADD_VIEW');
		setShowPopover(false);
		setOpen(false);
	};

	const handleCloseModal = () => {
		if (isLoading) return;

		if (personalLabels.length > 0) {
			if (!isMobile) {
				setOpenModal(false);
				setShowPopover(true);
				setOpen(true);
				setModalState('LIST_VIEW');
			} else if (modalState === 'ADD_VIEW') {
				setModalState('LIST_VIEW');
			} else {
				setOpenModal(false);
				setShowPopover(false);
				setOpen(false);
			}
		} else {
			setModalState('ADD_VIEW');
			setOpenModal(false);
			setShowPopover(false);
			setOpen(false);
		}
	};

	const handleAddViewBackClick = () => {
		if (isLoading) return;

		handleCloseModal();
	};

	const handleAddViewCloseClick = () => {
		if (isLoading) return;

		handleCloseModal();
	};

	const handleAddOrEditPersonalLabels = async (
		personalLabelsToEdit: string[],
		state: TAddOrDeleteState,
		onSuccess: () => void
	) => {
		if (!userId || isLoading) return;

		toastRef.current?.unPublish();

		try {
			const resData = await callEditUserPersonalLabels(userId, personalLabelsToEdit);

			dispatch(setUserPersonalLabels({ personalLabels: resData }));

			onSuccess();

			if (state === 'ADD') {
				mixpanelActions.trackLabel(mixPanelEvents.NEW_LABEL_ADDED);
				return;
			}

			if (state === 'DELETE') {
				mixpanelActions.trackLabel(mixPanelEvents.LABEL_DELETED);
				return;
			}
		} catch (error) {
			setToastError(error);
			toastRef.current?.publish();
		}
	};

	const handleAssignChatToLabel = async (labelName: string, isSelected: boolean) => {
		if (!userId || isLoading || !chatId) return;

		toastRef.current?.unPublish();

		let updatedChatLabels = [];

		if (isSelected) {
			updatedChatLabels = [...assignedChatLabels, labelName].filter((label) =>
				personalLabels.includes(label)
			);
		} else {
			updatedChatLabels = assignedChatLabels.filter(
				(label) => label !== labelName && personalLabels.includes(label)
			);
		}

		try {
			const payload: IAssignChatToLabelPayload = {
				chat_id: chatId,
				user_id: userId,
				personal_tags: updatedChatLabels,
			};

			await callAssignLabelToChat(payload);

			dispatch(setCurrAssignedLabelsChatsMap({ chatId, assignedLabels: updatedChatLabels }));

			if (isSelected) {
				mixpanelActions.trackLabel(mixPanelEvents.LABEL_ASSIGNED_TO_CHAT);
			} else {
				mixpanelActions.trackLabel(mixPanelEvents.LABEL_UNASSIGNED_FROM_CHAT);
			}
		} catch (error) {
			setToastError(error);
			toastRef.current?.publish();
		}
	};

	const trackLabelIconClicked = () => {
		mixpanelActions.trackLabel(mixPanelEvents.LABEL_ICON_CLICKED);
	};

	return (
		<>
			{showPopover && (
				<Popover.Root open={open} onOpenChange={setOpen}>
					<Popover.Trigger asChild>
						<button
							className={classes.labelBtn}
							onClick={(e) => {
								trackLabelIconClicked();
								e.stopPropagation();
							}}
						>
							<LabelIcon size={isMobile ? 2.4 : 2} className={classes.labelIcon} />
						</button>
					</Popover.Trigger>
					<Popover.Portal>
						<Popover.Content align="end" side="bottom" className={classes.labelsPopoverContent}>
							<ListView
								onClickLabel={handleAssignChatToLabel}
								personalLabels={personalLabels}
								onClickAddLabelBtn={handleOnClickAddLabelBtn}
								assignedChatLabels={assignedChatLabels}
								isLoading={isLoading}
							/>
						</Popover.Content>
					</Popover.Portal>
				</Popover.Root>
			)}

			{!showPopover && (
				<Button
					className={classes.labelBtn}
					onClick={() => {
						trackLabelIconClicked();
						setOpenModal(true);
					}}
					btnText={<LabelIcon size={isMobile ? 2.4 : 2} className={classes.labelIcon} />}
				/>
			)}

			<Modal
				onCloseModal={handleCloseModal}
				showModal={openModal}
				bottomInMobile
				noPadding
				customClass={classes.modalContainer}
			>
				{modalState === 'LIST_VIEW' && (
					<ListView
						onClickLabel={handleAssignChatToLabel}
						personalLabels={personalLabels}
						onClickAddLabelBtn={handleOnClickAddLabelBtn}
						assignedChatLabels={assignedChatLabels}
						isLoading={isLoading}
					/>
				)}
				{modalState === 'ADD_VIEW' && (
					<AddView
						onClickBackBtn={handleAddViewBackClick}
						onClickCancelBtn={handleAddViewCloseClick}
						onClickAddOrDeleteBtn={handleAddOrEditPersonalLabels}
						personalLabels={personalLabels}
						isLoading={isLoading}
					/>
				)}
			</Modal>

			<Toast
				ref={toastRef}
				toastType={'ERROR'}
				header={toastError}
				icon={<AlertIcon size={1.8} className={classes.toastIcon} />}
			/>
		</>
	);
}

export default Label;
