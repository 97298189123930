import React, { useEffect } from 'react';
import Button from '@components/ui/Button';
import Text from '@components/ui/Text';
import Loader from '@components/ui/Loader';
import { ErrorIconV2 } from '@src/hoc/withIconStyles';
import Lottie from 'react-lottie';
import animationData from '@src/lottie/success.json';
import { IReplyStatusViewProps } from './ReplyStatusView.types';
import classes from './ReplyStatusView.styles.module.scss';
import mixpanelActions from '@utils/mixpanel';
import { mixPanelEvents } from '@utils/constants';

function ReplyStatusView({
	isError,
	isLoading,
	isSuccess,
	onClickDone,
	chatsToIncludeOrExclude,
	isSelectAll,
	chatsDataTotalCount,
}: IReplyStatusViewProps) {
	const selectedChatsCount = isSelectAll
		? chatsDataTotalCount - chatsToIncludeOrExclude.length
		: chatsToIncludeOrExclude.length;

	const selectedChatsCountText =
		selectedChatsCount === 1 ? '1 reply' : `${selectedChatsCount} replies`;

	const handleDoneClick = () => {
		mixpanelActions.trackBulkReply(mixPanelEvents.BULK_REPLY_DONE_CLICKED);
		onClickDone();
	};

	const textToDisplay = isSuccess
		? `${selectedChatsCountText} sent.`
		: isLoading
		? 'Sending replies...'
		: isError
		? 'Sending Failed.'
		: '';

	const lottieDefaultOptions = {
		loop: false,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	useEffect(() => {
		if (isLoading) {
			mixpanelActions.trackBulkReply(mixPanelEvents.BULK_REPLY_LOADING_SHOWN);
			return;
		}

		if (isSuccess) {
			mixpanelActions.trackBulkReply(mixPanelEvents.BULK_REPLY_SUCCESS_SHOWN);
			return;
		}

		if (isError) {
			mixpanelActions.trackBulkReply(mixPanelEvents.BULK_REPLY_ERROR_SHOWN);
			return;
		}
	}, [isError, isLoading, isSuccess]);

	return (
		<>
			<div className={classes.replyStatusViewContainer}>
				<div className={classes.iconContainer}>
					{isLoading && <Loader size={7.6} customBorderWidth={6} />}
					{isError && <ErrorIconV2 size={9.6} />}
					{isSuccess && <Lottie options={lottieDefaultOptions} height={76} width={76} />}
				</div>

				<Text variant={'h3'} semiBold medium>
					{textToDisplay}
				</Text>
			</div>
			<Button
				btnText={
					<Text variant={'span'} tertiary={isLoading} lineHeight={2.2} semiBold white={!isLoading}>
						{'Done'}
					</Text>
				}
				primary
				onClick={handleDoneClick}
				key={'Next'}
				disabled={isLoading}
				customClass={classes.doneBtn}
			/>
		</>
	);
}

export default ReplyStatusView;
