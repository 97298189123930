import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import classes from './EmailSignifier.styles.module.scss';
import Text from '@components/ui/Text';
import { IEmailSignifierProps } from './EmailSignifier.types';
import { EyeIcon } from '@src/hoc/withIconStyles';
import Modal from '@components/ui/Modal';
import { EmailSignifier } from '@src/models/message';
import Button from '@components/ui/Button';
import { getDayMonthFromUTC } from '@utils/date';

function EmailSignifier({
	emailSignifierList,
	userId,
	senderFirstName,
	customClassName,
	inSwipeCard,
}: IEmailSignifierProps) {
	const [selectedEmailSignifier, setSelectedEmailSignifier] = useState<EmailSignifier | null>(null);

	const displayEmailSignifier = useMemo(() => {
		if (!emailSignifierList) return false;

		let canDisplay = false;

		emailSignifierList.forEach((emailSignifier) => {
			if (emailSignifier.forwardBy === userId) {
				canDisplay = true;
			}

			if (emailSignifier.forwardBy !== userId && emailSignifier.senderIncluded) {
				canDisplay = true;
			}
		});

		return canDisplay;
	}, [emailSignifierList, userId]);

	const handleEmailSignifierClick = (emailSignifier: EmailSignifier) => {
		setSelectedEmailSignifier(emailSignifier);
	};

	const handleCloseModal = () => {
		setSelectedEmailSignifier(null);
	};

	if (!displayEmailSignifier || !emailSignifierList?.length) return null;

	return (
		<div className={clsx(classes.emailSignifierMainContainer, customClassName && customClassName)}>
			{emailSignifierList.map((emailSignifier, index) => {
				const isSameUserForwarded = emailSignifier.forwardBy === userId;
				const isSenderIncluded = emailSignifier.senderIncluded;

				const textToDisplay = isSameUserForwarded
					? isSenderIncluded
						? 'You sent a reply via email. Tap to see more.'
						: 'You forwarded this chat to email. Tap to see more.'
					: `${senderFirstName} sent a reply via email. Tap to see more.`;

				if (!isSenderIncluded && !isSameUserForwarded) return null;

				return (
					<div
						className={clsx(
							classes.emailSignifierContainer,
							inSwipeCard && classes.emailSignifierContainerInSwipeCard
						)}
						key={index}
						tabIndex={0}
						onClick={(e) => {
							e.stopPropagation();
							handleEmailSignifierClick(emailSignifier);
						}}
					>
						<Text variant="p" semiBold tiny lineHeight={1.6} color={'#422D07'}>
							{textToDisplay}
						</Text>
						{!isSenderIncluded && isSameUserForwarded && (
							<div className={classes.onlyVisible}>
								<EyeIcon size={1.6} />
								<Text variant="span" fontSize={1.1} lineHeight={1.6} color={'#7E6E4F'}>
									{'Only visible to you'}
								</Text>
							</div>
						)}
					</div>
				);
			})}
			{!!selectedEmailSignifier && (
				<Modal noPadding bottomInMobile showModal={true} onCloseModal={handleCloseModal}>
					<div className={classes.emailSignifierModalContent}>
						<Text variant="h3" medium lineHeight={2.4} semiBold>
							{'Email sent'}
						</Text>
						<Text variant="p" secondary small lineHeight={2} light>{`On ${getDayMonthFromUTC(
							selectedEmailSignifier.forwardedAt
						)}, ${
							selectedEmailSignifier.forwardBy === userId ? 'you' : senderFirstName
						} created an email thread from this chat which was sent to:`}</Text>

						<div className={classes.mailsContainer}>
							{selectedEmailSignifier.emails.map((email, index) => (
								<Text key={index} variant="span" secondary small lineHeight={2} light>
									{email}
								</Text>
							))}
						</div>
						<Button
							btnText={
								<Text variant="span" semiBold white lineHeight={2}>
									{'Got it'}
								</Text>
							}
							primary
							onClick={handleCloseModal}
							customClass={classes.closeButton}
						/>
					</div>
				</Modal>
			)}
		</div>
	);
}

export default EmailSignifier;
