import React, { useEffect, useMemo } from 'react';
import classes from './CannedResponsesHorizontalView.styles.module.scss';
import { useSelector } from 'react-redux';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import Text from '@components/ui/Text';
import Button from '@components/ui/Button';
import { PlusIconV2, ThumpsUpIconColored } from '@src/hoc/withIconStyles';
import { LIKE_REACTION_TEXT, cannedResponseReplaceWords } from '@utils/constants';
import { ICannedResponseHorizontalViewProps } from './CannedResponsesHorizontalView.types';
import CannedResponseMobile from '@components/CannedResponseMobile';
import { IUserCannedResponse } from '@api/user/user.types';
import clsx from 'clsx';
import useWindowSize from '@hooks/useWindow';
import CannedResponseModal from '@components/Chats/ChatsSection/CannedResponseModal';

function CannedResponsesHorizontalView({
	isChatBookmarked,
	onSendBtnClick,
	onSelectCannedResponse,
	senderFirstName,
	maxCharLimit,
	minCharLimit,
	onTouchEnd,
	onTouchStart,
	customClassName,
}: ICannedResponseHorizontalViewProps) {
	const { isMobile } = useWindowSize();
	const [openModal, setOpenModal] = React.useState(false);
	const [selectedCannedResponse, setSelectedCannedResponse] =
		React.useState<IUserCannedResponse | null>(null);
	const cannedResponseContainerRef = React.useRef<HTMLDivElement>(null);

	const { data: userDetails } = useSelector(getUserDetailsFromGlobalState);

	const cannedResponses = useMemo(() => {
		return userDetails?.cannedResponses ?? [];
	}, [userDetails]);

	const addQuickReplyText =
		cannedResponses.length === 0 ? 'Add your most common replies' : 'Add or edit quick replies';

	const openCannedResponseMobile = openModal && isMobile;
	const openCannedResponseDesktop = openModal && !isMobile;

	const handleOnClickCannedResponse = (cannedResponse: IUserCannedResponse) => {
		onSelectCannedResponse && onSelectCannedResponse(cannedResponse);

		if (isMobile) {
			setSelectedCannedResponse(cannedResponse);
			setOpenModal(true);
		} else {
			// handle canned response click
			const cannedResponseText = cannedResponse.text.replace(
				cannedResponseReplaceWords.firstName,
				senderFirstName
			);
			onSendBtnClick(cannedResponseText, !!cannedResponse.bookmark_when_used);
		}
	};

	const handleAddCannedResponse = () => {
		if (isMobile) {
			setSelectedCannedResponse(null);
			setOpenModal(true);
		} else {
			setOpenModal(true);
		}
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const handleTouchStart = () => {
		onTouchStart && onTouchStart();
	};

	const handleTouchEnd = () => {
		onTouchEnd && onTouchEnd();
	};

	useEffect(() => {
		const ele = cannedResponseContainerRef.current;

		if (!ele) return;

		const parentEle = ele.parentElement;

		const parentEleWidth = parentEle?.clientWidth ?? 0;

		ele.style.width = `${parentEleWidth - 32}px`;

		if (ele.scrollWidth > ele.clientWidth || !isMobile) {
			ele.classList.remove(classes.justifyContentEnd);
		} else {
			ele.classList.add(classes.justifyContentEnd);
		}

		ele.scrollTo({ left: 0, behavior: 'instant' });
	}, [cannedResponses, isMobile]);

	return (
		<div
			className={clsx(classes.horizontalViewContainer, !!customClassName && customClassName)}
			ref={cannedResponseContainerRef}
			onTouchStart={handleTouchStart}
			onTouchEnd={handleTouchEnd}
		>
			<Button
				btnText={<ThumpsUpIconColored size={1.4} />}
				onClick={() => onSendBtnClick(LIKE_REACTION_TEXT, false)}
				customClass={classes.likeCannedResponse}
			/>
			{cannedResponses.map((cannedResponse) => {
				return (
					<Button
						key={cannedResponse.name}
						customClass={classes.cannedResponse}
						btnText={
							<Text variant="p" brandPrimaryColor lineHeight={1.6} tiny>
								{cannedResponse.name}
							</Text>
						}
						onClick={() => handleOnClickCannedResponse(cannedResponse)}
					/>
				);
			})}
			<Button
				prefixIcon={<PlusIconV2 size={1} className={classes.cannedResponseAddIcon} />}
				btnText={
					<Text variant="p" brandPrimaryColor lineHeight={1.6} tiny>
						{addQuickReplyText}
					</Text>
				}
				onClick={handleAddCannedResponse}
				customClass={classes.addCannedResponseBtn}
			/>
			{openCannedResponseMobile && (
				<CannedResponseMobile
					horizontalMode={true}
					onCloseModal={handleCloseModal}
					isChatBookmarked={isChatBookmarked}
					onSendBtnClick={onSendBtnClick}
					senderFirstName={senderFirstName}
					initialSelectedCannedResponse={selectedCannedResponse}
					minCharLimit={minCharLimit}
					maxCharLimit={maxCharLimit}
					onSelectCannedResponse={onSelectCannedResponse}
				/>
			)}
			{openCannedResponseDesktop && (
				<CannedResponseModal
					cannedResponseData={cannedResponses}
					onCloseModal={handleCloseModal}
					showModal={openCannedResponseDesktop}
					userId={userDetails?.userId}
				/>
			)}
		</div>
	);
}

export default CannedResponsesHorizontalView;
