import React from 'react';
import clsx from 'clsx';
import Text from '@components/ui/Text';
import { INoResultsFoundProps } from './NoResultsFound.types';
import classes from './NoResultsFound.styles.module.scss';
import Button from '@components/ui/Button';

function NoResultsFound({
	withBorder,
	styles,
	ctaAction,
	ctaActionText,
	heading,
	subText,
	icon: IconToDisplay,
}: INoResultsFoundProps) {
	const classNames = clsx(classes.noResultsContainer, withBorder && classes.withBorder);

	return (
		<section className={classNames} style={styles}>
			{IconToDisplay}
			<Text variant="h1" semiBold medium lineHeight={2.4}>
				{heading}
			</Text>
			{!ctaActionText && !!subText && (
				<Text variant="p" small light secondary lineHeight={2}>
					{subText}
				</Text>
			)}
			{!!ctaActionText && !!ctaAction && (
				<Button
					onClick={ctaAction}
					btnText={
						<Text variant="span" small semiBold brandPrimaryColor lineHeight={2}>
							{ctaActionText}
						</Text>
					}
				/>
			)}
		</section>
	);
}

export default NoResultsFound;
