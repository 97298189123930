import React from 'react';
import classes from './ChatCardUserInfo.styles.module.scss';
import Text from '@components/ui/Text';
import Avatar from '@components/ui/Avatar';
import FallbackPicIcon from '@public/icons/fallbackPicIcon.svg';
import { IChatCardUserInfoProps } from './ChatCardUserInfo.types';

function ChatCardUserInfo({
	profileHeadline,
	profileName,
	profilePicURL,
	renderAction,
	assignedLabels,
}: IChatCardUserInfoProps) {
	const assignedPersonalLabel = assignedLabels?.[0];
	const assignedPersonalLabelsExtraCount =
		assignedLabels && assignedLabels.length > 1 ? assignedLabels.length - 1 : 0;

	return (
		<div className={classes.profileInfoContainer}>
			<Avatar
				size={4.2}
				profilePicURL={profilePicURL}
				fallbackIcon={FallbackPicIcon}
				customRootClass={classes.profileAvatar}
			/>
			<Text
				variant="h3"
				semiBold
				lineHeight={2}
				color={'#24282D'}
				customClass={classes.profileName}
			>
				{profileName}
			</Text>
			<div className={classes.action}>{renderAction()}</div>
			<div className={classes.profileHeadline}>
				<Text variant="p" light fontSize={1.3} lineHeight={1.8}>
					{profileHeadline}
				</Text>
				<Text
					variant="span"
					lineHeight={1.8}
					fontSize={1.2}
					color={'#4A00C4'}
					customClass={classes.labelText}
				>
					{assignedPersonalLabel}
					{assignedPersonalLabelsExtraCount > 0 && (
						<Text
							variant="span"
							lineHeight={1.4}
							fontSize={1.1}
							color={'#4A00C4'}
							customClass={classes.labelCountText}
						>
							{`+${assignedPersonalLabelsExtraCount}`}
						</Text>
					)}
				</Text>
			</div>
		</div>
	);
}

export default ChatCardUserInfo;
