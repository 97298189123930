import React from 'react';
import clsx from 'clsx';
import classes from './ProfileSection.styles.module.scss';
import { useSelector } from 'react-redux';
import { getSelectedChatDetailsFromGlobalState } from '@store/selectedChatSlice/selectedChatSlice';
import Avatar from '@components/ui/Avatar';
import Text from '@components/ui/Text';
import { getMonthsDifference, getTotalYearsMonthText } from '@utils/date';
import Button from '@components/ui/Button';
import FallbackPicIcon from '@public/icons/fallbackPicIcon.svg';
import ExperienceFallbackIcon from '@public/icons/experienceFallbackIcon.svg';
import EducationFallbackIcon from '@public/icons/educationFallbackIcon.svg';
import { useNavigate } from 'react-router-dom';
import { PROFILE_PATH, mixPanelEvents } from '@utils/constants';
import mixpanelActions from '@utils/mixpanel';
import { OpenNewTabIcon, UnverifiedIcon } from '@src/hoc/withIconStyles';
import Tooltip from '@components/ui/Tooltip';
import {
	getFullNameSenderProfileFunc,
	getTimePeriodTextEdFunc,
	getTimePeriodTextExpFunc,
	getTotalExpYearsMonthTextFunc,
	isLinkedInVerificationPendingChatSenderFunc,
} from '@src/models/inbox';

function ProfileSection() {
	const navigate = useNavigate();
	const { selectedChat } = useSelector(getSelectedChatDetailsFromGlobalState);

	const selectedChatUserProfileData = selectedChat?.senderDetails?.profileData;
	const selectedChatLinkedInVerificationPending = selectedChat?.senderDetails
		? isLinkedInVerificationPendingChatSenderFunc(selectedChat.senderDetails)
		: false;

	const experienceMap = selectedChatUserProfileData?.experience ?? {};

	const experienceArrCompanyOrder = selectedChatUserProfileData?.experienceCompanyOrder ?? [];

	const educationArr = selectedChatUserProfileData?.education ?? [];

	const selectedChatUsername = selectedChat?.senderDetails?.username;

	const showViewProfileBtns = !selectedChat?.senderDetails?.isCompanyProfile;

	const handleViewProfileBtnClick = () => {
		mixpanelActions.trackChatInboxCategory(
			mixPanelEvents.INBOX_VIEW_FULL_PROFILE_CLICKED,
			selectedChatUsername ?? '',
			'no'
		);
		navigate(PROFILE_PATH + `/${selectedChat?.senderDetails?.username}`);
	};

	const handleOnClickViewInLinkedIn = () => {
		mixpanelActions.trackChatInboxCategory(
			mixPanelEvents.PROFILE_PANEL_LINKEDIN_CLICKED,
			selectedChatUsername ?? '',
			'no'
		);
		const url = selectedChat?.senderDetails?.profileData?.linkedInURL;

		if (!url) return;

		window.open(url, '__blank');
	};

	// TODO common component for experience or education display

	return (
		<article className={classes.profileSection}>
			<section className={classes.Intro}>
				<Avatar
					size={6.8}
					profilePicURL={selectedChatUserProfileData?.profilePicture}
					fallbackIcon={FallbackPicIcon}
				/>
				<div className={classes.nameWithIcon}>
					<Text variant="h2">
						{selectedChatUserProfileData
							? getFullNameSenderProfileFunc(selectedChatUserProfileData)
							: ''}
					</Text>
					{selectedChatLinkedInVerificationPending && (
						<Tooltip
							content={
								<Text variant="span" small light>
									{`This user's profile was created via their LinkedIn URL. They are yet to verify that the LinkedIn account for that URL belongs to them. `}
								</Text>
							}
						>
							<UnverifiedIcon size={1.8} className={classes.pendingIcon} />
						</Tooltip>
					)}
				</div>

				<Text variant="p" light small>
					{selectedChatUserProfileData?.headline ?? ''}
				</Text>
				<Text variant="p" light tertiary small>
					{selectedChatUserProfileData?.location?.default ?? ''}
				</Text>
			</section>
			{!!experienceArrCompanyOrder.length && (
				<section className={classes.experienceSection}>
					<Text variant="h2" tiny semiBold tertiary>
						{'EXPERIENCE'}
					</Text>
					{experienceArrCompanyOrder.map((companyNameInOrder) => {
						const experience = experienceMap[companyNameInOrder];

						if (!experience) return null;

						const companyExperienceArr = experience.allExperiences;
						const company = companyExperienceArr[0];
						const hasMoreExperiencesInSameCompany = companyExperienceArr.length > 1;

						const totalCompanyExpMonths = companyExperienceArr.reduce((totalMonths, eachExp) => {
							return (
								totalMonths +
								getMonthsDifference(eachExp.startDate ?? null, eachExp.endDate ?? null)
							);
						}, 0);

						const totalYearsMonthText = getTotalYearsMonthText(totalCompanyExpMonths);
						return (
							<div className={classes.experienceInfo} key={companyNameInOrder}>
								<Avatar
									size={4}
									profilePicURL={company.companyImageURL}
									customRootClass={classes.experienceLogo}
									fallbackIcon={ExperienceFallbackIcon}
								/>
								{hasMoreExperiencesInSameCompany && (
									<div className={classes.moreExperienceContent}>
										<div className={classes.experienceContent}>
											<Text variant="h3" small>
												{company.company}
											</Text>
											{experience.showTimePeriod && (
												<Text variant="p" tiny light tertiary>
													{totalYearsMonthText}
												</Text>
											)}
										</div>
										{companyExperienceArr.map((companyExperience) => {
											const yearsMonthsText = getTotalExpYearsMonthTextFunc(companyExperience);
											return (
												<div className={classes.experienceContent} key={companyExperience.title}>
													<Text variant="h3" small>
														{companyExperience.title}
													</Text>
													{companyExperience.employmentType && (
														<Text variant="p" tiny light>
															{companyExperience.employmentType}
														</Text>
													)}
													{yearsMonthsText && (
														<Text
															variant="p"
															tiny
															light
															tertiary
															customClass={clsx(yearsMonthsText && classes.yearsMonthText)}
														>
															{getTimePeriodTextExpFunc(companyExperience)}
															<Text variant="span" tiny light tertiary>
																{yearsMonthsText}
															</Text>
														</Text>
													)}
												</div>
											);
										})}
									</div>
								)}
								{!hasMoreExperiencesInSameCompany && (
									<div className={classes.experienceContent}>
										<Text variant="h3" small>
											{company.title}
										</Text>
										<Text variant="p" tiny light customClass={classes.companyName}>
											{company.company}
											{company.employmentType && (
												<Text variant="span" tiny light>
													{company.employmentType}
												</Text>
											)}
										</Text>
										{getTotalYearsMonthText(company.months) && (
											<Text
												variant="p"
												tiny
												light
												tertiary
												customClass={clsx(
													getTotalYearsMonthText(company.months) && classes.yearsMonthText
												)}
											>
												{getTimePeriodTextExpFunc(company)}
												<Text variant="span" tiny light tertiary>
													{getTotalYearsMonthText(company.months)}
												</Text>
											</Text>
										)}
									</div>
								)}
							</div>
						);
					})}
				</section>
			)}
			{educationArr?.length > 0 && (
				<section className={classes.educationSection}>
					<Text variant="h2" tiny semiBold tertiary>
						{'EDUCATION'}
					</Text>
					{educationArr.map((education, index) => {
						return (
							<div className={classes.educationInfo} key={index}>
								<Avatar
									size={4}
									profilePicURL={education.logo}
									customRootClass={classes.educationLogo}
									fallbackIcon={EducationFallbackIcon}
								/>
								<div className={classes.educationContent}>
									<Text variant="h3" small>
										{education.schoolName}
									</Text>
									<Text variant="p" tiny light>
										{education.degreeName}
									</Text>
									<Text variant="p" tertiary tiny light>
										{getTimePeriodTextEdFunc(education)}
									</Text>
								</div>
							</div>
						);
					})}
				</section>
			)}
			{showViewProfileBtns && (
				<Button
					btnText={
						<Text variant="span" small semiBold brandPrimaryColor>
							{'View full profile'}
						</Text>
					}
					onClick={handleViewProfileBtnClick}
					customClass={classes.viewProfileBtn}
				/>
			)}
			{showViewProfileBtns && (
				<Button
					btnText={
						<Text variant="span" small semiBold brandPrimaryColor>
							{'LinkedIn'}
						</Text>
					}
					suffixIcon={<OpenNewTabIcon size={1.4} />}
					onClick={handleOnClickViewInLinkedIn}
					customClass={classes.viewLinkedInProfileBtn}
				/>
			)}
		</article>
	);
}

export default React.memo(ProfileSection);
