import React from 'react';
import clsx from 'clsx';
import IButtonProps from './Button.types';
import classes from './Button.styles.module.scss';
import Loader from '@components/ui/Loader';

function Button(
	{
		btnText,
		customClass,
		disabled,
		prefixIcon: PrefixIcon,
		suffixIcon: SuffixIcon,
		primary,
		secondary,
		onClick,
		isLoading,
		customLoader,
		...resProps
	}: IButtonProps,
	ref: React.ForwardedRef<HTMLButtonElement>
) {
	const primaryClass = clsx(classes.primary, disabled && classes.disabledPrimary);
	const secondaryClass = clsx(classes.secondary, disabled && classes.disabledSecondary);

	const classNames = clsx(
		classes.commonBtnStyles,
		primary && primaryClass,
		secondary && secondaryClass,
		customClass && customClass
	);

	const handleOnClickBtn = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		if (isLoading) return;
		onClick();
	};

	return (
		<button
			className={classNames}
			disabled={disabled}
			onClick={handleOnClickBtn}
			{...resProps}
			ref={ref}
		>
			{isLoading && (customLoader ? customLoader : <Loader white={primary} />)}
			{!isLoading && (
				<>
					{PrefixIcon && PrefixIcon}
					{btnText}
					{SuffixIcon && SuffixIcon}
				</>
			)}
		</button>
	);
}

export default React.forwardRef(Button);
