import React from 'react';
import Text from '@components/ui/Text';
import Button from '@components/ui/Button';
import { IBulkReplyPromptProps } from './BulkReplyPrompt.types';
import classes from './BulkReplyPrompt.styles.module.scss';

function BulkReplyPrompt({
	headerText,
	onClickCTA,
	subHeaderText,
	ctaText,
}: IBulkReplyPromptProps) {
	return (
		<div className={classes.container}>
			<Text variant="h2" medium semiBold>
				{headerText}
			</Text>
			<Text variant="p" secondary light small lineHeight={2}>
				{subHeaderText}
			</Text>
			<Button
				onClick={onClickCTA}
				btnText={
					<Text variant="span" lineHeight={2.2} semiBold white>
						{ctaText}
					</Text>
				}
				primary
			/>
		</div>
	);
}

export default BulkReplyPrompt;
