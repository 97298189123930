import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import Text from '@components/ui/Text';
import { useDispatch, useSelector } from 'react-redux';
import * as Popover from '@radix-ui/react-popover';
import { getUserDetailsFromGlobalState } from '@store/userDetailsSlice/userDetailsSlice';
import classes from './MainLayoutHeader.styles.module.scss';
import Avatar from '@components/ui/Avatar';
import Button from '@components/ui/Button';
import {
	MainPageLogoTextMobileIcon,
	CheckCircleIcon,
	CopyIcon,
	SettingsIcon,
	UserIcon,
	LogoutIcon,
	SearchIcon,
	CloseIcon,
	ArrowLeftIcon,
	SearchIconLong,
	AlertIcon,
	DiscoverIcon,
} from '@src/hoc/withIconStyles';
import useWindowSize from '@hooks/useWindow';
import Toast from '@components/ui/Toast';
import { IRefProps } from '@components/ui/Toast/Toast.types';
import FallbackPicIcon from '@public/icons/fallbackPicIcon.svg';
import { IMainLayoutHeaderProps, TLinkBtnLocation } from './MainLayoutHeader.types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
	ACCESS_TOKEN,
	ALL,
	CHATS,
	CHAT_SEARCH_PARAM,
	DISCOVER_PATH,
	GET_EARLY_ACCESS,
	INBOX_PATH,
	INBOX_SEARCH_PATH,
	INBOX_SEARCH_PATH_DESKTOP,
	INBOX_TAB_SEARCH_PARAM,
	MAIN_PATH,
	PROFILE_PATH,
	REFRESH_TIMESTAMP,
	SEARCH_PARAM,
	SETTINGS,
	mixPanelEvents,
} from '@utils/constants';
import mixpanelActions from '@utils/mixpanel';
import { AppDispatch } from '@store/index';
import { clearSearchChatsData } from '@store/inboxSlice/inboxSlice';
import { getFullNameUserFunc, isLiteUserFunc, isProUserFunc } from '@src/models/user';
import { clearLocalStorage, setLocalStorage } from '@utils/localStorage';
import { callLogoutApi, clearRootState } from '@utils/store';
import { clearSelectedChat } from '@store/selectedChatSlice/selectedChatSlice';
import { switchUserOtherAccount } from '@api/user';
import useFetch from '@hooks/useFetch';
import { useLeavingModalContext } from '@hooks/leavingModalContext';

function MainLayoutHeader({
	withSearch,
	withSearchMobile,
	headerTxt,
	renderLeftSection,
}: IMainLayoutHeaderProps) {
	const navigate = useNavigate();
	const { isMobile } = useWindowSize();
	const [isOpen, setIsOpen] = useState(false);
	const { data: userDetails, otherAccounts: userOtherAccounts } = useSelector(
		getUserDetailsFromGlobalState
	);
	const { promptLeavingModal, isDirty } = useLeavingModalContext();
	const toastRef = useRef<IRefProps>(null);
	const [toastState, setToastState] = useState({ error: '', info: '' });
	const [searchStr, setSearchStr] = useState('');
	const searchInputRef = useRef<HTMLInputElement | null>(null);
	const linkBtnDesktopRef = useRef<HTMLButtonElement | null>(null);
	const linkBtnPopoverRef = useRef<HTMLButtonElement | null>(null);

	const [searchParams, setSearchParams] = useSearchParams();

	const { callApi: callSwitchUserOtherAccount, status: callSwitchUserOtherAccountStatus } =
		useFetch(switchUserOtherAccount);

	const dispatch = useDispatch<AppDispatch>();

	const isProUser = isProUserFunc(userDetails) ?? false;
	const isLiteUser = isLiteUserFunc(userDetails) ?? false;
	const searchEnabled = isProUser;
	const profilePicURL = userDetails?.imageURL;
	const username = userDetails?.username ?? '';
	const reachableLink = `${window.location.hostname}/${username}`;

	const showProUserLinkBtn = isProUser && (isMobile ? !searchEnabled : true);
	const showLiteUserLinkBtn = isLiteUser && (isMobile ? !searchEnabled : true);
	const showDiscoverBtn = !isMobile;

	const showSearchBox = withSearch && searchEnabled;
	const searchStrFromURL = decodeURIComponent(searchParams.get(SEARCH_PARAM) ?? '');
	const selectedChatFromURL = decodeURIComponent(searchParams.get(CHAT_SEARCH_PARAM) ?? '');

	const pathname = window.location.pathname;
	const showBackBtn = isMobile && (pathname !== INBOX_PATH ? true : selectedChatFromURL);

	const userOtherAccountsToDisplay = useMemo(() => {
		return userOtherAccounts.filter((otherAccount) => !otherAccount.loggedIn);
	}, [userOtherAccounts]);

	useEffect(() => {
		setSearchStr(searchStrFromURL ?? '');
	}, [searchStrFromURL]);

	const handleProUserLinkBtnClick = async (btnLocation: TLinkBtnLocation) => {
		toastRef.current?.unPublish();
		mixpanelActions.trackHeader(mixPanelEvents.HEADER_COPY_LINK_CLICKED);

		if (btnLocation === 'POPOVER') {
			setIsOpen(false);
			linkBtnPopoverRef.current?.classList.add(classes.bubbleBtn);

			setTimeout(() => {
				linkBtnPopoverRef.current?.classList.remove(classes.bubbleBtn);
			}, 200);
		}

		if (btnLocation === 'HEADER') {
			linkBtnDesktopRef.current?.classList.add(classes.bubbleBtn);

			setTimeout(() => {
				linkBtnDesktopRef.current?.classList.remove(classes.bubbleBtn);
			}, 200);
		}

		try {
			await navigator.clipboard?.writeText(reachableLink);
			setToastState({ info: 'Link copied to clipboard', error: '' });
			toastRef.current?.publish();
		} catch (error) {
			// handle error
		}
	};

	const handleLiteUserBtnClick = () => {
		mixpanelActions.trackHeader(mixPanelEvents.HEADER_CLAIM_LINK_CLICKED);
		navigate(GET_EARLY_ACCESS);
	};

	const handleOnClickLogo = () => {
		const funcToCall = () => {
			setTimeout(() => {
				mixpanelActions.trackHeader(mixPanelEvents.HEADER_LOGO_CLICKED);

				const urlSearchParams = new URLSearchParams();

				urlSearchParams.set(REFRESH_TIMESTAMP, Date.now().toString());

				const updatedURL = window.location.origin + '?' + urlSearchParams.toString();

				window.location.replace(updatedURL);
			}, 0);
		};

		if (isDirty) {
			promptLeavingModal(funcToCall);
			return;
		}

		funcToCall();
	};

	const handleProfileBtnClick = () => {
		const funcToCall = () => {
			setIsOpen(false);

			if (isLiteUser) {
				mixpanelActions.trackHeader(mixPanelEvents.HEADER_PROFILE_PIC_CLICKED);
			} else {
				mixpanelActions.trackHeader(mixPanelEvents.HEADER_MY_PROFILE_CLICKED);
			}

			const profilePath = PROFILE_PATH + `/${userDetails?.username}`;

			navigate(profilePath, { replace: window.location.pathname === profilePath });
		};

		if (isDirty) {
			promptLeavingModal(funcToCall);
			return;
		}

		funcToCall();
	};

	const handleOnClickSettings = () => {
		setIsOpen(false);
		mixpanelActions.trackHeader(mixPanelEvents.HEADER_SETTINGS_CLICKED);
		navigate(SETTINGS, { replace: window.location.pathname === SETTINGS });
	};

	const handleLogout = () => {
		const funcToCall = async () => {
			setIsOpen(false);
			mixpanelActions.trackHeader(mixPanelEvents.LOGOUT_CLICKED);
			await callLogoutApi();
			clearLocalStorage();
			mixpanelActions.resetUser();
			clearRootState();
			navigate(MAIN_PATH);
		};

		if (isDirty) {
			promptLeavingModal(funcToCall);
			return;
		}

		funcToCall();
	};

	const handleCancelSearchDesktop = () => {
		searchInputRef.current?.blur();
		setSearchStr('');
		if (searchParams.has(INBOX_TAB_SEARCH_PARAM)) return;
		navigate(INBOX_PATH);
	};

	const handleCancelSearchMobile = () => {
		searchInputRef.current?.blur();
		setSearchStr('');
		if (!searchParams.has(SEARCH_PARAM)) return;
		setSearchParams({});
	};

	const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const isPressedEnter = e.key === 'Enter';

		if (!isPressedEnter) return;

		searchInputRef.current?.blur();

		// previous search string will be the url search param value
		if (searchStrFromURL === searchStr) return;

		mixpanelActions.trackSearch(mixPanelEvents.SEARCH_DONE, searchStr);

		dispatch(clearSelectedChat());
		dispatch(clearSearchChatsData());

		if (!isMobile) {
			navigate(`${INBOX_SEARCH_PATH_DESKTOP}?search=${searchStr}`);
		} else {
			setSearchParams({ search: encodeURIComponent(searchStr) }, { replace: true });
		}
	};

	const handleSearchStrChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchStr(e.target.value);
	};

	const handleOnClickSearchBoxMobile = (e: React.MouseEvent<HTMLDivElement | MouseEvent>) => {
		e.stopPropagation();
		searchInputRef.current?.focus();
	};

	const handleOnClickSearchBox = () => {
		if (isMobile) {
			navigate(INBOX_SEARCH_PATH);
			return;
		}

		searchInputRef.current?.focus();
	};

	const handleAppBack = () => {
		const funcToCall = () => {
			// meaning the navigation stack has one path
			const isPathRenderedAsInitialRoute = window.history.state?.idx === 0;

			const searchParamsToUpdate = new URLSearchParams(searchParams);

			searchParamsToUpdate.forEach((value, key) => {
				// Remove all keys except INBOX_TAB_SEARCH_PARAM
				key !== INBOX_TAB_SEARCH_PARAM && searchParamsToUpdate.delete(key);
			});

			// If INBOX_TAB_SEARCH_PARAM does not exist, add ALL
			if (!searchParamsToUpdate.has(INBOX_TAB_SEARCH_PARAM)) {
				searchParamsToUpdate.set(
					INBOX_TAB_SEARCH_PARAM,
					encodeURIComponent(isProUser ? ALL : CHATS)
				);
			}
			isPathRenderedAsInitialRoute
				? navigate(`${INBOX_PATH}?` + searchParamsToUpdate.toString(), { replace: true })
				: navigate(-1);
		};

		if (isDirty) {
			promptLeavingModal(funcToCall);
			return;
		}

		funcToCall();
	};

	const onClickOtherAccount = async (userId: number, username: string) => {
		const funcToCall = async () => {
			toastRef.current?.unPublish();

			setIsOpen(false);

			if (callSwitchUserOtherAccountStatus === 'loading') return;

			mixpanelActions.trackSwitchUser(mixPanelEvents.SWITCH_USER_CLICKED, username);

			try {
				const token = await callSwitchUserOtherAccount(userId);
				if (token) {
					await callLogoutApi();
					mixpanelActions.resetUser();
					setLocalStorage(ACCESS_TOKEN, token);
					window.location.replace(window.location.origin);
				}
			} catch (error) {
				setToastState({ error: error, info: '' });
				toastRef.current?.publish();
			}
		};

		if (isDirty) {
			promptLeavingModal(funcToCall);
			return;
		}

		funcToCall();
	};

	const onClickDiscoverBtn = () => {
		const funcToCall = () => {
			mixpanelActions.trackDiscover({
				eventName: mixPanelEvents.INBOX_PAGE_DISCOVER_BTN_CLICKED,
			});
			navigate(DISCOVER_PATH);
		};

		if (isDirty) {
			promptLeavingModal(funcToCall);
			return;
		}

		funcToCall();
	};

	if (withSearchMobile && isMobile) {
		return (
			<div className={classes.headerContainer}>
				<Button
					customClass={classes.backButton}
					btnText={<ArrowLeftIcon size={2.4} />}
					onClick={handleAppBack}
				/>
				<div className={classes.searchBox} tabIndex={0} onClick={handleOnClickSearchBoxMobile}>
					<input
						className={classes.input}
						value={searchStr}
						onChange={handleSearchStrChange}
						placeholder="Search for people"
						ref={searchInputRef}
						onKeyDown={handleSearchKeyDown}
						autoFocus={!searchStrFromURL}
						type="text"
						autoComplete="off"
					/>
					{searchStr.length > 0 && (
						<Button
							btnText={<CloseIcon size={1.6} className={classes.searchBoxIcon} />}
							customClass={classes.cancelSearchBtn}
							onClick={handleCancelSearchMobile}
						/>
					)}
				</div>
			</div>
		);
	}

	return (
		<div className={classes.headerContainer}>
			{showBackBtn && (
				<Button
					btnText={<ArrowLeftIcon size={2.4} />}
					onClick={handleAppBack}
					customClass={classes.appBackBtn}
				/>
			)}
			{!showBackBtn && !!renderLeftSection ? renderLeftSection : null}

			{!showBackBtn && !renderLeftSection && (
				<Button
					btnText={
						isMobile ? (
							<MainPageLogoTextMobileIcon size={3.6} />
						) : (
							<MainPageLogoTextMobileIcon size={4.2} />
						)
					}
					onClick={handleOnClickLogo}
					customClass={classes.logoBtn}
				/>
			)}

			{headerTxt && isMobile && (
				<Text variant="h2" light customClass={classes.headerTxt}>
					{`${headerTxt}`}
				</Text>
			)}
			{showSearchBox && isMobile && (
				<Button
					onClick={handleOnClickSearchBox}
					btnText={<SearchIconLong size={2.6} className={classes.searchIcon} />}
					customClass={classes.searchIconBtn}
				/>
			)}
			{showSearchBox && !isMobile && (
				<div
					className={classes.searchBox}
					tabIndex={0}
					onClick={(e) => {
						e.stopPropagation();
						handleOnClickSearchBox();
					}}
				>
					<SearchIcon size={1.6} className={classes.searchBoxIcon} />
					{!isMobile && (
						<input
							className={classes.input}
							value={searchStr}
							onChange={handleSearchStrChange}
							placeholder="Search for people"
							ref={searchInputRef}
							onKeyDown={handleSearchKeyDown}
							type="text"
							autoComplete="off"
						/>
					)}
					{searchStr.length > 0 && (
						<Button
							btnText={<CloseIcon size={1.6} className={classes.searchBoxIcon} />}
							customClass={classes.cancelSearchBtn}
							onClick={handleCancelSearchDesktop}
						/>
					)}
				</div>
			)}

			{!!userDetails && (
				<div className={classes.profileHeaderContent}>
					{showDiscoverBtn && (
						<Button
							btnText={
								<Text variant="span" lineHeight={1.8} small semiBold color="#99BFF7">
									{'Discover'}
								</Text>
							}
							prefixIcon={<DiscoverIcon size={2.2} />}
							onClick={onClickDiscoverBtn}
							customClass={classes.discoverBtn}
						/>
					)}
					{showProUserLinkBtn && (
						<Button
							btnText={
								<Text variant="span" white small light customClass={classes.linkText}>
									{reachableLink}
								</Text>
							}
							onClick={() => {
								handleProUserLinkBtnClick('HEADER');
							}}
							customClass={classes.usernameLinkBtn}
							suffixIcon={<CopyIcon size={1.6} />}
							ref={linkBtnDesktopRef}
						/>
					)}
					{showLiteUserLinkBtn && (
						<Button
							btnText={
								<Text variant="span" white small light>
									{'Get your SuperDM link'}
								</Text>
							}
							onClick={handleLiteUserBtnClick}
							customClass={classes.usernameLinkBtn}
						/>
					)}
					<Popover.Root open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
						<Popover.Trigger asChild>
							<button
								className={clsx(classes.headerMenuTrigger, classes.logoBtn)}
								onClick={() => {
									mixpanelActions.trackHeader(mixPanelEvents.HEADER_PROFILE_PIC_CLICKED);
								}}
							>
								<Avatar size={3.6} profilePicURL={profilePicURL} fallbackIcon={FallbackPicIcon} />
							</button>
						</Popover.Trigger>
						<Popover.Portal>
							<Popover.Content align="end" side="bottom" className={classes.headerMenuContent}>
								<div className={classes.imageWithCopyLink}>
									<Avatar size={5.6} profilePicURL={profilePicURL} fallbackIcon={FallbackPicIcon} />
									<Text variant="h3" lineHeight={2.2}>
										{getFullNameUserFunc(userDetails)}
									</Text>
									{isProUser && (
										<Button
											btnText={
												<Text
													variant="span"
													small
													lineHeight={2}
													secondary
													light
													customClass={classes.linkText}
												>
													{reachableLink}
												</Text>
											}
											onClick={() => {
												handleProUserLinkBtnClick('POPOVER');
											}}
											customClass={classes.imageLinkBtn}
											suffixIcon={<CopyIcon size={1.4} />}
											ref={linkBtnPopoverRef}
										/>
									)}
								</div>

								<div className={classes.popoverSectionsBorder} />

								{!!userOtherAccountsToDisplay.length && (
									<Text
										variant="h4"
										extraTiny
										lineHeight={1.6}
										semiBold
										secondary
										customClass={classes.switchAccountHeader}
									>
										{'SWITCH ACCOUNT'}
									</Text>
								)}

								{!!userOtherAccountsToDisplay.length && (
									<div className={classes.otherAccountsContainer}>
										{userOtherAccountsToDisplay.map((otherAccount) => {
											return (
												<Button
													key={otherAccount.userId}
													prefixIcon={
														<Avatar
															size={2.4}
															profilePicURL={otherAccount.imageURL}
															fallbackIcon={FallbackPicIcon}
														/>
													}
													btnText={
														<Text variant="span" small>
															{getFullNameUserFunc(otherAccount)}
														</Text>
													}
													onClick={() =>
														onClickOtherAccount(
															otherAccount.userId,
															getFullNameUserFunc(otherAccount)
														)
													}
													customClass={classes.otherAccountBtn}
												/>
											);
										})}
									</div>
								)}

								{!!userOtherAccountsToDisplay.length && (
									<div className={classes.popoverSectionsBorder} />
								)}

								<Button
									btnText={
										<Text variant="span" small>
											{'Profile'}
										</Text>
									}
									prefixIcon={<UserIcon size={1.6} />}
									onClick={handleProfileBtnClick}
								/>
								{isProUser && (
									<Button
										prefixIcon={<SettingsIcon size={1.6} />}
										btnText={
											<Text variant="span" small>
												{'Settings'}
											</Text>
										}
										onClick={handleOnClickSettings}
									/>
								)}
								<Button
									btnText={
										<Text variant="span" small>
											{'Logout'}
										</Text>
									}
									prefixIcon={<LogoutIcon size={1.6} />}
									onClick={handleLogout}
								/>
							</Popover.Content>
						</Popover.Portal>
					</Popover.Root>
				</div>
			)}
			<Toast
				ref={toastRef}
				toastType={toastState.info ? 'INFO' : 'ERROR'}
				header={toastState.info ? toastState.info : toastState.error}
				icon={
					toastState.info ? (
						<CheckCircleIcon size={1.8} />
					) : (
						<AlertIcon size={1.8} className={classes.toastIcon} />
					)
				}
			/>
		</div>
	);
}

export default React.memo(MainLayoutHeader);
