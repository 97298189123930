import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import classes from './ChatsFooter.styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	addCurrMessage,
	getSelectedChatDetailsFromGlobalState,
	setBookmarkNudgeMessageId,
	setMessageStatus,
	setSelectedChatId,
	setSelectedChatMessageToRetry,
	setSelectedInboxCategory,
	triggerMessagesToScrollBottom,
} from '@store/selectedChatSlice/selectedChatSlice';
import { AppDispatch } from '@store/index';
import { InboxCategories, isAccountPendingFunc, isProUserFunc } from '@src/models/user';
import useFetch from '@hooks/useFetch';
import { bookmarkChat, sendChatMessage, sendChatMessageInFormFormat } from '@api/chats';
import { IBookmarkChatPayload, ISendChatMessagePayload } from '@api/chats/chats.types';
import {
	clearOnboardingInputsState,
	getUserDetailsFromGlobalState,
	setDisplayVerifyLinkedInModal,
	setLinkedInStatus,
} from '@store/userDetailsSlice/userDetailsSlice';
import {
	getInboxDataFromGlobalState,
	setBookmarkChatActionCount,
	setCurrBookmarkChatsMap,
	setCurrReadChatsMap,
	setReadChatActionCount,
} from '@store/inboxSlice/inboxSlice';
import Button from '@components/ui/Button';
import {
	CloseIcon,
	PdfFileIcon,
	QuickReplyIcon,
	SendIcon,
	UploadPDFIcon,
	ImageIcon,
	TickIconColored,
} from '@src/hoc/withIconStyles';
import InboxSelect from '../InboxSelect';
import {
	ALL,
	ATTACHMENT_SIZE_LIMIT,
	CHATS,
	CURRENT_CLICKED_DISCOVERY_USER,
	FIRST_MESSAGE_CHARACTER_LIMIT,
	FIRST_MESSAGE_MIN_CHARACTER_LIMIT,
	INBOX_PATH,
	LIKE_REACTION_TEXT,
	MESSAGE_CHARACTER_LIMIT,
	mixPanelEvents,
} from '@utils/constants';
import useWindowSize from '@hooks/useWindow';
import mixpanelActions, { TBookmarkPosition } from '@utils/mixpanel';
import { ChatMessage } from '@src/models/message';
import Text from '@components/ui/Text';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '@components/ui/Modal';
import Loader from '@components/ui/Loader';
import { convertArrayBufferToFile, formatFirstName, genDraftMessageKey } from '@utils/common';
import CannedResponse from '../CannedResponse';
import { IHandleSendMessage } from './ChatsFooter.types';
import useVerifyLinkedInContext from '@hooks/useVerifyLinkedInContext';
import CannedResponseMobile from '@components/CannedResponseMobile';
import CannedResponsesHorizontalView from '@components/CannedResponsesHorizontalView';
import { getSessionStorage } from '@utils/sessionStorage';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '@utils/localStorage';
import { debounce } from '@utils/debounce';

function ChatsFooter() {
	const location = useLocation();
	const navigate = useNavigate();
	const { setModalState: setVerifyLinkedInModalState, isOpen: isVerifyLinkedInModalOpen } =
		useVerifyLinkedInContext();

	const { isMobile } = useWindowSize();
	const [textAreaStr, setTextAreaStr] = useState('');
	const [selectedAttachment, setSelectedAttachment] = useState<File | null>(null);
	const currChatIdRef = useRef<number | null>(null);
	const currChatIdRes = useRef<ChatMessage | null>(null);
	const attachmentInputRef = useRef<HTMLInputElement | null>(null);
	const [isConvertingAttachment, setIsConvertingAttachment] = useState(false);
	const [isOpenCannedResponse, setIsOpenCannedResponse] = useState(false);
	const [bookmarkState, setBookmarkState] = useState({
		openBookmarkAction: true,
		bookmark: false,
	});
	const [showBookmarkReadActionState, setShowBookmarkReadActionState] = useState(false);
	const [validationState, setValidationState] = useState({
		error: '',
		info: '',
	});

	const closedOnOutsideClick = useRef(false);
	const textAreaFocused = useRef(false);
	const isCannedResponseSelected = useRef(false);

	const dispatch = useDispatch<AppDispatch>();
	const { selectedChat, selectedInboxCategory, allChatMessages, selectedChatMessageToRetry } =
		useSelector(getSelectedChatDetailsFromGlobalState);
	const { currBookmarkChatsMap } = useSelector(getInboxDataFromGlobalState);
	const {
		data: userDetails,
		onboardingInputsState,
		displayVerifyLinkedInModal,
	} = useSelector(getUserDetailsFromGlobalState);

	const textAreaRef = useRef<HTMLTextAreaElement>(null);

	const { callApi: sendMessage, status: sendMessageStatus } = useFetch(sendChatMessage);
	const { callApi: callSendMessageFormData, status: callSendMessageFormDataStatus } = useFetch(
		sendChatMessageInFormFormat
	);
	const { callApi: callBookmarkChat, status: callBookmarkChatStatus } = useFetch(bookmarkChat);

	const messageFromOnboarding = onboardingInputsState?.message ?? '';
	const selectedCategoryFromOnboarding = onboardingInputsState?.selectedCategory ?? '';
	const selectedPdfFileFromOnboarding: File | null = onboardingInputsState?.selectedPdfFile ?? null;

	const inboxCategoriesMap = selectedChat?.senderDetails?.InboxCategoriesMap ?? {};
	const isChatExist = !!selectedChat?.chatId;
	const selectedChatUsername = selectedChat?.senderDetails?.username ?? '';
	const senderFirstName = selectedChat?.senderDetails?.profileData?.firstName ?? '';
	const selectedChatUserId = selectedChat?.senderDetails?.userId ?? null;
	const currUserId = userDetails?.userId ?? null;
	const selectedChatId = selectedChat?.chatId;
	const chatBookmarked =
		currBookmarkChatsMap[selectedChatId ?? -1] ?? selectedChat?.bookmark ?? false;
	const chatInitiated = allChatMessages.length > 0;
	const receiverRepliedChat = selectedChat?.receiverReplied ?? false;

	const shouldSelectCategory = Object.keys(inboxCategoriesMap).length > 0 && !isChatExist;
	const showSelectCategory =
		shouldSelectCategory &&
		allChatMessages.length === 0 &&
		(textAreaStr.length > 0 || selectedInboxCategory);

	const checkForMaxMessageLength = selectedChat?.senderDetails?.maxCharCountEnabled ?? false;
	const checkForMinMessageLength = selectedChat?.senderDetails?.minCharCountEnabled ?? false;

	const validateMaxLength = checkForMaxMessageLength && !receiverRepliedChat;
	const validateMinLength = checkForMinMessageLength && !isChatExist;
	const showValidationInfo = checkForMaxMessageLength && !isChatExist;

	const sendButtonDisabled = textAreaStr.trim().length === 0 || isVerifyLinkedInModalOpen;

	const showMessageWithFileUploadLoader =
		callSendMessageFormDataStatus === 'loading' || isConvertingAttachment;
	const isProUser = isProUserFunc(userDetails);
	const canShowCannedResponse = isProUser && isChatExist;
	const showCannedResponse = isOpenCannedResponse && canShowCannedResponse;
	const showCannedResponseMobile = showCannedResponse && isMobile;
	const showCannedResponseDesktop = showCannedResponse && !isMobile;

	const showBookmarkNudgesBaseCondition =
		chatInitiated && !chatBookmarked && isProUser && !selectedChat?.replied && !showCannedResponse;

	const openBookmarkAction =
		showBookmarkNudgesBaseCondition &&
		bookmarkState.openBookmarkAction &&
		textAreaStr.trim().length > 0;

	const openBookmarkReadAction =
		(showBookmarkNudgesBaseCondition && textAreaStr.trim().length === 0) ||
		showBookmarkReadActionState;

	const showCannedResponseBtn =
		canShowCannedResponse && (isMobile ? !openBookmarkReadAction : true) && sendButtonDisabled;

	const showValidation = validationState.error || validationState.info;

	const currCharCountWithMaxLimitText =
		textAreaStr.length > 0 ? ` ${textAreaStr.length}/${FIRST_MESSAGE_CHARACTER_LIMIT}` : '';

	const storeMessageAsDraft = useCallback(
		debounce((username: string, message: string) => {
			const key = genDraftMessageKey(username);
			message.trim().length > 0 ? setLocalStorage(key, message) : removeLocalStorage(key);
		}, 300),
		[]
	);

	const handleInboxSelect = (option: InboxCategories, selected: boolean) => {
		textAreaFocused.current && textAreaRef.current?.focus();
		selected &&
			setValidationState({
				error: '',
				info: showValidationInfo
					? `Please keep this within ${FIRST_MESSAGE_CHARACTER_LIMIT} characters`
					: '',
			});
		dispatch(setSelectedInboxCategory({ selectedInboxCategory: selected ? option.name : null }));
	};

	const handleSendTextMessage = async (
		payload: ISendChatMessagePayload,
		chatMessage?: ChatMessage,
		canBookmarkChat?: boolean,
		isCannedResponse?: boolean
	) => {
		const isRetry = chatMessage?.status === 'FAILED';
		const tempId = chatMessage ? chatMessage.id : `temp-id-${allChatMessages.length + 1}`;

		if (!isRetry) {
			const tempChatMessage: ChatMessage = {
				content: payload.content,
				id: tempId,
				contentType: payload.content_type,
				createdAt: payload.timestamp,
				senderId: payload.sender_id,
				receiverId: payload.receiver_id,
				status: 'SENDING',
				chatId: payload.chat_id ?? undefined,
				attachmentType: 'NONE',
				attachmentMetaData: null,
				reaction: 'NONE',
				canBookmarkChat: canBookmarkChat,
				isCannedResponse: isCannedResponse,
			};

			// adding message to redux and resetting text area and attachment
			dispatch(addCurrMessage({ messageToAdd: tempChatMessage }));
			setTextAreaStr('');
			handleAttachmentClose();
		} else {
			// updating message status in redux
			dispatch(setMessageStatus({ messageId: tempId, status: 'SENDING' }));
		}

		try {
			const message = await sendMessage(payload);

			// updating message status in redux and updating the message object with server data
			dispatch(
				setMessageStatus({ messageId: tempId, status: 'SENT', updatedMessageData: message })
			);

			setTimeout(() => {
				dispatch(setMessageStatus({ messageId: message.id, status: 'DELIVERED' }));
			}, 10000);

			return message;
		} catch (error) {
			// updating message status in redux
			dispatch(setMessageStatus({ messageId: tempId, status: 'FAILED' }));

			return Promise.reject(error);
		}
	};

	const handleSendAttachmentMessage = async (payload: ISendChatMessagePayload) => {
		try {
			const message = await callSendMessageFormData(payload);

			// adding message to redux and resetting text area and attachment
			dispatch(addCurrMessage({ messageToAdd: message }));
			setTextAreaStr('');
			handleAttachmentClose();

			return message;
		} catch (error) {
			setValidationState({ error: error, info: '' });
			return Promise.reject(error);
		}
	};

	const handleSendMessage: IHandleSendMessage = async (paramsObj) => {
		if (callSendMessageFormDataStatus === 'loading' || sendMessageStatus === 'loading') return;
		if (!selectedChatUserId || !currUserId) return;

		// function params object
		const message = paramsObj?.message;
		const canBookmarkChat =
			paramsObj?.canBookmarkChat || (openBookmarkAction && bookmarkState.bookmark);
		const chatMessage = paramsObj?.ChatMessage;

		const isCannedResponse = chatMessage?.isCannedResponse ?? isCannedResponseSelected.current;

		isCannedResponseSelected.current = false;
		currChatIdRef.current = selectedChat?.chatId ?? null;

		const payloadMessageContent = chatMessage?.content ?? message ?? textAreaStr.trim();

		if (!selectedInboxCategory && shouldSelectCategory) {
			const errorToDisplay = `Please choose a message category`;

			setValidationState({
				error: errorToDisplay,
				info: '',
			});
			textAreaFocused.current && textAreaRef.current?.focus();
			mixpanelActions.trackMessageError({
				eventName: mixPanelEvents.MESSAGE_SENDING_ERROR_SHOWN,
				messageErrorType: 'Category',
				errorShown: errorToDisplay,
			});
			return;
		}

		if (payloadMessageContent.length < FIRST_MESSAGE_MIN_CHARACTER_LIMIT && validateMinLength) {
			const errorToDisplay = `First message needs to be at least ${FIRST_MESSAGE_MIN_CHARACTER_LIMIT} characters`;

			setValidationState({
				error: errorToDisplay,
				info: '',
			});
			textAreaFocused.current && textAreaRef.current?.focus();
			mixpanelActions.trackMessageError({
				eventName: mixPanelEvents.MESSAGE_SENDING_ERROR_SHOWN,
				messageErrorType: 'Min Limit',
				charCount: payloadMessageContent.length,
				validationCount: FIRST_MESSAGE_MIN_CHARACTER_LIMIT,
				errorShown: errorToDisplay,
			});
			return;
		}

		const isChatOpenedViaDiscovery =
			getSessionStorage(CURRENT_CLICKED_DISCOVERY_USER) === selectedChatUsername;

		const payload: ISendChatMessagePayload = {
			chat_id: currChatIdRef.current,
			content: payloadMessageContent,
			content_type: 'TEXT',
			label: selectedInboxCategory,
			receiver_id: selectedChatUserId,
			sender_id: currUserId,
			timestamp: new Date().toISOString(),
			attachment: selectedAttachment,
			initial_referrer: document.referrer ?? '',
			via_superdm: isChatOpenedViaDiscovery,
		};

		mixpanelActions.trackChatInboxCategory(
			mixPanelEvents.INBOX_MESSAGE_SEND_CLICKED,
			selectedChatUsername,
			'no',
			payload.label ?? '',
			payload.attachment?.type,
			isCannedResponse
		);

		try {
			let message: ChatMessage | null = null;

			if (selectedAttachment) {
				message = await handleSendAttachmentMessage(payload);
			} else {
				message = await handleSendTextMessage(
					payload,
					chatMessage,
					canBookmarkChat,
					isCannedResponse
				);
			}

			removeLocalStorage(genDraftMessageKey(selectedChatUsername));

			if (messageFromOnboarding) {
				dispatch(clearOnboardingInputsState());
			}

			if (showBookmarkNudgesBaseCondition && !canBookmarkChat && !isCannedResponse) {
				dispatch(setBookmarkNudgeMessageId({ messageId: message.id }));
			}

			dispatch(setReadChatActionCount({ chatRead: true, chatId: payload.chat_id }));
			dispatch(setCurrReadChatsMap({ chatId: payload.chat_id, chatRead: true }));

			mixpanelActions.trackChatInboxCategory(
				mixPanelEvents.INBOX_MESSAGE_SUCCESS,
				selectedChatUsername,
				'no',
				payload.label ?? '',
				payload.attachment?.type,
				isCannedResponse,
				'chat'
			);

			// handling linkedin modal display and fetching inbox data
			currChatIdRes.current = { ...message };

			handleUserChatVerificationCheck();

			// handling bookmark chat action
			if (canBookmarkChat) {
				handleBookmarkChat('CHECKBOX');
			}
		} catch (error) {
			// handle error
			isCannedResponseSelected.current = false;
		}
	};

	const handleUserChatVerificationCheck = () => {
		if (isAccountPendingFunc(userDetails) && !!userDetails?.linkedInVerificationPossible) {
			setTimeout(() => {
				handleOpenVerifyLinkedInModal();
			}, 1500);
		} else {
			handleFetchInboxCall();
		}
	};

	const handleFetchInboxCall = () => {
		const currChatId = currChatIdRef.current;
		const currChatMessage = currChatIdRes.current;

		if (!currChatId && currChatMessage?.chatId) {
			dispatch(setSelectedChatId({ chatId: currChatMessage.chatId }));
			currChatIdRef.current = currChatMessage.chatId;

			const tabParamToSet = isProUser ? ALL : CHATS;

			const searchParams = new URLSearchParams(location.search);
			searchParams.set('tab', tabParamToSet);

			navigate(`${INBOX_PATH}?${searchParams.toString()}`, { replace: true });
		}
	};

	const handleInputKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (isOpenCannedResponse) return;

		const shouldSendOnClickEnter = e.key === 'Enter' && !e.shiftKey && !isMobile;

		if (shouldSendOnClickEnter) {
			e.preventDefault();
		}

		if (shouldSendOnClickEnter && !sendButtonDisabled) {
			e.preventDefault();
			handleSendMessage();
		}
	};

	const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const content = e.target.value;

		storeMessageAsDraft(selectedChatUsername, content);

		if (content.length > FIRST_MESSAGE_CHARACTER_LIMIT && validateMaxLength) {
			const errorToDisplay = `First message cannot be longer than ${FIRST_MESSAGE_CHARACTER_LIMIT} characters`;

			setValidationState({
				error: errorToDisplay,
				info: '',
			});
			mixpanelActions.trackMessageError({
				eventName: mixPanelEvents.MESSAGE_SENDING_ERROR_SHOWN,
				messageErrorType: 'Max Limit',
				validationCount: FIRST_MESSAGE_CHARACTER_LIMIT,
				errorShown: errorToDisplay,
				charCount: content.length,
			});
			return;
		}

		if (content.length > MESSAGE_CHARACTER_LIMIT && !validateMaxLength) {
			const errorToDisplay = `Message cannot be longer than ${MESSAGE_CHARACTER_LIMIT} characters`;

			setValidationState({
				error: errorToDisplay,
				info: '',
			});
			mixpanelActions.trackMessageError({
				eventName: mixPanelEvents.MESSAGE_SENDING_ERROR_SHOWN,
				messageErrorType: 'Max Limit',
				validationCount: MESSAGE_CHARACTER_LIMIT,
				errorShown: errorToDisplay,
				charCount: content.length,
			});
			return;
		}

		setValidationState({
			error: '',
			info: showValidationInfo
				? `Please keep this within ${FIRST_MESSAGE_CHARACTER_LIMIT} characters`
				: '',
		});

		setTextAreaStr(content);

		if (content === '/') {
			mixpanelActions.trackCannedResponse({
				eventName: mixPanelEvents.QUICK_REPLY_POP_UP_SHOWN,
				trigger: 'slash',
				screen: 'chat',
			});
			setIsOpenCannedResponse(true);
		} else {
			setIsOpenCannedResponse(false);
		}

		// reset bookmark action position on empty text
		if (content.trim().length === 0) {
			setBookmarkState((prevState) => {
				return {
					...prevState,
					bookmark: false,
					openBookmarkAction: true,
				};
			});
		}
	};

	const handleTextAreaFocused = () => {
		textAreaFocused.current = true;
	};

	const handleCloseLinkedInVerifyModal = () => {
		if (isAccountPendingFunc(userDetails)) {
			dispatch(setLinkedInStatus({ linkedInVerificationStatus: 'PENDING_MESSAGE_SENT' }));
		}
		handleFetchInboxCall();
	};

	const handleOpenVerifyLinkedInModal = () => {
		setVerifyLinkedInModalState({
			isOpen: true,
			onClose: handleCloseLinkedInVerifyModal,
		});
		mixpanelActions.trackVerifyLinkedIn({
			eventName: mixPanelEvents.LINKEDIN_VERIFY_SHOWN,
			trigger: 'chat',
		});
	};

	const handleAttachmentChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files ?? [];

		if (files.length > 0) {
			const file = files[0];

			const isValidFileType =
				file.type &&
				(file.type === 'application/pdf' ||
					file.type === 'image/png' ||
					file.type === 'image/jpeg');

			if (!isValidFileType) {
				const errorToDisplay = `File needs to be PDF/PNG/JPEG`;
				setValidationState({
					error: errorToDisplay,
					info: '',
				});
				mixpanelActions.trackAttachments({
					eventName: mixPanelEvents.ATTACHMENT_ERROR_SHOWN,
					isTemp: 'no',
					attachment: file.type,
					errorShown: errorToDisplay,
				});
				return;
			}

			if (file.size > ATTACHMENT_SIZE_LIMIT) {
				const errorToDisplay = `File cannot be more than 3 MB`;
				setValidationState({
					error: errorToDisplay,
					info: '',
				});
				mixpanelActions.trackAttachments({
					eventName: mixPanelEvents.ATTACHMENT_ERROR_SHOWN,
					isTemp: 'no',
					attachment: file.type,
					errorShown: errorToDisplay,
				});
				return;
			}

			try {
				setIsConvertingAttachment(true);
				const fileToUpload = await convertArrayBufferToFile(file);
				setSelectedAttachment(fileToUpload);
				mixpanelActions.trackAttachments({
					eventName: mixPanelEvents.ATTACHMENT_ADDED,
					isTemp: 'no',
					attachment: file.type,
				});
				setIsConvertingAttachment(false);
				setValidationState({
					error: '',
					info: showValidationInfo
						? `Please keep this within ${FIRST_MESSAGE_CHARACTER_LIMIT} characters`
						: '',
				});
			} catch (error) {
				// handle error
				setIsConvertingAttachment(false);
				setValidationState({ error: 'Failed to load file, please try again', info: '' });
				mixpanelActions.trackAttachments({
					eventName: mixPanelEvents.ATTACHMENT_ERROR_SHOWN,
					isTemp: 'no',
					attachment: file.type,
					errorShown: error ?? 'Failed to load file, please try again',
				});
			}
		}
	};

	const handleAttachmentUpload = () => {
		textAreaFocused.current && textAreaRef.current?.focus();
		if (isConvertingAttachment) return;
		mixpanelActions.trackAttachments({
			eventName: mixPanelEvents.ATTACHMENT_ICON_CLICKED,
			isTemp: 'no',
		});
		handleAttachmentClose();
		attachmentInputRef.current?.click();
	};

	const handleAttachmentClose = (shouldFocusInputText?: boolean) => {
		shouldFocusInputText && textAreaFocused.current && textAreaRef.current?.focus();
		if (attachmentInputRef.current?.value) {
			attachmentInputRef.current.value = '';
		}
		setSelectedAttachment(null);
	};

	const handleCannedResponseBtn = () => {
		setIsOpenCannedResponse(closedOnOutsideClick.current ? false : true);
	};

	useEffect(() => {
		if (!showCannedResponse) return;
		if (showCannedResponse && textAreaStr === '/') return;
		mixpanelActions.trackCannedResponse({
			eventName: mixPanelEvents.QUICK_REPLY_POP_UP_SHOWN,
			trigger: 'icon',
			screen: 'chat',
		});
	}, [showCannedResponse, textAreaStr]);

	const handleBookmarkChat = async (bookmarkPosition: TBookmarkPosition) => {
		// this only triggers if chat is to be bookmarked
		if (!(selectedChatUserId && currUserId && selectedChatId)) return;
		if (callBookmarkChatStatus === 'loading') return;

		const payload: IBookmarkChatPayload = {
			chat_id: selectedChatId,
			sender_id: selectedChatUserId,
			user_id: currUserId,
			bookmark: !chatBookmarked,
		};

		try {
			await callBookmarkChat(payload);
			// handling bookmark chat state in redux
			dispatch(
				setBookmarkChatActionCount({
					chatBookmarked: payload.bookmark,
					chatId: payload.chat_id,
				})
			);
			dispatch(
				setCurrBookmarkChatsMap({ chatId: payload.chat_id, chatBookmarked: payload.bookmark })
			);

			if (payload.bookmark) {
				mixpanelActions.trackChatBookmark(
					mixPanelEvents.CHAT_ADDED_TO_BOOKMARK,
					selectedChatUsername,
					bookmarkPosition
				);
				setTimeout(() => {
					setShowBookmarkReadActionState(false);
				}, 2000);
			}
		} catch (error) {
			// handle error
		}
	};

	const handleBookmarkAction = () => {
		textAreaFocused.current && textAreaRef.current?.focus();
		setBookmarkState((prevState) => {
			return {
				...prevState,
				bookmark: !bookmarkState.bookmark,
			};
		});
	};

	const onSelectCannedResponseDesktop = (cannedResponseText: string, canBookmarkChat: boolean) => {
		setTextAreaStr(cannedResponseText);
		isCannedResponseSelected.current = true;

		setIsOpenCannedResponse(false);

		setBookmarkState((prevState) => {
			return {
				...prevState,
				openBookmarkAction: canBookmarkChat,
				bookmark: canBookmarkChat,
			};
		});

		textAreaFocused.current && textAreaRef.current?.focus();
	};

	const onCloseCannedResponse = () => {
		setIsOpenCannedResponse(false);
		setTextAreaStr('');
		closedOnOutsideClick.current = true;
		setTimeout(() => {
			closedOnOutsideClick.current = false;
		}, 500);
	};

	const onSelectCannedResponseMobile = (text: string, bookmark: boolean) => {
		isCannedResponseSelected.current = true;
		handleSendMessage({ message: text, canBookmarkChat: bookmark });
	};

	// set draft message and focus on text area to last character
	useEffect(() => {
		const draftMessage = getLocalStorage(genDraftMessageKey(selectedChatUsername));

		if (!draftMessage?.length) return;

		setTextAreaStr(draftMessage);
		textAreaRef.current?.setSelectionRange(draftMessage.length, draftMessage.length);
	}, [selectedChatUsername]);

	// handling data from onboarding part
	useEffect(() => {
		if (displayVerifyLinkedInModal) {
			handleUserChatVerificationCheck();
			dispatch(setDisplayVerifyLinkedInModal({ displayVerifyLinkedInModal: false }));
		}

		if (messageFromOnboarding) {
			setTextAreaStr(messageFromOnboarding);
		}

		if (selectedPdfFileFromOnboarding) {
			setSelectedAttachment(selectedPdfFileFromOnboarding);
		}

		if (isChatExist) return;

		if (selectedCategoryFromOnboarding) {
			dispatch(setSelectedInboxCategory({ selectedInboxCategory: selectedCategoryFromOnboarding }));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		messageFromOnboarding,
		selectedCategoryFromOnboarding,
		selectedPdfFileFromOnboarding,
		displayVerifyLinkedInModal,
		isChatExist,
	]);

	useEffect(() => {
		if (showSelectCategory && !selectedInboxCategory && !selectedCategoryFromOnboarding) {
			mixpanelActions.trackChatInboxCategory(
				mixPanelEvents.INBOX_CATEGORY_DROPDOWN_SHOW,
				selectedChatUsername,
				'no'
			);
		}

		if (showSelectCategory && selectedInboxCategory && !selectedCategoryFromOnboarding) {
			mixpanelActions.trackChatInboxCategory(
				mixPanelEvents.INBOX_CATEGORY_DROPDOWN_SELECTED,
				selectedChatUsername,
				'no',
				selectedInboxCategory ?? ''
			);
		}
	}, [
		showSelectCategory,
		selectedInboxCategory,
		selectedChatUsername,
		selectedCategoryFromOnboarding,
	]);

	useEffect(() => {
		if (selectedChatMessageToRetry) {
			handleSendMessage({
				ChatMessage: selectedChatMessageToRetry,
				canBookmarkChat: selectedChatMessageToRetry.canBookmarkChat,
			});
			dispatch(setSelectedChatMessageToRetry({ selectedChatMessageToRetry: null }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedChatMessageToRetry]);

	useEffect(() => {
		const textAreaEle = textAreaRef.current;

		if (!textAreaEle) return;

		textAreaEle.style.height = 'max-content';

		if (textAreaStr.length > 0) {
			textAreaEle.style.height = `${textAreaEle.scrollHeight}px`;
		}
	}, [textAreaStr]);

	// handling validation info
	useEffect(() => {
		setValidationState({
			error: '',
			info: showValidationInfo
				? `Please keep this within ${FIRST_MESSAGE_CHARACTER_LIMIT} characters`
				: '',
		});
	}, [showValidationInfo]);

	useEffect(() => {
		if (openBookmarkReadAction || openBookmarkAction) {
			dispatch(triggerMessagesToScrollBottom());
		}
	}, [openBookmarkReadAction, openBookmarkAction]);

	return (
		<div
			className={clsx(
				classes.chatsFooter,
				(openBookmarkAction || openBookmarkReadAction) && classes.chatsFooterBookMarkOpen,
				openBookmarkReadAction && classes.chatsFooterWithoutBorderEffect
			)}
		>
			{showCannedResponseDesktop && (
				<div className={classes.cannedResponseContainer}>
					<CannedResponse
						senderFirstName={formatFirstName(senderFirstName)}
						onSelectCannedResponse={(text, labelName, canBookmarkChat) => {
							mixpanelActions.trackCannedResponse({
								eventName: mixPanelEvents.QUICK_REPLY_SELECTED,
								chatUsername: selectedChatUsername,
								labelName: labelName,
								screen: 'chat',
								type: 'list',
							});
							onSelectCannedResponseDesktop(text, canBookmarkChat);
						}}
						onCloseCannedResponse={onCloseCannedResponse}
					/>
				</div>
			)}
			{showCannedResponseMobile && (
				<CannedResponseMobile
					onCloseModal={onCloseCannedResponse}
					onSendBtnClick={onSelectCannedResponseMobile}
					senderFirstName={formatFirstName(senderFirstName)}
					isChatBookmarked={chatBookmarked}
					maxCharLimit={validateMaxLength ? FIRST_MESSAGE_CHARACTER_LIMIT : undefined}
					minCharLimit={validateMinLength ? FIRST_MESSAGE_MIN_CHARACTER_LIMIT : undefined}
					onSelectCannedResponse={(cannedResponse) => {
						mixpanelActions.trackCannedResponse({
							eventName: mixPanelEvents.QUICK_REPLY_SELECTED,
							chatUsername: selectedChatUsername,
							labelName: cannedResponse.name,
							screen: 'chat',
							type: 'list',
						});
					}}
				/>
			)}

			{openBookmarkReadAction && (
				<CannedResponsesHorizontalView
					onSendBtnClick={(text, shouldBookmark) => {
						if (text.trim() === LIKE_REACTION_TEXT || isMobile) {
							onSelectCannedResponseMobile(text, shouldBookmark);
						} else {
							onSelectCannedResponseDesktop(text, shouldBookmark);
						}
					}}
					senderFirstName={formatFirstName(senderFirstName)}
					isChatBookmarked={chatBookmarked}
					maxCharLimit={validateMaxLength ? FIRST_MESSAGE_CHARACTER_LIMIT : undefined}
					minCharLimit={validateMinLength ? FIRST_MESSAGE_MIN_CHARACTER_LIMIT : undefined}
					onSelectCannedResponse={(cannedResponse) => {
						mixpanelActions.trackCannedResponse({
							eventName: mixPanelEvents.QUICK_REPLY_SELECTED,
							chatUsername: selectedChatUsername,
							labelName: cannedResponse.name,
							screen: 'chat',
							type: 'pill',
						});
					}}
					customClassName={classes.cannedResponseHorizontalViewCustomClass}
				/>
			)}

			{openBookmarkAction && (
				<div
					tabIndex={0}
					className={classes.bookmarkActionContainer}
					onClick={(e) => {
						e.stopPropagation();
						handleBookmarkAction();
					}}
				>
					<div className={clsx(classes.checkbox, bookmarkState.bookmark && classes.checkboxActive)}>
						{bookmarkState.bookmark && <TickIconColored size={1.6} />}
					</div>

					<Text variant="p" tiny light>
						{'Bookmark this chat for quick access'}
					</Text>
				</div>
			)}

			<div className={classes.inputMainContainer}>
				<div className={classes.inputContainer}>
					{showSelectCategory && (
						<InboxSelect
							optionsMap={inboxCategoriesMap}
							getOptionDisplayName={(option) => option.name}
							getOptionId={(option) => option.name}
							header={'Choose a message category'}
							onSelectOption={handleInboxSelect}
							selectedOptionId={selectedInboxCategory}
							name={senderFirstName}
						/>
					)}
					<div className={classes.inputBoxWithButton}>
						<div className={classes.textAreaWithPdf}>
							<textarea
								rows={1}
								ref={textAreaRef}
								className={classes.input}
								placeholder={'Type your message here'}
								onChange={handleTextAreaChange}
								onKeyDown={handleInputKeyDown}
								value={textAreaStr}
								autoFocus={!isMobile}
								autoComplete="off"
								onFocus={handleTextAreaFocused}
							/>
							{selectedAttachment && (
								<div className={classes.selectedPdfContainer}>
									{selectedAttachment.type === 'application/pdf' && <PdfFileIcon size={1.2} />}
									{(selectedAttachment.type === 'image/png' ||
										selectedAttachment.type === 'image/jpeg') && <ImageIcon size={1.2} />}
									<Text variant="span" tiny lineHeight={1.6} customClass={classes.pdfName}>
										{selectedAttachment.name}
									</Text>
									<Button
										btnText={<CloseIcon size={1.2} className={classes.closeIcon} />}
										onClick={() => handleAttachmentClose(true)}
										customClass={classes.closePdfBtn}
									/>
								</div>
							)}
						</div>
						{showCannedResponseBtn && (
							<Button
								btnText={<QuickReplyIcon size={2.4} />}
								onClick={handleCannedResponseBtn}
								customClass={classes.cannedResponseBtn}
							/>
						)}
						<>
							<input
								type="file"
								accept="image/png,image/jpeg,.pdf"
								name="upload"
								onChange={handleAttachmentChange}
								ref={attachmentInputRef}
								hidden
								autoComplete="off"
							/>
							<Button
								btnText={<UploadPDFIcon size={2.4} />}
								onClick={handleAttachmentUpload}
								customClass={classes.pdfUploadBtn}
							/>
						</>
					</div>
				</div>
				<Button
					btnText={
						<SendIcon size={2.4} className={clsx(!sendButtonDisabled && classes.sendIconActive)} />
					}
					onClick={handleSendMessage}
					customClass={clsx(classes.sendBtn, sendButtonDisabled && classes.sendBtnDisabled)}
					disabled={sendButtonDisabled}
				/>
			</div>

			{showValidation && (
				<Text
					variant="p"
					secondary={!!validationState.info}
					error={!!validationState.error}
					tiny
					light
					customClass={classes.validationText}
				>
					{validationState.error || validationState.info}
					{textAreaStr.length > 0 && validationState.info && (
						<Text variant="span" tiny secondary>
							{currCharCountWithMaxLimitText}
						</Text>
					)}
				</Text>
			)}

			{showMessageWithFileUploadLoader && (
				<Modal
					showModal={showMessageWithFileUploadLoader}
					customClass={classes.uploadLoaderModalCustomClass}
				>
					<Loader size={4} allWhite />
					<Text variant="p" white small light customClass={classes.sendingText}>
						{isConvertingAttachment && 'Adding attachment'}
						{callSendMessageFormDataStatus === 'loading' && 'Uploading attachment...'}
					</Text>
				</Modal>
			)}
		</div>
	);
}

export default React.memo(ChatsFooter);
